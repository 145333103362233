export default function ErrorMessage({
  className,
  error,
  ...rest
}: {
  className?: string;
  error: string;
  [key: string]: any;
}) {
  return (
    <div {...rest} className={`bg-red-100 rounded-xl py-2.5 px-4 text-red-700 text-xs ${className}`}>
      {`Sorry! It looks like there was an error: ${error}`}
    </div>
  );
}
