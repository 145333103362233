import { createSlice } from "@reduxjs/toolkit";

import { ContingenciesModalProps } from "../../components/feature/ContingenciesModal";
import { GlobalVideoSectionModalProps } from "../../components/feature/GlobalVideoSectionModal";
import { LessonComponentModalProps } from "../../components/feature/LessonComponentModal";
import { LessonModalProps } from "../../components/feature/LessonModal";
import { MatchFailDestinationModalProps } from "../../components/feature/MatchFailDestinationModal";
import { PhraseModalProps } from "../../components/feature/PhraseModal";
import { SectionModalProps } from "../../components/feature/SectionModal";
import { SupplementaryWordModalProps } from "../../components/feature/SupplementaryWordModal";
import { TargetPhraseModalProps } from "../../components/feature/TargetPhraseModal";
import { VideoClipModalProps } from "../../components/feature/VideoClipModal";
import { VocabComponentModalProps } from "../../components/feature/VocabComponentModal";
import { RootState } from "./store";

type ModalProps = null | {
  lessonModal?: LessonModalProps;
  lessonComponentModal?: LessonComponentModalProps;
  matchFailDestinationModal?: MatchFailDestinationModalProps;
  sectionModal?: SectionModalProps;
  supplementaryWordModal?: SupplementaryWordModalProps;
  targetPhraseModal?: TargetPhraseModalProps;
  phraseModal?: PhraseModalProps;
  videoClipModal?: VideoClipModalProps;
  selectGlobalVideoSectionModal?: {};
  globalVideoSectionModal?: GlobalVideoSectionModalProps;
  contingenciesModal?: ContingenciesModalProps;
  uploadVideosModal?: {};
  vocabComponentModal?: VocabComponentModalProps;
  importPhraseCSVModal?: {};
  importPackageCSVModal?: {};
};

const initialState = {
  isOpen: false,
  props: null as ModalProps,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    openModal(
      state,
      {
        payload: props,
      }: {
        payload: ModalProps;
      }
    ) {
      state.isOpen = true;
      state.props = props;
    },
    closeModal(state) {
      state.isOpen = false;
      state.props = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const getModalIsOpen = (state: RootState) => state.modal.isOpen;
export const getModalProps = (state: RootState) => state.modal.props;

export default modalSlice.reducer;
