import { Skill } from ".";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";

export default class SkillTree {
  skillIds: string[];

  constructor(object: any = {}) {
    this.skillIds = object.skillIds ?? [];
  }

  validate(skills: Skill[]) {
    const validation = new Validation({
      type: ValidationType.SkillTree,
      instance: this,
      issues: [],
      childValidations: [],
    });

    for (const id of this.skillIds)
      if (!skills.find((skill) => skill.id === id))
        validation.issues.push(
          new ValidationIssue(
            "Each item in field skillIds must reference a Skill",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            id
          )
        );

    return validation;
  }

  setSkillId(id: string, index: number) {
    this.skillIds[index] = id;
    this.skillIds.splice(index + 1);
  }
}
