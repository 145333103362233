import { DataLessonComponent } from ".";
import { Video, Phrase } from "../../domain/entities";

export default class FullLessonComponent {
  lessonComponent: DataLessonComponent;
  videos: Video[];
  phrases: Phrase[];

  constructor(lessonComponent: DataLessonComponent, videos: Video[], phrases: Phrase[]) {
    this.lessonComponent = lessonComponent;
    this.videos = videos;
    this.phrases = phrases;
  }
}
