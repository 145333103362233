import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Download, Plus, Upload } from "heroicons-react";

import { fetchPhrases, getAllPhrases, getPhrasesError, getPhrasesStatus } from "../core/redux/phrasesSlice";
import { getCurrentLocale } from "../core/redux/localesSlice";
import { sorted, getWorstStatus, getError } from "../service/helpers";
import { openModal } from "../core/redux/modalSlice";
import { getCurrentUser } from "../core/redux/userSlice";
import { Phrase } from "../core/domain/entities";

import { Button, PhraseSearchView, StatusView, PhraseCard, CheckBoxInput } from "../components";
import {
  fetchVocabComponents,
  getAllVocabComponents,
  getVocabComponentsError,
  getVocabComponentsStatus,
} from "../core/redux/vocabComponentsSlice";
import CSVService from "../service/CSVService";

export default function ManagePhrasesPage() {
  // -- Redux
  const dispatch = useDispatch();
  const currentLocale = useSelector(getCurrentLocale);
  const currentUser = useSelector(getCurrentUser);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);
  const vocabComponents = useSelector(getAllVocabComponents);
  const vocabComponentsStatus = useSelector(getVocabComponentsStatus);
  const vocabComponentsError = useSelector(getVocabComponentsError);

  // -- Local state
  const [showImage, setShowImage] = useState(false);

  // -- Effect
  // Fetch phrases
  useEffect(() => {
    if (currentLocale) dispatch(fetchPhrases(currentLocale));
    if (currentLocale) dispatch(fetchVocabComponents(currentLocale));
  }, [dispatch, currentLocale]);

  // -- Actions
  const openCreatePhraseModal = () => {
    dispatch(
      openModal({
        phraseModal: {
          phrase: new Phrase({
            localeId: currentLocale?.id,
            createdBy: currentUser?.attributes.sub,
            updatedBy: currentUser?.attributes.sub,
          }),
          isCreating: true,
        },
      })
    );
  };

  const importFromCSV = () => {
    dispatch(
      openModal({
        importPhraseCSVModal: {},
      })
    );
  };

  const exportToCSV = () => {
    CSVService.instance.downloadCSV(
      phrases.map((p) => p.toCSVEntry()),
      `${currentLocale!.title} Phrases - ${new Date()}.csv`
    );
  };

  // -- Components
  const TopSection = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="text-2xl font-bold">Manage Phrases</div>
        <div className="flex gap-2">
          <CheckBoxInput className="mr-3" message="Show image" value={showImage} set={setShowImage} />
          <Button onClick={importFromCSV} shade={100}>
            <Upload size={16} />
            Import from CSV
          </Button>
          <Button onClick={exportToCSV} shade={100}>
            <Download size={16} />
            Export to CSV
          </Button>
          <Button onClick={openCreatePhraseModal}>
            <Plus size={16} />
            Create Phrase
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 p-5 flex flex-col gap-2">
      <TopSection />
      <div />
      <StatusView
        status={getWorstStatus([phrasesStatus, vocabComponentsStatus])}
        error={getError([phrasesError, vocabComponentsError])}
      >
        <PhraseSearchView phrases={phrases} vocabComponents={vocabComponents} currentLocale={currentLocale!}>
          {(phrases: Phrase[], indicator: any) =>
            !indicator && phrases.length > 0
              ? sorted(phrases, (a, b) => (a.targetText < b.targetText ? -1 : 1)).map((phrase) => (
                  <PhraseCard key={phrase.id} phrase={phrase} showImage={showImage} />
                ))
              : indicator
          }
        </PhraseSearchView>
      </StatusView>
    </div>
  );
}
