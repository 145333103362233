import { useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import axios from "axios";
import { Upload } from "heroicons-react";
import { Auth } from "aws-amplify";

import { getCurrentLocale } from "../../core/redux/localesSlice";
import { getCurrentUser } from "../../core/redux/userSlice";

import { Modal, FormErrorMessage, Button, FileInput, LoadingIndicator } from "../index";

type UploadVideosFormValues = {
  files: any[];
};

export default function UploadVideosModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  // --Refs
  const formRef = useRef<FormikProps<UploadVideosFormValues>>(null);

  // -- Redux
  const currentLocale = useSelector(getCurrentLocale);

  // -- Local state
  const [uploadIndex, setUploadIndex] = useState(0);

  // -- Functions
  const handleError = (formProps: FormikProps<UploadVideosFormValues>, file: any, error: any) => {
    formProps.setErrors({ files: `${file.name} errored with: ${error.message}` });
    reset(formProps);
  };

  const upload = (formProps: FormikProps<UploadVideosFormValues>) => {
    if (formProps.values.files.length > 0) uploadFile(formProps, 0);
  };

  const uploadFile = async (formProps: FormikProps<UploadVideosFormValues>, uploadIndexLocal: number) => {
    const { values, setSubmitting } = formProps;
    const files = values.files;

    setSubmitting(true);
    setUploadIndex(uploadIndexLocal);

    if (uploadIndexLocal === files.length) {
      handleUploadComplete(formProps);
      return;
    }

    const file = files[uploadIndexLocal];

    try {
      const result: any = await axios.post(`${process.env.REACT_APP_BACKEND}/${process.env.REACT_APP_ENV}/upload/url`, {
        user: (await Auth.currentSession()).getIdToken().getJwtToken(),
        fileName: file.name.split(".")[0].trim(),
        fileType: file.name.split(".")[1],
        replace: true,
        locale: currentLocale,
      });

      console.log("RESULT", result);

      if (result.data.url) {
        await axios.put(result.data.url, file);
        uploadFile(formProps, uploadIndexLocal + 1);
      } else {
        throw "No url in result";
      }
    } catch (error) {
      console.error("ERROR", (error as any).message);
      handleError(formProps, file, error);
    }
  };

  const reset = (formProps: FormikProps<UploadVideosFormValues>) => {
    formProps.setFieldValue("files", []);
    formProps.setSubmitting(false);
    setUploadIndex(0);
  };

  const handleUploadComplete = (formProps: FormikProps<UploadVideosFormValues>) => {
    reset(formProps);
    alert("The uploads have finished!");
  };

  const handleFileChange = (e: any) => {
    if (!formRef.current) return;
    const { setFieldValue } = formRef.current;

    let localFiles = [];
    for (let x = 0; x < e.target.files.length; x++) {
      if (e.target.files[x].name !== ".DS_Store") {
        localFiles.push(e.target.files[x]);
      }
    }
    setFieldValue("files", localFiles);
  };

  // -- Components
  const ProgressIndicator = ({ files, isUploading }: { files: any[]; isUploading: boolean }) => {
    if (isUploading)
      return <div className="text-xs">{`${files.length - uploadIndex} of ${files.length} uploads remaining`}</div>;
    return null;
  };

  return (
    <Modal isOpen={isOpen} title="Upload Videos" onClose={onClose}>
      <Formik
        initialValues={{
          files: [],
        }}
        innerRef={formRef}
        onSubmit={() => {}}
      >
        {(formProps) => (
          <Form className="flex flex-col gap-2">
            <FileInput accept="video/*" onChange={handleFileChange} multiple />
            <FormErrorMessage error={formProps.errors.files as string | undefined} />
            <ProgressIndicator files={formProps.values.files} isUploading={formProps.isSubmitting} />
            <div className="h-3" />
            <Button onClick={() => upload(formProps)} disabled={formProps.isSubmitting}>
              {!formProps.isSubmitting ? <Upload size={16} /> : <LoadingIndicator />}
              {!formProps.isSubmitting ? <div>Upload</div> : null}
            </Button>
            <Button shade={100} onClick={onClose} disabled={formProps.isSubmitting}>
              Cancel
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
