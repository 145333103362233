import { v4 as uuidv4 } from "uuid";
import Validation, { ValidationIssue, ValidationIssueCause, ValidationIssueGravity } from "../../../service/Validation";
import Phrase from "./Phrase";
import VocabComponent from "./VocabComponent";

export default class VocabQuestion {
  uuid: string;
  phraseId: string;
  type: VocabQuestionType;
  phraseChoices: string[];

  constructor(arg: VocabQuestionArgument) {
    this.uuid = arg.uuid ?? uuidv4();
    this.phraseId = arg.phraseId;
    this.type = arg.type;
    this.phraseChoices = arg.phraseChoices;
  }

  static singlePhraseQuestionTypes() {
    return [
      VocabQuestionType.show,
      VocabQuestionType.translateText,
      VocabQuestionType.translateAudio,
      VocabQuestionType.speak,
    ];
  }

  toData(): VocabQuestionArgument {
    return {
      uuid: this.uuid,
      phraseId: this.phraseId,
      type: this.type,
      phraseChoices: this.phraseChoices,
    };
  }

  isShow() {
    return this.type == VocabQuestionType.show;
  }

  isSpeak() {
    return this.type == VocabQuestionType.speak;
  }

  isTranslateText() {
    return this.type == VocabQuestionType.translateText;
  }

  isTranslateAudio() {
    return this.type == VocabQuestionType.translateAudio;
  }

  // -- Validate
  validate(phrases: Phrase[]) {
    const validation: Validation = new Validation({
      instance: this,
      issues: [],
      childValidations: [],
    });

    const phrase = phrases.find((e) => e.id == this.phraseId);
    if (!phrase) {
      validation.issues.push(
        new ValidationIssue(
          `Field phraseId must be a valid phrase id: ${this.phraseId}`,
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
      return validation;
    }

    const choicePhrases = [] as Phrase[];
    for (const choice of this.phraseChoices) {
      const phrase = phrases.find((e) => e.id == choice);
      if (!phrase) {
        validation.issues.push(
          new ValidationIssue(
            `Field phraseChoices must have all valid phrase ids: ${choice}`,
            ValidationIssueGravity.error,
            ValidationIssueCause.userInput
          )
        );
        return validation;
      }

      choicePhrases.push(phrase);
    }

    if (this.type == VocabQuestionType.translateText || this.type == VocabQuestionType.translateAudio) {
      if (choicePhrases.length != VocabComponent.NUMBER_OF_WRONG_OPTIONS + 1) {
        validation.issues.push(
          new ValidationIssue(
            "Must have " + VocabComponent.NUMBER_OF_WRONG_OPTIONS + " wrong options and 1 correct answer",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );
      }
    }

    if (choicePhrases.length != new Set(choicePhrases.map((p) => p.id)).size) {
      validation.issues.push(
        new ValidationIssue("Must have unique choices", ValidationIssueGravity.error, ValidationIssueCause.userInput)
      );
    }

    let choicesContainsPhrase = false;
    const localeId = phrase.localeId;
    for (const [index, phraseChoice] of Object.entries(choicePhrases)) {
      if (phraseChoice.id === phrase.id) choicesContainsPhrase = true;
      if (phraseChoice.localeId !== localeId)
        validation.issues.push(
          new ValidationIssue(
            `All choice Phrases must have the same localeId`,
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );

      if (
        choicePhrases.findIndex(
          (p) => p.targetText === phraseChoice.targetText || p.nativeText === phraseChoice.nativeText
        ) != parseInt(index)
      ) {
        validation.issues.push(
          new ValidationIssue(
            `Choices should have unique targetText and nativeText (${phraseChoice.targetText}: ${
              phraseChoice.nativeText
            } - ${choicePhrases[parseInt(index)].targetText}: ${choicePhrases[parseInt(index)].nativeText})`,
            ValidationIssueGravity.warning,
            ValidationIssueCause.userInput
          )
        );
      }
    }

    if (choicePhrases.length > 0 && !choicesContainsPhrase)
      validation.issues.push(
        new ValidationIssue(
          `The phrase must be included in the choices`,
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }
  getIdentifier() {
    return `${this.phraseId + "-" + this.type}`;
  }
  getTypeName() {
    return "VocabQuestion";
  }
}

export type VocabQuestionArgument = {
  uuid?: string;
  phraseId: string;
  type: VocabQuestionType;
  phraseChoices: string[];
};

export enum VocabQuestionType {
  show = 0,
  translateText,
  translateAudio,
  speak,
  matching,
}
