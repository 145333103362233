import { useDispatch, useSelector } from "react-redux";
import { closeModal, getModalIsOpen, getModalProps } from "../../core/redux/modalSlice";

import {
  ContingenciesModal,
  GlobalVideoSectionModal,
  ImportPhraseCSVModal,
  LessonComponentModal,
  LessonModal,
  MatchFailDestinationModal,
  PhraseModal,
  SectionModal,
  SelectGlobalVideoSectionModal,
  SupplementaryWordModal,
  TargetPhraseModal,
  UploadVideosModal,
  VideoClipModal,
  VocabComponentModal,
} from "../index";
import ImportPackageCSVModal from "./ImportPackageCSVModal";

export default function RootModal() {
  // -- Redux
  const dispatch = useDispatch();
  const isOpen = useSelector(getModalIsOpen);
  const props = useSelector(getModalProps);

  const onClose = () => {
    dispatch(closeModal());
  };

  if (props) {
    if (props.lessonModal) return <LessonModal isOpen={isOpen} {...props.lessonModal} onClose={onClose} />;
    if (props.lessonComponentModal)
      return <LessonComponentModal isOpen={isOpen} {...props.lessonComponentModal} onClose={onClose} />;
    if (props.matchFailDestinationModal)
      return <MatchFailDestinationModal isOpen={isOpen} {...props.matchFailDestinationModal} onClose={onClose} />;
    if (props.sectionModal) return <SectionModal isOpen={isOpen} {...props.sectionModal} onClose={onClose} />;
    if (props.supplementaryWordModal)
      return <SupplementaryWordModal isOpen={isOpen} {...props.supplementaryWordModal} onClose={onClose} />;
    if (props.targetPhraseModal)
      return <TargetPhraseModal isOpen={isOpen} {...props.targetPhraseModal} onClose={onClose} />;
    if (props.phraseModal) return <PhraseModal isOpen={isOpen} {...props.phraseModal} onClose={onClose} />;
    if (props.videoClipModal) return <VideoClipModal isOpen={isOpen} {...props.videoClipModal} onClose={onClose} />;
    if (props.selectGlobalVideoSectionModal) return <SelectGlobalVideoSectionModal isOpen={isOpen} onClose={onClose} />;
    if (props.globalVideoSectionModal)
      return <GlobalVideoSectionModal isOpen={isOpen} {...props.globalVideoSectionModal} onClose={onClose} />;
    if (props.contingenciesModal)
      return <ContingenciesModal isOpen={isOpen} {...props.contingenciesModal} onClose={onClose} />;
    if (props.uploadVideosModal) return <UploadVideosModal isOpen={isOpen} onClose={onClose} />;
    if (props.vocabComponentModal)
      return <VocabComponentModal isOpen={isOpen} {...props.vocabComponentModal} onClose={onClose} />;
    if (props.importPhraseCSVModal) return <ImportPhraseCSVModal isOpen={isOpen} onClose={onClose} />;
    if (props.importPackageCSVModal) return <ImportPackageCSVModal isOpen={isOpen} onClose={onClose} />;
  }

  return null;
}
