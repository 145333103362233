import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { remoteDataSource } from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { DeepLink, Locale } from "../domain/entities";
import { RootState } from "./store";
import { StatusType } from "./types";

export const fetchDeepLinks = createAsyncThunk(`deepLinks/fetch`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.deepLinks.items.length > 0 && state.deepLinks.items[0].localeId !== locale.id;
  if (state.deepLinks.items.length > 0 && (!localeDidChange || state.deepLinks.status !== StatusType.loading))
    return state.deepLinks.items;

  const result = await remoteDataSource.allDeepLinks(locale);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const generateDeepLink = createAsyncThunk(
  `deepLinks/generate`,
  async (
    {
      lessonType,
      componentId,
      lessonId,
      title,
      description,
      type,
      imageUrl,
    }: {
      lessonType: "vocab" | "video";
      componentId: string;
      lessonId?: string;
      title: string;
      description: string;
      type: string;
      imageUrl?: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const localeId = state.locales.current!.id;

    const longLink = `https://meetkleo.com/downloads?id=${componentId}&type=${lessonType}&locale=${localeId}${
      lessonId ? `&lessonId=${lessonId}` : ""
    }`;
    const response = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
      {
        dynamicLinkInfo: {
          domainUriPrefix: "https://meetkleo.page.link",
          link: longLink,
          androidInfo: {
            androidPackageName: "com.wesayhi.app",
          },
          iosInfo: {
            iosBundleId: "com.wesayhi.mobile",
            iosAppStoreId: "1535893500",
          },
          socialMetaTagInfo: {
            socialTitle: title,
            socialDescription: description,
            socialImageLink: imageUrl,
          },
          navigationInfo: {
            enableForcedRedirect: true,
          },
        },
      }
    );
    const shortLink = response.data.shortLink;

    const result = await remoteDataSource.createDeepLink(
      new DeepLink({
        shortLink: shortLink,
        componentId: componentId,
        componentType: type,
        title: title,
        description: description,
        imageUrl: imageUrl,
        localeId: localeId,
      })
    );

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

const initialState = {
  items: [] as DeepLink[],
  status: StatusType.idle,
  error: null as string | undefined | null,
};

export const deepLinksSlice = createSlice({
  name: "deepLinks",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeepLinks.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchDeepLinks.fulfilled, (state, { payload: deepLinks }: { payload: DeepLink[] }) => {
      state.status = StatusType.succeeded;
      state.items = deepLinks;
    });
    builder.addCase(fetchDeepLinks.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(generateDeepLink.fulfilled, (state, { payload: deepLink }: { payload: DeepLink }) => {
      state.items = [deepLink, ...state.items];
    });
    builder.addCase(generateDeepLink.rejected, () => {
      alert("Error! DeepLink was not generated. Cheeck logs for more info");
    });
  },
});

export const getAllDeepLinks = (state: RootState) => state.deepLinks.items;
export const getDeepLinksStatus = (state: RootState) => state.deepLinks.status;
export const getDeepLinksError = (state: RootState) => state.deepLinks.error;

export default deepLinksSlice.reducer;
