export default function FileInput({
  className,
  title,
  accept,
  onChange,
  ...rest
}: {
  className?: string;
  title?: string;
  accept: string;
  onChange: (e: any) => void;
  [key: string]: any;
}) {
  return (
    <div className={`flex flex-col gap-1 text-xs font-medium ${className}`}>
      {title}
      <input
        {...rest}
        className={`text-xs p-5 bg-gray-100 rounded-lg`}
        type="file"
        accept={accept}
        onChange={onChange}
      />
    </div>
  );
}
