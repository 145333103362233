import RemoteDataSource from "./RemoteDataSource";
import GlobalVideoSectionRepository from "../domain/GlobalVideoSectionRepository";
import { GlobalVideoSection, Locale } from "../domain/entities";
import Outcome from "../../service/api/Outcome";

export default class GlobalVideoSectionRepositoryImplementation extends GlobalVideoSectionRepository {
  remoteDataSource: RemoteDataSource;

  constructor(remoteDataSource: RemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async allGlobalVideoSections(locale: Locale): Promise<Outcome<GlobalVideoSection[]>> {
    return this.remoteDataSource.allGlobalVideoSections(locale);
  }

  async createGlobalVideoSection(globalVideoSection: GlobalVideoSection): Promise<Outcome<GlobalVideoSection>> {
    return this.remoteDataSource.createGlobalVideoSection(globalVideoSection);
  }

  async updateGlobalVideoSection(globalVideoSection: GlobalVideoSection): Promise<Outcome<GlobalVideoSection>> {
    return this.remoteDataSource.updateGlobalVideoSection(globalVideoSection);
  }

  async deleteGlobalVideoSection(globalVideoSection: GlobalVideoSection): Promise<Outcome<GlobalVideoSection>> {
    return this.remoteDataSource.deleteGlobalVideoSection(globalVideoSection);
  }
}
