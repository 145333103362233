import { FormikProps } from "formik";
import { LocationMarker, XCircle } from "heroicons-react";
import _ from "lodash";

import { DisplayItemType } from "../../core/domain/entities/types";
import { VideoClipFormValues } from "./VideoClipModal";

import { Input, Button, SelectMenu, ExpandableCard } from "..";

export default function DisplayItemCard({
  index,
  player,
  handleDelete,
  formProps,
}: {
  index: number;
  player: any;
  handleDelete: () => void;
  formProps: FormikProps<VideoClipFormValues>;
}) {
  // -- Constants
  const { values, setFieldValue } = formProps;
  const {
    type,
    primaryText,
    secondaryText,
    primaryDisplayTime,
    secondaryDisplayTime,
    primaryEndTime,
    secondaryEndTime,
  } = values.displayItems[index];

  // -- Functions
  const setType = (value: DisplayItemType) => {
    setFieldValue(`displayItems[${index}].type`, value);
  };
  const setPrimaryText = (value: string) => {
    setFieldValue(`displayItems[${index}].primaryText`, value);
  };
  const setSecondaryText = (value: string) => {
    setFieldValue(`displayItems[${index}].secondaryText`, value);
  };
  const setPrimaryDisplayTime = (value: number) => {
    setFieldValue(`displayItems[${index}].primaryDisplayTime`, value ? value : null);
  };
  const setSecondaryDisplayTime = (value: number) => {
    setFieldValue(`displayItems[${index}].secondaryDisplayTime`, value ? value : null);
  };
  const setPrimaryEndTime = (value: number) => {
    setFieldValue(`displayItems[${index}].primaryEndTime`, value ? value : null);
  };
  const setSecondaryEndTime = (value: number) => {
    setFieldValue(`displayItems[${index}].secondaryEndTime`, value ? value : null);
  };

  const markPrimaryDisplayTime = () => {
    if (!player.current) return;
    setFieldValue(`displayItems[${index}].primaryDisplayTime`, player.current.currentTime);
  };
  const markSecondaryDisplayTime = () => {
    if (!player.current) return;
    setFieldValue(`displayItems[${index}].secondaryDisplayTime`, player.current.currentTime);
  };
  const markPrimaryEndTime = () => {
    if (!player.current) return;
    setFieldValue(`displayItems[${index}].primaryEndTime`, player.current.currentTime);
  };
  const markSecondaryEndTime = () => {
    if (!player.current) return;
    setFieldValue(`displayItems[${index}].secondaryEndTime`, player.current.currentTime);
  };

  // -- Components
  const ExpandedView = () => {
    const TextInputSet = () => {
      return (
        <div className="flex flex-col gap-2">
          <Input title="Primary (Target)" value={primaryText ?? ""} set={setPrimaryText} />
          <Input title="Secondary (Native)" value={secondaryText ?? ""} set={setSecondaryText} />
        </div>
      );
    };

    const TimeInputSet = ({
      primarySet,
      secondarySet,
      primaryMark,
      secondaryMark,
      primaryValue,
      secondaryValue,
      text,
    }: {
      primarySet: (value: number) => void;
      secondarySet: (value: number) => void;
      primaryMark: () => void;
      secondaryMark: () => void;
      primaryValue?: number;
      secondaryValue?: number;
      text: string;
    }) => {
      return (
        <div className="flex flex-col gap-2 justify-center">
          <Input
            inputClassName="w-20"
            type="number"
            title={text}
            value={primaryValue?.toString() ?? ""}
            set={primarySet}
            sideComponent={
              <Button shade={100} onClick={primaryMark}>
                <LocationMarker size={16} />
              </Button>
            }
          />
          <div
            className="absolute self-end bg-indigo-600 w-5 h-5 mt-5 mr-2 rounded-md cursor-pointer hover:bg-indigo-700 text-white flex items-center justify-center"
            onClick={() => {
              primaryMark();
              secondaryMark();
            }}
          >
            <LocationMarker size={8} />
          </div>
          <Input
            inputClassName="w-20"
            type="number"
            title={text}
            value={secondaryValue?.toString() ?? ""}
            set={secondarySet}
            sideComponent={
              <Button shade={100} onClick={secondaryMark}>
                <LocationMarker size={16} />
              </Button>
            }
          />
        </div>
      );
    };

    return (
      <div className="flex flex-col gap-2">
        <SelectMenu title="Type" value={type} set={setType}>
          {Object.values(DisplayItemType)
            .filter((type) => typeof type === "string")
            .map((type) => (
              <option key={type}>{type}</option>
            ))}
        </SelectMenu>
        <div className="flex gap-2">
          {TextInputSet()}
          {TimeInputSet({
            primarySet: setPrimaryDisplayTime,
            secondarySet: setSecondaryDisplayTime,
            primaryMark: markPrimaryDisplayTime,
            secondaryMark: markSecondaryDisplayTime,
            primaryValue: primaryDisplayTime ?? undefined,
            secondaryValue: secondaryDisplayTime ?? undefined,
            text: "Start",
          })}
          {TimeInputSet({
            primarySet: setPrimaryEndTime,
            secondarySet: setSecondaryEndTime,
            primaryMark: markPrimaryEndTime,
            secondaryMark: markSecondaryEndTime,
            primaryValue: primaryEndTime ?? undefined,
            secondaryValue: secondaryEndTime ?? undefined,
            text: "End",
          })}
        </div>
      </div>
    );
  };

  return (
    <ExpandableCard expandedView={ExpandedView} initialIsExpanded={true}>
      <div className="flex gap-2 justify-between items-center">
        <div>
          <div className="text-xs font-medium">{primaryText}</div>
          <div className="text-xs">{secondaryText}</div>
        </div>
        <Button color="red" shade={100} onClick={handleDelete}>
          <XCircle size={16} />
        </Button>
      </div>
    </ExpandableCard>
  );
}
