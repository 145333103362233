export const prod = {
  aws_project_region: "us-east-2",
  aws_appsync_graphqlEndpoint: "https://wwxb3w4affbbpgd2ezdpl3hdqq.appsync-api.us-east-2.amazonaws.com/graphql",
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-3miyoqrj3jbvnfqmsddakblrhe",
  aws_cognito_identity_pool_id: "us-east-2:2eb78c63-79bf-4962-82ce-e5dedf07f69e",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_RYqVH4DFc",
  aws_user_pools_web_client_id: "58r8nchbfeusbg8phushdbmqfi",
  oauth: {
    domain: "kleobcffc406-bcffc406-prod.auth.us-east-2.amazoncognito.com",
    scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "myapp://",
    redirectSignOut: "myapp://",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export const dev = {
  aws_project_region: "us-east-2",
  aws_appsync_graphqlEndpoint: "https://u6dk7cql2zbr3gghum3mjdotqe.appsync-api.us-east-2.amazonaws.com/graphql",
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-5q5dfxkgdrdwrg2oe4bi2oyitu",
  aws_cognito_identity_pool_id: "us-east-2:241d8b77-2beb-483f-900f-3656b797fe77",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_jfjbRrztH",
  aws_user_pools_web_client_id: "4vv2k936059ovjur98scgl88lb",
  oauth: {
    domain: "kleo6401b58c-6401b58c-dev.auth.us-east-2.amazoncognito.com",
    scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "myapp://",
    redirectSignOut: "myapp://",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export const staging = {
  aws_project_region: "us-east-2",
  aws_appsync_graphqlEndpoint: "",
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-3miyoqrj3jbvnfqmsddakblrhe",
  aws_cognito_identity_pool_id: "us-east-2:2eb78c63-79bf-4962-82ce-e5dedf07f69e",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_RYqVH4DFc",
  aws_user_pools_web_client_id: "58r8nchbfeusbg8phushdbmqfi",
  oauth: {
    domain: "kleobcffc406-bcffc406-prod.auth.us-east-2.amazoncognito.com",
    scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "myapp://",
    redirectSignOut: "myapp://",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

const getExports = () => {
  switch (process.env.REACT_APP_ENV) {
    case "prod":
      return prod;
    case "dev":
      return dev;
    case "staging":
      return staging;
    default:
      return dev;
  }
};

const awsConfig = getExports();

export default awsConfig;
