import aws from "aws-sdk";

const docClient = new aws.DynamoDB.DocumentClient({
  region: "us-east-2",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

export async function updateItem(table, key, item) {
  return await docClient.put({ TableName: table, Item: item }).promise();
  // delete item.id;
  // const itemKeys = Object.keys(item);
  // const params = {
  //   TableName: table,
  //   Key: { id: key },
  //   ReturnValues: "ALL_NEW",
  //   UpdateExpression: `SET ${itemKeys.map((k, index) => `#field${index} = :value${index}`).join(", ")}`,
  //   ExpressionAttributeNames: itemKeys.reduce(
  //     (accumulator, k, index) => ({ ...accumulator, [`#field${index}`]: k }),
  //     {}
  //   ),
  //   ExpressionAttributeValues: itemKeys.reduce(
  //     (accumulator, k, index) => ({ ...accumulator, [`:value${index}`]: item[k] }),
  //     {}
  //   ),
  // };
  // return await docClient.update(params).promise();
}

export async function createItem(table, item) {
  return await docClient.put({ TableName: table, Item: item }).promise();
}

export async function deleteItem(table, id) {
  await await docClient.delete({ TableName: table, Key: { id: id } }).promise();
}
