export default function DropdownItem({
  className,
  color,
  shade = 600,
  children,
  ...rest
}: {
  className?: string;
  color?: string;
  shade?: number;
  children?: any;
  [key: string]: any;
}) {
  const textColor = color ?? "gray";
  const highlightColor = color ?? "indigo";

  return (
    <div
      {...rest}
      className={`w-full p-2 rounded-md text-${textColor}-${shade} text-xs flex gap-2 hover:bg-${highlightColor}-50 cursor-pointer ${className}`}
    >
      {children}
    </div>
  );
}
