import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import { GlobalVideoSection } from "../../core/domain/entities";
import { createGlobalVideoSection, updateGlobalVideoSection } from "../../core/redux/globalVideoSectionsSlice";
import { updateObject } from "../../service/helpers";

import { CheckBoxInput, FormErrorMessage, Input, Modal, ModalFormButtons } from "../index";

export type GlobalVideoSectionModalProps = {
  globalVideoSection: GlobalVideoSection;
  isCreating?: boolean;
};

export default function GlobalVideoSectionModal({
  isOpen,
  globalVideoSection,
  isCreating,
  onClose,
}: {
  isOpen: boolean;
  globalVideoSection: GlobalVideoSection;
  isCreating?: boolean;
  onClose: () => void;
}) {
  // -- Redux
  const dispatch = useDispatch();

  // -- Functions
  const onSubmit = (values: { title: string; isGeneric: boolean; isDojo: boolean; lessonPrefix?: string }) => {
    isCreating
      ? dispatch(createGlobalVideoSection(updateObject(globalVideoSection, values)))
      : dispatch(updateGlobalVideoSection(updateObject(globalVideoSection, values)));
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={isCreating ? "Create Global Video Setion" : globalVideoSection.title}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          title: globalVideoSection.title,
          isGeneric: globalVideoSection.isGeneric,
          isDojo: globalVideoSection.isDojo,
          lessonPrefix: globalVideoSection.lessonPrefix,
        }}
        validate={(values) => {
          const errors: { title?: string; type?: string } = {};
          if (!values.title) errors.title = "Cannot be blank";
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange }) => (
          <Form className="flex flex-col gap-2">
            <div>
              <Input
                autoFocus
                title="Title"
                placeholder="Introduction: Quiero"
                name="title"
                value={values.title}
                onChange={handleChange}
              />
              <FormErrorMessage error={errors.title} />
            </div>
            <Input
              title="Lesson Prefix"
              placeholder="P1"
              name="lessonPrefix"
              value={values.lessonPrefix ?? ""}
              onChange={handleChange}
            />
            <CheckBoxInput
              className="pt-2"
              value={values.isGeneric}
              message="isGeneric"
              name="isGeneric"
              onChange={handleChange}
            />
            <CheckBoxInput
              className="pt-2"
              value={values.isDojo}
              message="isDojo"
              name="isDojo"
              onChange={handleChange}
            />
            <div className="h-5" />
            <ModalFormButtons submitText={isCreating ? "Create" : "Done"} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
