import RemoteDataSource from "./RemoteDataSource";
import SkillRepository from "../domain/SkillRepository";
import { Skill, Locale } from "../domain/entities";
import Outcome from "../../service/api/Outcome";

export default class SkillRepositoryImplementation extends SkillRepository {
  remoteDataSource: RemoteDataSource;

  constructor(remoteDataSource: RemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async allSkills(locale: Locale): Promise<Outcome<Skill[]>> {
    return this.remoteDataSource.allSkills(locale);
  }
}
