import Papa from "papaparse";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalVideoSection, Phrase } from "../../core/domain/entities";
import {
  createGlobalVideoSection,
  getAllGlobalVideoSections,
  getGlobalVideoSectionsError,
  getGlobalVideoSectionsStatus,
} from "../../core/redux/globalVideoSectionsSlice";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import {
  createPhrase,
  getAllPhrases,
  getPhrasesError,
  getPhrasesStatus,
  updatePhrase,
} from "../../core/redux/phrasesSlice";
import { dupLinkingRules } from "../../service/constants";
import CSVService from "../../service/CSVService";
import { getError, getWorstStatus } from "../../service/helpers";
import StatusView from "../base/StatusView";
import { FileInput, LoadingIndicator, Modal, ModalFormButtons } from "../index";

export default function ImportPhraseCSVModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  // -- Redux
  const dispatch = useDispatch();
  const currentLocale = useSelector(getCurrentLocale);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);
  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSectionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const globalVideoSectionsError = useSelector(getGlobalVideoSectionsError);

  // -- Local state
  const [file, setFile] = useState<any>();
  const [isProcessing, setIsProcessing] = useState(false);

  // -- Functions
  const onSubmit = async () => {
    if (!file) return onClose();

    setIsProcessing(true);

    const dupRulesUrl: string | undefined = dupLinkingRules[currentLocale!.targetLanguageCode as "ES" | "IT" | "DE"];
    const dupRulesConfig = {
      targetText: "phrase",
      links: [
        { nativeContains: "nativecontains1", linkToNative: "linkto1" },
        { nativeContains: "nativecontains2", linkToNative: "linkto2" },
        { nativeContains: "nativecontains3", linkToNative: "linkto3" },
        { nativeContains: "nativecontains4", linkToNative: "linkto4" },
        { nativeContains: "nativecontains5", linkToNative: "linkto5" },
      ],
    };
    const dupRulesData = dupRulesUrl ? await CSVService.instance.readFromUrl(dupRulesUrl) : undefined;
    const dupRules = [] as any[];
    if (dupRulesData) {
      for (const entry of dupRulesData) {
        const links = [];
        for (const link of dupRulesConfig.links) {
          if (entry[link.nativeContains] && entry[link.linkToNative]) {
            links.push({
              nativeContains: entry[link.nativeContains],
              linkToNative: entry[link.linkToNative],
            });
          }
        }
        dupRules.push({
          targetText: entry[dupRulesConfig.targetText],
          links: links,
        });
      }
    }

    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (!target || !target.result) return;
      const data = Papa.parse(target.result as any, { header: true }).data;

      const allErrors = [];
      const allWarnings = [];
      const updated = [];

      const allPhrases = [...phrases];
      for (const entry of data) {
        const { phrase, errors, warnings, isUpdated, isCreated, generateGlobalVideoSection } = Phrase.fromCSVEntry(
          entry,
          currentLocale!.id,
          allPhrases
        );
        allErrors.push(...errors);
        allWarnings.push(...warnings);

        if (isUpdated || isCreated) updated.push({ phrase, isUpdated, isCreated, generateGlobalVideoSection });
        if (isCreated) {
          allPhrases.push(phrase);
        } else if (isUpdated) {
          allPhrases.splice(
            allPhrases.findIndex((p) => p.id === phrase.id),
            1,
            phrase
          );
        }
      }

      const updatedPhrases = updated.map((entry) => entry.phrase);
      for (const phrase of updatedPhrases) {
        if (!phrase.isHidden) {
          const { errors, warnings } = phrase.linkWords([...updatedPhrases, ...phrases], dupRules);
          allErrors.push(...errors);
          allWarnings.push(...warnings);
        }
      }

      console.log("Errors", allErrors);
      console.log("Warnings", allWarnings);
      CSVService.instance.downloadCSV(
        [
          ...allErrors.map((error) => {
            return {
              Error: error,
            };
          }),
          ...allWarnings.map((warning) => {
            return {
              Warning: warning,
            };
          }),
        ],
        `CSV Phrase Errors ${new Date()}.csv`
      );

      setIsProcessing(false);
      onClose();

      if (allErrors.length > 0)
        return alert("There were errors that prevented the phrase import. Check the downloaded file the details");

      if (
        allWarnings.length > 0 &&
        !window.confirm(
          "There were warnings that prevented the phrase import. The phrases can still be created/updated but there are some things to look at. Click OK if you still want to continue with the creating/updating"
        )
      )
        return;

      for (const { phrase, isUpdated, isCreated, generateGlobalVideoSection } of updated) {
        if (isUpdated) {
          console.log("Update", phrase);
          dispatch(updatePhrase(phrase));
        } else if (isCreated) {
          console.log("Create", phrase);
          dispatch(createPhrase(phrase));
          if (generateGlobalVideoSection) {
            const createdGVS = GlobalVideoSection.generateFromPhrases(
              [phrase],
              phrase.nativeText,
              globalVideoSections,
              [phrase]
            );
            dispatch(createGlobalVideoSection(createdGVS));
          }
        }
      }
    };
    reader.readAsText(file);
  };

  const onFileChange = (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
  };

  return (
    <Modal isOpen={isOpen} title="Import Phrase CSV" onClose={onClose}>
      <StatusView
        status={getWorstStatus([phrasesStatus, globalVideoSectionsStatus])}
        error={getError([phrasesError, globalVideoSectionsError])}
      >
        <div className="flex flex-col gap-5 ">
          <FileInput title="CSV File" onChange={(e) => onFileChange(e)} accept=".csv" />
          {isProcessing ? <LoadingIndicator className="self-center" /> : null}
          <ModalFormButtons onSubmit={onSubmit} onClose={onClose} />
        </div>
      </StatusView>
    </Modal>
  );
}
