import { Locale, Skill } from "./entities";
import Outcome from "../../service/api/Outcome";
import SkillRepository from "./SkillRepository";

export class UseCaseGetSkills {
  repository: SkillRepository;
  constructor(repository: SkillRepository) {
    this.repository = repository;
  }

  async execute(locale: Locale): Promise<Outcome<Skill[]>> {
    return this.repository.allSkills(locale);
  }
}
