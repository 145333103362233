import { Form, Formik, FormikProps } from "formik";
import { CubeTransparent, DocumentDuplicate, Link, Photograph, Plus, Refresh, XCircle } from "heroicons-react";
import { useEffect, useRef, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch, useSelector } from "react-redux";

import { AcceptedText, GlobalVideoSection, Phrase, SkillTree } from "../../core/domain/entities";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import {
  createPhrase,
  getAllPhrases,
  getPhrasesError,
  getPhrasesStatus,
  updatePhrase,
} from "../../core/redux/phrasesSlice";
import { getCurrentUser } from "../../core/redux/userSlice";
import {
  copyToClipboard,
  kleoWords,
  phraseByWordSearchFilter,
  phraseExactSearchFilter,
  phraseSearchFilter,
  SearchType,
  spaceStripped,
  standardString,
  stripSpecialCharacters,
  updateObject,
  updateObjectInList,
} from "../../service/helpers";
import { ButtonType } from "../base/Button";

import {
  createGlobalVideoSection,
  fetchGlobalVideoSections,
  getAllGlobalVideoSections,
  getGlobalVideoSectionsError,
  getGlobalVideoSectionsStatus,
} from "../../core/redux/globalVideoSectionsSlice";
import { environments } from "../../service/constants";
import {
  Button,
  Card,
  CheckBoxInput,
  Dropdown,
  FormErrorMessage,
  Input,
  LoadingIndicator,
  Modal,
  ModalFormButtons,
  SearchView,
  SelectPhraseImage,
  SkillsInput,
  StatusView,
} from "../index";
import DistributionModal from "./DistributionModal";

export type PhraseModalProps = {
  isCreating?: boolean;
  phrase: Phrase;
};

export type PhraseFormValues = {
  targetText: string;
  nativeText: string;
  requiredExtraWordCount: number;
  acceptedTexts: AcceptedText[];
  skillTrees: SkillTree[];
  isHidden: boolean;
  isWord: boolean;
  isChecked: boolean;
  isDojo: boolean;
  imageUrl?: string;
  audioUrl?: string;
  intermediateTimeout: number;
  phonetic?: string;
};

export default function PhraseModal({
  isOpen,
  isCreating,
  phrase: rootPhrase,
  onClose,
}: {
  isOpen: boolean;
  isCreating?: boolean;
  phrase: Phrase;
  onClose: () => void;
}) {
  // -- Refs
  const formRef = useRef<FormikProps<PhraseFormValues>>(null);

  // -- Redux
  const dispatch = useDispatch();
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);
  const currentLocale = useSelector(getCurrentLocale);
  const currentUser = useSelector(getCurrentUser);
  const allPhrases = useSelector(getAllPhrases);
  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSectionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const globalVideoSectionsError = useSelector(getGlobalVideoSectionsError);

  // -- Local State
  const tempCreatingPhrases: Phrase[] = [];
  const [creatingPhrases, setCreatingPhrases] = useState<Phrase[]>([]);
  const getPhrases = (rootPhrase: Phrase): Phrase[] => {
    return [
      rootPhrase,
      ...rootPhrase.wordIds
        .map(
          (id) =>
            allPhrases.find((p) => p.id === id) ??
            creatingPhrases.find((p) => p.id === id) ??
            tempCreatingPhrases.find((p) => p.id === id) ??
            new Phrase()
        )
        .filter((phrase) => !!phrase.localeId),
    ];
  };

  const [phrases, setPhrases] = useState(getPhrases(rootPhrase));
  const [selectedPhraseId, setSelectedPhraseId] = useState(rootPhrase.id);
  const [excludedSkillWordIds, setExcludedSkillWordIds] = useState(rootPhrase.excludedSkillWordIds);
  const [distributionModalIsOpen, setDistributionModalIsOpen] = useState(false);

  // -- Effect
  useEffect(() => {
    if (!rootPhrase) onClose();
  }, [rootPhrase]);

  useEffect(() => {
    if (currentLocale) dispatch(fetchGlobalVideoSections(currentLocale));
  }, [dispatch, currentLocale]);

  // -- Functions
  const onSubmit = (values: PhraseFormValues) => {
    // Get rid of trailing skill emprty strings
    for (const skillTree of values.skillTrees) {
      skillTree.skillIds = skillTree.skillIds.filter((id) => !!id);
    }

    // Update the currently selected phrase
    const updatedPhrases: Phrase[] = updateObjectInList(
      phrases.filter((phrase, i) => phrases.findIndex((p) => p.id === phrase.id) === i),
      updateObject(getSelectedPhrase(), values)
    );

    // Create or update the root phrase and it's linked phrases
    for (const phrase of updatedPhrases) {
      if (phrase.id === rootPhrase.id) phrase.excludedSkillWordIds = excludedSkillWordIds;

      const existingPhrase = allPhrases.find((p) => p.id === phrase.id);
      !existingPhrase ? dispatch(createPhrase(phrase)) : dispatch(updatePhrase(phrase));
    }

    onClose();
  };

  const getInitialValues = (phrase: Phrase) => {
    return {
      targetText: phrase.targetText,
      nativeText: phrase.nativeText,
      requiredExtraWordCount: phrase.requiredExtraWordCount,
      acceptedTexts: phrase.acceptedTexts,
      skillTrees: phrase.skillTrees,
      isHidden: phrase.isHidden,
      isWord: phrase.isWord,
      isChecked: phrase.isChecked,
      isDojo: phrase.isDojo,
      imageUrl: phrase.imageUrl,
      audioUrl: phrase.audioUrl,
      intermediateTimeout: phrase.intermediateTimeout,
      phonetic: phrase.phonetic,
    };
  };

  const getSelectedPhrase = () => {
    return (
      phrases.find((phrase) => phrase.id === selectedPhraseId) ??
      creatingPhrases.find((p) => p.id === selectedPhraseId) ??
      new Phrase()
    );
  };

  const getRootPhrase = () => {
    return phrases.find((phrase) => phrase.id === rootPhrase.id) ?? new Phrase();
  };

  const selectPhrase = async (phrase: Phrase) => {
    if (!formRef.current) return;

    const validation = await formRef.current.validateForm(formRef.current.values);
    if (Object.values(validation).length === 0) {
      // Update currently selected phrase so we don't lose local changes on switch
      const updatedPhrase = updateObject(getSelectedPhrase(), formRef.current.values);
      update(updatedPhrase);

      // Select new phrase and reset form values
      formRef.current.setValues(getInitialValues(phrase));
      setSelectedPhraseId(phrase.id);
    }
  };

  const update = (input: Phrase) => {
    if (!formRef.current) return;

    const updatedPhrase = input.id === selectedPhraseId ? updateObject(input, formRef.current.values) : input;

    // Update phrase if root phrase has changed
    setPhrases((prev) =>
      updateObjectInList(updatedPhrase.id === rootPhrase.id ? getPhrases(updatedPhrase) : prev, updatedPhrase)
    );
    setCreatingPhrases((prev) => updateObjectInList(prev, updatedPhrase));

    // Select root phrase if needed
    if (updatedPhrase.id !== rootPhrase.id && !phrases.find((phrase) => phrase.id === updatedPhrase.id))
      setSelectedPhraseId(rootPhrase.id);
  };

  const replaceLinkedWord = (id: string, prevId: string) => {
    const rootPhrase = getRootPhrase();
    rootPhrase.replaceLinkedWord(id, prevId);
    update(rootPhrase);
  };

  const linkWord = (id: string) => {
    const rootPhrase = getRootPhrase();
    if (rootPhrase.wordIds.includes(id)) {
      alert(`Phrase with id ${id} is already linked to this phrase`);
      return;
    }
    rootPhrase.linkWord(id);
    update(rootPhrase);
  };

  const unlinkWord = (id: string) => {
    const rootPhrase = getRootPhrase();
    rootPhrase.unlinkWord(id);
    update(rootPhrase);
  };

  const handleExcludedSkillWordIdsToggle = (value: boolean) => {
    const wordId = selectedPhraseId;
    setExcludedSkillWordIds((prev) => (value ? [...prev, wordId] : prev.filter((id) => id !== wordId)));
  };

  const linkWords = () => {
    const targetText = formRef.current?.values.targetText;
    if (!targetText) return;
    const words = kleoWords(targetText);
    const wordPhrases = words.map((word) =>
      allPhrases.find((p) => standardString(p.targetText) === standardString(word))
    );
    for (const phrase of wordPhrases) {
      if (!phrase) continue;
      linkWord(phrase.id);
    }
  };

  const openDistributionModal = () => {
    setDistributionModalIsOpen(true);
  };

  const closeDistributionModal = () => {
    setDistributionModalIsOpen(false);
  };

  // -- Components
  const TitleSection = () => {
    const selectedPhrase = getSelectedPhrase();

    return (
      <div className="flex-1 flex flex-col mb-2">
        <div className="text-2xl font-bold">{selectedPhrase.targetText}</div>
        <div className="text-sm">{selectedPhrase.nativeText}</div>
        <div className="text-xs text-gray-400">{selectedPhrase.id}</div>
      </div>
    );
  };

  const GenerateGlobalVideoSectionView = ({ formProps }: { formProps: FormikProps<PhraseFormValues> }) => {
    function generateGlobalVideoSection() {
      const phrase = updateObject(getSelectedPhrase(), formProps.values);
      const createdGVS = GlobalVideoSection.generateFromPhrases([phrase], phrase.nativeText, globalVideoSections, [
        phrase,
      ]);
      dispatch(createGlobalVideoSection(createdGVS));
    }

    const usedInSections = globalVideoSections.filter((gvs) => gvs.getAllPrimaryPhraseIds().includes(selectedPhraseId));
    return (
      <StatusView status={globalVideoSectionsStatus} error={globalVideoSectionsError}>
        <div className="flex flex-col gap-2 mb-5">
          <Button shade={100} onClick={generateGlobalVideoSection}>
            <Plus size={16} />
            Generate Global Video Section
          </Button>
          {usedInSections.length > 0 ? (
            <div className="flex-1 flex flex-col">
              <div className="text-xs text-gray-500">Used in...</div>
              <div className="text-xs">{usedInSections.map((gvs) => gvs.title).join(", ")}</div>
            </div>
          ) : null}
        </div>
      </StatusView>
    );
  };

  const SideBar = ({ formProps }: { formProps: FormikProps<PhraseFormValues> }) => {
    // -- Local state
    const [selectPhraseDropdownIsOpen, setSelectPhraseDropdownIsOpen] = useState(false);

    // -- Functions
    const openSelectPhraseDropdown = () => {
      setSelectPhraseDropdownIsOpen(true);
    };
    const closeSelectPhraseDropdown = () => {
      setSelectPhraseDropdownIsOpen(false);
    };

    // -- Components
    const SelectPhraseDropdown = ({
      isOpen,
      isAdding,
      phrase: prevPhrase,
      onClose,
    }: {
      isOpen: boolean;
      isAdding?: boolean;
      phrase?: Phrase;
      onClose: () => void;
    }) => {
      // -- Functions
      const handleLinkPhrase = (phrase: Phrase) => {
        isAdding || !prevPhrase ? linkWord(phrase.id) : replaceLinkedWord(phrase.id, prevPhrase.id);
      };

      const handleCreateLinkedPhrase = () => {
        const newPhrase = new Phrase({
          localeId: currentLocale?.id,
          createdBy: currentUser?.attributes.sub,
          updatedBy: currentUser?.attributes.sub,
        });
        tempCreatingPhrases.push(newPhrase);
        setCreatingPhrases((prev) => [...prev, newPhrase]);
        handleLinkPhrase(newPhrase);
      };

      // -- Components
      const PhraseCard = ({ phrase }: { phrase: Phrase }) => {
        return (
          <Card onClick={() => handleLinkPhrase(phrase)} className="flex-1 cursor-pointer">
            <div className="text-sm font-bold">{phrase.targetText}</div>
            <div className="text-xs text-gray-500">{phrase.nativeText}</div>
            <div className="text-xs text-gray-400">{phrase.id}</div>
          </Card>
        );
      };

      return (
        <Dropdown className="left-0 w-96 p-5 flex flex-col gap-2" isOpen={isOpen} onClose={onClose} disableAutoClose>
          <SearchView
            items={allPhrases}
            containsFilter={phraseSearchFilter}
            exactFilter={phraseExactSearchFilter}
            wordFilter={phraseByWordSearchFilter}
            noneFoundMessage="No phrases found"
            limit={100}
            initialSearchType={SearchType.exact}
          >
            {(phrases, indicator) => (
              <div className="bg-gray-100 rounded-md p-5 flex flex-col gap-2 max-h-96 overflow-scroll">
                {!indicator ? phrases.map((phrase) => <PhraseCard key={phrase.id} phrase={phrase} />) : indicator}
              </div>
            )}
          </SearchView>
          {!isAdding ? (
            <Button color="red" shade={100} onClick={() => unlinkWord(prevPhrase?.id ?? "")}>
              <Link size={16} />
              Unlink
            </Button>
          ) : (
            <Button shade={100} onClick={handleCreateLinkedPhrase}>
              <Plus size={16} />
              Create Phrase
            </Button>
          )}
        </Dropdown>
      );
    };

    const SideBarCard = ({
      phrase,
      isSelected,
      isRoot,
    }: {
      phrase: Phrase;
      isSelected?: boolean;
      isRoot?: boolean;
    }) => {
      // -- Local state
      const [selectPhraseDropdownIsOpen, setSelectPhraseDropdownIsOpen] = useState(false);

      // -- Functions
      const openSelectPhraseDropdown = () => {
        setSelectPhraseDropdownIsOpen(true);
      };
      const closeSelectPhraseDropdown = () => {
        setSelectPhraseDropdownIsOpen(false);
      };

      function selectImage() {
        formProps.setFieldValue("imageUrl", phrase.imageUrl);
      }

      return (
        <div
          className={`
            py-5 pl-5 pr-10 flex items-center cursor-pointer
            ${isSelected ? "bg-indigo-50" : ""}
          `}
          onClick={() => selectPhrase(phrase)}
        >
          {!isRoot ? (
            <div className="relative">
              <Button displayType={ButtonType.text} className="mr-3" onClick={openSelectPhraseDropdown}>
                <Link size={16} />
              </Button>
              {phrase.imageUrl && (
                <Button displayType={ButtonType.text} onClick={selectImage}>
                  <Photograph size={16} />
                </Button>
              )}
              <SelectPhraseDropdown
                isOpen={selectPhraseDropdownIsOpen}
                phrase={phrase}
                onClose={closeSelectPhraseDropdown}
              />
            </div>
          ) : null}
          <div>
            <div className="text-sm font-bold">{phrase.targetText}</div>
            <div className="text-xs text-gray-500">{phrase.nativeText}</div>
            <div className="text-xs text-gray-400">{phrase.id}</div>
          </div>
        </div>
      );
    };

    return (
      <div className="flex flex-col gap-2">
        <div className="relative flex flex-col">
          <SelectPhraseDropdown isOpen={selectPhraseDropdownIsOpen} isAdding onClose={closeSelectPhraseDropdown} />
          <Button shade={100} onClick={openSelectPhraseDropdown}>
            <Plus size={16} />
          </Button>
        </div>
        <div className="border divide-y rounded-md flex-1">
          <SideBarCard phrase={getRootPhrase()} isSelected={rootPhrase.id === selectedPhraseId} isRoot />
          {phrases
            .filter((phrase) => phrase.id !== rootPhrase.id)
            .map((phrase) => {
              if (!phrase) return null;
              return <SideBarCard key={phrase.id} phrase={phrase} isSelected={phrase.id === selectedPhraseId} />;
            })}
        </div>
      </div>
    );
  };

  const AcceptedTextsInput = ({ formProps }: { formProps: FormikProps<PhraseFormValues> }) => {
    const { values, setFieldValue } = formProps;

    const addAcceptedText = () => {
      setFieldValue("acceptedTexts", [...values.acceptedTexts, new AcceptedText()]);
    };

    const setText = (value: string, index: number) => {
      setFieldValue(`acceptedTexts[${index}].text`, value);
    };

    const deleteAcceptedText = (uuid: string) => {
      setFieldValue(
        "acceptedTexts",
        values.acceptedTexts.filter((at) => at.uuid !== uuid)
      );
    };

    return (
      <div className="flex flex-col gap-1">
        <div className="text-xs font-medium">Accepted Texts</div>
        <div className="bg-gray-100 rounded-md p-3 flex flex-col gap-2">
          {values.acceptedTexts.length > 0 ? (
            values.acceptedTexts.map((acceptedText, index) => (
              <div className="flex gap-1" key={acceptedText.uuid}>
                <Input
                  className="flex-1"
                  value={acceptedText.text}
                  set={(value) => setText(value, index)}
                  filter={(text: string) =>
                    currentLocale?.title !== "Ukrainian" ? stripSpecialCharacters(text) : text
                  }
                />
                <Button color="red" shade={100} onClick={() => deleteAcceptedText(acceptedText.uuid)}>
                  <XCircle size={16} />
                </Button>
              </div>
            ))
          ) : (
            <div className="text-xs text-gray-400">
              This phrase has no accepted texts yet. You'll need to add at least one
            </div>
          )}
        </div>
        <div className="flex gap-1">
          <Button className="flex-1" shade={100} onClick={addAcceptedText}>
            <Plus size={16} />
            Add new
          </Button>
          <Button className="flex-1" shade={100} onClick={openDistributionModal}>
            <CubeTransparent size={16} />
            Add from distibutions
          </Button>
        </div>
      </div>
    );
  };

  const PollyInput = ({ formProps }: { formProps: FormikProps<PhraseFormValues> }) => {
    const [isGenerating, setIsGenerating] = useState(false);

    const regeneratePolly = async (formProps: FormikProps<PhraseFormValues>) => {
      const { setFieldValue } = formProps;
      setIsGenerating(true);
      const audioUrl = await rootPhrase.generatePollyAudio(currentLocale!);
      setFieldValue("audioUrl", audioUrl);
      setIsGenerating(false);
    };

    return (
      <div className="flex flex-col gap-2 mt-2">
        <div className="text-xs font-medium">Polly File</div>
        <div className="flex gap-2 items-center">
          <ReactAudioPlayer src={formProps.values.audioUrl} controls />

          <Button onClick={() => regeneratePolly(formProps)}>
            {isGenerating ? <LoadingIndicator /> : <Refresh size={16} />}
          </Button>
        </div>
        <div className="flex gap-2">
          <Button
            className="flex-1"
            shade={100}
            onClick={() => {
              copyToClipboard(
                formProps.values.audioUrl?.replace(
                  environments.staging.cloudfrontUrl,
                  environments.staging.cloudfrontUrl
                ) ?? ""
              );
            }}
          >
            <DocumentDuplicate size={16} />
            Staging url
          </Button>
          <Button
            className="flex-1"
            shade={100}
            onClick={() =>
              copyToClipboard(
                formProps.values.audioUrl?.replace(
                  environments.staging.cloudfrontUrl,
                  environments.prod.cloudfrontUrl
                ) ?? ""
              )
            }
          >
            <DocumentDuplicate size={16} />
            Prod url
          </Button>
        </div>
      </div>
    );
  };

  if (!rootPhrase) return null;

  return (
    <Modal isOpen={isOpen} containerClassName="w-4/5" onClose={onClose}>
      <StatusView status={phrasesStatus} error={phrasesError}>
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(rootPhrase)}
          validateOnChange={false}
          validate={(values) => {
            const errors: {
              targetText?: string;
              nativeText?: string;
              requiredExtraWordCount?: string;
              acceptedTexts?: string;
              audioUrl?: string;
              intermediateTimeout?: string;
              phonetic?: string;
            } = {};

            const findExactDuplicate = (targetText: string, nativeText: string) => {
              const searchCombination = targetText.toLowerCase() + nativeText.toLowerCase();
              return allPhrases.find((phrase) => {
                const combination = phrase.targetText + phrase.nativeText;
                return combination === searchCombination && selectedPhraseId !== phrase.id;
              });
            };

            if (!values.targetText) errors.targetText = "Cannot be blank";
            if (!values.nativeText && !values.isHidden) errors.nativeText = "Cannot be blank";
            if (spaceStripped(values.targetText) !== values.targetText)
              errors.targetText = "Cannot have leading, trailing, or double spaces";
            if (spaceStripped(values.nativeText) !== values.nativeText)
              errors.nativeText = "Cannot have leading, trailing, or double spaces";
            if (!values.requiredExtraWordCount && values.requiredExtraWordCount !== 0)
              errors.requiredExtraWordCount = "Cannot be blank";
            if (values.requiredExtraWordCount < 0) errors.requiredExtraWordCount = "Cannot be less than 0";
            if (values.intermediateTimeout < 0) errors.intermediateTimeout = "Cannot be less than 0";
            if (!values.intermediateTimeout && values.intermediateTimeout !== 0)
              errors.intermediateTimeout = "Cannot be blank";
            if (values.acceptedTexts.some((at) => !at.text)) errors.acceptedTexts = "No accepted text can be blank";
            if (
              currentLocale?.title !== "Ukrainian" &&
              values.acceptedTexts.some((at) => spaceStripped(stripSpecialCharacters(at.text)) !== at.text)
            )
              errors.acceptedTexts = "Cannot have special characters leading, trailing, or double spaces";
            if (values.acceptedTexts.length === 0) errors.acceptedTexts = "Must have at least one accepted text";
            if (findExactDuplicate(values.targetText, values.nativeText)) {
              errors.targetText = "There is a duplicate phrase with the same target and native text";
              errors.nativeText = "There is a duplicate phrase with the same target and native text";
            }

            return errors;
          }}
          onSubmit={onSubmit}
        >
          {(formProps) => {
            const { values, handleChange, setFieldValue, errors } = formProps;
            return (
              <div className="flex gap-5">
                <DistributionModal
                  isOpen={distributionModalIsOpen}
                  phrase={rootPhrase}
                  formProps={formProps}
                  onClose={closeDistributionModal}
                />
                <SideBar formProps={formProps} />
                <Form className="flex flex-col flex-1 gap-2">
                  <div className="divide-y">
                    <div className="flex items-center">
                      <TitleSection />
                      <GenerateGlobalVideoSectionView formProps={formProps} />
                    </div>
                    <div className="flex-1 flex gap-5 pt-5">
                      <div className="flex-1 flex flex-col gap-2">
                        <div className="flex gap-2 items-end">
                          <div className="flex-1">
                            <Input
                              autoFocus
                              title="Target Text"
                              placeholder="Hola"
                              name="targetText"
                              value={values.targetText}
                              onChange={handleChange}
                            />
                            <FormErrorMessage error={errors.targetText} />
                          </div>
                          <Button onClick={linkWords}>Populate words</Button>
                        </div>
                        <div>
                          <Input
                            title="Native Text"
                            placeholder="Hello"
                            name="nativeText"
                            value={values.nativeText}
                            onChange={handleChange}
                          />
                          <FormErrorMessage error={errors.nativeText} />
                        </div>
                        <Input
                          title="Phonetic Text"
                          placeholder="Hello"
                          name="phonetic"
                          value={values.phonetic ?? ""}
                          onChange={handleChange}
                        />
                        <div>
                          <Input
                            title="Required Extra Word Count"
                            type="number"
                            placeholder="0"
                            name="requiredExtraWordCount"
                            value={values.requiredExtraWordCount.toString()}
                            onChange={handleChange}
                          />
                          <FormErrorMessage error={errors.requiredExtraWordCount} />
                        </div>
                        <div>
                          <Input
                            title="Intermediate Timeout"
                            // type="number"
                            placeholder="0"
                            name="intermediateTimeout"
                            value={values.intermediateTimeout.toString()}
                            onChange={handleChange}
                          />
                          <FormErrorMessage error={errors.intermediateTimeout} />
                        </div>
                        <div />
                        <CheckBoxInput
                          value={values.isHidden}
                          message="isHidden"
                          name="isHidden"
                          onChange={formProps.handleChange}
                          note="This phrase will never show to the user (ex. mistakes)"
                        />
                        <CheckBoxInput
                          value={values.isWord}
                          message="isWord"
                          name="isWord"
                          onChange={formProps.handleChange}
                          note="This phrase will show on the My Words page"
                        />
                        <CheckBoxInput
                          value={values.isChecked}
                          message="isChecked"
                          name="isChecked"
                          onChange={formProps.handleChange}
                        />
                        <CheckBoxInput
                          value={values.isDojo}
                          message="isDojo"
                          name="isDojo"
                          onChange={formProps.handleChange}
                        />
                        <div />
                        <div>
                          <AcceptedTextsInput formProps={formProps} />
                          <FormErrorMessage error={errors.acceptedTexts?.toString()} />
                        </div>
                        <PollyInput formProps={formProps} />
                      </div>
                      <div className="flex-1 flex flex-col gap-2">
                        {selectedPhraseId !== rootPhrase.id ? (
                          <CheckBoxInput
                            value={excludedSkillWordIds.includes(getSelectedPhrase().id)}
                            set={handleExcludedSkillWordIdsToggle}
                            message="Exclude the skills of this word for the phrase"
                          />
                        ) : null}
                        <SkillsInput formProps={formProps} />
                        <SelectPhraseImage formProps={formProps} phraseId={rootPhrase.id} />
                      </div>
                    </div>
                  </div>
                  <div className="h-5" />
                  <ModalFormButtons submitText={isCreating ? "Create" : "Done"} onClose={onClose} />
                </Form>
              </div>
            );
          }}
        </Formik>
      </StatusView>
    </Modal>
  );
}
