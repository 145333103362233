import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { StatusType } from "./types";
import { Locale, Video } from "../domain/entities";
import { remoteDataSource, useCaseDeleteVideo, useCaseGetVideos, useCaseUpdateVideo } from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { RootState } from "./store";
import { updateObjectInList } from "../../service/helpers";

export const fetchVideos = createAsyncThunk(`videos/fetch`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.videos.items.length > 0 && state.videos.items[0].localeId !== locale.id;
  if (state.videos.items.length > 0 && (!localeDidChange || state.videos.status !== StatusType.loading))
    return state.videos.items;

  const result = await useCaseGetVideos.execute(locale);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const fetchProdVideos = createAsyncThunk(`videos/fetchProd`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.videos.prodItems.length > 0 && state.videos.prodItems[0].localeId !== locale.id;
  if (state.videos.prodItems.length > 0 && (!localeDidChange || state.videos.status !== StatusType.loading))
    return state.videos.prodItems;

  const result = await remoteDataSource.allVideos(locale, "prod");

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const verifyVideo = createAsyncThunk(`videos/verify`, async (video: Video) => {
  video.hasChanged = false;
  const result = await useCaseUpdateVideo.execute(video);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const deleteVideo = createAsyncThunk(`videos/delete`, async (video: Video) => {
  const result = await useCaseDeleteVideo.execute(video);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

const initialState = {
  items: [] as Video[],
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as Video[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
};

export const videosSlice = createSlice({
  name: "videos",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVideos.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchVideos.fulfilled, (state, { payload: videos }: { payload: Video[] }) => {
      state.status = StatusType.succeeded;
      state.items = videos;
    });
    builder.addCase(fetchVideos.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(fetchProdVideos.pending, (state) => {
      state.prodStatus = StatusType.loading;
    });
    builder.addCase(fetchProdVideos.fulfilled, (state, { payload: videos }: { payload: Video[] }) => {
      state.prodStatus = StatusType.succeeded;
      state.prodItems = videos;
    });
    builder.addCase(fetchProdVideos.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
    builder.addCase(verifyVideo.fulfilled, (state, { payload: video }: { payload: Video }) => {
      state.items = updateObjectInList(state.items, video);
    });
    builder.addCase(verifyVideo.rejected, () => {
      alert("Error! Video was not updated. Cheeck logs for more info");
    });
    builder.addCase(deleteVideo.fulfilled, (state, { payload: video }: { payload: Video }) => {
      state.items = state.items.filter((item) => item.id !== video.id);
    });
    builder.addCase(deleteVideo.rejected, () => {
      alert("Error! Video was not deleted. Cheeck logs for more info");
    });
  },
});

export const getAllVideos = (state: RootState) => state.videos.items;
export const getVideosStatus = (state: RootState) => state.videos.status;
export const getVideosError = (state: RootState) => state.videos.error;
export const getAllProdVideos = (state: RootState) => state.videos.prodItems;

export const getProdVideosStatus = (state: RootState) => state.videos.prodStatus;
export const getProdVideosError = (state: RootState) => state.videos.prodError;

export default videosSlice.reducer;
