export const polly = {
  voices: {
    ES: ["Penelope", "Lupe", "Miguel", "Mia"],
    IT: ["Carla", "Bianca", "Giorgio"],
    DE: ["Marlene", "Vicki", "Hans"],
  },
};

export const dupLinkingRules = {
  ES: "https://kleo-script-files.s3.us-east-2.amazonaws.com/es-dup-links.csv",
  IT: "https://kleo-script-files.s3.us-east-2.amazonaws.com/it-dup-links.csv",
  DE: "https://kleo-script-files.s3.us-east-2.amazonaws.com/de-dup-links.csv",
};

export const defaultUrl = "https://www.wesayhi.com";

export const cloudfrontBaseUrl = "https://d1wccmadqdmt6c.cloudfront.net";

export const environments = {
  prod: {
    cloudfrontUrl: "https://d302naonb9wq01.cloudfront.net",
    tableSuffix: "-zpnpcv3ljvgo3icrmqh4m5jzyi-prod",
    bucket: "kleo-prod",
    cloudfrontDistributionId: "E2L541EGXW73BC",
    cacheServerEndpoint: "https://yupza4h2tf.us-east-2.awsapprunner.com",
    cloudinaryUrl: "https://ddseu0ssi.mo.cloudinary.net",
  },
  staging: {
    cloudfrontUrl: "https://d1wccmadqdmt6c.cloudfront.net",
    tableSuffix: "-4yv4464s6vacbpflmtipj24vjm-staging",
    bucket: "kleo-staging",
    cloudfrontDistributionId: "E264XU6YF3Y47B",
    cacheServerEndpoint: "https://m3ammdxyvp.us-east-2.awsapprunner.com",
    cloudinaryUrl: "https://ddseu0ssi.mo.cloudinary.net/staging",
  },
  dev: {
    cloudfrontUrl: "https://d14ecftbqpo8y9.cloudfront.net",
    tableSuffix: "-xqx57w7gg5ge3lurwzrxryolrq-dev",
    bucket: "kleo-dev",
    cloudfrontDistributionId: "E2ED3JH51AC1F",
    cacheServerEndpoint: "https://m3ammdxyvp.us-east-2.awsapprunner.com",
    cloudinaryUrl: "https://ddseu0ssi.mo.cloudinary.net/staging",
  },
};

export const newNamedTables = ["Package"];
