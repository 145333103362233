import { v4 as uuidv4 } from "uuid";

import { DataTargetPhrase } from "../../data/entities";
import { Phrase, TargetPhraseGroup } from ".";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";

export default class TargetPhrase {
  uuid: string;
  rootPhraseId: string;
  acceptedTextId?: string | null;
  isMistake: boolean;
  isPrimary: boolean;
  android: OSTargetData;
  ios: OSTargetData;

  constructor(object: Partial<TargetPhrase> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.rootPhraseId = object.rootPhraseId ?? "";
    this.acceptedTextId = object.acceptedTextId;
    this.isMistake = object.isMistake ?? false;
    this.isPrimary = object.isPrimary ?? false;
    this.android = new OSTargetData(object.android) ?? new OSTargetData();
    this.ios = new OSTargetData(object.ios) ?? new OSTargetData();
  }

  convertToData(group: TargetPhraseGroup): DataTargetPhrase {
    return new DataTargetPhrase({
      uuid: this.uuid,
      rootPhraseId: this.rootPhraseId,
      acceptedTextId: this.acceptedTextId,
      matchDestinationId: group.matchDestinationId,
      supplementaryWords: group.supplementaryWords,
      contingencies: group.contingencies,
      isMistake: this.isMistake,
      isPrimary: this.isPrimary,
      android: this.android,
      ios: this.ios,
    });
  }

  update(targetPhrase: TargetPhrase) {
    this.uuid = targetPhrase.uuid;
    this.rootPhraseId = targetPhrase.rootPhraseId;
    this.acceptedTextId = targetPhrase.acceptedTextId;
    this.isMistake = targetPhrase.isMistake;
    this.isPrimary = targetPhrase.isPrimary;
    this.android = targetPhrase.android;
    this.ios = targetPhrase.ios;
  }

  validate(phrases: Phrase[]) {
    const validation = new Validation({
      type: ValidationType.TargetPhrase,
      instance: this,
      issues: [],
      childValidations: [],
    });

    const rootPhrase = phrases.find((phrase) => phrase.id === this.rootPhraseId);
    if (!rootPhrase)
      validation.issues.push(
        new ValidationIssue(
          "Field rootPhraseId must reference a phrase",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );
    else {
      if (
        this.acceptedTextId &&
        !rootPhrase.acceptedTexts.map((acceptedText) => acceptedText.uuid).includes(this.acceptedTextId)
      )
        validation.issues.push(
          new ValidationIssue(
            "Field acceptedTextId must reference an acceptedText in phrase referenced by rootPhraseId",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );

      // if (!rootPhrase.isChecked && !rootPhrase.isHidden)
      //   validation.issues.push(
      //     new Issue(
      //       "Field rootPhraseId must reference a phrase that is checked or hidden",
      //       GravityType.error,
      //       IssueFixType.userInput
      //     )
      //   );
      // TODO
    }

    return validation;
  }

  copy() {
    return new TargetPhrase({
      rootPhraseId: this.rootPhraseId,
      acceptedTextId: this.acceptedTextId,
      isMistake: this.isMistake,
      isPrimary: this.isPrimary,
      android: this.android,
      ios: this.ios,
    });
  }

  paste(copied: TargetPhrase) {
    this.rootPhraseId = copied.rootPhraseId;
    this.acceptedTextId = copied.acceptedTextId;
    this.isMistake = copied.isMistake;
    this.isPrimary = copied.isPrimary;
    this.android = copied.android;
    this.ios = copied.ios;
  }
}

export class OSTargetData {
  ignorePartialUnstableTranscriptions: boolean;
  ignorePartialStableTranscriptions: boolean;
  ignoreFinalTranscriptions: boolean;

  constructor(object: Partial<OSTargetData> = {}) {
    this.ignorePartialUnstableTranscriptions = object.ignorePartialUnstableTranscriptions ?? false;
    this.ignorePartialStableTranscriptions = object.ignorePartialStableTranscriptions ?? false;
    this.ignoreFinalTranscriptions = object.ignoreFinalTranscriptions ?? false;
  }
}
