import { Auth } from "aws-amplify";
import axios from "axios";
import { environments } from "../constants";

const env = (process.env.REACT_APP_ENV! as "dev" | "staging" | "prod") || "staging";

export async function generatePollyAudio(text: string, phraseId: string, localeId: string) {
  const response = await axios.post(
    `${environments[env].cacheServerEndpoint}/admin/synthesizeSpeech`,
    {
      text,
      phraseId,
      localeId,
    },
    {
      headers: {
        authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    }
  );
  return response.data;
}
