import { v4 as uuidv4 } from "uuid";

import { generatePollyAudio } from "../../../service/audio/AudioService";
import { Changeable } from "../../../service/Change";
import {
  booleanFromText,
  getSubjectPronouns,
  isS3Url,
  kleoWords,
  spaceStripped,
  standardString,
  stripSpecialCharacters,
  updateObject,
} from "../../../service/helpers";
import { EnvironmentArgument, envUrl } from "../../../service/PushService";
import { hasFile } from "../../../service/StorageSerivce";
import Validation, {
  Validatable,
  ValidationArgument,
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
  ValidationType,
} from "../../../service/Validation";
import { AcceptedText, SkillTree } from "./index";
import Locale from "./Locale";
import VocabComponent from "./VocabComponent";

export default class Phrase implements Validatable, Changeable {
  id: string;
  targetText: string;
  nativeText: string;
  acceptedTexts: AcceptedText[];
  category?: string | null;
  isHidden: boolean;
  audioUrl?: string;
  imageUrl?: string;
  videoUrl?: string;
  wordIds: string[];
  localeId: string;
  skillTrees: SkillTree[];
  excludedSkillWordIds: string[];
  createdBy: string;
  updatedBy: string;
  groupsCanAccess: string[];
  isWord: boolean;
  requiredExtraWordCount: number;
  updatedAt: string;
  createdAt: string;
  isChecked: boolean;
  isDojo: boolean;
  intermediateTimeout: number;
  phonetic?: string;
  wordCount: number;

  constructor(object: Partial<Phrase> = {}) {
    this.id = object.id ?? uuidv4();
    this.targetText = object.targetText ?? "";
    this.nativeText = object.nativeText ?? "";
    this.acceptedTexts = object.acceptedTexts
      ? object.acceptedTexts.map((at: AcceptedText) => new AcceptedText(at))
      : [];
    this.category = object.category;
    this.isHidden = object.isHidden ?? false;
    this.audioUrl = object.audioUrl;
    this.imageUrl = object.imageUrl;
    this.videoUrl = object.videoUrl;
    this.wordIds = object.wordIds ?? [];
    this.localeId = object.localeId ?? "";
    this.skillTrees = object.skillTrees ? object.skillTrees.map((st: SkillTree) => new SkillTree(st)) : [];
    this.excludedSkillWordIds = object.excludedSkillWordIds ?? [];
    this.createdBy = object.createdBy ?? "";
    this.updatedBy = object.updatedBy ?? "";
    this.groupsCanAccess = ["free"];
    this.isWord = object.isWord ?? kleoWords(this.targetText).length === 1;
    this.requiredExtraWordCount = object.requiredExtraWordCount ?? 0;
    this.updatedAt = object.updatedAt ?? new Date().toISOString();
    this.createdAt = object.createdAt ?? new Date().toISOString();
    this.isChecked = object.isChecked ?? true;
    this.isDojo = object.isDojo === false ? false : object.isDojo ?? this.determineIsDojo();
    this.intermediateTimeout = object.intermediateTimeout ?? this.determineIntermediateTimeout();
    this.phonetic = object.phonetic;
    this.wordCount = object.wordCount ?? 0;
  }

  getIdentifier(): string {
    return `${this.targetText}/${this.nativeText} - ${this.id}`;
  }
  getTypeName() {
    return "Phrase";
  }

  async toEnv(fromEnv: EnvironmentArgument, toEnv: EnvironmentArgument, push: boolean = false) {
    if (this.audioUrl) this.audioUrl = await envUrl(this.audioUrl, fromEnv, toEnv, push);
    if (this.imageUrl) this.imageUrl = await envUrl(this.imageUrl, fromEnv, toEnv, push);
    if (this.videoUrl) this.videoUrl = await envUrl(this.videoUrl, fromEnv, toEnv, push);
    return this;
  }

  isVocab(vocabComponents: VocabComponent[]) {
    return vocabComponents.some((v) => v.getAllPhraseIds().includes(this.id));
  }

  async validate({ phrases, lessons, vocabComponents, locales, currentLocale, files, env }: ValidationArgument) {
    const validation = new Validation({
      type: ValidationType.Phrase,
      instance: this,
      issues: [],
      childValidations: [],
    });
    // this.skillTrees.forEach((st) => validation.childValidations.push(st.validate(skills)));

    this.wordIds.forEach((id, index) => {
      if (this.wordIds.findIndex((wId) => wId === id) !== index) {
        validation.issues.push(
          new ValidationIssue("Duplicate words", ValidationIssueGravity.error, ValidationIssueCause.userInput, id)
        );
      }

      const phrase = phrases.find((phrase) => phrase.id === id);
      if (!phrase)
        validation.issues.push(
          new ValidationIssue(
            "Each item in field wordIds must reference a phrase",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            id
          )
        );
      // else if (!phrase.isChecked && !phrase.isHidden)
      //   validation.issues.push(
      //     new Issue(
      //       "Each item in field wordIds must reference a phrase that is checked or hidden",
      //       GravityType.error,
      //       IssueFixType.internal,
      //       id
      //     )
      //   );
      // TODO
    });

    this.excludedSkillWordIds.forEach((id, index) => {
      if (this.excludedSkillWordIds.findIndex((wId) => wId === id) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate excludedSkillWordIds",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            id
          )
        );
      }

      if (!this.wordIds.includes(id))
        validation.issues.push(
          new ValidationIssue(
            "Each item in field excludedSkillWordIds must also be included in wordIds",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            id
          )
        );
    });

    if (this.acceptedTexts.length === 0) {
      validation.issues.push(
        new ValidationIssue(
          "Must have at least one accepted text",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    } else {
      this.acceptedTexts.forEach((acceptedText, index) => {
        if (this.acceptedTexts.findIndex((at) => at.uuid === acceptedText.uuid) !== index) {
          validation.issues.push(
            new ValidationIssue(
              "Duplicate accepted texts",
              ValidationIssueGravity.error,
              ValidationIssueCause.internal,
              acceptedText.uuid
            )
          );
        }
        if (currentLocale.title !== "Ukrainian") validation.childValidations.push(acceptedText.validate());
      });
    }

    if (
      currentLocale.title !== "Ukrainian" &&
      !this.acceptedTexts.some(
        (at) => at.text === spaceStripped(stripSpecialCharacters(this.targetText.toLowerCase().split("+")[0]))
      )
    )
      validation.issues.push(
        new ValidationIssue(
          "Must include the targetText as an acceptedText",
          ValidationIssueGravity.warning,
          ValidationIssueCause.userInput
        )
      );

    if (!this.targetText)
      validation.issues.push(
        new ValidationIssue("Field targetText required", ValidationIssueGravity.error, ValidationIssueCause.userInput)
      );
    else if (!(this.targetText === spaceStripped(this.targetText)))
      validation.issues.push(
        new ValidationIssue(
          "Field targetText cannot have leading, trailing, or double spaces",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    if (!this.nativeText && !this.isHidden)
      validation.issues.push(
        new ValidationIssue("Field nativeText required", ValidationIssueGravity.error, ValidationIssueCause.userInput)
      );
    else if (!(this.nativeText === spaceStripped(this.nativeText)))
      validation.issues.push(
        new ValidationIssue(
          "Field nativeText cannot have leading, trailing, or double spaces",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (!this.createdBy)
      validation.issues.push(
        new ValidationIssue("Field createdBy required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );
    if (!this.updatedBy)
      validation.issues.push(
        new ValidationIssue("Field updatedBy required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );
    if (!this.updatedAt)
      validation.issues.push(
        new ValidationIssue("Field updatedAt required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );
    if (!this.createdAt)
      validation.issues.push(
        new ValidationIssue("Field createdAt required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );

    // if (!this.isChecked && !this.isHidden)
    //   validation.issues.push(new Issue("Must be checked or hidden", GravityType.error, IssueFixType.userInput));
    // TODO

    if (this.audioUrl && isS3Url(this.audioUrl))
      validation.issues.push(
        new ValidationIssue(
          "Field audioUrl cannot be an S3 url",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );
    if (!this.audioUrl || (files && !hasFile(files, this.audioUrl, env)))
      validation.issues.push(
        new ValidationIssue(
          `Invalid audioUrl: ${this.audioUrl ? new URL(this.audioUrl).pathname : "null"}`,
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (this.imageUrl && isS3Url(this.imageUrl))
      validation.issues.push(
        new ValidationIssue(
          "Field imageUrl cannot be an S3 url",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );
    if (this.imageUrl && files && !hasFile(files, this.imageUrl, env))
      validation.issues.push(
        new ValidationIssue(
          `Invalid imageUrl: ${this.imageUrl ? new URL(this.imageUrl).pathname : "null"}`,
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (!this.isHidden && this.isDojo !== this.determineIsDojo())
      validation.issues.push(
        new ValidationIssue(
          `Field isDojo has unexpected value: ${this.isDojo}`,
          ValidationIssueGravity.warning,
          ValidationIssueCause.userInput
        )
      );

    if (this.intermediateTimeout === 0)
      validation.issues.push(
        new ValidationIssue(
          `Field intermediateTimeout cannot be 0`,
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (lessons.some((l) => l.getAllVocabPhraseIds(vocabComponents).includes(this.id)) && !this.imageUrl) {
      validation.issues.push(
        new ValidationIssue(
          "All vocab phrases must have an imageUrl",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    }

    if (
      (currentLocale.formattedCode === "en_nt_es" || currentLocale.formattedCode === "en_nt_it") &&
      !this.getPhraseWithoutSubjectPronoun(currentLocale, phrases)
    )
      validation.issues.push(
        new ValidationIssue(
          "There must be a phrase with targetText the same as this targetText without subject pronouns",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (!locales.map((locale) => locale.id).includes(this.localeId))
      validation.issues.push(
        new ValidationIssue(
          "The field localeId must reference a locale",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }

  determineIsDojo() {
    return (
      !this.isHidden &&
      this.targetText.length > 1 &&
      this.targetText
        .toLowerCase()
        .replace("a", "")
        .replace("e", "")
        .replace("i", "")
        .replace("o", "")
        .replace("u", "")
        .replace("y", "")
        .replace("h", "").length > 0
    );
  }

  determineIntermediateTimeout(baseValue: number = 1.1, multiplier: number = 0.1) {
    const timeForWords = kleoWords(this.targetText).length * multiplier;
    const timeForSentences = this.isExtensive() ? 1.0 : 0.0;
    const intermediateTimeout = parseFloat((baseValue + timeForWords + timeForSentences).toFixed(3));
    this.intermediateTimeout = intermediateTimeout;
    return intermediateTimeout;
  }

  // Checks for [.?!,] in between the phrase. e.g. if punctuation in middle of phrase, return true
  isExtensive() {
    const middle = this.targetText.slice(1, -1);
    return middle.includes(".") || middle.includes("?") || middle.includes("!") || middle.includes(",");
  }

  getPhraseWithoutSubjectPronoun(locale: Locale, phrases: Phrase[]) {
    if (this.isHidden) return true;

    const subjectPronouns = getSubjectPronouns(locale);

    let target = standardString(this.targetText);
    const words = kleoWords(target);

    if (!subjectPronouns.some((pronoun) => words.includes(pronoun))) return true;

    const wordsWithoutSubjectPronouns = words.filter((word) => !subjectPronouns.includes(word));
    const newTarget = wordsWithoutSubjectPronouns.join(" ");

    const phraseWithoutSubPron = phrases.find((p) => standardString(p.targetText) === standardString(newTarget));
    return phraseWithoutSubPron;
  }

  replaceLinkedWord(id: string, prevId: string) {
    const index = this.wordIds.indexOf(prevId);
    if (index === -1 || this.wordIds.includes(id)) return;
    this.wordIds[index] = id;
  }

  linkWord(id: string) {
    this.wordIds.push(id);
  }

  unlinkWord(id: string) {
    const index = this.wordIds.indexOf(id);
    if (index === -1) return;
    this.wordIds.splice(index, 1);
  }

  words() {
    return kleoWords(this.targetText);
  }

  isKeepTogether() {
    return this.words().length > 1 && this.isWord;
  }

  async generatePollyAudio(locale: Locale) {
    const audioText = (this.targetText.includes("+") ? this.targetText.split("+")[0].trim() : this.targetText)
      .split("(")[0]
      .trim();
    const url = await generatePollyAudio(audioText, this.id, locale.id);
    if (url) this.audioUrl = url;
    return url;
  }

  getImageName() {
    return this.imageUrl ? this.imageUrl.split("/").pop() : null;
  }

  // -- CSV
  static csvHeaders = {
    id: "id",
    targetText: "Target",
    nativeText: "Native",
    phonetic: "Display",
    isWord: "isWord (default: calculated)",
    isHidden: "isHidden (default: no)",
    isDojo: "isDojo (default: calculated)",
    acceptedTexts: "Accepted Texts (comma separated, no space)",
    requiredExtraWordCount: "Required Extra Word Count",
    generateGlobalVideoSection: "Generate Global Video Section (default: no)",
  };
  toCSVEntry() {
    return {
      [Phrase.csvHeaders.id]: this.id,
      [Phrase.csvHeaders.targetText]: this.targetText,
      [Phrase.csvHeaders.nativeText]: this.nativeText,
      [Phrase.csvHeaders.phonetic]: this.phonetic,
      [Phrase.csvHeaders.isWord]: this.isWord ? "yes" : "no",
      [Phrase.csvHeaders.isHidden]: this.isHidden ? "yes" : "no",
      [Phrase.csvHeaders.isDojo]: this.isDojo ? "yes" : "no",
      [Phrase.csvHeaders.acceptedTexts]: this.acceptedTexts.map((at) => at.text).join(","),
      [Phrase.csvHeaders.requiredExtraWordCount]: this.requiredExtraWordCount,
      [Phrase.csvHeaders.generateGlobalVideoSection]: "",
    };
  }
  static fromCSVEntry(entry: any, localeId: string, phrases: Phrase[]) {
    const errors = [] as string[];
    const warnings = [] as string[];

    for (const key in entry) {
      if (entry[key] === "") entry[key] = undefined;
    }

    const id = entry[Phrase.csvHeaders.id];
    const targetText = entry[Phrase.csvHeaders.targetText] ?? "";
    const nativeText = entry[Phrase.csvHeaders.nativeText] ?? "";
    const phonetic = entry[Phrase.csvHeaders.phonetic] ?? "";
    const acceptedTextStrings = entry[Phrase.csvHeaders.acceptedTexts]
      ? entry[Phrase.csvHeaders.acceptedTexts].split(",")
      : [spaceStripped(stripSpecialCharacters(targetText))];
    const isWord = booleanFromText(entry[Phrase.csvHeaders.isWord]);
    const isHidden = booleanFromText(entry[Phrase.csvHeaders.isHidden]);
    const isDojo = booleanFromText(entry[Phrase.csvHeaders.isDojo]);
    const requiredExtraWordCount = parseInt(entry[Phrase.csvHeaders.requiredExtraWordCount] ?? 0);
    const generateGlobalVideoSection = booleanFromText(entry[Phrase.csvHeaders.generateGlobalVideoSection]);

    const existingPhrase = phrases.find(
      (p) =>
        (p.id === id && p.id !== "" && p.id !== undefined) ||
        (p.targetText === targetText && p.nativeText === nativeText)
    );

    if (existingPhrase && !id) {
      errors.push(
        `${targetText} - ${nativeText} already exists but has no id. Did you mean to add this as a new phrase or did you include a duplicate in the file?`
      );
    }

    const phraseObject = {
      id: id,
      targetText: targetText,
      nativeText: nativeText,
      phonetic: phonetic,
      isWord: isWord,
      isHidden: isHidden,
      isDojo: isDojo,
      acceptedTexts: acceptedTextStrings.map(
        (text: string) =>
          new AcceptedText({
            uuid: existingPhrase?.acceptedTexts.find((t) => t.text === text)?.uuid,
            text: spaceStripped(stripSpecialCharacters(text)),
          })
      ),
      requiredExtraWordCount: requiredExtraWordCount,
      localeId: localeId,
      createdBy: "CSV",
      updatedBy: "CSV",
    };

    if (existingPhrase) {
      const updatedPhrase = updateObject(existingPhrase, phraseObject);

      return {
        phrase: updatedPhrase,
        errors,
        warnings,
        isUpdated: !existingPhrase.equals(updatedPhrase),
        isCreated: false,
        generateGlobalVideoSection,
      };
    }

    return {
      phrase: new Phrase(phraseObject),
      errors,
      warnings,
      isUpdated: false,
      isCreated: true,
      generateGlobalVideoSection,
    };
  }

  linkWords(
    phrases: Phrase[],
    dupRules?: {
      targetText: string;
      links: {
        nativeContains: string;
        linkToNative: string;
      }[];
    }[]
  ) {
    const errors = [] as string[];
    const warnings = [] as string[];

    const nativeLowerCased = (this.nativeText ?? "").toLowerCase();

    if (this.words().length > 1) {
      // Link to keep togethers first
      let remainingWords = this.words();
      const keepTogethers = phrases.filter((p) => !p.isHidden && p.isKeepTogether());
      for (const keepTogether of keepTogethers) {
        const keepTogetherWords = kleoWords(stripSpecialCharacters(keepTogether.targetText ?? ""));

        if (
          keepTogetherWords.every((word) => remainingWords.map((word) => stripSpecialCharacters(word)).includes(word))
        ) {
          remainingWords = remainingWords.filter((word) => !keepTogetherWords.includes(stripSpecialCharacters(word)));
          this.linkWord(keepTogether.id);
        }
      }

      // Link to remaining words
      for (const [i, word] of Object.entries(remainingWords)) {
        const wordStandard = stripSpecialCharacters(word ?? "");
        const wordOptions = phrases.filter(
          (p) => !p.isHidden && stripSpecialCharacters(p.targetText ?? "") === wordStandard
        );
        if (wordOptions.length === 0) {
          errors.push(`No phrase to link to for word ${word} in phrase (${this.targetText} - ${this.nativeText})`);
        } else if (wordOptions.length === 1) {
          this.linkWord(wordOptions[0].id);
        } else {
          if (!dupRules) {
            warnings.push(
              `Multiple phrases to link to for word ${word} in phrase (${this.targetText} - ${this.nativeText}) but there are no rules for this language`
            );
            continue;
          }

          // Link with rules
          const rule = dupRules.find((r) => r.targetText.toLowerCase() === word.toLowerCase());
          if (!rule) {
            warnings.push(
              `Duplicate phrases to link to for word ${word} in phrase (${this.targetText} - ${this.nativeText}) but no rule found`
            );
            continue;
          }

          let linked = false;
          for (const link of rule.links) {
            // Special s rules
            const index = parseInt(i);
            if (
              link.nativeContains.includes(`, followed by word NOT ending in "s"`) ||
              link.nativeContains.includes(`, followed by word ending in "s"`)
            ) {
              const newNativeWords = kleoWords(nativeLowerCased);
              const shouldHaveS = link.nativeContains.includes(`, followed by word ending in "s"`);
              const nativeContains = link.nativeContains
                .replace(`, followed by word NOT ending in "s"`, "")
                .replace(`, followed by word ending in "s"`, "")
                .trim();
              const foundIndex = newNativeWords.indexOf(nativeContains);

              if (
                foundIndex !== -1 &&
                newNativeWords.length > index + 1 &&
                shouldHaveS === stripSpecialCharacters(newNativeWords[foundIndex + 1] ?? "").endsWith("s")
              ) {
                const word = wordOptions.find((word) => word.nativeText === link.linkToNative);
                if (!word) {
                  warnings.push(
                    `No phrases found to link to with target ${wordStandard} and native ${link.linkToNative} but a rule specified it. You may need to update the rule`
                  );
                  continue;
                }
                this.linkWord(word.id);
                linked = true;
                break;
              }
            }

            // All other rules
            const nativeOptions = link.nativeContains.includes(" or ")
              ? link.nativeContains.split(" or ")
              : [link.nativeContains];

            if (nativeOptions.some((nativeOption) => nativeLowerCased.includes(nativeOption.toLowerCase()))) {
              const word = wordOptions.find((p) => p.nativeText.toLowerCase() === link.linkToNative.toLowerCase());
              if (!word) {
                warnings.push(
                  `Duplicate phrases to link to for word ${word} in phrase (${this.targetText} - ${this.nativeText}) and rule found but no phrase found with the nativeText to link to`
                );
                continue;
              }
              this.linkWord(word.id);
              linked = true;
              break;
            }
          }
          if (!linked) {
            warnings.push(
              `Duplicate phrases to link to for word ${word} in phrase (${this.targetText} - ${this.nativeText}) but the rule didn't match anything in the nativeText`
            );
          }
        }
      }
    }

    return { errors, warnings };
  }

  equals(phrase: Phrase) {
    return (
      this.id === phrase.id &&
      this.targetText === phrase.targetText &&
      this.nativeText === phrase.nativeText &&
      this.isWord === phrase.isWord &&
      this.isHidden === phrase.isHidden &&
      this.isDojo === phrase.isDojo &&
      this.acceptedTexts.length === phrase.acceptedTexts.length &&
      this.acceptedTexts.every((at, i) => at.equals(phrase.acceptedTexts[i])) &&
      this.requiredExtraWordCount === phrase.requiredExtraWordCount
    );
  }
}
