import {
  GlobalVideoSection,
  Lesson,
  LessonComponent,
  Locale,
  Package,
  Phrase,
  Video,
  VocabComponent,
} from "../core/domain/entities";

export class ValidationGroup {
  validations: Validation[];

  constructor(validations: Validation[]) {
    this.validations = validations;
  }

  getUniqueIssues() {
    return [...new Set(this.validations.flatMap((v) => v.getUniqueIssues()))];
  }

  filterByInvalid() {
    return new ValidationGroup(
      this.validations.filter((v) => !v.isDeepValidated()).map((v) => v.removeValidChildren())
    );
  }

  removeValidChildren() {
    return new ValidationGroup(this.validations.map((v) => v.removeValidChildren()));
  }

  filterIssuesByGravity(gravities: ValidationIssueGravity[]) {
    return new ValidationGroup(this.validations.map((v) => v.filterIssuesByGravity(gravities)));
  }

  filterIssuesByFixType(fixTypes: ValidationIssueCause[]) {
    return new ValidationGroup(this.validations.map((v) => v.filterIssuesByFixType(fixTypes)));
  }

  filterIssuesByMessage(messages: string[]) {
    return new ValidationGroup(this.validations.map((v) => v.filterIssuesByMessage(messages)));
  }

  toCSV() {
    return this.validations.map((v) => v.toCSVObject());
  }
}

export enum ValidationType {
  LessonComponent = "LessonComponent",
  VideoClip = "VideoClip",
  Objective = "Objective",
  TargetPhraseGroup = "TargetPhraseGroup",
  TargetPhrase = "TargetPhrase",
  AcceptedText = "AcceptedText",
  Contingency = "Contingency",
  DisplayItem = "DisplayItem",
  FailDestination = "FailDestination",
  MatchDestination = "MatchDestination",
  SectionInfo = "SectionInfo",
  SkillTree = "SkillTree",
  Skill = "Skill",
  SupplementaryWord = "SupplementaryWord",
  Lesson = "Lesson",
  Phrase = "Phrase",
  GlobalVideoSection = "GlobalVideoSection",
  Video = "Video",
  Variant = "Variant",
  StreamPath = "StreamPath",
  VocabComponent = "VocabComponent",
}

export type ValidationArgument = {
  lessonComponents: LessonComponent[];
  vocabComponents: VocabComponent[];
  globalVideoSections: GlobalVideoSection[];
  videos: Video[];
  phrases: Phrase[];
  lessons: Lesson[];
  packages: Package[];
  locales: Locale[];
  currentLocale: Locale;
  files?: Map<string, string>;
  env?: "dev" | "staging" | "prod";
};

export interface Validatable {
  validate(arg: ValidationArgument): Validation | Promise<Validation>;
}

export default class Validation {
  type: ValidationType;
  instance: any;
  issues: ValidationIssue[];
  childValidations: Validation[];

  constructor(object: Partial<Validation>) {
    this.type = object.type ?? ValidationType.LessonComponent;
    this.instance = object.instance ?? null;
    this.issues = object.issues ?? [];
    this.childValidations = object.childValidations ?? [];
  }

  isDeepValidated(): boolean {
    if (this.issues.length > 0) return false;
    return this.childValidations.every((validation) => validation.isDeepValidated());
  }

  getUniqueIssues(): string[] {
    const issues = this.issues
      .map((issue) => issue.message)
      .concat(...this.childValidations.map((v) => v.getUniqueIssues()));
    return [...new Set(issues)];
  }

  toString() {
    return `${this.type}: ${
      this.instance.getIdentifier ? this.instance.getIdentifier() : this.instance.id
    }. Errors: ${this.getUniqueIssues().join(", ")}`;
  }

  toCSVObject() {
    return {
      type: this.type,
      id: this.instance.id,
      identifier: this.getIdentifier(),
      errors: this.filterIssuesByGravity([ValidationIssueGravity.error]).getUniqueIssues().join(", "),
      warnings: this.filterIssuesByGravity([ValidationIssueGravity.warning]).getUniqueIssues().join(", "),
    };
  }

  getIdentifier() {
    switch (this.type) {
      case ValidationType.Video:
        return this, this.instance.name;
      case ValidationType.Lesson:
      case ValidationType.LessonComponent:
      case ValidationType.GlobalVideoSection:
        return this.instance.title;
      case ValidationType.Phrase:
        return `${this.instance.targetText} - ${this.instance.nativeText}`;
      default:
        return this.instance.id;
    }
  }

  removeValidChildren() {
    const copy = new Validation(this);
    copy.childValidations = copy.childValidations
      .filter((v) => !v.isDeepValidated())
      .map((v) => v.removeValidChildren());
    return copy;
  }

  filterIssuesByGravity(gravities: ValidationIssueGravity[]) {
    const copy = new Validation(this);
    copy.issues = copy.issues.filter((issue) => gravities.includes(issue.gravity));
    copy.childValidations = copy.childValidations.map((v) => v.filterIssuesByGravity(gravities));
    return copy;
  }

  filterIssuesByFixType(fixTypes: ValidationIssueCause[]) {
    const copy = new Validation(this);
    copy.issues = copy.issues.filter((issue) => fixTypes.includes(issue.cause));
    copy.childValidations = copy.childValidations.map((v) => v.filterIssuesByFixType(fixTypes));
    return copy;
  }

  filterIssuesByMessage(messages: string[]) {
    const copy = new Validation(this);
    copy.issues = copy.issues.filter((issue) => messages.some((message) => message === issue.message));
    copy.childValidations = copy.childValidations.map((v) => v.filterIssuesByMessage(messages));
    return copy;
  }
}

export enum ValidationIssueCause {
  userInput = "userInput",
  internal = "internal",
}

export enum ValidationIssueGravity {
  error = "error",
  warning = "warning",
}

export class ValidationIssue {
  message: string;
  gravity: ValidationIssueGravity;
  cause: ValidationIssueCause;
  data?: any | null;

  constructor(message: string, gravity: ValidationIssueGravity, type: ValidationIssueCause, data?: any | null) {
    this.cause = type;
    this.gravity = gravity;
    this.message = message;
    this.data = data;
  }
}

export function validateNumbers(fields: { [key: string]: any }) {
  const issues = [];
  for (const [name, value] of Object.entries(fields)) {
    if (!["number", "undefined", "object"].includes(typeof value)) {
      issues.push(
        new ValidationIssue(
          `Schema issue: Field ${name} must be a number`,
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );
    }
  }
  return issues;
}
