import { AcademicCap, DotsHorizontal, Duplicate, PencilAlt, Plus, Terminal, XCircle } from "heroicons-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lesson, LessonComponent, VocabComponent } from "../../core/domain/entities";
import { deleteLesson, getAllLessons, setDestinationLessonId, updateLesson } from "../../core/redux/lessonsSlice";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import { openModal } from "../../core/redux/modalSlice";
import { getCurrentUser } from "../../core/redux/userSlice";
import { getError, getWorstStatus, updateObject } from "../../service/helpers";
import { ButtonType } from "../base/Button";

import { Button, Dropdown, DropdownItem, IsHiddenButton, StatusView } from "..";
import {
  getAllGlobalVideoSections,
  getGlobalVideoSectionsError,
  getGlobalVideoSectionsStatus,
} from "../../core/redux/globalVideoSectionsSlice";
import {
  getAllLessonComponents,
  getLessonComponentsError,
  getLessonComponentsStatus,
} from "../../core/redux/lessonComponentsSlice";
import { getAllPhrases, getPhrasesError, getPhrasesStatus } from "../../core/redux/phrasesSlice";
import {
  getAllVocabComponents,
  getVocabComponentsError,
  getVocabComponentsStatus,
} from "../../core/redux/vocabComponentsSlice";
import Input from "../base/Input";

export default function LessonCard({
  lesson,
  isReordering,
  children,
}: {
  lesson: Lesson;
  isReordering?: boolean;
  children: any;
}) {
  // -- Redux
  const dispatch = useDispatch();

  const currentLocale = useSelector(getCurrentLocale);
  const currentUser = useSelector(getCurrentUser);
  const lessons = useSelector(getAllLessons);
  const lessonComponents = useSelector(getAllLessonComponents);
  const lessonComponentsStatus = useSelector(getLessonComponentsStatus);
  const lessonComponentsError = useSelector(getLessonComponentsError);
  const vocabComponents = useSelector(getAllVocabComponents);
  const vocabComponentsStatus = useSelector(getVocabComponentsStatus);
  const vocabComponentsError = useSelector(getVocabComponentsError);
  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSectionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const globalVideoSectionsError = useSelector(getGlobalVideoSectionsError);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);

  // -- Local state
  const [isHidden, setIsHidden] = useState(lesson.isHidden);
  const [isCourse, setIsCourse] = useState(lesson.isCourse);

  const [optionsDropdownIsOpen, setOptionsDropdownIsOpen] = useState(false);
  const [createDropdownIsOpen, setCreateDropdownIsOpen] = useState(false);

  // -- Functions
  const updateIsHidden = (value: boolean) => {
    setIsHidden(value);
    dispatch(
      updateLesson(
        updateObject(lesson, {
          isHidden: value,
        })
      )
    );
  };
  const toggleIsCourse = () => {
    setIsCourse(!isCourse);
    dispatch(
      updateLesson(
        updateObject(lesson, {
          isCourse: !lesson.isCourse,
        })
      )
    );
  };
  const handleDeleteLesson = () => {
    dispatch(deleteLesson(lesson));
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  // UI
  const openEditModal = () => {
    dispatch(
      openModal({
        lessonModal: {
          lesson: lesson,
        },
      })
    );
  };

  const openCreateLessonComponentModal = () => {
    dispatch(
      openModal({
        lessonComponentModal: {
          lesson: lesson,
          lessonComponent: new LessonComponent({
            localeId: currentLocale!.id,
            createdBy: currentUser!.attributes.sub,
            updatedBy: currentUser!.attributes.sub,
          }),
          isCreating: true,
        },
      })
    );
  };
  const openCreateVocabComponentModal = () => {
    dispatch(
      openModal({
        vocabComponentModal: {
          lesson: lesson,
          vocabComponent: new VocabComponent({
            title: "",
            phraseConfigurations: [],
            localeId: currentLocale!.id,
            orderIndex: 0,
          }),
          isCreating: true,
        },
      })
    );
  };
  const openCheckInComponentModal = (orderNumber: number) => {
    const checkInComponent = LessonComponent.checkIn(
      lesson,
      orderNumber,
      lessons,
      lessonComponents,
      vocabComponents,
      globalVideoSections,
      phrases
    );
    dispatch(
      openModal({
        lessonComponentModal: {
          lesson: lesson,
          lessonComponent: checkInComponent,
          isCreating: true,
        },
      })
    );
  };

  const openOptionsDropdown = () => {
    setOptionsDropdownIsOpen(true);
  };
  const closeOptionsDropdown = () => {
    setOptionsDropdownIsOpen(false);
  };

  const openCreateDropdown = () => {
    setCreateDropdownIsOpen(true);
  };
  const closeCreateDropdown = () => {
    setCreateDropdownIsOpen(false);
  };

  const toggleChooseComponent = () => {
    dispatch(setDestinationLessonId(lesson.id));
  };

  // -- Components
  const OptionsDropdown = () => {
    return (
      <Dropdown isOpen={optionsDropdownIsOpen} onClose={closeOptionsDropdown}>
        <DropdownItem onClick={openEditModal}>
          <PencilAlt size={16} />
          Edit
        </DropdownItem>
        <DropdownItem color="red" shade={500} onClick={handleDeleteLesson}>
          <XCircle size={16} />
          Delete
        </DropdownItem>
      </Dropdown>
    );
  };

  const CreateDowndown = () => {
    const CheckInDropdownItem = () => {
      const [orderNumber, setOrderNumber] = useState(0);

      return (
        <StatusView
          status={getWorstStatus([lessonComponentsStatus, globalVideoSectionsStatus, phrasesStatus])}
          error={getError([lessonComponentsError, globalVideoSectionsError, phrasesError])}
        >
          <DropdownItem className="flex-col">
            Check-In
            <div className="flex gap-2">
              <div onClick={(e) => e.stopPropagation()}>
                <Input
                  placeholder="Order number"
                  type="number"
                  value={orderNumber === 0 ? "" : orderNumber.toString()}
                  set={setOrderNumber}
                />
              </div>
              <Button onClick={() => openCheckInComponentModal(orderNumber)}>
                <Terminal size={16} />
              </Button>
            </div>
          </DropdownItem>
        </StatusView>
      );
    };

    return (
      <Dropdown isOpen={createDropdownIsOpen} onClose={closeCreateDropdown}>
        <DropdownItem onClick={openCreateLessonComponentModal}>New Lesson Component</DropdownItem>
        <DropdownItem onClick={openCreateVocabComponentModal}>New Vocab Set</DropdownItem>
        <CheckInDropdownItem />
        <DropdownItem onClick={toggleChooseComponent}>Choose Component</DropdownItem>
      </Dropdown>
    );
  };

  return (
    <div className={`bg-white rounded-xl shadow-sm p-5 relative ${isReordering ? "cursor-pointer" : ""}`}>
      {/* <ValidationIndicator object={lesson} /> */}
      <div className="divide-y">
        <div className={`${!isReordering ? "pb-2" : ""}`}>
          <div className="flex items-center">
            <div className="flex flex-col mr-5" style={{ flex: 0.4 }}>
              <div className="text-md font-bold">{lesson.title}</div>
            </div>
            <div className="flex gap-5 items-center" style={{ flex: 0.6 }}>
              <div className={"flex gap-2 items-center text-gray-400"} style={{ flex: 0.5 }}>
                <Button displayType={ButtonType.pure} color="gray" onClick={() => copyToClipboard(lesson.id)}>
                  <Duplicate size={16} />
                </Button>
                <div className="text-xs">{lesson.id}</div>
              </div>
              <div className="text-xs text-gray-400" style={{ flex: 0.2 }}>
                {lesson.orderIndex + 1}
              </div>
              <div style={{ flex: 0.3 }} className="text-xs text-gray-400">
                {lesson.vocabComponentIds.length + " vocab sets"}
              </div>
            </div>

            <div className="flex justify-end gap-1">
              {!isReordering ? (
                <div className="relative">
                  <Button onClick={openCreateDropdown}>
                    <Plus size={16} />
                  </Button>
                  <CreateDowndown />
                </div>
              ) : (
                <div className="w-9" />
              )}
              <Button className="w-9" onClick={toggleIsCourse} shade={100}>
                {isCourse ? <AcademicCap size={16} /> : null}
              </Button>
              <IsHiddenButton isHidden={isHidden} onToggle={updateIsHidden} />
              <div className="relative">
                <Button className="w-9" onClick={openOptionsDropdown} displayType={ButtonType.text}>
                  <DotsHorizontal size={16} />
                </Button>
                <OptionsDropdown />
              </div>
            </div>
          </div>
          {!isReordering ? <div className="text-xs text-gray-400 flex mt-1">{lesson.description}</div> : null}
        </div>
        {!isReordering ? <div className="pt-1">{children}</div> : null}
      </div>
    </div>
  );
}
