import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { SectionInfo } from "../../core/domain/entities";
import { SectionPresetType } from "../../core/domain/entities/types";
import { getComponent, updateCurrentComponent } from "../../core/redux/componentSlice";

import { Modal, Input, ModalFormButtons, FormErrorMessage, SelectMenu } from "../index";

export type SectionModalProps = {
  isCreating?: boolean;
  sectionInfo: SectionInfo;
  sectionId?: string;
};

export default function SectionModal({
  isOpen,
  isCreating,
  sectionInfo,
  sectionId,
  onClose,
}: {
  isOpen: boolean;
  isCreating?: boolean;
  sectionInfo: SectionInfo;
  sectionId?: string;
  onClose: () => void;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);

  // -- Functions
  const onSubmit = (values: {
    title: string;
    targetText?: string | null | undefined;
    nativeText?: string | null | undefined;
    preset: SectionPresetType;
  }) => {
    const sectionInfo = new SectionInfo({
      title: values.title,
      targetText: values.targetText,
      nativeText: values.nativeText,
    });
    isCreating
      ? lessonComponent!.addSection(sectionInfo, values.preset)
      : lessonComponent!.updateSection(sectionId ?? "", sectionInfo);

    dispatch(updateCurrentComponent(lessonComponent!));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} title={isCreating ? "Create Section" : "Edit Section"} onClose={onClose}>
      <Formik
        initialValues={{
          title: sectionInfo.title,
          targetText: sectionInfo.targetText,
          nativeText: sectionInfo.nativeText,
          preset: SectionPresetType.reprompt,
        }}
        validate={(values) => {
          const errors: { title?: string } = {};
          if (!values.title) errors.title = "Cannot be blank";
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange }) => (
          <Form className="grid grid-cols-2 gap-2">
            <div className="col-span-full">
              <Input
                autoFocus
                title="Title"
                placeholder="Section title"
                name="title"
                value={values.title}
                onChange={handleChange}
              />
              <FormErrorMessage error={errors.title} />
            </div>
            <Input
              title="Target Text"
              placeholder="Hola"
              name="targetText"
              value={values.targetText ?? ""}
              onChange={handleChange}
            />
            <Input
              title="Native Text"
              placeholder="Hello"
              name="nativeText"
              value={values.nativeText ?? ""}
              onChange={handleChange}
            />
            {isCreating ? (
              <SelectMenu
                className="col-span-full"
                title="Preset"
                name="preset"
                value={values.preset}
                onChange={handleChange}
              >
                {Object.values(SectionPresetType)
                  .filter((type) => typeof type === "string")
                  .map((type) => (
                    <option key={type} value={type}>
                      {_.capitalize(type)}
                    </option>
                  ))}
              </SelectMenu>
            ) : null}
            <div className="h-5 col-span-full" />
            <ModalFormButtons className="col-span-full" submitText={isCreating ? "Create" : "Done"} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
