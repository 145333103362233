import { Locale, Phrase } from "./entities";
import Outcome from "../../service/api/Outcome";
import PhraseRepository from "./PhraseRepository";

export class UseCaseGetPhrases {
  repository: PhraseRepository;
  constructor(repository: PhraseRepository) {
    this.repository = repository;
  }

  async execute(locale: Locale): Promise<Outcome<Phrase[]>> {
    return this.repository.allPhrases(locale);
  }
}

export class UseCaseGetAllPhrases {
  repository: PhraseRepository;
  constructor(repository: PhraseRepository) {
    this.repository = repository;
  }

  async execute(): Promise<Outcome<Phrase[]>> {
    return this.repository.allPhrasesNoLocale();
  }
}

export class UseCaseCreatePhrase {
  repository: PhraseRepository;
  constructor(repository: PhraseRepository) {
    this.repository = repository;
  }

  async execute(phrase: Phrase): Promise<Outcome<Phrase[]>> {
    return this.repository.createPhrase(phrase);
  }
}

export class UseCaseUpdatePhrase {
  repository: PhraseRepository;
  constructor(repository: PhraseRepository) {
    this.repository = repository;
  }

  async execute(phrase: Phrase): Promise<Outcome<Phrase[]>> {
    return this.repository.updatePhrase(phrase);
  }
}

export class UseCaseDeletePhrase {
  repository: PhraseRepository;
  constructor(repository: PhraseRepository) {
    this.repository = repository;
  }

  async execute(phrase: Phrase): Promise<Outcome<Phrase[]>> {
    return this.repository.deletePhrase(phrase);
  }
}
