import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Plus } from "heroicons-react";

import {
  fetchGlobalVideoSections,
  getAllGlobalVideoSections,
  getGlobalVideoSectionsError,
  getGlobalVideoSectionsStatus,
} from "../core/redux/globalVideoSectionsSlice";
import {
  globalVideoSectionFilter,
  globalVideoSectionExactFilter,
  globalVideoSectionByWordFilter,
} from "../service/helpers";
import { openModal } from "../core/redux/modalSlice";
import { getCurrentUser } from "../core/redux/userSlice";
import { getCurrentLocale } from "../core/redux/localesSlice";
import { GlobalVideoSection } from "../core/domain/entities";

import { Button, SearchView, StatusView, GlobalVideoSectionCard } from "../components";

export default function ManageGlobalVideoSectionsPage() {
  // -- Redux
  const dispatch = useDispatch();
  const currentLocale = useSelector(getCurrentLocale);
  const currentUser = useSelector(getCurrentUser);
  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSectionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const globalVideoSectionsError = useSelector(getGlobalVideoSectionsError);

  // -- Effect
  // Fetch global video sections
  useEffect(() => {
    if (currentLocale) dispatch(fetchGlobalVideoSections(currentLocale));
  }, [dispatch, currentLocale]);

  // -- Functions
  const openCreateGlobalVideoSectionModal = () => {
    dispatch(
      openModal({
        globalVideoSectionModal: {
          globalVideoSection: new GlobalVideoSection({
            localeId: currentLocale?.id,
            createdBy: currentUser?.attributes.sub,
            updatedBy: currentUser?.attributes.sub,
          }),
          isCreating: true,
        },
      })
    );
  };

  // -- Components
  const TopSection = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="text-2xl font-bold">Manage Global Video Sections</div>
        <Button onClick={openCreateGlobalVideoSectionModal}>
          <Plus size={16} />
          Create Global Video Section
        </Button>
      </div>
    );
  };

  return (
    <div className="flex-1 p-5 flex flex-col gap-2">
      <TopSection />
      <div />
      <StatusView status={globalVideoSectionsStatus} error={globalVideoSectionsError}>
        <SearchView
          items={globalVideoSections}
          containsFilter={globalVideoSectionFilter}
          exactFilter={globalVideoSectionExactFilter}
          wordFilter={globalVideoSectionByWordFilter}
          noneFoundMessage="No global video sections found"
          fixed
          limit={100}
        >
          {(globalVideoSections, indicator) =>
            !indicator
              ? globalVideoSections.map((globalVideoSection) => (
                  <GlobalVideoSectionCard key={globalVideoSection.id} globalVideoSection={globalVideoSection} />
                ))
              : indicator
          }
        </SearchView>
      </StatusView>
    </div>
  );
}
