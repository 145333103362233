import { Lesson, LessonComponent, Locale, VocabComponent } from "./entities";
import Outcome from "../../service/api/Outcome";
import VocabComponentRepository from "./VocabComponentRepository";
import LessonRepository from "./LessonRepository";
import { updateObject } from "../../service/helpers";

export class UseCaseGetVocabComponents {
  repository: VocabComponentRepository;
  constructor(repository: VocabComponentRepository) {
    this.repository = repository;
  }

  async execute(locale: Locale): Promise<Outcome<Lesson[]>> {
    return this.repository.allVocabComponents(locale);
  }
}

export class UseCaseCreateVocabComponent {
  repository: VocabComponentRepository;
  lessonRepository: LessonRepository;
  constructor(repository: VocabComponentRepository, lessonRepository: LessonRepository) {
    this.repository = repository;
    this.lessonRepository = lessonRepository;
  }

  async execute(
    vocabComponent: VocabComponent,
    lesson: Lesson,
    vocabComponents: VocabComponent[],
    lessonComponents: LessonComponent[]
  ): Promise<{
    vocabComponent: Promise<Outcome<VocabComponent>>;
    lesson: Lesson;
  }> {
    const allSortedComponents = [...lesson.allSortedComponents(lessonComponents, vocabComponents)];

    if (vocabComponent.title !== "Check-In") vocabComponent.orderIndex = allSortedComponents.length;

    allSortedComponents.splice(vocabComponent.orderIndex, 0, vocabComponent);
    const updatedVocabComponentIds = allSortedComponents.filter((c) => c instanceof VocabComponent).map((vc) => vc.id);

    const updatedLesson = updateObject(lesson, {
      vocabComponentIds: updatedVocabComponentIds,
    });
    return {
      vocabComponent: this.repository.createVocabComponent(vocabComponent),
      lesson: updatedLesson,
    };
  }
}

export class UseCaseUpdateVocabComponent {
  repository: VocabComponentRepository;
  constructor(repository: VocabComponentRepository) {
    this.repository = repository;
  }

  async execute(vocabComponent: VocabComponent): Promise<Outcome<VocabComponent>> {
    return this.repository.updateVocabComponent(vocabComponent);
  }
}

export class UseCaseDeleteVocabComponent {
  repository: VocabComponentRepository;
  lessonRepository: LessonRepository;
  constructor(repository: VocabComponentRepository, lessonRepository: LessonRepository) {
    this.repository = repository;
    this.lessonRepository = lessonRepository;
  }

  async execute(
    vocabComponent: VocabComponent,
    lesson?: Lesson
  ): Promise<{
    vocabComponent: Promise<Outcome<VocabComponent>>;
    lesson?: Lesson;
  }> {
    const lessonResponse = lesson
      ? {
          lesson: updateObject(lesson, {
            vocabComponentIds: lesson.vocabComponentIds.filter((id) => id !== vocabComponent.id),
          }),
        }
      : {};
    return {
      vocabComponent: this.repository.deleteVocabComponent(vocabComponent),
      ...lessonResponse,
    };
  }
}
