import { v4 as uuidv4 } from "uuid";

import { Contingency, SupplementaryWord, TargetPhrase } from "../../domain/entities";
import { OSTargetData } from "../../domain/entities/TargetPhrase";

export default class DataTargetPhrase {
  uuid: string;
  rootPhraseId: string;
  acceptedTextId?: string | null;
  matchDestinationId: string;
  contingencies: Contingency[];
  supplementaryWords: SupplementaryWord[];
  isMistake: boolean;
  isPrimary: boolean;
  android: OSTargetData;
  ios: OSTargetData;

  constructor(object: Partial<DataTargetPhrase> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.rootPhraseId = object.rootPhraseId ?? "";
    this.acceptedTextId = object.acceptedTextId;
    this.matchDestinationId = object.matchDestinationId ?? "";
    this.contingencies = object.contingencies ? object.contingencies.map((c: Contingency) => new Contingency(c)) : [];
    this.supplementaryWords = object.supplementaryWords
      ? object.supplementaryWords.map((sw: SupplementaryWord) => new SupplementaryWord(sw))
      : [];
    this.isMistake = object.isMistake ?? false;
    this.isPrimary = object.isPrimary ?? false;
    this.android = new OSTargetData(object.android) ?? new OSTargetData();
    this.ios = new OSTargetData(object.ios) ?? new OSTargetData();
  }
  getIdentifier() {
    return `${this.uuid}`;
  }
  getTypeName() {
    return "TargetPhrase";
  }

  convertToDomain(): TargetPhrase {
    return new TargetPhrase({
      uuid: this.uuid,
      rootPhraseId: this.rootPhraseId,
      acceptedTextId: this.acceptedTextId,
      isMistake: this.isMistake,
      isPrimary: this.isPrimary,
      android: this.android,
      ios: this.ios,
    });
  }
}
