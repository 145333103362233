import { DynamoDB } from "aws-sdk";
import { Key } from "aws-sdk/clients/dynamodb";
import Outcome, { Failure, Success } from "../../service/api/Outcome";
import { invalidateProdJSON } from "../../service/CloudFrontService";
import { environments } from "../../service/constants";
import { getFile, uploadFile } from "../../service/StorageSerivce";
import { unzip, zip } from "../../service/ZipService";
import {
  DeepLink,
  DisplaySkill,
  GlobalVideoSection,
  Lesson,
  LessonComponent,
  Locale,
  Package,
  Phrase,
  Section,
  Skill,
  UserResult,
  Video,
  VocabComponent,
} from "../domain/entities";
import UserPhrase from "../domain/entities/UserPhrase";
import { VocabComponentArgument } from "../domain/entities/VocabComponent";
import { DataGlobalVideoSection, DataLessonComponent } from "./entities";

const docClient = new DynamoDB.DocumentClient({
  region: "us-east-2",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});
export default class RemoteDataSource {
  async allLocales(env: "prod" | "staging" = "staging"): Promise<Outcome<Locale[]>> {
    try {
      const locales = await this.fetchItems("Locale", env);
      return new Success(locales.map((l: Locale) => new Locale(l)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allLessons(locale: Locale, env: "prod" | "staging" = "staging"): Promise<Outcome<Lesson[]>> {
    try {
      const lessons = await this.fetchItemsWithIndex(
        "Lesson",
        "lessonsByLocaleId",
        {
          localeId: locale.id,
        },
        env
      );
      return new Success(lessons.map((l: Lesson) => new Lesson(l)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async createLesson(lesson: Lesson): Promise<Outcome<Lesson>> {
    try {
      const createdLesson = await this.writeItem("Lesson", lesson);
      return new Success(new Lesson(createdLesson));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async updateLesson(lesson: Lesson): Promise<Outcome<Lesson>> {
    try {
      const updatedLesson = await this.writeItem("Lesson", lesson);
      return new Success(new Lesson(updatedLesson));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async deleteLesson(lesson: Lesson): Promise<Outcome<Lesson>> {
    try {
      const deletedLesson = await this.deleteItem("Lesson", lesson);
      return new Success(new Lesson(deletedLesson));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allSections(locale: Locale, env: "prod" | "staging" = "staging"): Promise<Outcome<Section[]>> {
    try {
      const sections = await this.fetchItemsWithIndex(
        "Section",
        "sectionsByLocaleId",
        {
          localeId: locale.id,
        },
        env,
        false
      );
      return new Success(sections.map((s: Section) => new Section(s)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allPackages(locale: Locale, env: "prod" | "staging" = "staging"): Promise<Outcome<Package[]>> {
    try {
      const packages = await this.fetchItemsWithIndex(
        "Package",
        "byLocaleId",
        {
          localeId: locale.id,
        },
        env,
        false
      );
      return new Success(packages.map((p: Package) => new Package(p)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async createPackage(pkg: Package): Promise<Outcome<Package>> {
    try {
      const createdPackage = await this.writeItem("Package", pkg, undefined, false);
      return new Success(new Package(createdPackage));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async updatePackage(pkg: Package): Promise<Outcome<Package>> {
    try {
      const updatedPackage = await this.writeItem("Package", pkg, undefined, false);
      return new Success(new Package(updatedPackage));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async deletePackage(pkg: Package): Promise<Outcome<Package>> {
    try {
      const deletedPackage = await this.deleteItem("Package", pkg, undefined, false);
      return new Success(new Package(deletedPackage));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allLessonComponents(locale: Locale, env: "prod" | "staging" = "staging"): Promise<Outcome<LessonComponent[]>> {
    try {
      const lessonComponents = await this.fetchItemsWithIndex(
        "LessonComponent",
        "lessonComponentsByLocaleIdAndType",
        {
          localeId: locale.id,
        },
        env
      );

      return new Success(
        lessonComponents.map((lc: any) => {
          if (lc.objectives.length === 0) {
            return new DataLessonComponent(lc).convertToDomain();
          } else if (lc.objectives[0].targetPhrases) {
            return new DataLessonComponent(lc).convertToDomain();
          } else if (lc.objectives[0].targetPhraseGroups) {
            return new LessonComponent(lc);
          } else {
            throw new Error(lc);
          }
        })
      );
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async createLessonComponent(lessonComponent: LessonComponent): Promise<Outcome<LessonComponent>> {
    try {
      const createdLessonComponent = await this.writeItem("LessonComponent", lessonComponent.convertToData());
      return new Success(new DataLessonComponent(createdLessonComponent).convertToDomain());
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async updateLessonComponent(
    lessonComponent: LessonComponent,
    env: "prod" | "staging" = "staging"
  ): Promise<Outcome<LessonComponent>> {
    try {
      const updatedLessonComponent = await this.writeItem("LessonComponent", lessonComponent.convertToData(), env);
      return new Success(new DataLessonComponent(updatedLessonComponent).convertToDomain());
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async deleteLessonComponent(lessonComponent: LessonComponent): Promise<Outcome<LessonComponent>> {
    try {
      const deletedLessonComponent = await this.deleteItem("LessonComponent", lessonComponent.convertToData());
      return new Success(deletedLessonComponent);
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allVocabComponents(locale: Locale, env: "prod" | "staging" = "staging"): Promise<Outcome<VocabComponent[]>> {
    try {
      const vocabComponents = await this.fetchItemsWithIndex(
        "VocabComponent",
        "vocabComponentsByLocaleId",
        {
          localeId: locale.id,
        },
        env
      );
      return new Success(vocabComponents.map((vc: VocabComponentArgument) => new VocabComponent(vc)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async createVocabComponent(vocabComponent: VocabComponent): Promise<Outcome<VocabComponent>> {
    try {
      const createdVocabComponent = await this.writeItem("VocabComponent", vocabComponent);
      return new Success(new VocabComponent(createdVocabComponent));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async updateVocabComponent(
    vocabComponent: VocabComponent,
    env: "prod" | "staging" = "staging"
  ): Promise<Outcome<VocabComponent>> {
    try {
      const updatedVocabComponent = await this.writeItem("VocabComponent", vocabComponent, env);
      return new Success(new VocabComponent(updatedVocabComponent));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async deleteVocabComponent(vocabComponent: VocabComponent): Promise<Outcome<VocabComponent>> {
    try {
      const deletedVocabComponent = await this.deleteItem("VocabComponent", vocabComponent);
      return new Success(new VocabComponent(deletedVocabComponent));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allPhrases(locale: Locale, env: "prod" | "staging" = "staging"): Promise<Outcome<Phrase[]>> {
    try {
      const phrases = await this.fetchItemsWithIndex(
        "Phrase",
        "phrasesByLocaleIdAndTargetText",
        {
          localeId: locale.id,
        },
        env
      );
      return new Success(phrases.map((p: Phrase) => new Phrase(p)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allPhrasesNoLocale(): Promise<Outcome<Phrase[]>> {
    try {
      const phrases = await this.fetchItems("Phrase");
      return new Success(phrases.map((p: Phrase) => new Phrase(p)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async createPhrase(phrase: Phrase): Promise<Outcome<Phrase>> {
    try {
      const createdPhrase = await this.writeItem("Phrase", phrase);
      return new Success(new Phrase(createdPhrase));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async updatePhrase(phrase: Phrase): Promise<Outcome<Phrase>> {
    try {
      const updatedPhrase = await this.writeItem("Phrase", phrase);
      return new Success(new Phrase(updatedPhrase));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async deletePhrase(phrase: Phrase): Promise<Outcome<Phrase>> {
    try {
      const deletedPhrase = await this.deleteItem("Phrase", phrase);
      return new Success(new Phrase(deletedPhrase));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allVideos(locale: Locale, env: "prod" | "staging" = "staging"): Promise<Outcome<Video[]>> {
    try {
      const videos = await this.fetchItemsWithIndex(
        "Video",
        "videosByLocaleIdAndName",
        {
          localeId: locale.id,
        },
        env
      );
      return new Success(videos.map((v: Video) => new Video(v)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async updateVideo(video: Video): Promise<Outcome<Video>> {
    try {
      const updatedVideo = await this.writeItem("Video", video);
      return new Success(new Video(updatedVideo));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async deleteVideo(video: Video): Promise<Outcome<Video>> {
    try {
      const deletedVideo = await this.deleteItem("Video", video);
      return new Success(new Video(deletedVideo));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allSkills(locale: Locale, env: "prod" | "staging" = "staging"): Promise<Outcome<Skill[]>> {
    try {
      const skills = await this.fetchItemsWithIndex(
        "Skill",
        "skillsByLocaleIdAndTitle",
        {
          localeId: locale.id,
        },
        env
      );
      return new Success(skills.map((s: Skill) => new Skill(s)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allSkillsNoLocale(env: "prod" | "staging" = "staging"): Promise<Outcome<Skill[]>> {
    try {
      const skills = await this.fetchItems("Skill", env);
      return new Success(skills.map((s: Skill) => new Skill(s)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async createSkill(skill: Skill): Promise<Outcome<Skill>> {
    try {
      const createdSkill = await this.writeItem("Skill", skill);
      return new Success(new Skill(createdSkill));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allGlobalVideoSections(
    locale: Locale,
    env: "prod" | "staging" = "staging"
  ): Promise<Outcome<GlobalVideoSection[]>> {
    try {
      const globalVideoSections = await this.fetchItemsWithIndex(
        "GlobalVideoSection",
        "globalVideoSectionsByLocaleIdAndTitle",
        {
          localeId: locale.id,
        },
        env
      );
      return new Success(
        globalVideoSections.map((gvs: DataGlobalVideoSection) => new DataGlobalVideoSection(gvs).convertToDomain())
      );
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async createGlobalVideoSection(globalVideoSection: GlobalVideoSection): Promise<Outcome<GlobalVideoSection>> {
    try {
      const createdGlobalVideoSection = await this.writeItem("GlobalVideoSection", globalVideoSection.convertToData());
      return new Success(new DataGlobalVideoSection(createdGlobalVideoSection).convertToDomain());
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async updateGlobalVideoSection(globalVideoSection: GlobalVideoSection): Promise<Outcome<GlobalVideoSection>> {
    try {
      const updatedGlobalVideoSection = await this.writeItem("GlobalVideoSection", globalVideoSection.convertToData());
      return new Success(new DataGlobalVideoSection(updatedGlobalVideoSection).convertToDomain());
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async deleteGlobalVideoSection(globalVideoSection: GlobalVideoSection): Promise<Outcome<GlobalVideoSection>> {
    try {
      const deletedGlobalVideoSection = await this.deleteItem("GlobalVideoSection", globalVideoSection.convertToData());
      return new Success(new DataGlobalVideoSection(deletedGlobalVideoSection).convertToDomain());
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allDisplaySkills(locale: Locale, env: "prod" | "staging" = "staging"): Promise<Outcome<DisplaySkill[]>> {
    try {
      const displaySkills = await this.fetchItemsWithIndex(
        "DisplaySkill",
        "displaySkillsByLocaleId",
        {
          localeId: locale.id,
        },
        env
      );
      return new Success(displaySkills.map((ds: DisplaySkill) => new DisplaySkill(ds)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async createDisplaySkill(displaySkill: DisplaySkill): Promise<Outcome<DisplaySkill>> {
    try {
      const createdDisplaySkill = await this.writeItem("DisplaySkill", displaySkill);
      return new Success(new DisplaySkill(createdDisplaySkill));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allUserResults(env: "prod" | "staging" = "staging"): Promise<Outcome<UserResult[]>> {
    try {
      const userResults = await this.fetchItems("UserResult", env);
      return new Success(userResults.map((ur: UserResult) => new UserResult(ur)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async userResultsByUserId(locale: Locale, userId: string): Promise<Outcome<UserResult[]>> {
    try {
      const userResults = await this.fetchItemsWithIndex("UserResult", "userResultByUserIdAndLocaleId", {
        localeId: locale.id,
        userId: userId,
      });
      return new Success(userResults.map((ur: UserResult) => new UserResult(ur)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async userResultsByPhraseId(locale: Locale, phraseId: string): Promise<UserResult[]> {
    try {
      const userResults = await this.fetchItemsWithIndex("UserResult", "userResultByPhraseIdAndLocaleId", {
        localeId: locale.id,
        phraseId: phraseId,
      });

      return userResults.map((ur: UserResult) => new UserResult(ur));
    } catch (error: any) {
      throw error;
    }
  }

  async updateUserResult(userResult: UserResult, env: "prod" | "staging" = "staging"): Promise<Outcome<UserResult>> {
    try {
      const updatedUserResult = await this.writeItem("UserResult", userResult, env);
      return new Success(new UserResult(updatedUserResult));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async userPhrasesByUserId(locale: Locale, userId: string, env: "staging" | "prod"): Promise<Outcome<UserPhrase[]>> {
    try {
      const userPhrases = await this.fetchItemsWithIndex(
        "UserPhrase",
        "userPhraseByUserIdAndLocaleId",
        {
          localeId: locale.id,
          userId: userId,
        },
        env
      );

      return new Success(userPhrases.map((ur: UserPhrase) => new UserPhrase(ur)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async allDeepLinks(locale: Locale): Promise<Outcome<DeepLink[]>> {
    try {
      const deepLinks = await this.fetchItemsWithIndex("DeepLink", "deepLinksByLocaleId", {
        localeId: locale.id,
      });

      return new Success(deepLinks.map((ur: DeepLink) => new DeepLink(ur)));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async createDeepLink(deepLink: DeepLink): Promise<Outcome<DeepLink>> {
    try {
      delete deepLink.createdAt;
      const createdDeepLink = await this.writeItem("DeepLink", deepLink);
      return new Success(new DeepLink(createdDeepLink));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async updateDeepLink(deepLink: DeepLink): Promise<Outcome<DeepLink>> {
    try {
      const updatedDeepLink = await this.writeItem("DeepLink", deepLink);
      return new Success(new DeepLink(updatedDeepLink));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async deleteDeepLink(deepLink: DeepLink): Promise<Outcome<DeepLink>> {
    try {
      const deletedDeepLink = await this.deleteItem("DeepLink", deepLink);
      return new Success(new DeepLink(deletedDeepLink));
    } catch (error: any) {
      return new Failure(error);
    }
  }

  async zipToS3(
    bucket: string,
    locale: Locale,
    phrases: Phrase[],
    videos: Video[],
    globalVideoSections: DataGlobalVideoSection[],
    lessonComponents: DataLessonComponent[],
    vocabComponents: VocabComponent[]
  ) {
    await zipAndUpload(phrases, `Phrase/${locale.id}.zip`, `${locale.id}.json`);
    await zipAndUpload(globalVideoSections, `GlobalVideoSection/${locale.id}.zip`, `${locale.id}.json`);
    await zipAndUpload(videos, `Video/${locale.id}.zip`, `${locale.id}.json`);
    await zipAndUpload(vocabComponents, `VocabComponent/${locale.id}.zip`, `${locale.id}.json`);

    // Create full LessonComponents
    const fullLessonComponents = lessonComponents.map((lc) => lc.full(videos, phrases, globalVideoSections));
    for (const fullLessonComponent of fullLessonComponents)
      await zipAndUpload(
        fullLessonComponent,
        `LessonComponent/${fullLessonComponent.lessonComponent.id}.zip`,
        `${fullLessonComponent.lessonComponent.id}.json`
      );

    async function zipAndUpload(data: any, path: string, fileName: string) {
      const zipData = zip(JSON.stringify(data), fileName);
      await uploadFile(zipData, bucket, `json/${path}`, "application/zip");
      console.log(`Zipped and uploaded ${path} to S3`);
    }

    await invalidateProdJSON();

    console.log("Zipped and uploaded all data to S3");
  }

  async allProdZippedPhrases(locale: Locale) {
    const file = await getFile(`json/Phrase/${locale!.id}.zip`, environments.prod.bucket)!;
    return JSON.parse(unzip(file?.Body)).map((p: Phrase) => new Phrase(p));
  }

  async allProdZippedGlobalVideoSections(locale: Locale) {
    const file = await getFile(`json/GlobalVideoSection/${locale!.id}.zip`, environments.prod.bucket)!;
    return JSON.parse(unzip(file?.Body)).map((gvs: DataGlobalVideoSection) =>
      new DataGlobalVideoSection(gvs).convertToDomain()
    );
  }

  async allProdZippedVideos(locale: Locale) {
    const file = await getFile(`json/Video/${locale!.id}.zip`, environments.prod.bucket)!;
    return JSON.parse(unzip(file?.Body)).map((v: Video) => new Video(v));
  }

  async allProdZippedLessonComponents(locale: Locale) {
    const lessonComponentsOutcome = await this.allLessonComponents(locale, "prod");
    if (lessonComponentsOutcome instanceof Failure) return [];

    const zippedLessonComponents = [];
    const lessonComponents = (lessonComponentsOutcome as Success<LessonComponent[]>).data;
    for (const lessonComponent of lessonComponents) {
      const file = await getFile(`json/LessonComponent/${lessonComponent.id}.zip`, environments.prod.bucket)!;
      zippedLessonComponents.push(
        new DataLessonComponent(JSON.parse(unzip(file?.Body)).lessonComponent).convertToDomain()
      );
    }
    return zippedLessonComponents;
  }

  // -- Helpers
  private getTableName(name: string, env: string = "staging", useOldSuffix: boolean = true) {
    const suffix = useOldSuffix ? environments[env as "dev" | "staging" | "prod"].tableSuffix : `-${env}`;
    return `${name}${suffix}`;
  }

  // Fetch helpers
  private async fetchItemsWithIndex(
    tableName: string,
    indexName: string,
    objectKey: any,
    env?: string,
    useOldSuffix: boolean = true
  ) {
    const allItems = [] as any[];
    let KeyConditionExpression = "";
    let ExpressionAttributeValues: any = {};
    let index = 0;
    for (const key of Object.keys(objectKey)) {
      KeyConditionExpression += `${key} = :${key}${index === Object.keys(objectKey).length - 1 ? "" : " and "}`;
      ExpressionAttributeValues[`:${key}`] = objectKey[key];
      index++;
    }

    let continuePagination = true;
    const params: {
      TableName: string;
      IndexName: string;
      KeyConditionExpression: string;
      ExpressionAttributeValues: any;
      ExclusiveStartKey: Key | undefined;
    } = {
      TableName: this.getTableName(tableName, env, useOldSuffix),
      IndexName: indexName,
      KeyConditionExpression,
      ExpressionAttributeValues,
      ExclusiveStartKey: undefined,
    };
    while (continuePagination) {
      const result = await docClient.query(params).promise();
      if (!result || !result.Items) throw "Invalid result in fetch";
      allItems.push(...result.Items);
      params.ExclusiveStartKey = result.LastEvaluatedKey;
      continuePagination = !!result.LastEvaluatedKey;
    }

    return allItems;
  }
  private async fetchItems(tableName: string, env?: string, useOldSuffix: boolean = true) {
    console.log("Starting scan of " + tableName + "...");
    const params = { TableName: this.getTableName(tableName, env, useOldSuffix), ExclusiveStartKey: undefined };
    const scanResults = [] as any[];
    let items: any;
    do {
      items = await docClient.scan(params).promise();
      items.Items.forEach((item: any) => scanResults.push(item));
      params.ExclusiveStartKey = items.LastEvaluatedKey;
    } while (typeof items.LastEvaluatedKey !== "undefined");

    console.log("Scan complete");
    return scanResults;
  }

  // Mutate helpers
  async writeItem(tableName: string, item: any, env?: string, useOldSuffix: boolean = true) {
    this.updateItemDate(item);
    const params = {
      TableName: this.getTableName(tableName, env, useOldSuffix),
      Item: item,
    };
    await docClient.put(params).promise();
    return item;
  }
  private async updateItemDate(item: any) {
    if (!item.createdAt) item.createdAt = new Date().toISOString();
    if (item.updatedAt) item.updatedAt = new Date().toISOString();
  }

  private async deleteItem(tableName: string, item: any, env?: string, useOldSuffix: boolean = true) {
    const params = {
      TableName: this.getTableName(tableName, env, useOldSuffix),
      Key: { id: item.id },
    };
    await docClient.delete(params).promise();
    return item;
  }
}
