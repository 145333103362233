import { v4 as uuidv4 } from "uuid";

export default class Locale {
  id: string;
  nativeLanguageCode: string;
  targetLanguageCode: string;
  iOSSpeechRecognitionCode: string;
  androidSpeechRecognitionCode: string;
  formattedCode: string;
  title: string;
  lastUpdatedAt: string;

  constructor(object: Partial<Locale> = {}) {
    this.id = object.id ?? uuidv4();
    this.nativeLanguageCode = object.nativeLanguageCode ?? "";
    this.targetLanguageCode = object.targetLanguageCode ?? "";
    this.iOSSpeechRecognitionCode = object.iOSSpeechRecognitionCode ?? "";
    this.androidSpeechRecognitionCode = object.androidSpeechRecognitionCode ?? "";
    this.formattedCode = object.formattedCode ?? "";
    this.title = object.title ?? "";
    this.lastUpdatedAt = object.lastUpdatedAt ?? new Date().toISOString();
  }
}
