import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import Validation, {
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
  ValidationType,
} from "../../../service/Validation";
import { DataObjective } from "../../data/entities";
import Contingency from "./Contingency";
import { FailDestination, LessonComponent, MatchDestination, TargetPhrase, TargetPhraseGroup } from "./index";
import Phrase from "./Phrase";
import { ObjectiveType } from "./types";

export default class Objective {
  uuid: string;
  type: ObjectiveType;
  matchDestinations: MatchDestination[];
  failDestination: FailDestination;
  targetPhraseGroups: TargetPhraseGroup[];
  isGeneric?: boolean;

  constructor(object: Partial<Objective> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.type = object.type ?? ObjectiveType.SPEECH;
    this.matchDestinations = object.matchDestinations
      ? object.matchDestinations.map((md: MatchDestination) => new MatchDestination(md))
      : [];
    this.failDestination = new FailDestination(object.failDestination);
    this.targetPhraseGroups = object.targetPhraseGroups
      ? object.targetPhraseGroups.map((group: TargetPhraseGroup) => new TargetPhraseGroup(group))
      : [];
    this.isGeneric = object.isGeneric;
  }

  convertToData(): DataObjective {
    const targetPhrases = _.flatten(
      this.targetPhraseGroups.map((group) => group.targetPhrases.map((tp) => tp.convertToData(group)))
    );

    return new DataObjective({
      uuid: this.uuid,
      type: this.type,
      matchDestinations: this.matchDestinations,
      failDestination: this.failDestination,
      targetPhrases: targetPhrases,
      isGeneric: this.isGeneric,
    });
  }

  update(objective: Objective) {
    this.uuid = objective.uuid;
    this.type = objective.type;
    this.matchDestinations = objective.matchDestinations;
    this.failDestination = objective.failDestination;
    this.targetPhraseGroups = objective.targetPhraseGroups;
    this.isGeneric = objective.isGeneric;
  }

  validate(lessonComponent: LessonComponent, phrases: Phrase[]) {
    const validation = new Validation({
      type: ValidationType.Objective,
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (!lessonComponent.getAllLinkedObjectiveIds().includes(this.uuid))
      validation.issues.push(
        new ValidationIssue(
          `Objective ${this.uuid} not used in the parent lesson component`,
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    this.matchDestinations.forEach((matchDestination, index) => {
      if (this.matchDestinations.findIndex((md) => md.uuid === matchDestination.uuid) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate match destinations",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            matchDestination.uuid
          )
        );
      }
      validation.childValidations.push(matchDestination.validate(lessonComponent, this));
    });

    validation.childValidations.push(this.failDestination.validate(lessonComponent, this));

    this.targetPhraseGroups.forEach((targetPhraseGroup, index) => {
      if (this.targetPhraseGroups.findIndex((tpg) => tpg.uuid === targetPhraseGroup.uuid) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate target phrase groups",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            targetPhraseGroup.uuid
          )
        );
      }
      validation.childValidations.push(targetPhraseGroup.validate(lessonComponent, this, phrases));
    });

    if (!this.getAllTargetPhrases().some((tp) => !tp.isMistake))
      validation.issues.push(
        new ValidationIssue(
          "Must have at least one non-mistake TargetPhrase",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (this.getAllTargetPhrases().every((tp) => !tp.isPrimary) && this.getAllTargetPhrases().length > 1)
      validation.issues.push(
        new ValidationIssue(
          "Must have at least one primary TargetPhrase if there are more than one",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    const allVideoNames = new Set(
      this.getAllVideoClipIds().map((uuid) => lessonComponent.getVideoClip(uuid)?.databaseName)
    );
    if (allVideoNames.size >= 10)
      validation.issues.push(
        new ValidationIssue(
          "Must not have more than 10 unique possible videos as destinations",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    return validation;
  }

  // -- Gets

  getAllDestinationVideoClipIds() {
    return this.targetPhraseGroups.flatMap((group) => {
      const matchDestination = this.getMatchDestination(group.matchDestinationId);
      if (matchDestination) return matchDestination.getAllVideoClipIds();
      else return [];
    });
  }

  // Video clip
  getVideoClipIdsForTargetPhraseGroup(uuid: string): string[] {
    let matchVideoClipIds = [];

    const group = this.getTargetPhraseGroup(uuid);
    if (group) {
      // Handle match destination
      const matchDestination = this.getMatchDestination(group.matchDestinationId);
      if (matchDestination) {
        matchVideoClipIds.push(...matchDestination.getAllVideoClipIds());
      }

      // Handle supplementary words
      matchVideoClipIds.push(...group.supplementaryWords.map((sw) => sw.destinationVideoClipId));
    }

    return matchVideoClipIds;
  }
  getAllVideoClipIds() {
    return _.flattenDeep(this.matchDestinations.map((md) => md.getAllVideoClipIds()));
  }
  getAllVideoClipIdsForContingency(uuid: string) {
    const contingency = this.getContingency(uuid);
    if (!contingency) return [];
    return contingency.matchDestinationIds.flatMap((matchDestinationId) => {
      const matchDestination = this.getMatchDestination(matchDestinationId);
      if (!matchDestination) return [];
      return matchDestination.getAllVideoClipIds();
    });
  }

  // Target phrase
  getTargetPhrase(uuid: string): TargetPhrase | undefined {
    for (const targetPhrasesGroup of this.targetPhraseGroups) {
      const targetPhrase = targetPhrasesGroup.getTargetPhrase(uuid);
      if (targetPhrase) return targetPhrase;
    }
  }
  getGroupForTargetPhrase(uuid: string): TargetPhraseGroup | undefined {
    return this.targetPhraseGroups.find((group) => group.targetPhrases.map((tp) => tp.uuid).includes(uuid));
  }
  getAllTargetPhrases() {
    return this.targetPhraseGroups.flatMap((group) => group.targetPhrases);
  }

  // Target phrase group
  getTargetPhraseGroup(uuid: string): TargetPhraseGroup | undefined {
    return this.targetPhraseGroups.find((group) => group.uuid === uuid);
  }
  getTargetPhraseGroupIndex(uuid: string): number {
    return this.targetPhraseGroups.findIndex((group) => group.uuid === uuid);
  }
  getTargetPhraseGroupForSupplementaryWord(uuid: string): TargetPhraseGroup | undefined {
    return this.targetPhraseGroups.find((group) => group.supplementaryWords.map((sw) => sw.uuid).includes(uuid));
  }

  // Match destination
  getMatchDestination(uuid: string): MatchDestination | undefined {
    return this.matchDestinations.find((md) => md.uuid === uuid);
  }

  // Contigency
  getContingency(uuid: string) {
    for (const targetPhraseGroup of this.targetPhraseGroups) {
      for (const contingency of targetPhraseGroup.contingencies) if (contingency.uuid === uuid) return contingency;
    }
  }

  // -- Mutations

  // Contingencies
  addContingencyToTargetPhraseGroup(uuid: string, placeholderId: string, matchSectionId: string) {
    const targetPhraseGroup = this.getTargetPhraseGroup(uuid);
    if (!targetPhraseGroup) return;

    // Create new contingency and add to target pharase group
    const contingency = new Contingency({ placeholderId: placeholderId, matchDestinationIds: [] });
    targetPhraseGroup.contingencies.push(contingency);

    // Add match destination to blank contingency
    this.addMatchDestinationToContingency(contingency.uuid, matchSectionId);
  }
  addMatchDestinationToContingency(uuid: string, sectionId: string) {
    const contingency = this.getContingency(uuid);
    if (!contingency) return;

    // Create match destination and add to array
    const matchDestination = new MatchDestination({ successVideoClipId: sectionId });
    this.matchDestinations.push(matchDestination);

    // Add match destination to contingency
    contingency.matchDestinationIds.push(matchDestination.uuid);
  }
  removeMatchDestinationFromContingency(uuid: string, matchDestinationId: string) {
    const contingency = this.getContingency(uuid);
    if (!contingency) return;

    // Remove from array
    this.matchDestinations = this.matchDestinations.filter((md) => md.uuid !== matchDestinationId);

    // Remove from contingency
    contingency.matchDestinationIds = contingency.matchDestinationIds.filter((mduuid) => mduuid !== matchDestinationId);
  }
  removeContingency(uuid: string) {
    const contingency = this.getContingency(uuid);
    if (!contingency) return;

    // Remove match destinations
    this.matchDestinations = this.matchDestinations.filter((md) => !contingency.matchDestinationIds.includes(md.uuid));

    // Remove contingency from target phrase group
    const targetPhraseGroup = this.targetPhraseGroups.find((tfg) =>
      tfg.contingencies.map((c) => c.uuid).includes(uuid)
    );
    if (!targetPhraseGroup) return;
    targetPhraseGroup.contingencies = targetPhraseGroup.contingencies.filter((c) => c.uuid !== uuid);
  }
}
