import Papa from "papaparse";

export default class CSVService {
  static instance = new CSVService();

  readFromUrl(url: string): Promise<any[]> {
    return new Promise((resolve) => {
      Papa.parse(url, {
        download: true,
        header: true,
        complete: (results) => {
          resolve(results.data);
        },
      });
    });
  }

  downloadCSV(data: any, filename: string) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/plain;charset=UTF-8" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
