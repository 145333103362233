import { Formik, Form, FormikProps } from "formik";
import { Adjustments, Plus, X, XCircle } from "heroicons-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Lesson, Phrase } from "../../core/domain/entities";
import { LessonComponentDisplayType } from "../../core/domain/entities/types";
import VocabComponent, { PhraseConfiguration } from "../../core/domain/entities/VocabComponent";
import VocabQuestion, { VocabQuestionType } from "../../core/domain/entities/VocabQuestion";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import { fetchPhrases, getAllPhrases, getPhrasesError, getPhrasesStatus } from "../../core/redux/phrasesSlice";
import { createVocabComponent, updateVocabComponent } from "../../core/redux/vocabComponentsSlice";
import {
  phraseByWordSearchFilter,
  phraseExactSearchFilter,
  phraseSearchFilter,
  SearchType,
  updateObject,
} from "../../service/helpers";
import { ButtonType } from "../base/Button";
import CheckBoxInput from "../base/CheckBoxInput";

import SelectMenu from "../base/SelectMenu";
import { Modal, Input, ModalFormButtons, Button, SearchView, StatusView, Dropdown } from "../index";
import DeepLinkGenerator from "./DeepLinkGenerator";

export type VocabComponentModalProps = {
  vocabComponent: VocabComponent;
  lesson: Lesson;
  isCreating?: boolean;
};

type VocabComponentFormValues = {
  title: string;
  phraseConfigurations: PhraseConfiguration[];
  questions: VocabQuestion[];
  viewGroups: string[];
  displayType: LessonComponentDisplayType;
  freePromoImage: string | undefined;
};

export default function VocabComponentModal({
  isOpen,
  vocabComponent,
  lesson,
  isCreating,
  onClose,
}: {
  isOpen: boolean;
  vocabComponent: VocabComponent;
  lesson: Lesson;
  isCreating?: boolean;
  onClose: () => void;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const currentLocale = useSelector(getCurrentLocale);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);

  // -- Effect
  useEffect(() => {
    if (currentLocale) dispatch(fetchPhrases(currentLocale));
  }, [dispatch, currentLocale]);

  // -- Functions
  const onSubmit = (values: VocabComponentFormValues) => {
    const updatedVocabComponent = updateObject(vocabComponent, values);
    updatedVocabComponent.questions = updatedVocabComponent.generateQustions(phrases);
    isCreating ? handleCreate(updatedVocabComponent) : handleUpdate(updatedVocabComponent);
    onClose();
  };
  const handleCreate = (vocabComponent: VocabComponent) => {
    dispatch(createVocabComponent({ vocabComponent: vocabComponent, lesson: lesson }));
  };
  const handleUpdate = (vocabComponent: VocabComponent) => {
    dispatch(updateVocabComponent({ vocabComponent: vocabComponent, lesson: lesson }));
  };

  const addPhrase = (phrase: Phrase, formProps: FormikProps<VocabComponentFormValues>) => {
    const { values } = formProps;
    const newPhraseConfigurations = [...values.phraseConfigurations, PhraseConfiguration.fromPhrase(phrase)];
    updateQuestions(formProps, newPhraseConfigurations);
  };
  const removePhrase = (phrase: Phrase, formProps: FormikProps<VocabComponentFormValues>) => {
    const { values } = formProps;
    const newPhraseConfigurations = values.phraseConfigurations.filter((p) => p.phraseId !== phrase.id);
    updateQuestions(formProps, newPhraseConfigurations);
  };

  function updateQuestions(
    formProps: FormikProps<VocabComponentFormValues>,
    phraseConfigurations: PhraseConfiguration[]
  ) {
    const { setFieldValue } = formProps;
    const updatedVocabComponent = updateObject(vocabComponent, { phraseConfigurations: phraseConfigurations });
    const newQuestions = updatedVocabComponent.generateQustions(phrases);
    console.log(updatedVocabComponent);
    console.log(newQuestions);
    console.log(phraseConfigurations);
    setFieldValue(
      "phraseConfigurations",
      phraseConfigurations.map((config) => config.updatePhraseChoices(newQuestions))
    );
    setFieldValue(`questions`, newQuestions);
  }

  function regenerateQuestions(formProps: FormikProps<VocabComponentFormValues>) {
    const { values, setFieldValue } = formProps;
    const newComponent = updateObject(vocabComponent, {
      phraseConfigurations: values.phraseConfigurations,
    });
    const newQuestions = newComponent.generateQustions(phrases, true);
    setFieldValue(
      "phraseConfigurations",
      values.phraseConfigurations.map((config) => config.updatePhraseChoices(newQuestions))
    );
    setFieldValue(`questions`, newQuestions);
  }

  function getPhraseIds(formProps: FormikProps<VocabComponentFormValues>) {
    return formProps.values.phraseConfigurations.map((p) => p.phraseId);
  }

  // -- Components
  const VocabPhraseCard = ({
    phrase,
    formProps,
  }: {
    phrase: Phrase;
    formProps: FormikProps<VocabComponentFormValues>;
  }) => {
    const [isEditingOptions, setIsEditingOptions] = useState(false);

    const phraseConfiguration = formProps.values.phraseConfigurations.find((config) => config.phraseId === phrase.id);
    if (!phraseConfiguration) return null;

    function toggleIsEditingOptions() {
      setIsEditingOptions(!isEditingOptions);
    }

    function setQuestionType(questionType: VocabQuestionType, isAdding: boolean) {
      if (!phraseConfiguration) return;
      const newPhraseConfiguration = updateObject(phraseConfiguration, {
        questionTypes: isAdding
          ? [...phraseConfiguration.questionTypes, questionType]
          : phraseConfiguration.questionTypes.filter((qt) => qt !== questionType),
      });
      const newPhraseConfigurations = [
        ...formProps.values.phraseConfigurations.map((p) => (p.phraseId === phrase.id ? newPhraseConfiguration : p)),
      ];
      formProps.setFieldValue("phraseConfigurations", newPhraseConfigurations);
    }

    function changeOption(prevPhrase: Phrase, newPhrase: Phrase, phraseConfiguration: PhraseConfiguration) {
      let updated = false;
      const newPhraseConfiguration = updateObject(phraseConfiguration, {
        phraseChoices: phraseConfiguration.phraseChoices!.map((id) => {
          if (updated) return id;
          if (id === prevPhrase.id) {
            updated = true;
            return newPhrase.id;
          }
          return id;
        }),
      });
      const newPhraseConfigurations: PhraseConfiguration[] = [
        ...formProps.values.phraseConfigurations.map((p) =>
          p.phraseId === newPhraseConfiguration.phraseId ? newPhraseConfiguration : p
        ),
      ];
      formProps.setFieldValue("phraseConfigurations", newPhraseConfigurations);
    }

    const Option = ({ phrase }: { phrase: Phrase }) => {
      const [selectPhraseDropdownIsOpen, setSelectPhraseDropdownIsOpen] = useState(false);

      function openSelectPhraseDropdown() {
        setSelectPhraseDropdownIsOpen(true);
      }
      function closeSelectPhraseDropdown() {
        setSelectPhraseDropdownIsOpen(false);
      }

      const SelectPhraseDropdown = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
        return (
          <Dropdown
            className="left-0 p-5 flex flex-col gap-2"
            style={{ width: 500 }}
            isOpen={isOpen}
            onClose={onClose}
            disableAutoClose
          >
            <SearchView
              items={phrases}
              containsFilter={phraseSearchFilter}
              exactFilter={phraseExactSearchFilter}
              wordFilter={phraseByWordSearchFilter}
              noneFoundMessage="No phrases found"
              limit={100}
              initialSearchType={SearchType.exact}
            >
              {(phrases) => (
                <div className="flex flex-col gap-2 max-h-96 overflow-scroll">
                  {phrases.map((phraseChoice) => (
                    <PhraseCard
                      key={phraseChoice.id}
                      phrase={phraseChoice}
                      onClick={() => changeOption(phrase, phraseChoice, phraseConfiguration)}
                      formProps={formProps}
                    />
                  ))}
                </div>
              )}
            </SearchView>
          </Dropdown>
        );
      };

      return (
        <div className="relative">
          <div
            className="text-xs text-gray-500 hover:text-indigo-600 cursor-pointer"
            onClick={openSelectPhraseDropdown}
          >
            {phrase.targetText}
          </div>
          <SelectPhraseDropdown isOpen={selectPhraseDropdownIsOpen} onClose={closeSelectPhraseDropdown} />
        </div>
      );
    };

    return (
      <div className="flex flex-col bg-gray-100 rounded-md p-2">
        <div className="flex gap-2 items-center">
          <div className="flex flex-1 gap-2">
            <div className="text-xs font-bold">{phrase.targetText}</div>
            <div className="text-xs">{phrase.nativeText}</div>
          </div>
          <div className="flex">
            {VocabQuestion.singlePhraseQuestionTypes().map((questionType) => (
              <CheckBoxInput
                key={questionType}
                value={phraseConfiguration.questionTypes.includes(questionType)}
                set={(value) => setQuestionType(questionType, value)}
              />
            ))}
          </div>
          <Button displayType={ButtonType.pure} onClick={toggleIsEditingOptions}>
            <Adjustments />
          </Button>
          <Button color="red" displayType={ButtonType.pure} shade={400} onClick={() => removePhrase(phrase, formProps)}>
            <XCircle />
          </Button>
        </div>
        {isEditingOptions ? (
          <div className="flex-col">
            {phraseConfiguration.phraseChoices && phraseConfiguration.phraseChoices.length > 0 ? (
              phraseConfiguration.phraseChoices?.map((phraseId) => {
                if (phraseId === phraseConfiguration.phraseId) return null;
                const phrase = phrases.find((p) => p.id === phraseId);
                if (!phrase) return null;
                return <Option key={phrase.id} phrase={phrase} />;
              })
            ) : (
              <div className="gray-400 text-xs">No options yet</div>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  const PhraseCard = ({
    phrase,
    onClick,
  }: {
    phrase: Phrase;
    onClick: () => void;
    formProps: FormikProps<VocabComponentFormValues>;
  }) => {
    return (
      <div
        onClick={onClick}
        className="flex-1 border border-gray-300 hover:border-indigo-400 cursor-pointer shadow-sm rounded-md p-2 flex items-center"
      >
        <div className={`flex-1 text-xs`}>
          <div className="font-bold text-gray-600">{phrase.targetText}</div>
          <div className="text-gray-600">{phrase.nativeText}</div>
          <div className="text-gray-400">{phrase.id}</div>
          {phrase.isHidden ? <div className="text-red-400">Hidden</div> : null}
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      title={isCreating ? "Create Vocab Set" : vocabComponent.title}
      subtitle={vocabComponent.id}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          title: vocabComponent.title,
          phraseConfigurations: vocabComponent.phraseConfigurations,
          questions: vocabComponent.questions,
          viewGroups: vocabComponent.viewGroups,
          displayType: vocabComponent.displayType,
          freePromoImage: vocabComponent.freePromoImage,
        }}
        validate={(values) => {
          const errors: { title?: string; subtitle?: string } = {};
          if (!values.title) errors.title = "Cannot be blank";
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {(formProps) => (
          <Form>
            <StatusView status={phrasesStatus} error={phrasesError}>
              <div className="flex flex-col gap-2">
                <Input
                  title="Title"
                  value={formProps.values.title}
                  set={(title) => formProps.setFieldValue(`title`, title)}
                />
                <SelectMenu
                  title="Display Type"
                  name="displayType"
                  value={formProps.values.displayType}
                  onChange={formProps.handleChange}
                >
                  {Object.values(LessonComponentDisplayType)
                    .filter((type) => typeof type === "string")
                    .map((type) => (
                      <option key={type}>{type}</option>
                    ))}
                </SelectMenu>
                <SelectMenu
                  title="Available to..."
                  value={formProps.values.viewGroups[0]}
                  set={(group) => {
                    formProps.setFieldValue(`viewGroups`, group === "pro" ? ["pro"] : ["free", "pro"]);
                  }}
                >
                  <option value={"free"}>Free Users</option>
                  <option value={"pro"}>Pro Users</option>
                </SelectMenu>
                <Input
                  title="Promo Image URL"
                  name="freePromoImage"
                  value={formProps.values.freePromoImage ?? ""}
                  set={(url) => formProps.setFieldValue("freePromoImage", url === "" ? undefined : url)}
                  className="col-span-full"
                />
                <Button className="my-2" shade={100} onClick={() => regenerateQuestions(formProps)}>
                  Generate Questions
                </Button>
                <div className="flex-1 flex flex-col gap-1 mb-2">
                  <div className="flex text-xs mb-0.5 justify-between">
                    <div className="font-medium">Phrases</div>
                    <div className="text-gray-500">Question Types (Show, Translate Text, Translate Audio, Speak)</div>
                  </div>
                  {getPhraseIds(formProps).length > 0 ? (
                    getPhraseIds(formProps).map((id) => {
                      const phrase = phrases.find((p) => p.id === id);
                      if (!phrase) return null;
                      return <VocabPhraseCard key={id} phrase={phrase} formProps={formProps} />;
                    })
                  ) : (
                    <div className="text-xs text-gray-400">None</div>
                  )}
                </div>
                <SearchView
                  items={phrases}
                  containsFilter={phraseSearchFilter}
                  exactFilter={phraseExactSearchFilter}
                  wordFilter={phraseByWordSearchFilter}
                  noneFoundMessage="No phrases found"
                  limit={100}
                  initialSearchType={SearchType.exact}
                >
                  {(phrases) => (
                    <div className="flex flex-col gap-2 max-h-96 overflow-scroll">
                      {phrases.map((phrase) => (
                        <PhraseCard
                          key={phrase.id}
                          phrase={phrase}
                          onClick={() => addPhrase(phrase, formProps)}
                          formProps={formProps}
                        />
                      ))}
                    </div>
                  )}
                </SearchView>
                <DeepLinkGenerator lesson={lesson} component={vocabComponent} />
              </div>
            </StatusView>
            <div className="h-5" />
            <ModalFormButtons className="col-span-full" submitText={isCreating ? "Create" : "Done"} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
