import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "./store";

const initialState = {
  current: null as any,
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    selectUser(state, { payload: user }: { payload: any }) {
      state.current = user;
    },
    deselectUser(state) {
      state.current = null;
    },
  },
});

export const { selectUser, deselectUser } = usersSlice.actions;

export const getCurrentUser = (state: RootState) => state.users.current;

export default usersSlice.reducer;
