import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { TargetPhraseGroup } from "../../core/domain/entities";
import { FailType, MatchType } from "../../core/domain/entities/types";
import { getComponent, updateCurrentComponent } from "../../core/redux/componentSlice";
import ModalFormButtons from "../base/ModalFormButtons";

import { Modal, SelectMenu } from "../index";

export type MatchFailDestinationModalProps = {
  targetPhraseGroup: TargetPhraseGroup;
};

export default function MatchFailDestinationModal({
  isOpen,
  targetPhraseGroup,
  onClose,
}: {
  isOpen: boolean;
  targetPhraseGroup: TargetPhraseGroup;
  onClose: () => void;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);

  // -- Functions
  const onSubmit = (values: { type: MatchType | FailType }) => {
    if (Object.values(MatchType).includes(values.type as string as MatchType)) {
      lessonComponent!.addMatchDestinationToTargetPhraseGroup(targetPhraseGroup.uuid, values.type as MatchType);
    } else {
      lessonComponent!.addFailDestinationToTargetPhraseGroup(targetPhraseGroup.uuid, values.type as FailType);
    }
    dispatch(updateCurrentComponent(lessonComponent!));
    onClose();
  };

  const getAvailableMatchFailTypes = () => {
    const objective = lessonComponent!.getObjectiveForTargetPhraseGroup(targetPhraseGroup.uuid);
    if (!objective) return [];
    const matchDestination = objective.getMatchDestination(targetPhraseGroup.matchDestinationId);
    if (!matchDestination) return [];
    return Object.values({ ...MatchType, ...FailType })
      .filter((type) => typeof type === "string")
      .filter(
        (type) =>
          !matchDestination.getAvailableMatchTypes().includes(type as MatchType) &&
          !objective.failDestination.getAvailableFailTypes().includes(type as FailType)
      );
  };

  return (
    <Modal isOpen={isOpen} title={"Add Match/Fail Type"} onClose={onClose}>
      <Formik
        initialValues={{
          type: MatchType.EXACT,
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleChange }) => (
          <Form>
            <SelectMenu title="Type" name="type" value={values.type} onChange={handleChange}>
              {getAvailableMatchFailTypes().map((type) => (
                <option key={type}>{type}</option>
              ))}
            </SelectMenu>
            <div className="h-10" />
            <ModalFormButtons submitText="Add" onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
