import { Lesson, LessonComponent, Locale, VocabComponent } from "./entities";
import Outcome from "../../service/api/Outcome";
import LessonRepository from "./LessonRepository";
import LessonComponentRepository from "./LessonComponentRepository";
import VocabComponentRepository from "./VocabComponentRepository";

export class UseCaseGetLessons {
  repository: LessonRepository;
  constructor(repository: LessonRepository) {
    this.repository = repository;
  }

  async execute(locale: Locale): Promise<Outcome<Lesson[]>> {
    return this.repository.allLessons(locale);
  }
}

export class UseCaseCreateLesson {
  repository: LessonRepository;
  constructor(repository: LessonRepository) {
    this.repository = repository;
  }

  async execute(lesson: Lesson): Promise<Outcome<Lesson>> {
    return this.repository.createLesson(lesson);
  }
}

export class UseCaseUpdateLesson {
  repository: LessonRepository;
  constructor(repository: LessonRepository) {
    this.repository = repository;
  }

  async execute(lesson: Lesson): Promise<Outcome<Lesson>> {
    return this.repository.updateLesson(lesson);
  }
}

export class UseCaseDeleteLesson {
  repository: LessonRepository;
  constructor(repository: LessonRepository) {
    this.repository = repository;
  }

  async execute(lesson: Lesson): Promise<Outcome<Lesson>> {
    return this.repository.deleteLesson(lesson);
  }
}

export class UseCaseReorderLessons {
  repository: LessonRepository;
  constructor(repository: LessonRepository) {
    this.repository = repository;
  }

  execute(updatedLessons: Lesson[]): Lesson[] {
    for (const [index, updatedLesson] of Object.entries(updatedLessons)) {
      updatedLesson.orderIndex = Number(index);
      this.repository.updateLesson(updatedLesson);
    }

    return updatedLessons;
  }
}

export class UseCaseReorderComponents {
  repository: LessonRepository;
  lessonComponentRepository: LessonComponentRepository;
  vocabComponentRepository: VocabComponentRepository;

  constructor(
    repository: LessonRepository,
    lessonComponentRepository: LessonComponentRepository,
    vocabComponentRepository: VocabComponentRepository
  ) {
    this.repository = repository;
    this.lessonComponentRepository = lessonComponentRepository;
    this.vocabComponentRepository = vocabComponentRepository;
  }

  execute(
    fromLesson: Lesson,
    toLesson: Lesson,
    component: LessonComponent | VocabComponent,
    fromIndex: number,
    toIndex: number,
    lessonComponents: LessonComponent[],
    vocabComponents: VocabComponent[]
  ): Lesson[] {
    if (fromLesson.id === toLesson.id) {
      const allComponents = fromLesson.allSortedComponents(lessonComponents, vocabComponents);
      const ordered = [...allComponents];
      const [reordered] = ordered.splice(fromIndex, 1);
      ordered.splice(toIndex, 0, reordered);

      this.updateOrderIndicies(fromLesson, ordered);

      const newComponentIds = ordered.filter((c) => c instanceof LessonComponent).map((c) => c.id);
      const newVocabComponentIds = ordered.filter((c) => c instanceof VocabComponent).map((c) => c.id);
      fromLesson.componentIds = newComponentIds;
      fromLesson.vocabComponentIds = newVocabComponentIds;

      this.repository.updateLesson(fromLesson);
      return [fromLesson];
    } else {
      fromLesson.removeComponent(component);
      this.updateOrderIndicies(fromLesson, fromLesson.allSortedComponents(lessonComponents, vocabComponents));

      const allToLessonComponents = toLesson.allSortedComponents(lessonComponents, vocabComponents);
      toLesson.addComponent(component);
      const ordered = [...allToLessonComponents];
      ordered.splice(toIndex, 0, component);
      this.updateOrderIndicies(toLesson, ordered);

      this.repository.updateLesson(fromLesson);
      this.repository.updateLesson(toLesson);
      return [fromLesson, toLesson];
    }
  }

  updateOrderIndicies(lesson: Lesson, orderedComponents: (LessonComponent | VocabComponent)[]) {
    for (let i = 0; i < orderedComponents.length; i++) {
      const component = orderedComponents[i];
      if (component.orderIndex !== i) {
        component.orderIndex = i;
        component instanceof LessonComponent
          ? this.lessonComponentRepository.updateLessonComponent(component)
          : this.vocabComponentRepository.updateVocabComponent(component);
      }
    }
  }
}
