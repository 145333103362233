import { Duplicate, Link } from "heroicons-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, StatusView, UploadView } from "..";
import { DeepLink, Lesson, LessonComponent, VocabComponent } from "../../core/domain/entities";
import {
  fetchDeepLinks,
  generateDeepLink,
  getAllDeepLinks,
  getDeepLinksError,
  getDeepLinksStatus,
} from "../../core/redux/deepLinksSlice";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import { copyToClipboard } from "../../service/helpers";
import { getNextSuffix } from "../../service/StorageSerivce";
import { ButtonType } from "../base/Button";

export default function DeepLinkGenerator({
  lesson,
  component,
  className,
  ...rest
}: {
  lesson?: Lesson;
  component: LessonComponent | VocabComponent;
  className?: string;
  [key: string]: any;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const currentLocale = useSelector(getCurrentLocale);
  const deepLinks = useSelector(getAllDeepLinks);
  const deepLinksStatus = useSelector(getDeepLinksStatus);
  const deepLinksError = useSelector(getDeepLinksError);

  // -- Local state
  const [title, setTitle] = useState(`${lesson ? `${lesson.title} - ` : ""}${component.title}`);
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState(component instanceof LessonComponent ? component.imageUrl : undefined);
  const [deepLink, setDeepLink] = useState<DeepLink | undefined>();

  // -- Effect
  useEffect(() => {
    dispatch(fetchDeepLinks(currentLocale!));
  }, [currentLocale]);

  useEffect(() => {
    const existingLinks = deepLinks.filter((d) => d.componentId === component.id);
    existingLinks.sort((a, b) => {
      return b.createdAt!.getTime() - a.createdAt!.getTime();
    });
    if (existingLinks[0]) {
      setTitle(existingLinks[0].title);
      setDescription(existingLinks[0].description);
      setImageUrl(existingLinks[0].imageUrl);
      setDeepLink(existingLinks[0]);
    }
  }, [deepLinks, deepLinksStatus]);

  // -- Functions
  function generate() {
    dispatch(
      generateDeepLink({
        lessonType: component instanceof LessonComponent ? "video" : "vocab",
        componentId: component.id,
        lessonId: lesson?.id,
        title: title,
        description: description,
        type: component instanceof LessonComponent ? component.type : "VOCAB",
        imageUrl: imageUrl,
      })
    );
  }

  return (
    <div {...rest} className={`flex flex-col gap-2 ${className}`}>
      <div className="text-sm font-medium">Deep Link</div>
      <StatusView status={deepLinksStatus} error={deepLinksError}>
        <div className="grid grid-cols-2 gap-2">
          <Input title="Title" name="title" value={title} set={setTitle} />
          <Input title="Description" name="description" value={description} set={setDescription} />
          <UploadView
            title="Image"
            accept="image/*"
            url={imageUrl}
            setUrl={setImageUrl}
            path={`images/${currentLocale!.formattedCode}/lesson/deepLink`}
            fileName={component.id + getNextSuffix(imageUrl)}
          />
          <img className="rounded-md w-1/2 self-center" src={imageUrl} />
          <Button className="col-span-full" shade={100} onClick={generate}>
            <Link size={16} />
            Generate Deep Link
          </Button>
          <div className="p-1 px-5 text-xs text-gray-600 text-center bg-gray-100 rounded-md col-span-full flex gap-2 justify-center items-center">
            <Button
              displayType={ButtonType.pure}
              color="gray"
              onClick={() => copyToClipboard(deepLink?.shortLink ?? "")}
            >
              <Duplicate size={16} />
            </Button>
            {deepLink ? deepLink.shortLink : "No Deep Link"}
          </div>
        </div>
      </StatusView>
    </div>
  );
}
