import { Button } from "..";

export default function ModalFormButtons({
  className,
  onSubmit,
  onClose,
  submitText,
  ...rest
}: {
  className?: string;
  onSubmit?: () => void;
  onClose: () => void;
  submitText?: string;
  [key: string]: any;
}) {
  return (
    <div {...rest} className={`flex flex-col gap-2 ${className}`}>
      <Button type="submit" onClick={onSubmit}>
        {submitText ?? "Done"}
      </Button>
      <Button shade={100} onClick={onClose}>
        Cancel
      </Button>
    </div>
  );
}
