export default function Input({
  className,
  inputClassName,
  title,
  value,
  onChange,
  set,
  filter,
  children,
  note,
  sideComponent,
  type,
  ...rest
}: {
  className?: string;
  inputClassName?: string;
  title?: string;
  value: string;
  onChange?: (e: any) => void;
  set?: (e: any) => void;
  filter?: (value: string) => string;
  children?: any;
  note?: string;
  sideComponent?: any;
  type?: string;
  [key: string]: any;
}) {
  var cursor: number | null = null;

  return (
    <div className={`flex flex-col gap-1 text-xs font-medium ${className}`}>
      {title}
      <div className="flex items-center">
        <div className="absolute h-9 flex items-center ml-2">{children}</div>
        <div className="flex gap-1 flex-1">
          <input
            {...rest}
            type={type}
            children={null}
            className={`flex-1 bg-white h-9 px-3 border rounded-md shadow-sm text-xs ${inputClassName}`}
            value={value}
            onChange={(e) => {
              cursor = e.target.selectionStart;
              const value = filter ? filter(e.target.value) : e.target.value;
              e.target.value = value;
              if (onChange) onChange(e);
              if (set) set(type === "number" ? parseFloat(value) : value);
            }}
            onFocus={(e) => {
              if (e.target.selectionStart) e.target.selectionStart = cursor;
            }}
          />
          {sideComponent ? sideComponent : null}
        </div>
      </div>
      {note ? <div className="text-xs font-normal text-gray-400 mt-1.5">{note}</div> : null}
    </div>
  );
}
