import RemoteDataSource from "./RemoteDataSource";
import LessonComponentRepository from "../domain/LessonComponentRepository";
import { LessonComponent, Locale } from "../domain/entities";
import Outcome from "../../service/api/Outcome";

export default class LessonComponentRepositoryImplementation extends LessonComponentRepository {
  remoteDataSource: RemoteDataSource;

  constructor(remoteDataSource: RemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async allLessonComponents(locale: Locale): Promise<Outcome<LessonComponent[]>> {
    return this.remoteDataSource.allLessonComponents(locale);
  }

  async createLessonComponent(lessonComponent: LessonComponent): Promise<Outcome<LessonComponent>> {
    return this.remoteDataSource.createLessonComponent(lessonComponent);
  }

  async updateLessonComponent(lessonComponent: LessonComponent): Promise<Outcome<LessonComponent>> {
    return this.remoteDataSource.updateLessonComponent(lessonComponent);
  }

  async deleteLessonComponent(lessonComponent: LessonComponent): Promise<Outcome<LessonComponent>> {
    return this.remoteDataSource.deleteLessonComponent(lessonComponent);
  }
}
