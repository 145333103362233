export default function FormErrorMessage({
  className,
  error,
  ...rest
}: {
  className?: string;
  error: string | undefined;
  [key: string]: any;
}) {
  if (error) {
    return (
      <div {...rest} className={`text-red-500 text-xs mt-1 ${className}`}>
        {error}
      </div>
    );
  } else {
    return null;
  }
}
