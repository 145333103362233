import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import { DataGlobalVideoSection, DataObjective } from ".";
import { GlobalVideoSection, LessonComponent, Phrase, Video, VideoClip } from "../../domain/entities";
import { LessonComponentType, LessonComponentDisplayType, generatedComponentTypes } from "../../domain/entities/types";
import { EnvironmentArgument, envUrl } from "../../../service/PushService";
import { Changeable } from "../../../service/Change";
import FullLessonComponent from "./FullLessonComponent";

export default class DataLessonComponent implements Changeable {
  id: string;
  title: string;
  audioUrl?: string | null;
  imageUrl: string;
  videoClips: VideoClip[];
  type: LessonComponentType;
  objectives: DataObjective[];
  masterPlaylist: string[];
  localeId: string;
  isHidden: boolean;
  isMagnet: boolean;
  createdBy: string;
  updatedBy: string;
  groupsCanAccess: string[];
  createdAt: string;
  updatedAt: string;
  isChecked: boolean;
  displayType: LessonComponentDisplayType;
  viewGroups: string[];
  orderIndex: number;
  freePromoImage?: string;

  constructor(object: Partial<DataLessonComponent> = {}) {
    this.id = object.id ?? uuidv4();
    this.title = object.title ?? "";
    this.audioUrl = object.audioUrl;
    this.imageUrl = object.imageUrl ?? "https://google.com";
    this.videoClips = object.videoClips ? object.videoClips.map((vc: VideoClip) => new VideoClip(vc)) : [];
    this.type = object.type ?? LessonComponentType.INTRODUCTION;
    this.objectives = object.objectives ? object.objectives.map((o: DataObjective) => new DataObjective(o)) : [];
    this.masterPlaylist = object.masterPlaylist ?? [];
    this.localeId = object.localeId ?? "";
    this.isHidden = object.isHidden ?? false;
    this.isMagnet = object.isMagnet ?? false;
    this.createdBy = object.createdBy ?? "";
    this.updatedBy = object.updatedBy ?? "";
    this.createdAt = object.createdAt ?? new Date().toISOString();
    this.updatedAt = object.updatedAt ?? new Date().toISOString();
    this.groupsCanAccess = ["free"];
    this.isChecked = object.isChecked ?? false;
    this.displayType = object.displayType ?? LessonComponentDisplayType.COURSE;
    this.viewGroups = object.viewGroups ?? ["pro"];
    this.orderIndex = object.orderIndex ?? 0;
    this.freePromoImage = object.freePromoImage;
  }

  convertToDomain(): LessonComponent {
    return new LessonComponent({
      id: this.id,
      title: this.title,
      audioUrl: this.audioUrl,
      imageUrl: this.imageUrl,
      videoClips: this.videoClips,
      type: this.type,
      objectives: this.objectives.map((o) => o.convertToDomain()),
      masterPlaylist: this.masterPlaylist,
      localeId: this.localeId,
      isHidden: this.isHidden,
      isMagnet: this.isMagnet,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      groupsCanAccess: this.groupsCanAccess,
      isChecked: this.isChecked,
      displayType: this.displayType,
      viewGroups: this.viewGroups,
      orderIndex: this.orderIndex,
      freePromoImage: this.freePromoImage,
    });
  }
  getIdentifier() {
    return `${this.title} - ${this.id}`;
  }
  getTypeName() {
    return "LessonComponent";
  }

  async toEnv(fromEnv: EnvironmentArgument, toEnv: EnvironmentArgument, push: boolean = false) {
    if (this.audioUrl) this.audioUrl = await envUrl(this.audioUrl, fromEnv, toEnv, push);
    this.imageUrl = await envUrl(this.imageUrl, fromEnv, toEnv, push);
    return this;
  }

  full(videos: Video[], phrases: Phrase[], globalVideoSections: DataGlobalVideoSection[]) {
    let videoClips, objectives;

    if (generatedComponentTypes.includes(this.type)) {
      const linkedGlobalVideoSections = this.masterPlaylist.map((id) =>
        globalVideoSections.find((gvs) => gvs.sectionId === id)
      );
      if (globalVideoSections.some((gvs) => !gvs)) throw "Not all GlobalVideoSections found";
      this.videoClips = linkedGlobalVideoSections.flatMap((gvs) => gvs!.videoClips);
      this.objectives = linkedGlobalVideoSections.flatMap((gvs) => gvs!.objectives);
    }

    videoClips = this.videoClips;
    objectives = this.objectives;

    let neededVideos = [] as Video[];
    for (const name of videoClips.map((vc) => vc.databaseName)) {
      if (!name) continue;
      const video = videos.find((v) => v.name === name);
      if (!video) {
        console.log("VIDEO NOT FOUND", name, this.id);
        continue;
        throw `Video not found: ${name}`;
      }
      if (!neededVideos.find((v) => v.id === video.id)) neededVideos.push(video);
    }
    let neededPhrases = [] as Phrase[];
    for (const id of objectives.flatMap((o) => o.targetPhrases.map((tp) => tp.rootPhraseId))) {
      const phrase = phrases.find((p) => p.id === id);
      if (!phrase) {
        console.log("PHRASE NOT FOUND", id, this.id);
        continue;
        throw `Phrase not found: ${id}`;
      }
      if (!neededPhrases.find((p) => p.id === phrase.id)) neededPhrases.push(phrase);
    }
    let neededWords = [] as Phrase[];
    for (const phrase of neededPhrases) {
      for (const wordId of phrase.wordIds) {
        const word = phrases.find((p) => p.id === wordId);
        if (!word) throw `Word not found: ${wordId}`;
        if (!neededWords.find((w) => w.id === word.id)) neededWords.push(word);
      }
    }

    for (const word of neededWords) if (!neededPhrases.some((p) => p.id === word.id)) neededPhrases.push(word);
    if (neededPhrases.some((phrase) => phrase.targetText === "")) {
      throw "Not all Phrases found";
    }

    return new FullLessonComponent(this, neededVideos, neededPhrases);
  }
}
