import { DotsHorizontal, Duplicate, PencilAlt, XCircle } from "heroicons-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { pagePaths } from "../../App";
import { Lesson, LessonComponent } from "../../core/domain/entities";
import { selectComponent } from "../../core/redux/componentSlice";
import { deleteLessonComponent, updateLessonComponent } from "../../core/redux/lessonComponentsSlice";
import { chooseComponent, getDestinationLessonId, selectLesson } from "../../core/redux/lessonsSlice";
import { openModal } from "../../core/redux/modalSlice";
import { copyToClipboard, parseDateTime, updateObject } from "../../service/helpers";
import { ButtonType } from "../base/Button";

import { Button, CheckBoxInput, Dropdown, DropdownItem, IsHiddenButton } from "../index";

export default function LessonComponentCard({
  lessonComponent,
  lesson,
}: {
  lessonComponent: LessonComponent;
  lesson: Lesson;
}) {
  // -- Navigation
  const history = useHistory();

  // -- Redux
  const dispatch = useDispatch();
  const destinationLessonId = useSelector(getDestinationLessonId);

  // -- Local state
  const [isHidden, setIsHidden] = useState(lessonComponent.isHidden);
  const [isChecked, setIsChecked] = useState(lessonComponent.isChecked);

  const [optionsDropdownIsOpen, setOptionsDropdownIsOpen] = useState(false);

  // -- Functions
  const handleMainAction = () => {
    if (destinationLessonId) {
      dispatch(chooseComponent(lessonComponent));
      return;
    }

    dispatch(selectLesson(lesson));
    dispatch(selectComponent(lessonComponent));
    history.push(pagePaths.editLessonComponent);
  };
  const toggleIsChecked = (value: boolean) => {
    setIsChecked(value);
    dispatch(
      updateLessonComponent(
        updateObject(lessonComponent, {
          isChecked: value,
        })
      )
    );
  };
  const toggleIsHidden = (value: boolean) => {
    setIsHidden(value);
    dispatch(
      updateLessonComponent(
        updateObject(lessonComponent, {
          isHidden: value,
        })
      )
    );
  };
  const handleDeleteLessonComponent = () => {
    dispatch(deleteLessonComponent({ lessonComponent: lessonComponent, lesson: lesson }));
  };

  // UI
  const openEditModal = () => {
    dispatch(
      openModal({
        lessonComponentModal: {
          lesson: lesson,
          lessonComponent: lessonComponent,
        },
      })
    );
  };

  const openOptionsDropdown = () => {
    setOptionsDropdownIsOpen(true);
  };
  const closeOptionsDropdown = () => {
    setOptionsDropdownIsOpen(false);
  };

  // Components
  const OptionsDropdown = () => {
    return (
      <Dropdown isOpen={optionsDropdownIsOpen} onClose={closeOptionsDropdown}>
        <DropdownItem onClick={openEditModal}>
          <PencilAlt size={16} />
          Edit
        </DropdownItem>
        <DropdownItem color="red" shade={500} onClick={handleDeleteLessonComponent}>
          <XCircle size={16} />
          Delete
        </DropdownItem>
      </Dropdown>
    );
  };

  return (
    <div className={`rounded-md flex justify-between items-center relative`}>
      <div style={{ flex: 0.4 }} className="flex items-center gap-2 mr-5">
        {/* <ValidationIndicator object={lessonComponent} absolute={false} className="relative mr-2" /> */}
        <Button className="text-sm text-left" displayType={ButtonType.textAlt} onClick={handleMainAction}>
          {lessonComponent.orderIndex + 1 + ". " + lessonComponent.title}
        </Button>
      </div>
      <div style={{ flex: 0.6 }} className="flex gap-5 items-center">
        <div className="flex gap-2 items-center text-gray-400" style={{ flex: 0.5 }}>
          <Button displayType={ButtonType.pure} color="gray" onClick={() => copyToClipboard(lessonComponent.id)}>
            <Duplicate size={16} />
          </Button>
          <div className="text-xs">{lessonComponent.id}</div>
        </div>
        <div className="text-xs text-gray-400" style={{ flex: 0.2 }}>
          {lessonComponent.type}
        </div>
        <div className="text-xs text-gray-400" style={{ flex: 0.3 }}>
          {parseDateTime(lessonComponent.updatedAt)}
        </div>
      </div>
      <div style={{ flex: 0.1 }} className="flex gap-1 justify-end items-center">
        <CheckBoxInput value={isChecked} set={toggleIsChecked} />
        <IsHiddenButton isHidden={isHidden} onToggle={toggleIsHidden} />
        <div className="relative">
          <Button className="w-9" onClick={openOptionsDropdown} displayType={ButtonType.text}>
            <DotsHorizontal size={16} />
          </Button>
          <OptionsDropdown />
        </div>
      </div>
    </div>
  );
}
