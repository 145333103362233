import { useState } from "react";
import { Clipboard, Code, Duplicate, Flag, PencilAlt, PlusCircle, XCircle } from "heroicons-react";
import { useDispatch, useSelector } from "react-redux";

import {
  Objective,
  SupplementaryWord,
  TargetPhraseGroup,
  VideoClip,
  LessonComponent,
} from "../../core/domain/entities";
import { FailType, kleoColorHex, MatchType, SectionType, VideoClipType } from "../../core/domain/entities/types";
import {
  copyVideoClip,
  getCopiedVideoClip,
  getComponent,
  pasteVideoClip,
  updateCurrentComponent,
} from "../../core/redux/componentSlice";
import { openModal } from "../../core/redux/modalSlice";
import { getAllVideos, getVideosError, getVideosStatus } from "../../core/redux/videosSlice";

import { Dropdown, DropdownItem, ValidationIndicator, StatusView } from "..";
import { ValidationIssueGravity } from "../../service/Validation";

export default function VideoClipCard({
  videoClip,
  row,
  column,
}: {
  videoClip: VideoClip;
  row: number;
  column: number;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);
  const copiedVideoClip = useSelector(getCopiedVideoClip);

  const videos = useSelector(getAllVideos);
  const videosStatus = useSelector(getVideosStatus);
  const videosError = useSelector(getVideosError);

  // -- Local state
  const [optionsDropdownIsOpen, setOptionsDropdownIsOpen] = useState(false);
  const [colorsDropdownIsOpen, setColorsDropdownIsOpen] = useState(false);

  // -- Functions
  const copy = () => {
    dispatch(copyVideoClip(videoClip));
  };
  const paste = () => {
    if (copiedVideoClip) {
      videoClip.paste(copiedVideoClip);
      lessonComponent!.updateVideoClip(videoClip);
      dispatch(updateCurrentComponent(lessonComponent!));
      dispatch(pasteVideoClip());
    }
  };

  const handleDeleteVideoClip = () => {
    lessonComponent!.deleteVideoClip(videoClip.uuid);
    dispatch(updateCurrentComponent(lessonComponent!));
  };

  const handleColorChange = (color: string) => {
    const colorHex = kleoColorHex[color];
    videoClip.colorHex = colorHex;
    lessonComponent!.updateVideoClip(videoClip);
    dispatch(updateCurrentComponent(lessonComponent!));
  };

  const addDestination = () => {
    lessonComponent!.addDestinationToVideoClip(videoClip.uuid);
    dispatch(updateCurrentComponent(lessonComponent!));
  };

  const canDelete = () => {
    const parent = lessonComponent!.getParentForVideoClip(videoClip.uuid);
    if (!parent) return false;

    if (parent instanceof Objective) {
      if (parent.failDestination.getFailTypeOfVideoClip(videoClip.uuid) === FailType.FAIL) return false;
    }
    if (parent instanceof TargetPhraseGroup) {
      const matchDestination = lessonComponent!.getMatchDestinationWithVideoClip(videoClip.uuid);
      if (!matchDestination || matchDestination.getMatchTypeOfVideoClip(videoClip.uuid) === MatchType.SUCCESS)
        return false;
    }
    if (parent instanceof SupplementaryWord) return true;

    return true;
  };

  // UI
  const openEditModal = () => {
    dispatch(
      openModal({
        videoClipModal: {
          videoClip: videoClip,
        },
      })
    );
  };

  const openSupplementaryWordModal = (supplementaryWord: SupplementaryWord) => {
    dispatch(
      openModal({
        supplementaryWordModal: {
          supplementaryWord: supplementaryWord,
        },
      })
    );
  };

  const openOptionsDropdown = () => {
    if (videoClip.type !== VideoClipType.PLACEHOLDER) setOptionsDropdownIsOpen(true);
  };
  const closeOptionsDropdown = () => {
    setOptionsDropdownIsOpen(false);
  };

  const openColorsDropdown = () => {
    setColorsDropdownIsOpen(true);
  };
  const closeColorsDropdown = () => {
    setColorsDropdownIsOpen(false);
  };

  // -- Components
  const ColorPicker = () => {
    if (videoClip.colorHex)
      return (
        <div
          className="rounded-full mr-1 w-6 h-6 shadow-sm hover:shadow-xl transform transition hover:scale-125 cursor-pointer"
          style={{ backgroundColor: videoClip.colorHex === kleoColorHex.goldKleo ? "#4f46e5" : videoClip.colorHex }}
          onClick={(e) => {
            e.stopPropagation();
            openColorsDropdown();
          }}
        />
      );
    return null;
  };

  const OptionsDropdown = () => {
    const EditSupplementaryWordOption = () => {
      const supplementaryWord = lessonComponent!.getSupplementaryWordForVideoClip(videoClip.uuid);
      if (!supplementaryWord) return null;
      return (
        <DropdownItem onClick={() => openSupplementaryWordModal(supplementaryWord)}>
          <Flag size={16} />
          Edit Supplementary Word
        </DropdownItem>
      );
    };

    return (
      <Dropdown className="top-4 divide-y" isOpen={optionsDropdownIsOpen} onClose={closeOptionsDropdown}>
        <div>
          <DropdownItem onClick={openEditModal}>
            <PencilAlt size={16} />
            Edit
          </DropdownItem>
          <EditSupplementaryWordOption />
          <DropdownItem onClick={addDestination}>
            <PlusCircle size={16} />
            Add Destinaton
          </DropdownItem>
        </div>
        <div>
          <DropdownItem onClick={copy}>
            <Duplicate size={16} />
            Copy
          </DropdownItem>
          <DropdownItem onClick={paste}>
            <Clipboard size={16} />
            Paste
          </DropdownItem>
        </div>
        {canDelete() ? (
          <div>
            <DropdownItem color="red" shade={500} onClick={handleDeleteVideoClip}>
              <XCircle size={16} />
              Delete
            </DropdownItem>
          </div>
        ) : null}
      </Dropdown>
    );
  };

  const ColorsDropdown = () => {
    return (
      <Dropdown className="top-2" isOpen={colorsDropdownIsOpen} onClose={closeColorsDropdown}>
        {Object.keys(kleoColorHex).map((color) => (
          <DropdownItem
            key={color}
            className="flex jusfity-between items-center"
            onClick={() => handleColorChange(color)}
          >
            <div className="flex-1">{color}</div>
            <div className="w-3 h-3 rounded-full" style={{ backgroundColor: kleoColorHex[color] }} />
          </DropdownItem>
        ))}
      </Dropdown>
    );
  };

  const HasChangedIndicator = () => {
    const video = videos.find((video) => video.name === videoClip.databaseName);

    if (video && video.hasChanged)
      return (
        <div className={`w-3 h-3 absolute -top-1 -right-1`}>
          <div className={`absolute w-full h-full rounded-full bg-indigo-600`} />
          <div className={`absolute w-full h-full rounded-full animate-ping bg-indigo-600`} />
        </div>
      );
    return null;
  };

  const ContingencyIndicator = () => {
    if (
      Object.keys(SectionType).includes(videoClip.type as string) &&
      !lessonComponent!.masterPlaylist.includes(videoClip.uuid) &&
      lessonComponent!.masterPlaylist.length > 0
    )
      return <Code size={16} className="self-center mr-2" />;
    return null;
  };

  return (
    <div className="relative" style={{ gridRow: row, gridColumn: column }}>
      <div
        className={`
          w-52 py-1 px-2 h-full
          hover:shadow-xl 
          rounded-lg 
          flex justify-between items-center gap-2
          transform transition hover:scale-105
          bg-white
          cursor-pointer 
        `}
        onClick={openOptionsDropdown}
      >
        <StatusView status={videosStatus} error={videosError}>
          <ValidationIndicator validationOverride={videoClip.validate(lessonComponent!, videos)} />
          <HasChangedIndicator />
          <div className="flex-1 flex flex-col py-1.5 px-1">
            <div className="font-medium" style={{ fontSize: 11 }}>
              {videoClip.title}
            </div>
            <div className="text-gray-400" style={{ fontSize: 9 }}>
              {videoClip.databaseName ? videoClip.databaseName : "No video"}
            </div>
          </div>
          <ContingencyIndicator />
          <ColorPicker />
        </StatusView>
      </div>
      <ColorsDropdown />
      <OptionsDropdown />
    </div>
  );
}
