import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { remoteDataSource } from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { updateObjectInList } from "../../service/helpers";
import { Locale, Section } from "../domain/entities";
import { RootState } from "./store";
import { StatusType } from "./types";

export const fetchSections = createAsyncThunk(`sections/fetch`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.sections.items.length > 0 && state.sections.items[0].localeId !== locale.id;
  if (state.sections.items.length > 0 && (!localeDidChange || state.sections.status !== StatusType.loading))
    return state.sections.items;

  const result = await remoteDataSource.allSections(locale);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const fetchProdSections = createAsyncThunk(`sections/fetchProd`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.sections.items.length > 0 && state.sections.items[0].localeId !== locale.id;
  if (state.sections.items.length > 0 && (!localeDidChange || state.sections.status !== StatusType.loading))
    return state.sections.items;

  const result = await remoteDataSource.allSections(locale, "prod");

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

const initialState = {
  items: [] as Section[],
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as Section[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
};

export const sectionsSlice = createSlice({
  name: "sections",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSections.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchSections.fulfilled, (state, { payload: sections }: { payload: Section[] }) => {
      state.status = StatusType.succeeded;
      state.items = sections;
    });
    builder.addCase(fetchSections.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(fetchProdSections.pending, (state) => {
      state.prodStatus = StatusType.loading;
    });
    builder.addCase(fetchProdSections.fulfilled, (state, { payload: sections }: { payload: Section[] }) => {
      state.prodStatus = StatusType.succeeded;
      state.prodItems = sections;
    });
    builder.addCase(fetchProdSections.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
  },
});

export const getAllSections = (state: RootState) => state.sections.items;
export const getSectionsStatus = (state: RootState) => state.sections.status;
export const getSectionsError = (state: RootState) => state.sections.error;
export const getAllProdSections = (state: RootState) => state.sections.prodItems;
export const getProdSectionsStatus = (state: RootState) => state.sections.prodStatus;
export const getProdSectionsError = (state: RootState) => state.sections.prodError;

export default sectionsSlice.reducer;
