import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Upload } from "heroicons-react";

import { getCurrentLocale } from "../core/redux/localesSlice";
import { fetchVideos, getAllVideos, getVideosError, getVideosStatus } from "../core/redux/videosSlice";

import {
  fetchLessonComponents,
  getAllLessonComponents,
  getLessonComponentsError,
  getLessonComponentsStatus,
} from "../core/redux/lessonComponentsSlice";
import {
  fetchGlobalVideoSections,
  getAllGlobalVideoSections,
  getGlobalVideoSectionsError,
  getGlobalVideoSectionsStatus,
} from "../core/redux/globalVideoSectionsSlice";
import {
  allVideosFilter,
  getError,
  getWorstStatus,
  videoExactSearchFilter,
  VideoFilterType,
  videoAllCompleteFilter,
  videoAllErroredFilter,
  videoChangedCompleteFilter,
  videoChangedErroredFilter,
  videoChangedFilter,
  videoSearchFilter,
  parseDateTime,
} from "../service/helpers";
import { Video } from "../core/domain/entities";
import { openModal } from "../core/redux/modalSlice";

import {
  Button,
  SearchView,
  SelectMenu,
  StatusView,
  Table,
  TableItem,
  TableRow,
  ValidationIndicator,
} from "../components";

export default function VideosPage() {
  // -- Redux
  const dispatch = useDispatch();

  const currentLocale = useSelector(getCurrentLocale);

  const videos = useSelector(getAllVideos);
  const videosStatus = useSelector(getVideosStatus);
  const videosError = useSelector(getVideosError);
  const lessonComponents = useSelector(getAllLessonComponents);
  const lessonComponentsStatus = useSelector(getLessonComponentsStatus);
  const lessonComponentsError = useSelector(getLessonComponentsError);
  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSectionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const globalVideoSectionsError = useSelector(getGlobalVideoSectionsError);

  // -- Local state
  const [extraFilterType, setExtraFilterType] = useState(VideoFilterType.all);

  // -- Effect
  // Fetch videos
  useEffect(() => {
    if (currentLocale) {
      dispatch(fetchVideos(currentLocale));
      dispatch(fetchLessonComponents(currentLocale));
      dispatch(fetchGlobalVideoSections(currentLocale));
    }
  }, [dispatch, currentLocale]);

  // -- Functions
  const getLocations = (video: Video) => {
    const locations = [];

    for (const lessonComponent of lessonComponents) {
      if (lessonComponent.getAllVideoNames().includes(video.name))
        locations.push({ type: "Lesson Component", id: lessonComponent.id });
    }
    for (const globalVideoSection of globalVideoSections) {
      if (globalVideoSection.getAllVideoNames().includes(video.name))
        locations.push({ type: "Global Video Section", id: globalVideoSection.id });
    }

    return locations.length > 0 ? locations : [{ type: "Not used", id: "Not used" }];
  };

  const getExtraFilter = (video: Video) => {
    switch (extraFilterType) {
      case VideoFilterType.all:
        return allVideosFilter();
      case VideoFilterType.allComplete:
        return videoAllCompleteFilter(video);
      case VideoFilterType.allErrored:
        return videoAllErroredFilter(video);
      case VideoFilterType.changed:
        return videoChangedFilter(video);
      case VideoFilterType.changedComplete:
        return videoChangedCompleteFilter(video);
      case VideoFilterType.changedErrored:
        return videoChangedErroredFilter(video);
    }
  };

  // UI
  const openUploadVideosModal = () => {
    dispatch(
      openModal({
        uploadVideosModal: {},
      })
    );
  };

  // -- Components
  const TopSection = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="text-2xl font-bold">Manage Videos</div>
        <Button onClick={openUploadVideosModal}>
          <Upload size={16} />
          Upload Videos
        </Button>
      </div>
    );
  };

  const ContainerTable = ({ children }: { children: any }) => {
    return <Table rows={["name", "id", "status", "has changed", "last updated", "usage", "used in"]}>{children}</Table>;
  };

  return (
    <div className="flex-1 flex flex-col p-5 gap-2">
      <TopSection />
      <div />
      <StatusView
        status={getWorstStatus([videosStatus, lessonComponentsStatus, globalVideoSectionsStatus])}
        error={getError([videosError, lessonComponentsError, globalVideoSectionsError])}
      >
        <SearchView
          items={videos}
          containsFilter={videoSearchFilter}
          exactFilter={videoExactSearchFilter}
          noneFoundMessage="No videos found"
          fixed
          limit={100}
          containerComponent={ContainerTable}
          extraFilterComponent={
            <SelectMenu value={extraFilterType} set={setExtraFilterType}>
              <option value={VideoFilterType.all}>All</option>
              <option value={VideoFilterType.allComplete}>All Complete</option>
              <option value={VideoFilterType.allErrored}>All Errored</option>
              <option value={VideoFilterType.changed}>Need Attention</option>
              <option value={VideoFilterType.changedComplete}>Need Attention + Complete</option>
              <option value={VideoFilterType.changedErrored}>Need Attention + Errored</option>
            </SelectMenu>
          }
        >
          {(videos, indicator) =>
            !indicator
              ? videos.filter(getExtraFilter).map((video, index) => {
                  const locations = getLocations(video);

                  return (
                    <TableRow key={video.id} index={index}>
                      <TableItem>{video.name}</TableItem>
                      <TableItem>{video.id}</TableItem>
                      <TableItem className={video.outputS3Status.includes("error") ? "text-red-500 font-bold" : ""}>
                        {video.outputS3Status}
                      </TableItem>
                      <TableItem>{video.hasChanged.toString()}</TableItem>
                      <TableItem>{parseDateTime(video.updatedAt)}</TableItem>
                      <TableItem>
                        {locations.map((l) => (
                          <div>{l.type}</div>
                        ))}
                      </TableItem>
                      <TableItem>
                        {locations.map((l) => (
                          <div>{l.id}</div>
                        ))}
                      </TableItem>
                    </TableRow>
                  );
                })
              : indicator
          }
        </SearchView>
      </StatusView>
    </div>
  );
}
