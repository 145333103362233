export default function Table({
  className,
  title,
  rows,
  children,
  ...rest
}: {
  className?: string;
  title?: string;
  rows: string[];
  children: any;
  [key: string]: any;
}) {
  return (
    <div {...rest} className={`${className} flex flex-col`}>
      <div className="font-medium text-sm mb-2">{title}</div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {rows.map((row, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {row}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{children}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
