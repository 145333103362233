export default function TableItem({
  className,
  children,
  ...rest
}: {
  className?: string;
  children: any;
  [key: string]: any;
}) {
  return (
    <td {...rest} className={`${className} px-6 py-3 whitespace-nowrap text-xs font-medium text-gray-500`}>
      {children}
    </td>
  );
}
