export default function TableRow({
  className,
  index,
  children,
  ...rest
}: {
  className?: string;
  index: number;
  children: any;
  [key: string]: any;
}) {
  return (
    <tr {...rest} className={`${className} ${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
      {children}
    </tr>
  );
}
