import { Download, Plus, Upload } from "heroicons-react";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import { Lesson, LessonComponent } from "../core/domain/entities";
import {
  fetchLessonComponents,
  getAllLessonComponents,
  getLessonComponentsError,
  getLessonComponentsStatus,
} from "../core/redux/lessonComponentsSlice";
import {
  fetchLessons,
  getLessonsError,
  getLessonsStatus,
  getSortedLessons,
  reorderComponents,
  reorderLessons,
} from "../core/redux/lessonsSlice";
import { getCurrentLocale } from "../core/redux/localesSlice";
import { getCurrentUser } from "../core/redux/userSlice";

import {
  Button,
  LessonCard,
  LessonComponentCard,
  ReorderButton,
  SearchView,
  StatusView,
  VocabComponentCard,
} from "../components";
import { openModal } from "../core/redux/modalSlice";
import { fetchPackages, getAllPackages, getPackagesError, getPackagesStatus } from "../core/redux/packagesSlice";
import {
  fetchVocabComponents,
  getAllVocabComponents,
  getVocabComponentsError,
  getVocabComponentsStatus,
} from "../core/redux/vocabComponentsSlice";
import CSVService from "../service/CSVService";
import { lessonByWordFilter, lessonExactFilter, lessonFilter } from "../service/helpers";

export default function ManageLessonsPage() {
  // -- Redux
  const dispatch = useDispatch();

  const currentLocale = useSelector(getCurrentLocale);
  const currentUser = useSelector(getCurrentUser);

  const sortedLessons = useSelector(getSortedLessons);
  const lessonsStatus = useSelector(getLessonsStatus);
  const lessonsError = useSelector(getLessonsError);

  const lessonComponents = useSelector(getAllLessonComponents);
  const lessonComponentsStatus = useSelector(getLessonComponentsStatus);
  const lessonComponentsError = useSelector(getLessonComponentsError);

  const vocabComponents = useSelector(getAllVocabComponents);
  const vocabComponentsStatus = useSelector(getVocabComponentsStatus);
  const vocabComponentsError = useSelector(getVocabComponentsError);

  const packages = useSelector(getAllPackages);
  const packagesStatus = useSelector(getPackagesStatus);
  const packagesError = useSelector(getPackagesError);

  // -- Local state
  const [isReordering, setIsReordering] = useState(false);

  // -- Effect
  useEffect(() => {
    if (currentLocale) {
      dispatch(fetchLessons(currentLocale));
      dispatch(fetchLessonComponents(currentLocale));
      dispatch(fetchVocabComponents(currentLocale));
      dispatch(fetchPackages(currentLocale));
    }
  }, [dispatch, currentLocale]);

  // -- Functions
  const toggleIsReordering = () => {
    setIsReordering((prev) => !prev);
    console.log(sortedLessons[0].allSortedComponents(lessonComponents, vocabComponents));
  };

  const handleLessonsDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return;
    const ordered = [...sortedLessons];
    const [reordered] = ordered.splice(source.index, 1);
    ordered.splice(destination.index, 0, reordered);

    dispatch(reorderLessons(ordered));
  };
  const handleComponentsDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return;
    const fromLesson = sortedLessons.find((lesson) => lesson.id === source.droppableId);
    const toLesson = sortedLessons.find((lesson) => lesson.id === destination.droppableId);
    if (!fromLesson || !toLesson) return;

    const component = fromLesson.allSortedComponents(lessonComponents, vocabComponents)[source.index];
    if (!component) return;

    dispatch(
      reorderComponents({
        fromLesson,
        toLesson,
        component: component,
        fromIndex: source.index,
        toIndex: destination.index,
        lessonComponents,
        vocabComponents,
      })
    );
  };

  const openCreateLessonModal = () => {
    dispatch(
      openModal({
        lessonModal: {
          lesson: new Lesson({
            localeId: currentLocale?.id,
            createdBy: currentUser?.attributes.sub,
            updatedBy: currentUser?.attributes.sub,
          }),
          isCreating: true,
        },
      })
    );
  };

  const openImportPackageCSVModal = () => {
    dispatch(
      openModal({
        importPackageCSVModal: {},
      })
    );
  };

  const exportPackageCSV = () => {
    CSVService.instance.downloadCSV(
      packages.map((p) => p.toCSVEntry()),
      `${currentLocale!.title} Packages - ${new Date()}.csv`
    );
  };

  // -- Components
  const TopSection = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="text-2xl font-bold">Manage Lessons</div>
        <div className="flex gap-2">
          <ReorderButton isReordering={isReordering} onToggle={toggleIsReordering} />
          <Button onClick={openCreateLessonModal}>
            <Plus size={16} />
            Create Lesson
          </Button>
          <StatusView status={packagesStatus} error={packagesError}>
            <div className="flex gap-2">
              <Button onClick={openImportPackageCSVModal} shade={100}>
                <Upload size={16} />
                Import Package CSV
              </Button>
              <Button onClick={exportPackageCSV} shade={100}>
                <Download size={16} />
                Export Package CSV
              </Button>
            </div>
          </StatusView>
        </div>
      </div>
    );
  };

  const CardSearchView = () => {
    return (
      <SearchView
        items={sortedLessons}
        containsFilter={lessonFilter}
        exactFilter={lessonExactFilter}
        wordFilter={lessonByWordFilter}
        noneFoundMessage="No lessons found"
        limit={100}
        fixed
      >
        {(lessons, indicator) =>
          !indicator
            ? lessons.map((lesson, index) => (
                <Draggable key={lesson.id} draggableId={lesson.id} index={index} isDragDisabled={!isReordering}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <LessonCard lesson={lesson} isReordering={isReordering}>
                        <StatusView status={lessonComponentsStatus} error={lessonComponentsError}>
                          <Droppable droppableId={lesson.id}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                className={`flex flex-col gap-1`}
                                {...provided.droppableProps}
                              >
                                {lesson
                                  .allSortedComponents(lessonComponents, vocabComponents)
                                  .map((component, index) => {
                                    const id = component.id;
                                    return (
                                      <Draggable key={id} draggableId={id} index={index}>
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            {component instanceof LessonComponent ? (
                                              <LessonComponentCard
                                                key={id}
                                                lessonComponent={component}
                                                lesson={lesson}
                                              />
                                            ) : (
                                              <VocabComponentCard key={id} vocabComponent={component} lesson={lesson} />
                                            )}
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </StatusView>
                      </LessonCard>
                    </div>
                  )}
                </Draggable>
              ))
            : indicator
        }
      </SearchView>
    );
  };

  return (
    <div className="flex-1 p-5 flex flex-col gap-2">
      <TopSection />
      <div />
      <StatusView status={lessonsStatus} error={lessonsError}>
        <DragDropContext onDragEnd={handleLessonsDragEnd}>
          <Droppable droppableId="lessons">
            {(provided) => (
              <div ref={provided.innerRef} className={`flex flex-col gap-2 flex-1`} {...provided.droppableProps}>
                {isReordering ? (
                  CardSearchView()
                ) : (
                  <DragDropContext onDragEnd={handleComponentsDragEnd}>{CardSearchView()}</DragDropContext>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </StatusView>
    </div>
  );
}
