import { ChevronLeft, Cloud, DotsHorizontal, Globe, PencilAlt, Plus, Selector, XCircle } from "heroicons-react";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ButtonType } from "../components/base/Button";
import { GlobalVideoSection, SectionInfo, TargetPhraseGroup, VideoClip } from "../core/domain/entities";
import { GridCard } from "../core/domain/entities/LessonComponent";
import { LessonComponentType, generatedComponentTypes } from "../core/domain/entities/types";
import {
  getComponent,
  getComponentError,
  getComponentIsSaved,
  getComponentStatus,
  setIsSaved,
  updateComponent,
  updateCurrentComponent,
} from "../core/redux/componentSlice";
import {
  createGlobalVideoSection,
  fetchGlobalVideoSections,
  getAllGlobalVideoSections,
  getGlobalVideoSectionsError,
  getGlobalVideoSectionsStatus,
} from "../core/redux/globalVideoSectionsSlice";
import { getAllLessons, getCurrentLesson } from "../core/redux/lessonsSlice";
import { getAllLocales, getCurrentLocale } from "../core/redux/localesSlice";
import { openModal } from "../core/redux/modalSlice";
import { fetchPhrases, getAllPhrases, getPhrasesStatus } from "../core/redux/phrasesSlice";
import { StatusType } from "../core/redux/types";
import { fetchVideos, getAllVideos, getVideosError, getVideosStatus } from "../core/redux/videosSlice";
import { parseDateTime } from "../service/helpers";

import {
  Button,
  CheckBoxInput,
  Dropdown,
  DropdownItem,
  Input,
  ReorderButton,
  SectionModal,
  StatusView,
  TargetPhraseGroupCard,
  VideoClipCard,
} from "../components";

export default function EditLessonComponentPage() {
  // -- Navigation
  const history = useHistory();

  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);
  const status = useSelector(getComponentStatus);
  const error = useSelector(getComponentError);
  const isSaved = useSelector(getComponentIsSaved);

  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSectionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const globalVideoSectionsError = useSelector(getGlobalVideoSectionsError);
  const videos = useSelector(getAllVideos);
  const videosStatus = useSelector(getVideosStatus);
  const videosError = useSelector(getVideosError);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const lessons = useSelector(getAllLessons);
  const currentLesson = useSelector(getCurrentLesson);
  const locales = useSelector(getAllLocales);
  const currentLocale = useSelector(getCurrentLocale);

  const isGlobalVideoSection = lessonComponent instanceof GlobalVideoSection;

  // -- Local state
  const [optionsDropdownIsOpen, setOptionsDropdownIsOpen] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [saveText, setSaveText] = useState("Saved");

  // -- Effect
  // Go back if no selected lesson component
  useEffect(() => {
    if (!lessonComponent) goBack();
  }, []);

  // Update save text in sync with status
  useEffect(() => {
    const getSaveText = () => {
      if (isSaved) return "Saved";
      else {
        switch (status) {
          case StatusType.loading:
            return "Saving...";
          case StatusType.errored:
            return `ERROR: ${error}`;
          default:
            return "Not Saved";
        }
      }
    };
    setSaveText(getSaveText());
  }, [status, isSaved]);

  // Fetch data if needed
  useEffect(() => {
    if (currentLocale) {
      dispatch(fetchPhrases(currentLocale));
      dispatch(fetchVideos(currentLocale));
      dispatch(fetchGlobalVideoSections(currentLocale));
    }
  }, [dispatch, currentLocale]);

  // Handle populating global video sections for practice
  useEffect(() => {
    if (
      lessonComponent &&
      generatedComponentTypes.includes(lessonComponent.type) &&
      lessonComponent.videoClips.length === 0 &&
      lessonComponent.objectives.length === 0 &&
      lessonComponent.masterPlaylist.length > 0 &&
      globalVideoSectionsStatus === StatusType.succeeded
    ) {
      for (const sectionId of lessonComponent!.masterPlaylist) {
        if (!lessonComponent.getVideoClip(sectionId)) {
          const globalVideoSection = globalVideoSections.find((gvs) => gvs.sectionId === sectionId);
          if (globalVideoSection) {
            console.log(globalVideoSection);
            lessonComponent.populateGlobalVideoSection(globalVideoSection);
          }
        }
      }
      dispatch(updateCurrentComponent(lessonComponent!));
      dispatch(setIsSaved(true));
    }
  }, [dispatch, globalVideoSectionsStatus, lessonComponent]);

  // -- Functions
  const save = async () => {
    const handleUpdateMutation = () => {
      dispatch(updateComponent(lessonComponent!));
    };

    if (
      lessonComponent!
        .getAllTargetPhraseGroups()
        .some((targetPhraseGroup) => targetPhraseGroup.targetPhrases.length === 0)
    ) {
      alert("Sorry! You can't save right now because there is a target phrase group without any target phrases");
    } else {
      handleUpdateMutation();
    }
  };

  const goBack = async () => {
    if (isSaved || window.confirm("The LessonComponent is not saved. Are you sure you want to go back?"))
      history.goBack();
  };

  const handleAddSection = () => {
    if (generatedComponentTypes.includes(lessonComponent!.type)) openSelectGlobalVideoSectionModal();
    else openSectionModal();
  };

  // UI
  const openOptionsDropdown = () => {
    setOptionsDropdownIsOpen(true);
  };
  const closeOptionsDropdown = () => {
    setOptionsDropdownIsOpen(false);
  };

  const toggleIsReordering = () => {
    setIsReordering((prev) => !prev);
  };

  const openEditModal = () => {
    isGlobalVideoSection
      ? dispatch(
          openModal({
            globalVideoSectionModal: {
              globalVideoSection: lessonComponent! as GlobalVideoSection,
            },
          })
        )
      : dispatch(
          openModal({
            lessonComponentModal: {
              lessonComponent: lessonComponent!,
              lesson: currentLesson!,
            },
          })
        );
  };

  const openSectionModal = () => {
    dispatch(
      openModal({
        sectionModal: {
          isCreating: true,
          sectionInfo: new SectionInfo(),
        },
      })
    );
  };

  const openSelectGlobalVideoSectionModal = () => {
    dispatch(
      openModal({
        selectGlobalVideoSectionModal: {},
      })
    );
  };

  // -- Components
  const TopBar = () => {
    const BackButton = () => {
      return (
        <Button onClick={goBack}>
          <ChevronLeft size={16} />
        </Button>
      );
    };

    const TitleSection = () => {
      return (
        <div className="flex flex-col">
          <div className="text-2xl font-bold">{lessonComponent!.title}</div>
          <div className="text-xs text-gray-400">{lessonComponent!.id}</div>
        </div>
      );
    };

    const SaveIndicators = () => {
      return (
        <div className="text-xs text-gray-400 mx-3 flex flex-col items-end">
          <div className={`${saveText.toLowerCase().includes("error") ? "text-red-500" : ""}`}>{saveText}</div>
          <div>{`Last saved: ${parseDateTime(lessonComponent!.updatedAt)}`}</div>
        </div>
      );
    };

    const SaveButton = () => {
      return (
        <Button onClick={save}>
          <Cloud size={16} />
          Save
        </Button>
      );
    };

    const OptionsButton = () => {
      return (
        <div className="relative">
          <Button displayType={ButtonType.text} onClick={openOptionsDropdown}>
            <DotsHorizontal size={16} />
          </Button>
          <OptionsDropdown />
        </div>
      );
    };

    const OptionsDropdown = () => {
      return (
        <Dropdown isOpen={optionsDropdownIsOpen} onClose={closeOptionsDropdown}>
          <DropdownItem onClick={openEditModal}>
            <PencilAlt size={16} />
            Edit
          </DropdownItem>
        </Dropdown>
      );
    };

    return (
      <div className="flex justify-between items-center bg-white p-5 fixed inset-x-0 z-10">
        <div className="flex gap-5 items-center">
          <BackButton />
          <TitleSection />
        </div>
        <div className="flex gap-2 items-center text-right">
          <SaveIndicators />
          <SaveButton />
          <ReorderButton isReordering={isReordering} onToggle={toggleIsReordering} />
          <OptionsButton />
        </div>
      </div>
    );
  };

  const CardsView = () => {
    // -- Local state
    const [isReorderingTargetPhraseGroups, setIsReorderIngTargetPhraseGroups] = useState(false);

    const getGrid = () => {
      return lessonComponent instanceof GlobalVideoSection
        ? lessonComponent.getGrid(lessonComponent!.sectionId!)
        : lessonComponent!.getGrid();
    };
    const grid = getGrid();

    const handleSectionsDragEnd = ({ destination, source }: DropResult) => {
      if (!destination) return;
      console.log([...lessonComponent!.masterPlaylist]);
      const ordered = [...lessonComponent!.masterPlaylist];
      const [reordered] = ordered.splice(source.index, 1);
      ordered.splice(destination.index, 0, reordered);
      lessonComponent!.masterPlaylist = ordered;
      dispatch(updateCurrentComponent(lessonComponent!));
    };

    // -- Functions
    const toggleIsReordering = () => {
      setIsReorderIngTargetPhraseGroups((prev) => !prev);
    };

    // -- Components
    const SectionHeader = ({ cards }: { cards: GridCard[] }) => {
      const sectionVideoClip = cards[0].data as VideoClip;
      const sectionInfo = sectionVideoClip.sectionInfo;
      const sectionId = sectionVideoClip.sectionId;

      // -- Local state
      const [optionsDropdownIsOpen, setOptionsDropdownIsOpen] = useState(false);
      const [editModalIsOpen, setEditModalIsOpen] = useState(false);

      // -- Functions
      const handleDeleteSection = () => {
        lessonComponent!.deleteVideoClip(sectionId);
        dispatch(updateCurrentComponent(lessonComponent!));
      };

      const generateGlobalVideoSectionFromSection = () => {
        const globalVideoSection = lessonComponent!.generateGlobalVideoSectionFromSection(sectionVideoClip.uuid);
        if (globalVideoSection) {
          dispatch(createGlobalVideoSection(globalVideoSection));
          alert("Global Video Section created!");
        }
      };

      // UI
      const openOptionsDropdown = () => {
        setOptionsDropdownIsOpen(true);
      };
      const closeOptionsDropdown = () => {
        setOptionsDropdownIsOpen(false);
      };

      const openEditModal = () => {
        setEditModalIsOpen(true);
      };
      const closeEditModal = () => {
        setEditModalIsOpen(false);
      };

      // -- Components
      const OptionsDropdown = () => {
        return (
          <Dropdown isOpen={optionsDropdownIsOpen} onClose={closeOptionsDropdown}>
            <DropdownItem onClick={openEditModal}>
              <PencilAlt size={16} />
              Edit
            </DropdownItem>
            <DropdownItem onClick={generateGlobalVideoSectionFromSection}>
              <Globe size={16} />
              Generate Global Video Section
            </DropdownItem>
            {!isGlobalVideoSection ? (
              <DropdownItem onClick={handleDeleteSection} color="red" shade={500}>
                <XCircle size={16} />
                Delete
              </DropdownItem>
            ) : null}
          </Dropdown>
        );
      };

      const GlobalVideoSectionCheckbox = () => {
        const toggleIsChecked = (globalVideoSection: GlobalVideoSection, isChecked: boolean) => {
          globalVideoSection.isChecked = isChecked;
          dispatch(updateCurrentComponent(globalVideoSection!));
        };

        if (cards[0].data instanceof VideoClip) {
          let globalVideoSection: GlobalVideoSection | null | undefined = null;
          if (lessonComponent instanceof GlobalVideoSection) {
            globalVideoSection = lessonComponent as GlobalVideoSection;

            if (globalVideoSection) {
              return (
                <CheckBoxInput
                  value={globalVideoSection.isChecked}
                  set={(isChecked) => toggleIsChecked(globalVideoSection!, isChecked)}
                />
              );
            }
          } else if (generatedComponentTypes.includes(lessonComponent!.type)) {
            const sectionId = cards[0].data.sectionId;

            globalVideoSection = globalVideoSections.find((gvs) => gvs.sectionId === sectionId);
            if (globalVideoSection) {
              return <CheckBoxInput value={globalVideoSection.isChecked} />;
              // const isValidated = globalVideoSection.validate(lessons, videos, phrases, locales, globalVideoSections)[0]
              //   .isDeepValidated;
              // if (isValidated) return <CompletionIndicator isComplete={isValidated} />;
            }
          }
        }
        return null;
      };

      if (cards[0].data instanceof VideoClip) {
        return (
          <div className="flex justify-between border-t py-2 mb-2 items-center">
            <div className="flex gap-5">
              <div className="flex flex-col">
                <div className="text-sm font-bold">{sectionInfo.title}</div>
                <div className="text-xs font-medium">{sectionInfo.targetText}</div>
                <div className="text-xs text-gray-500">{sectionInfo.nativeText}</div>
              </div>
              <GlobalVideoSectionCheckbox />
            </div>

            <div className="flex items-center">
              <Button displayType={ButtonType.text} onClick={toggleIsReordering}>
                <Selector size={16} />
              </Button>
              <div className="relative">
                <Button displayType={ButtonType.text} onClick={openOptionsDropdown}>
                  <DotsHorizontal size={16} />
                </Button>
                <OptionsDropdown />
              </div>
            </div>
            <SectionModal
              isOpen={editModalIsOpen}
              sectionInfo={cards[0].data.sectionInfo}
              sectionId={cards[0].data.sectionId}
              onClose={closeEditModal}
            />
          </div>
        );
      }
      return null;
    };

    const SectionCards = ({ cards }: { cards: GridCard[] }) => {
      const handleDragEnd = ({ draggableId, destination }: DropResult) => {
        console.log(draggableId, destination);
        if (!destination) return;
        lessonComponent!.moveTargetPhraseToGroup(draggableId, destination.droppableId, destination.index);
        dispatch(updateCurrentComponent(lessonComponent!));
      };

      return (
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="-mt-2 grid gap-2 pb-5 pt-2 auto-cols-min">
            {cards.map((card, index) => {
              if (card.data instanceof VideoClip) {
                return <VideoClipCard key={index} videoClip={card.data} row={card.row} column={card.column} />;
              }
              if (card.data instanceof TargetPhraseGroup) {
                return (
                  <TargetPhraseGroupCard
                    key={index}
                    isReordering={isReorderingTargetPhraseGroups}
                    targetPhraseGroup={card.data}
                    row={card.row}
                    column={card.column}
                  />
                );
              }
              return null;
            })}
          </div>
        </DragDropContext>
      );
    };

    const PracticeWarning = () => {
      const [databaseName, setDatabaseName] = useState("");

      const populateGlobalVideoSections = () => {
        if (
          window.confirm(
            "Are you sure you want to populate this practice Lesson Component with Global Video Sections? This will override the current practice Lesson Component"
          )
        ) {
          lessonComponent!.populateGlobalVideoSections(globalVideoSections, databaseName);
          dispatch(updateCurrentComponent(lessonComponent!));
        }
      };

      const SectionCountIndicator = () => {
        if (generatedComponentTypes.includes(lessonComponent!.type)) {
          const sectionCount = lessonComponent!.masterPlaylist.length;
          return <div className="mt-2 font-bold">{"Section Count: " + sectionCount}</div>;
        }
        return null;
      };

      if (generatedComponentTypes.includes(lessonComponent!.type))
        return (
          <div className="flex flex-1 items-start justify-start">
            <div className="text-xs mb-5 flex-1">
              <div className="text-red-500">
                Note: This is a practice Lesson Component containing Global Video Sections. You can ONLY add, delete, or
                reorder sections - any other changes WILL NOT save
              </div>
              <SectionCountIndicator />
            </div>
            <Button className="-mt-2 mr-2" onClick={populateGlobalVideoSections}>
              Populate
            </Button>
            <Input placeholder="P1" className="-mt-2" value={databaseName} set={setDatabaseName} />
          </div>
        );
      return null;
    };

    return (
      <DragDropContext onDragEnd={handleSectionsDragEnd}>
        <Droppable droppableId="sections">
          {(provided) => (
            <div ref={provided.innerRef} className={`pt-28 p-5`} {...provided.droppableProps}>
              <PracticeWarning />
              {grid
                .filter(
                  (cards) =>
                    cards.length > 0 && (!isReordering || lessonComponent!.masterPlaylist.includes(cards[0].data.uuid))
                )
                .map((cards, index) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={cards[0].data.uuid}
                      index={index}
                      isDragDisabled={!isReordering}
                    >
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div>
                            <SectionHeader cards={cards} />
                            {!isReordering ? <SectionCards cards={cards} /> : null}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
              {!isGlobalVideoSection || lessonComponent!.videoClips.length === 0 ? (
                <Button className="mt-5" onClick={handleAddSection}>
                  <Plus size={16} />
                </Button>
              ) : null}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const UnusedVideosView = () => {
    if (lessonComponent! instanceof GlobalVideoSection) {
      const allVideoNames = lessonComponent!.getAllVideoNames();
      const usedName = allVideoNames[0];
      const namePrefix = usedName ? usedName.split("_")[0] + "_" + usedName.split("_")[1] : null;
      const filteredVideos = videos
        .filter((v) => v.name.split("_")[0] + "_" + v.name.split("_")[1] === namePrefix)
        .filter((v) => !allVideoNames.includes(v.name));

      return (
        <div className="absolute bottom-10 left-10 bg-white rounded-xl text-left p-4">
          {namePrefix ? (
            <div>
              <div className="text-xs font-medium mb-1">{namePrefix + " Unused Videos"}</div>
              <StatusView status={videosStatus} error={videosError}>
                <div className="h-96 overflow-scroll">
                  {filteredVideos.length > 0 ? (
                    filteredVideos.map((video) => <div className="text-xs text-gray-500">{video.name}</div>)
                  ) : (
                    <div className="text-xs text-gray-400">None</div>
                  )}
                </div>
              </StatusView>
            </div>
          ) : (
            <div className="text-xs text-gray-400">Insert one videoClip with a relevant databaseName</div>
          )}
        </div>
      );
    }
    return null;
  };

  if (
    lessonComponent &&
    (isGlobalVideoSection || currentLesson || lessonComponent.type === LessonComponentType.MAGENT)
  ) {
    return (
      <div className="flex-1 flex flex-col gap-2 pb-40">
        <TopBar />
        <StatusView
          status={
            lessonComponent!.type === LessonComponentType.PRACTICE ? globalVideoSectionsStatus : StatusType.succeeded
          }
          error={globalVideoSectionsError}
        >
          <CardsView />
          <UnusedVideosView />
        </StatusView>
      </div>
    );
  }
  return null;
}
