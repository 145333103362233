import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import {
  remoteDataSource,
  useCaseCreateLesson,
  useCaseDeleteLesson,
  useCaseGetLessons,
  useCaseReorderComponents,
  useCaseReorderLessons,
  useCaseUpdateLesson,
  useCaseUpdateLessonComponent,
  useCaseUpdateVocabComponent,
} from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { sortList, updateObject, updateObjectInList } from "../../service/helpers";
import { Lesson, LessonComponent, Locale, VocabComponent } from "../domain/entities";
import { createLessonComponent, deleteLessonComponent } from "./lessonComponentsSlice";
import { RootState } from "./store";
import { StatusType } from "./types";
import { createVocabComponent } from "./vocabComponentsSlice";

export const fetchLessons = createAsyncThunk(`lessons/fetch`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.lessons.items.length > 0 && state.lessons.items[0].localeId !== locale.id;
  if (state.lessons.items.length > 0 && (!localeDidChange || state.lessons.status !== StatusType.loading))
    return state.lessons.items;

  const result = await useCaseGetLessons.execute(locale);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const fetchProdLessons = createAsyncThunk(`lessons/fetchProd`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.lessons.prodItems.length > 0 && state.lessons.prodItems[0].localeId !== locale.id;
  if (state.lessons.prodItems.length > 0 && (!localeDidChange || state.lessons.status !== StatusType.loading))
    return state.lessons.prodItems;

  const result = await remoteDataSource.allLessons(locale, "prod");

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const createLesson = createAsyncThunk(`lessons/create`, async (lesson: Lesson, { getState }) => {
  const state = getState() as RootState;

  const maxOrderIndex = Math.max(...state.lessons.items.map((l) => l.orderIndex), 0);

  const result = await useCaseCreateLesson.execute(
    updateObject(lesson, {
      orderIndex: maxOrderIndex + 1,
    })
  );

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const updateLesson = createAsyncThunk(`lessons/update`, async (lesson: Lesson, { getState }) => {
  // Update updatedBy to the current user
  const state: any = getState();
  lesson.updatedBy = state.users.current.attributes.sub;

  const allComponents = lesson.allSortedComponents(state.lessonComponents.items, state.vocabComponents.items);
  for (let i = 0; i < allComponents.length; i++) {
    const component = allComponents[i];
    if (component.orderIndex !== i) {
      component.orderIndex = i;
      component instanceof LessonComponent
        ? await useCaseUpdateLessonComponent.execute(component)
        : await useCaseUpdateVocabComponent.execute(component);
    }
  }

  const result = await useCaseUpdateLesson.execute(lesson);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const deleteLesson = createAsyncThunk(`lessons/delete`, async (lesson: Lesson, { getState, dispatch }) => {
  if (window.confirm(`Are you sure you want to delete the Lesson: ${lesson.title}?`)) {
    const state = getState() as RootState;

    // Update order indicies of all lessons
    const updatedLessons = state.lessons.items.filter((item) => item.id !== lesson.id);
    updatedLessons.sort((a, b) => a.orderIndex - b.orderIndex);
    for (const [index, lesson] of Object.entries(updatedLessons)) {
      dispatch(
        updateLesson(
          updateObject(lesson, {
            orderIndex: parseInt(index),
          }) as Lesson
        )
      );
    }

    const result = await useCaseDeleteLesson.execute(lesson);

    if (result instanceof Success) {
      // Delete all lesson components inside this lesson
      for (const id of result.data.componentIds) {
        const lessonComponent = state.lessonComponents.items.find((lc: LessonComponent) => lc.id === id);
        if (lessonComponent) dispatch(deleteLessonComponent({ lessonComponent: lessonComponent }));
      }
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  } else throw "Cancelled";
});

export const chooseComponent = createAsyncThunk(
  `lessons/chooseComponent`,
  async (component: LessonComponent | VocabComponent, { getState, dispatch }) => {
    const state = getState() as RootState;

    const lesson = state.lessons.items.find((l) => l.id === state.lessons.destinationLessonId);
    if (!lesson) return;

    const clonedComponent = updateObject(component, {
      id: uuidv4(),
    });

    if (clonedComponent instanceof VocabComponent) {
      dispatch(
        createVocabComponent({
          lesson: lesson,
          vocabComponent: clonedComponent,
        })
      );
    } else {
      dispatch(
        createLessonComponent({
          lesson: lesson,
          lessonComponent: clonedComponent,
        })
      );
    }
  }
);

const initialState = {
  items: [] as Lesson[],
  current: null as Lesson | undefined | null,
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as Lesson[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
  destinationLessonId: undefined as string | undefined,
};

export const lessonsSlice = createSlice({
  name: "lessons",
  initialState: initialState,
  reducers: {
    selectLesson(state, { payload: lesson }: { payload: Lesson }) {
      state.current = lesson;
    },
    reorderLessons(state, { payload: lessons }: { payload: Lesson[] }) {
      const reorderedLessons = useCaseReorderLessons.execute(lessons);
      state.items = reorderedLessons;
    },
    reorderComponents(
      state,
      {
        payload: { fromLesson, toLesson, component, fromIndex, toIndex, lessonComponents, vocabComponents },
      }: {
        payload: {
          fromLesson: Lesson;
          toLesson: Lesson;
          component: LessonComponent | VocabComponent;
          fromIndex: number;
          toIndex: number;
          lessonComponents: LessonComponent[];
          vocabComponents: VocabComponent[];
        };
      }
    ) {
      const updatedLessons = useCaseReorderComponents.execute(
        fromLesson,
        toLesson,
        component,
        fromIndex,
        toIndex,
        lessonComponents,
        vocabComponents
      );
      for (const updatedLesson of updatedLessons) {
        state.items = updateObjectInList(state.items, updatedLesson);
      }
    },
    setDestinationLessonId(state, { payload: lessonId }: { payload: string }) {
      state.destinationLessonId = lessonId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLessons.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchLessons.fulfilled, (state, { payload: lessons }: { payload: Lesson[] }) => {
      state.status = StatusType.succeeded;
      state.items = lessons;
    });
    builder.addCase(fetchLessons.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(fetchProdLessons.pending, (state) => {
      state.prodStatus = StatusType.loading;
    });
    builder.addCase(fetchProdLessons.fulfilled, (state, { payload: lessons }: { payload: Lesson[] }) => {
      state.prodStatus = StatusType.succeeded;
      state.prodItems = lessons;
    });
    builder.addCase(fetchProdLessons.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
    builder.addCase(createLesson.fulfilled, (state, { payload: lesson }: { payload: Lesson }) => {
      state.items = [...state.items, lesson];
    });
    builder.addCase(createLesson.rejected, () => {
      alert("Error! Lesson was not created. Cheeck logs for more info");
    });
    builder.addCase(updateLesson.fulfilled, (state, { payload: lesson }: { payload: Lesson }) => {
      state.items = updateObjectInList(state.items, lesson);
      state.destinationLessonId = undefined;
    });
    builder.addCase(updateLesson.rejected, (state) => {
      alert("Error! Lesson was not updated. Cheeck logs for more info");
      state.destinationLessonId = undefined;
    });
    builder.addCase(deleteLesson.fulfilled, (state, { payload: lesson }: { payload: Lesson }) => {
      state.items = state.items.filter((item) => item.id !== lesson.id);
    });
    builder.addCase(deleteLesson.rejected, () => {
      alert("Error! Lesson was not deleted. Cheeck logs for more info");
    });
  },
});

export const { selectLesson, reorderLessons, reorderComponents, setDestinationLessonId } = lessonsSlice.actions;

export const getAllLessons = (state: RootState) => state.lessons.items;
export const getLessonsStatus = (state: RootState) => state.lessons.status;
export const getLessonsError = (state: RootState) => state.lessons.error;
export const getAllProdLessons = (state: RootState) => state.lessons.prodItems;
export const getProdLessonsStatus = (state: RootState) => state.lessons.prodStatus;
export const getProdLessonsError = (state: RootState) => state.lessons.prodError;
export const getSortedLessons = (state: RootState) =>
  sortList(state.lessons.items, (a, b) => a.orderIndex - b.orderIndex);
export const getSortedProdLessons = (state: RootState) =>
  sortList(state.lessons.prodItems, (a, b) => a.orderIndex - b.orderIndex);
export const getCurrentLesson = (state: RootState) => state.lessons.current;
export const getIsChoosingComponent = (state: RootState) => state.lessons.destinationLessonId !== undefined;
export const getDestinationLessonId = (state: RootState) => state.lessons.destinationLessonId;

export default lessonsSlice.reducer;
