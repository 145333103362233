import { v4 as uuidv4 } from "uuid";

import { SupplementaryWordType } from "./types";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";
import LessonComponent from "./LessonComponent";
import { TargetPhraseGroup } from ".";

export default class SupplementaryWord {
  uuid: string;
  destinationVideoClipId: string;
  type: SupplementaryWordType;
  text: string;
  isExclusive: boolean;

  constructor(object: Partial<SupplementaryWord> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.destinationVideoClipId = object.destinationVideoClipId ?? "";
    this.type = object.type ?? SupplementaryWordType.MISSING;
    this.text = object.text ?? "";
    this.isExclusive = object.isExclusive ?? this.type === SupplementaryWordType.MISSING;
  }

  update(supplementaryWord: SupplementaryWord) {
    this.uuid = supplementaryWord.uuid;
    this.destinationVideoClipId = supplementaryWord.destinationVideoClipId;
    this.type = supplementaryWord.type;
    this.text = supplementaryWord.text;
    this.isExclusive = supplementaryWord.isExclusive;
  }

  validate(lessonComponent: LessonComponent) {
    const validation = new Validation({
      type: ValidationType.SupplementaryWord,
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (!this.text)
      validation.issues.push(
        new ValidationIssue("Field text required", ValidationIssueGravity.error, ValidationIssueCause.userInput)
      );

    if (!lessonComponent.getVideoClip(this.destinationVideoClipId))
      validation.issues.push(
        new ValidationIssue(
          "Field destinationVideoClipId must reference a video clip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }
  getIdentifier() {
    return `${this.text} - ${this.uuid}`;
  }
  getTypeName() {
    return "SupplementaryWord";
  }

  copy() {
    return new SupplementaryWord({
      destinationVideoClipId: this.destinationVideoClipId,
      type: this.type,
      text: this.text,
      isExclusive: this.isExclusive,
    });
  }
}
