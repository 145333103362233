import { Auth } from "aws-amplify";
import axios from "axios";
import _ from "lodash";

import {
  GlobalVideoSection,
  Lesson,
  LessonComponent,
  Locale,
  Phrase,
  Video,
  VocabComponent,
} from "../core/domain/entities";
import { StatusType } from "../core/redux/types";
import { environments } from "./constants";

type Identifiable = {
  id: string;
  [key: string]: any;
};

type SubIdentifiable = {
  uuid: string;
  [key: string]: any;
};

type Object = {
  [key: string]: any;
};

// Immutable mutation functions

export const sorted = <T>(list: T[], sortFunction: (a: T, b: T) => number) => {
  const newList = [...list];
  newList.sort(sortFunction);
  return newList;
};

export const updateObjectInList = <T extends Identifiable>(list: T[], object: T): T[] => {
  const clone = _.clone(list);
  const index = clone.findIndex((item) => item.id === object.id);
  if (index !== -1) clone[index] = object;
  return clone;
};

export const updateSubObjectInList = <T extends SubIdentifiable>(list: T[], object: T): T[] => {
  const clone = _.clone(list);
  const index = clone.findIndex((item) => item.uuid === object.uuid);
  if (index !== -1) clone[index] = object;
  return clone;
};

export const updateObject = <T extends Object>(object: T, updates: Partial<T>): T => {
  const clone: any = _.cloneDeep(object);
  Object.keys(updates).forEach((key) => {
    clone[key] = updates[key];
  });
  return clone as T;
};

export const updateListAtIndex = <T>(list: T[], index: number, value: T): T[] => {
  const clone = _.clone(list);
  clone[index] = value;
  return clone;
};

export const removeFromListAtIndex = <T>(list: T[], index: number): T[] => {
  const clone = _.clone(list);
  clone.splice(index, 1);
  return clone;
};

export const sortList = <T>(list: T[], sortFunction: (a: T, b: T) => number) => {
  const clone = [...list];
  clone.sort(sortFunction);
  return clone;
};

// Filters

export enum SearchType {
  contains = "contians",
  exact = "exact",
  word = "word",
}

export enum PhraseFilterType {
  all = "all",
  singleWord = "singleWord",
  notSingleWord = "notSingleWord",
  isWord = "isWord",
  notIsWord = "notIsWord",
  isHidden = "isHidden",
  notIsHidden = "notIsHidden",
  article = "article",
  articlePairs = "articlePairs",
  isVocab = "isVocab",
}

export const allPhrasesFilter = () => true;

export const phraseSingleWordFilter = (phrase: Phrase) => {
  return kleoWords(standardString(phrase.targetText)).length === 1;
};

export const phraseNotSingleWordFilter = (phrase: Phrase) => {
  return kleoWords(standardString(phrase.targetText)).length !== 1;
};

export const phraseIsWordFilter = (phrase: Phrase) => phrase.isWord;

export const phraseNotIsWordFilter = (phrase: Phrase) => !phrase.isWord;

export const phraseIsHiddenFilter = (phrase: Phrase) => phrase.isHidden;

export const phraseNotIsHiddenFilter = (phrase: Phrase) => !phrase.isHidden;

export const phraseIsVocabFilter = (phrase: Phrase, vocabComponents: VocabComponent[]) => {
  if (vocabComponents.some((vc) => vc.getAllPhraseIds().includes(phrase.id))) return true;
};

export const phraseSearchFilter = (phrase: Phrase, searchText: string) => {
  return (
    phrase.targetText.toLowerCase().includes(searchText.toLowerCase()) ||
    phrase.nativeText.toLowerCase().includes(searchText.toLowerCase()) ||
    phrase.id.includes(searchText)
  );
};

export const phraseLibSearchFilter = (phrase: Phrase, searchText: string) => {
  return (
    standardString(phrase.targetText).includes(standardString(searchText)) ||
    standardString(phrase.nativeText).includes(standardString(searchText)) ||
    phrase.id == searchText
  );
};

export const phraseExactSearchFilter = (phrase: Phrase, searchText: string) => {
  return (
    phrase.targetText.toLowerCase() === searchText.toLowerCase() ||
    phrase.id === searchText ||
    phrase.nativeText.toLowerCase() === searchText.toLowerCase()
  );
};

export const phraseByWordSearchFilter = (phrase: Phrase, searchText: string) => {
  return kleoWords(searchText.toLowerCase()).every((word) => kleoWords(phrase.targetText.toLowerCase()).includes(word));
};

export const phraseNativeSearchFilter = (phrase: Phrase, searchText: string) => {
  return phrase.nativeText.toLowerCase().includes(searchText.toLowerCase()) || phrase.id.includes(searchText);
};

export const phraseNativeExactSearchFilter = (phrase: Phrase, searchText: string) => {
  return phrase.nativeText.toLowerCase() === searchText.toLowerCase();
};

export const phraseNativeByWordSearchFilter = (phrase: Phrase, searchText: string) => {
  return kleoWords(searchText.toLowerCase()).every((word) => kleoWords(phrase.nativeText.toLowerCase()).includes(word));
};

export const phraseArticleFilter = (phrase: Phrase, locale: Locale) => {
  const articles = getArticles(locale);
  return articles.some((article) => phrase.targetText.includes(article + " "));
};

export const phraseArticlePairsFilter = (phrase: Phrase, phrases: Phrase[], locale: Locale) => {
  const articles = getArticles(locale);
  return !!phrases.find(
    (p) =>
      articles.some((article) => standardString(p.targetText) == standardString(article + " " + phrase.targetText)) ||
      articles.some((article) => standardString(phrase.targetText) == standardString(article + " " + p.targetText))
  );
};

export const lessonFilter = (lesson: Lesson, searchText: string) => {
  return (
    standardString(lesson.title).includes(standardString(searchText)) ||
    standardString(lesson.description).includes(standardString(searchText)) ||
    lesson.id.includes(searchText) ||
    lesson.componentIds.includes(searchText) ||
    lesson.vocabComponentIds.includes(searchText)
  );
};

export const lessonExactFilter = (lesson: Lesson, searchText: string) => {
  return (
    standardString(lesson.title) === standardString(searchText) ||
    lesson.id === standardString(searchText) ||
    lesson.componentIds.includes(standardString(searchText)) ||
    lesson.vocabComponentIds.includes(standardString(searchText))
  );
};

export const lessonByWordFilter = (lesson: Lesson, searchText: string) => {
  return (
    kleoWords(standardString(searchText)).every((word) => kleoWords(standardString(lesson.title)).includes(word)) ||
    lesson.componentIds.includes(standardString(searchText)) ||
    lesson.vocabComponentIds.includes(standardString(searchText))
  );
};

export const lessonComponentFilter = (lessonComponent: LessonComponent, searchText: string) => {
  return (
    standardString(lessonComponent.title).includes(standardString(searchText)) ||
    lessonComponent.id.includes(standardString(searchText))
  );
};

export const lessonComponentExactFilter = (lessonComponent: LessonComponent, searchText: string) => {
  return (
    standardString(lessonComponent.title) === standardString(searchText) ||
    lessonComponent.id === standardString(searchText)
  );
};

export const lessonComponentByWordFilter = (lessonComponent: LessonComponent, searchText: string) => {
  return kleoWords(standardString(searchText)).every((word) =>
    kleoWords(standardString(lessonComponent.title)).includes(word)
  );
};

export const globalVideoSectionFilter = (globalVideoSection: GlobalVideoSection, searchText: string) => {
  return (
    globalVideoSection.title.toLowerCase().includes(searchText.toLowerCase()) ||
    globalVideoSection.getVideoNamePrefixs().includes(searchText) ||
    globalVideoSection.id === searchText
  );
};

export const globalVideoSectionExactFilter = (globalVideoSection: GlobalVideoSection, searchText: string) => {
  return (
    globalVideoSection.title.toLowerCase() === searchText.toLowerCase() ||
    globalVideoSection.getVideoNamePrefixs().includes(searchText) ||
    globalVideoSection.id === searchText
  );
};

export const globalVideoSectionByWordFilter = (globalVideoSection: GlobalVideoSection, searchText: string) => {
  return kleoWords(standardString(searchText)).every(
    (word) =>
      kleoWords(standardString(globalVideoSection.title)).includes(word) ||
      globalVideoSection.getVideoNamePrefixs().includes(searchText) ||
      globalVideoSection.id === searchText
  );
};

export enum VideoFilterType {
  all = "all",
  allErrored = "allErrored",
  allComplete = "allComplete",
  changed = "changed",
  changedErrored = "changedErrored",
  changedComplete = "changedComplete",
}

export const videoSearchFilter = (video: Video, searchText: string) => {
  return video.name.includes(searchText) || video.id.includes(searchText);
};

export const videoExactSearchFilter = (video: Video, searchText: string) => {
  return video.name === searchText || video.id === searchText;
};

export const allVideosFilter = () => true;

export const videoAllCompleteFilter = (video: Video) => video.outputS3Status === "complete";

export const videoAllErroredFilter = (video: Video) => video.outputS3Status.includes("error");

export const videoChangedFilter = (video: Video) => video.hasChanged;

export const videoChangedCompleteFilter = (video: Video) => video.outputS3Status === "complete" && video.hasChanged;

export const videoChangedErroredFilter = (video: Video) => video.outputS3Status.includes("error") && video.hasChanged;

// Other helper functions

export const capitalize = (string: string) => {
  for (var i = 0; i < string.length; i++) {
    if (string.charAt(i).replace(/[^-'’\p{L}\p{M} ]+/giu, "").length == 0) continue;
    return string.substring(0, i + 1).toUpperCase() + string.substring(i + 1);
  }
  return string;
};

export const lowerCase = (string: string) => {
  for (var i = 0; i < string.length; i++) {
    if (string.charAt(i).replace(/[^-'’\p{L}\p{M} ]+/giu, "").length == 0) continue;
    return string.substring(0, i + 1).toLowerCase() + string.substring(i + 1);
  }
  return string;
};

export const kleoWords = (string: string): string[] => {
  return string.split(" ");
};

export const standardString = (string: string): string => {
  return string.toLowerCase().replace(/[^-'’\p{L}\p{M} ]+/giu, "");
};

export const stripSpecialCharacters = (string: string): string => {
  return string.toLowerCase().replace(/[^A-Za-z0-9^À-Ö^Ø-ö^ø-ÿ^ ^'^:^&^"^-]+/giu, "");
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const parseDateTime = (string: string): string => {
  const date = new Date(string);
  return `${date.toDateString()} at ${String(((date.getHours() + 11) % 12) + 1).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")} ${date.getHours() >= 12 ? "PM" : "AM"}`;
};

export const getWorstStatus = (statusList: StatusType[]) => {
  const getStatusRank = (status: StatusType) => {
    switch (status) {
      case StatusType.errored:
        return 0;
      case StatusType.idle:
        return 1;
      case StatusType.loading:
        return 2;
      case StatusType.succeeded:
        return 3;
    }
  };

  return sorted(statusList, (a, b) => getStatusRank(a) - getStatusRank(b))[0];
};

export const getError = (errorList: (string | null | undefined)[]) => {
  for (const error of errorList) {
    if (error) return error;
  }
};

export const getArticles = (locale: Locale) => {
  switch (locale.formattedCode) {
    case "en_nt_es":
      return ["el", "la", "un", "una", "los", "las"];
    default:
      return [];
  }
};

export const getSubjectPronouns = (locale: Locale) => {
  switch (locale.formattedCode) {
    case "en_nt_es":
      return ["he", "she", "they", "we", "i", "you", "who"];
    default:
      return [];
  }
};

export const getTargetSubjectPronouns = (locale: Locale) => {
  switch (locale.formattedCode) {
    case "en_nt_es":
      return ["ellos", "ellas", "nosotros", "nosotras", "ustedes", "yo", "tú", "usted", "él", "ella"];
    default:
      return [];
  }
};

export const spaceStripped = (string: string) => {
  return string.trim().replace(/ +(?= )/g, "");
};

export const getMultiWordWords = (locale: Locale) => {
  switch (locale.formattedCode) {
    case "en_nt_es":
      return [
        "Lo siento",
        "Tengo que",
        "todo el mundo",
        "¿Qué tal?",
        "¿Por qué?",
        "Buenas noches",
        "De nada",
        "a veces",
        "Buenos días",
        "Hasta luego",
        "Tienes que",
        "Tenemos que",
        "Buenas tardes",
        "por favor",
        "Más tarde",
        "Hacer caso",
        "caja registradora",
      ];
    default:
      return [];
  }
};

export const validateFile = async (url: string, env?: "dev" | "staging" | "prod") => {
  const path = new URL(url).pathname;
  if (path) {
    try {
      const result: any = await axios.post(
        `${process.env.REACT_APP_BACKEND}/${process.env.REACT_APP_ENV}/upload/validateFile`,
        {
          user: (await Auth.currentSession()).getIdToken().getJwtToken(),
          path: path,
          bucket: environments[env ?? (process.env.REACT_APP_ENV! as "dev" | "staging" | "prod")].bucket!,
        }
      );
      return result.data.found;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
};

export const isS3Url = (url: string) => {
  return url.includes("amazonaws.com");
};

export const booleanFromText = (text: string) => {
  if (text === "yes") return true;
  if (text === "no") return false;
  return undefined;
};

export const convertToCloudinary = (url: string) => {
  return url.replace(environments.staging.cloudfrontUrl, environments.staging.cloudinaryUrl);
};
