import { shuffle } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Phrase, VocabQuestion } from ".";
import { isS3Url } from "../../../service/helpers";
import { EnvironmentArgument, envUrl } from "../../../service/PushService";
import { hasFile } from "../../../service/StorageSerivce";
import Validation, {
  Validatable,
  ValidationArgument,
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
} from "../../../service/Validation";
import { LessonComponentDisplayType } from "./types";
import { VocabQuestionArgument, VocabQuestionType } from "./VocabQuestion";

export default class VocabComponent implements Validatable {
  id: string;
  title: string;
  imageUrl?: string;
  phraseConfigurations: PhraseConfiguration[];
  questions: VocabQuestion[];
  localeId: string;
  orderIndex: number;
  viewGroups: string[];
  displayType: LessonComponentDisplayType;
  groupsCanAccess: string[];
  freePromoImage?: string;

  static NUMBER_OF_WRONG_OPTIONS = 2;

  constructor(arg: VocabComponentArgument) {
    this.id = arg.id ?? uuidv4();
    this.title = arg.title;
    this.imageUrl = arg.imageUrl;
    this.questions = arg.questions ? arg.questions.map((q) => new VocabQuestion(q)) : [];
    this.phraseConfigurations = arg.phraseConfigurations.map(
      (config) => new PhraseConfiguration(config, this.questions)
    );
    this.viewGroups = arg.viewGroups ?? ["pro"];
    this.localeId = arg.localeId;
    this.orderIndex = arg.orderIndex;
    this.displayType = arg.displayType ?? LessonComponentDisplayType.OTHER;
    this.groupsCanAccess = ["free"];
    this.freePromoImage = arg.freePromoImage;
  }

  toData(): VocabComponentArgument {
    return {
      id: this.id,
      title: this.title,
      imageUrl: this.imageUrl,
      questions: this.questions.map((q) => q.toData()),
      phraseConfigurations: this.phraseConfigurations.map((p) => p.toData()),
      viewGroups: this.viewGroups,
      groupsCanAccess: this.groupsCanAccess,
      localeId: this.localeId,
      displayType: this.displayType,
      orderIndex: this.orderIndex,
      freePromoImage: this.freePromoImage,
    };
  }

  async toEnv(fromEnv: EnvironmentArgument, toEnv: EnvironmentArgument, push: boolean = false) {
    if (this.imageUrl) this.imageUrl = await envUrl(this.imageUrl, fromEnv, toEnv, push);
    return this;
  }

  generateQustions(phrases: Phrase[], overrideChoices: boolean = false) {
    const questions: VocabQuestion[] = [];

    const allQuestions = this.phraseConfigurations.flatMap((phraseConfiguration) =>
      this.generateQuestionFromConfiguration(phraseConfiguration, overrideChoices, phrases)
    );

    // Gather questions by question type
    const showQuestions = allQuestions.filter((question) => question.isShow());
    const speakQuestions = allQuestions
      .filter((question) => question.isSpeak())
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    const notSpeakOrShowQuestions = allQuestions.filter((question) => !question.isShow() && !question.isSpeak());
    const notSpeakOrShowShuffled = notSpeakOrShowQuestions
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    // Add show questions
    questions.push(...showQuestions);

    // Add translate text & listen
    questions.push(...notSpeakOrShowShuffled);

    // Add speaking questions
    questions.push(...speakQuestions);

    // Add matching question
    const showPhraseIds = this.phraseConfigurations
      .filter((config) => config.questionTypes.includes(VocabQuestionType.show))
      .map((config) => config.phraseId);
    const phraseCount = showPhraseIds.length;
    if (phraseCount <= 6 && phraseCount > 0) {
      questions.push(
        new VocabQuestion({
          phraseId: showPhraseIds[0],
          phraseChoices: showPhraseIds,
          type: VocabQuestionType.matching,
        })
      );
    }

    return [...new Set(questions)];
  }

  generateQuestionFromConfiguration(
    phraseConfiguration: PhraseConfiguration,
    overrideChoices: boolean,
    phrases: Phrase[]
  ) {
    const questions = phraseConfiguration.questionTypes
      .filter((type) => VocabQuestion.singlePhraseQuestionTypes().includes(type))
      .map((type) => {
        const existingQuestion = this.questions.find(
          (q) => q.phraseId === phraseConfiguration.phraseId && q.type === type
        );
        const phraseChoices =
          !phraseConfiguration.phraseChoices || phraseConfiguration.phraseChoices?.length == 0 || overrideChoices
            ? type == VocabQuestionType.translateText || type == VocabQuestionType.translateAudio
              ? this.getMultipleChoice(
                  phraseConfiguration,
                  VocabComponent.NUMBER_OF_WRONG_OPTIONS,
                  phrases,
                  overrideChoices
                )
              : []
            : phraseConfiguration.phraseChoices ?? [];

        return new VocabQuestion({
          uuid: existingQuestion?.uuid,
          phraseId: phraseConfiguration.phraseId,
          phraseChoices: phraseChoices,
          type: type,
        });
      });
    questions.sort((a, b) => a.type - b.type);
    return questions;
  }

  getMultipleChoice(
    phraseConfiguration: PhraseConfiguration,
    numberOfWrongOptions: number,
    phrases: Phrase[],
    overrideChoices: boolean
  ) {
    const phraseId = phraseConfiguration.phraseId;
    const phrase = phrases.find((p) => p.id == phraseId)!;

    const constructChoiceIds = this.phraseConfigurations
      .filter((config) => config.isConstruct())
      .map((config) => config.phraseId);
    const nonConstructChoiceIds = this.phraseConfigurations
      .filter((config) => !config.isConstruct())
      .map((config) => config.phraseId);

    const choicePhraseIds = phraseConfiguration.isConstruct()
      ? [...shuffle(constructChoiceIds), ...shuffle(nonConstructChoiceIds)]
      : [...shuffle(nonConstructChoiceIds), ...shuffle(constructChoiceIds)];

    var options = [...new Set(choicePhraseIds)]
      .filter((id) => id !== phraseConfiguration.phraseId)
      .slice(0, numberOfWrongOptions);

    if (options.length < numberOfWrongOptions) console.log("Not enough options", phraseId, choicePhraseIds, options);

    // Add correct answer and shuffle
    options.push(phrase.id);
    options = options
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    if (overrideChoices) phraseConfiguration.phraseChoices = options;

    return options;
  }

  getAllPhraseIds() {
    return this.phraseConfigurations.map((config) => config.phraseId);
  }

  getAllPhrases(phrases: Phrase[]) {
    return phrases.filter((p) => this.getAllPhraseIds().includes(p.id));
  }

  // -- Validate
  validate({ phrases, files, env }: ValidationArgument) {
    const validation: Validation = new Validation({
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (this.imageUrl) {
      if (isS3Url(this.imageUrl))
        validation.issues.push(
          new ValidationIssue(
            "Field imageUrl cannot be an S3 url",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );
      if (files && !hasFile(files, this.imageUrl, env))
        validation.issues.push(
          new ValidationIssue(
            `Invalid imageUrl: ${this.imageUrl ? new URL(this.imageUrl).pathname : "null"}`,
            ValidationIssueGravity.error,
            ValidationIssueCause.userInput
          )
        );
    }

    if (this.freePromoImage && files && !hasFile(files, this.freePromoImage, env))
      validation.issues.push(
        new ValidationIssue(
          `Invalid freePromoImage: ${this.freePromoImage ? new URL(this.freePromoImage).pathname : "null"}`,
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    for (const question of this.questions) {
      if (this.questions.filter((q) => q.phraseId == question.phraseId && q.type == question.type).length != 1) {
        validation.issues.push(
          new ValidationIssue(
            `Cannot have multiple questions of the same type for the same phrase: ${question.phraseId}`,
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );
      }
      validation.childValidations.push(question.validate(phrases));
    }

    return validation;
  }
  getIdentifier() {
    return `${this.title} - ${this.id}`;
  }
  getTypeName() {
    return "VocabComponent";
  }
}

export class PhraseConfiguration {
  phraseId: string;
  questionTypes: VocabQuestionType[];
  phraseChoices?: string[];

  constructor(arg: PhraseConfigurationArgument, questions?: VocabQuestion[]) {
    this.phraseId = arg.phraseId;
    this.questionTypes = arg.questionTypes;
    this.phraseChoices = this.getPhraseChoices(questions);
  }

  static fromPhrase(phrase: Phrase) {
    return new PhraseConfiguration({
      phraseId: phrase.id,
      questionTypes: VocabQuestion.singlePhraseQuestionTypes(),
    });
  }

  isConstruct() {
    return !this.questionTypes.includes(VocabQuestionType.show);
  }

  toData(): PhraseConfigurationArgument {
    return {
      phraseId: this.phraseId,
      questionTypes: this.questionTypes,
    };
  }

  updatePhraseChoices(questions: VocabQuestion[]) {
    this.phraseChoices = this.getPhraseChoices(questions);
    return this;
  }

  getPhraseChoices(questions: VocabQuestion[] | undefined) {
    return questions?.find((q) => q.phraseId == this.phraseId && q.phraseChoices.length > 0)?.phraseChoices;
  }
}

export type PhraseConfigurationArgument = {
  phraseId: string;
  questionTypes: number[];
};

export type VocabComponentArgument = {
  id?: string;
  title: string;
  imageUrl?: string;
  phraseConfigurations: PhraseConfigurationArgument[];
  questions?: VocabQuestionArgument[];
  viewGroups?: string[];
  groupsCanAccess?: string[];
  displayType?: LessonComponentDisplayType;
  localeId: string;
  orderIndex: number;
  freePromoImage?: string;
};
