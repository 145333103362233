import { Eye, EyeOff } from "heroicons-react";

import Button from "../base/Button";

export default function IsHiddenButton({
  className,
  isHidden,
  onToggle,
  ...rest
}: {
  className?: string;
  isHidden: boolean;
  onToggle: (value: boolean) => void;
  [key: string]: any;
}) {
  if (isHidden) {
    return (
      <Button {...rest} className={`w-9 ${className}`} onClick={() => onToggle(false)} color="yellow" shade={100}>
        <EyeOff size={16} />
      </Button>
    );
  } else {
    return (
      <Button {...rest} className={`w-9 ${className}`} onClick={() => onToggle(true)} shade={100}>
        <Eye size={16} />
      </Button>
    );
  }
}
