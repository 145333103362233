import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  remoteDataSource,
  useCaseCreateLessonComponent,
  useCaseCreateMagnet,
  useCaseDeleteLessonComponent,
  useCaseDeleteMagnet,
  useCaseGetLessonComponents,
  useCaseUpdateLessonComponent,
} from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { updateObjectInList } from "../../service/helpers";
import { Lesson, LessonComponent, Locale } from "../domain/entities";
import { updateLesson } from "./lessonsSlice";
import { RootState } from "./store";
import { StatusType } from "./types";

export const fetchLessonComponents = createAsyncThunk(
  `lessonComponents/fetch`,
  async (locale: Locale, { getState }) => {
    const state = getState() as RootState;

    const localeDidChange =
      state.lessonComponents.items.length > 0 && state.lessonComponents.items[0].localeId !== locale.id;
    if (
      state.lessonComponents.items.length > 0 &&
      (!localeDidChange || state.lessonComponents.status !== StatusType.loading)
    )
      return state.lessonComponents.items;

    const result = await useCaseGetLessonComponents.execute(locale);

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const fetchProdLessonComponents = createAsyncThunk(
  `lessonComponents/fetchProd`,
  async (locale: Locale, { getState }) => {
    const state = getState() as RootState;

    const localeDidChange =
      state.lessonComponents.prodItems.length > 0 && state.lessonComponents.prodItems[0].localeId !== locale.id;
    if (
      state.lessonComponents.prodItems.length > 0 &&
      (!localeDidChange || state.lessonComponents.status !== StatusType.loading)
    )
      return state.lessonComponents.prodItems;

    const result = await remoteDataSource.allLessonComponents(locale, "prod");

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const createLessonComponent = createAsyncThunk(
  `lessonComponents/create`,
  async ({ lessonComponent, lesson }: { lessonComponent: LessonComponent; lesson: Lesson }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const allLessonComponents = state.lessonComponents.items;
    const allVocabComponents = state.vocabComponents.items;
    const { lessonComponent: lessonComponentPromise, lesson: updatedLesson } =
      await useCaseCreateLessonComponent.execute(lessonComponent, lesson, allLessonComponents, allVocabComponents);
    const result = await lessonComponentPromise;

    if (result instanceof Success) {
      if (updatedLesson) dispatch(updateLesson(updatedLesson));
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const createMagnet = createAsyncThunk(
  `lessonComponents/createMagnet`,
  async (lessonComponent: LessonComponent) => {
    const result = await useCaseCreateMagnet.execute(lessonComponent);

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const updateLessonComponent = createAsyncThunk(
  `lessonComponents/update`,
  async (lessonComponent: LessonComponent, { getState }) => {
    // Update updatedBy to the current user
    const state: any = getState();
    lessonComponent.updatedBy = state.users.current.attributes.sub;
    lessonComponent.updatedAt = new Date().toISOString();

    const result = await useCaseUpdateLessonComponent.execute(lessonComponent);

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const deleteLessonComponent = createAsyncThunk(
  `lessonComponents/delete`,
  async ({ lessonComponent, lesson }: { lessonComponent: LessonComponent; lesson?: Lesson }, { dispatch }) => {
    if (window.confirm(`Are you sure you want to delete the LessonComponent: ${lessonComponent.title}?`)) {
      const { lessonComponent: lessonComponentPromise, lesson: updatedLesson } =
        await useCaseDeleteLessonComponent.execute(lessonComponent, lesson);
      const result = await lessonComponentPromise;

      if (result instanceof Success) {
        if (updatedLesson) dispatch(updateLesson(updatedLesson));
        return result.data;
      } else if (result instanceof Failure) {
        throw result.error;
      }
    } else throw "Cancelled";
  }
);

export const deleteMagnet = createAsyncThunk(
  `lessonComponents/deleteManget`,
  async (lessonComponent: LessonComponent) => {
    const result = await useCaseDeleteMagnet.execute(lessonComponent);

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

const initialState = {
  items: [] as LessonComponent[],
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as LessonComponent[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
};

export const lessonComponentsSlice = createSlice({
  name: "lessonComponents",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLessonComponents.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(
      fetchLessonComponents.fulfilled,
      (state, { payload: lessonComponents }: { payload: LessonComponent[] }) => {
        state.status = StatusType.succeeded;
        state.items = lessonComponents;
      }
    );
    builder.addCase(fetchLessonComponents.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(fetchProdLessonComponents.pending, (state) => {
      state.prodStatus = StatusType.loading;
    });
    builder.addCase(
      fetchProdLessonComponents.fulfilled,
      (state, { payload: lessonComponents }: { payload: LessonComponent[] }) => {
        state.prodStatus = StatusType.succeeded;
        state.prodItems = lessonComponents;
      }
    );
    builder.addCase(fetchProdLessonComponents.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
    builder.addCase(
      createLessonComponent.fulfilled,
      (state, { payload: lessonComponent }: { payload: LessonComponent }) => {
        state.items = [...state.items, lessonComponent];
      }
    );
    builder.addCase(createLessonComponent.rejected, () => {
      alert("Error! Lesson Component was not created. Cheeck logs for more info");
    });
    builder.addCase(createMagnet.fulfilled, (state, { payload: lessonComponent }: { payload: LessonComponent }) => {
      state.items = [...state.items, lessonComponent];
    });
    builder.addCase(createMagnet.rejected, () => {
      alert("Error! Magnet Lesson Component was not created. Cheeck logs for more info");
    });
    builder.addCase(
      updateLessonComponent.fulfilled,
      (state, { payload: lessonComponent }: { payload: LessonComponent }) => {
        state.items = updateObjectInList(state.items, lessonComponent);
      }
    );
    builder.addCase(updateLessonComponent.rejected, () => {
      alert("Error! Lesson Component was not updated. Cheeck logs for more info");
    });
    builder.addCase(
      deleteLessonComponent.fulfilled,
      (state, { payload: lessonComponent }: { payload: LessonComponent }) => {
        state.items = state.items.filter((item) => item.id !== lessonComponent.id);
      }
    );
    builder.addCase(deleteLessonComponent.rejected, () => {
      alert("Error! Lesson Component was not deleted. Cheeck logs for more info");
    });
    builder.addCase(deleteMagnet.fulfilled, (state, { payload: lessonComponent }: { payload: LessonComponent }) => {
      state.items = state.items.filter((item) => item.id !== lessonComponent.id);
    });
    builder.addCase(deleteMagnet.rejected, () => {
      alert("Error! Magnet Lesson Component was not deleted. Cheeck logs for more info");
    });
  },
});

export const getAllLessonComponents = (state: RootState) => state.lessonComponents.items;
export const getLessonComponentsStatus = (state: RootState) => state.lessonComponents.status;
export const getLessonComponentsError = (state: RootState) => state.lessonComponents.error;
export const getAllProdLessonComponents = (state: RootState) => state.lessonComponents.prodItems;
export const getProdLessonComponentsStatus = (state: RootState) => state.lessonComponents.prodStatus;
export const getProdLessonComponentsError = (state: RootState) => state.lessonComponents.prodError;

export default lessonComponentsSlice.reducer;
