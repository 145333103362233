import { Provider, useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import store from "../src/core/redux/store";
import { getCurrentUser } from "./core/redux/userSlice";

import { Header, RootModal } from "./components";
import EditLessonComponentPage from "./pages/EditLessonComponentPage";
import ManageGlobalVideoSectionsPage from "./pages/ManageGlobalVideoSectionsPage";
import ManageLessonsPage from "./pages/ManageLessonsPage";
import ManagePhrasesPage from "./pages/ManagePhrasesPage";
import PushPage from "./pages/PushPage";
import ScriptsPage from "./pages/ScriptsPage";
import SignInPage from "./pages/SignInPage";
import VideosPage from "./pages/VideosPage";

export const pagePaths = {
  signIn: "/sign-in",
  manageLessons: "/manage-lessons",
  editLessonComponent: "/edit-lesson-component",
  managePhrases: "/manage-phrases",
  manageGlobalVideoSections: "/manage-global-video-sections",
  scripts: "/scripts",
  push: "/push",
  videos: "/videos",
};

export default function App() {
  console.log(process.env.REACT_APP_AWS_ACCESS_KEY);
  console.log(process.env.REACT_APP_AWS_SECRET_KEY);

  const PrivateRoute = ({ children, ...rest }: { children: any; [key: string]: any }) => {
    const user = useSelector(getCurrentUser);
    return <Route {...rest}>{user ? children : <Redirect to={pagePaths.signIn} />}</Route>;
  };

  return (
    <Provider store={store}>
      <Router>
        <div className="min-h-screen flex flex-col bg-gray-100 text-gray-700">
          <Header
            pages={[
              {
                title: "Sign In",
                path: pagePaths.signIn,
                isHidden: true,
              },
              {
                title: "Lessons",
                path: pagePaths.manageLessons,
              },
              {
                title: "Edit",
                path: pagePaths.editLessonComponent,
                isHidden: true,
              },
              {
                title: "Phrases",
                path: pagePaths.managePhrases,
              },
              {
                title: "Global Video Sections",
                path: pagePaths.manageGlobalVideoSections,
              },
              {
                title: "Videos",
                path: pagePaths.videos,
              },
              {
                title: "Scripts",
                path: pagePaths.scripts,
              },
              {
                title: "Push",
                path: pagePaths.push,
              },
            ]}
          />
          <Switch>
            <Route path={pagePaths.signIn}>
              <SignInPage />
            </Route>
            <PrivateRoute path={pagePaths.manageLessons}>
              <ManageLessonsPage />
            </PrivateRoute>
            <PrivateRoute path={pagePaths.editLessonComponent}>
              <EditLessonComponentPage />
            </PrivateRoute>
            <PrivateRoute path={pagePaths.managePhrases}>
              <ManagePhrasesPage />
            </PrivateRoute>
            <PrivateRoute path={pagePaths.manageGlobalVideoSections}>
              <ManageGlobalVideoSectionsPage />
            </PrivateRoute>

            <PrivateRoute path={pagePaths.videos}>
              <VideosPage />
            </PrivateRoute>
            <PrivateRoute path={pagePaths.scripts}>
              <ScriptsPage />
            </PrivateRoute>
            <PrivateRoute path={pagePaths.push}>
              <PushPage />
            </PrivateRoute>
            <Route path="/" render={() => <Redirect to={pagePaths.signIn} />} />
          </Switch>
        </div>
      </Router>
      <RootModal />
    </Provider>
  );
}
