import { EnvironmentArgument } from "./PushService";

export class ChangeGroup {
  changes: Change[];

  constructor(changes: Change[]) {
    this.changes = changes;
  }

  filterByChanged() {
    return new ChangeGroup(this.changes.filter((v) => v.isDeepChanged()).map((v) => v.removeUnchangedChildren()));
  }

  filterDifferences(predicate: (difference: Difference) => boolean) {
    return new ChangeGroup(this.changes.map((c) => c.filterDifferences(predicate)));
  }

  getUniqueDifferenceKeys() {
    return [...new Set(this.changes.flatMap((v) => v.getUniqueDifferenceKeys()))];
  }

  toCSV() {
    return this.changes.flatMap((v) => v.toCSVEntries());
  }
}

export interface Changeable {
  id: string;

  toEnv(fromEnv: EnvironmentArgument, toEnv: EnvironmentArgument, push?: boolean): Promise<Changeable>;
  getIdentifier(): string;
  getTypeName(): string;
}

export class Change {
  type: "create" | "update" | "delete";
  instance: Changeable;
  differences: Difference[];
  childChanges: Change[];

  constructor(object: {
    type: "create" | "update" | "delete";
    instance: any;
    differences?: Difference[];
    childChanges?: Change[];
  }) {
    this.type = object.type;
    this.instance = object.instance;
    this.differences = object.differences ?? [];
    this.childChanges = object.childChanges ?? [];
  }

  isDeepChanged(): boolean {
    if (this.type !== "update" || this.differences.length > 0) return true;
    return this.childChanges.some((change) => change.isDeepChanged());
  }

  removeUnchangedChildren() {
    const copy = new Change(this);
    copy.childChanges = copy.childChanges.filter((v) => v.isDeepChanged()).map((v) => v.removeUnchangedChildren());
    return copy;
  }

  filterDifferences(predicate: (difference: Difference) => boolean): Change {
    return new Change({
      ...this,
      childChanges: this.childChanges.map((c) => c.filterDifferences(predicate)),
      differences: this.differences.filter((d) => predicate(d)),
    });
  }

  getUniqueDifferenceKeys(): string[] {
    const issues = this.differences
      .map((issue) => issue.key)
      .concat(...this.childChanges.map((v) => v.getUniqueDifferenceKeys()));
    return [...new Set(issues)];
  }

  toCSVEntries(): {
    "Change Type": string;
    "Data Type": string;
    Identifier: string;
    Differences: string;
  }[] {
    const csvEntries = [];
    csvEntries.push({
      "Change Type": this.type,
      "Data Type": this.instance.getTypeName ? this.instance.getTypeName() : this.instance.constructor.name,
      Identifier: this.instance.getIdentifier ? this.instance.getIdentifier() : "",
      Differences: this.differences.map((d) => d.toString()).join(", "),
    });
    csvEntries.push(...this.childChanges.flatMap((v) => v.toCSVEntries()));
    return csvEntries;
  }
}

export class Difference {
  key: string;
  fromValue: any;
  toValue: any;
  type: "field" | "array";

  constructor(object: Difference) {
    this.key = object.key;
    this.fromValue = object.fromValue;
    this.toValue = object.toValue;
    this.type = object.type;
  }

  toString() {
    switch (this.type) {
      case "array":
        return `Change in ${this.key} array`;
      default:
        return `${this.key}: ${this.fromValue} -> ${this.toValue}`;
    }
  }
}

export function getChanges(iterativeObjects: any[], sourceObjects: any[]) {
  const changes = [] as Change[];

  iterativeObjects.forEach((iterativeObject, index) => {
    const sourceObject = findSource(iterativeObject, sourceObjects, index);

    if (!sourceObject) {
      changes.push(
        new Change({
          type: "create",
          instance: iterativeObject,
          differences: [],
          childChanges: [],
        })
      );
    } else {
      changes.push(getChange(iterativeObject, sourceObject));
    }
  });

  sourceObjects.forEach((sourceObject, index) => {
    const iterativeObject = findSource(sourceObject, iterativeObjects, index);

    if (!iterativeObject) {
      changes.push(
        new Change({
          type: "delete",
          instance: sourceObject,
          differences: [],
          childChanges: [],
        })
      );
    }
  });

  return changes;

  function findSource(iterativeObject: any, sourceObjects: any[], index: number) {
    return iterativeObject.id || iterativeObject.uuid
      ? sourceObjects.find((o) => {
          if (o.id) return o.id === iterativeObject.id;
          if (o.uuid) return o.uuid === iterativeObject.uuid;
          return false;
        })
      : sourceObjects[index];
  }
}

function getChange(iterativeObject: any, sourceObject: any) {
  const change = new Change({
    type: "update",
    instance: iterativeObject,
  });

  for (const key in iterativeObject) {
    if (Array.isArray(iterativeObject[key])) {
      const iterativeArray = iterativeObject[key];
      const sourceArray = sourceObject[key];

      if (
        JSON.stringify(iterativeArray).replace(" null", " undefined") !==
        JSON.stringify(sourceArray).replace(" null", " undefined")
      ) {
        change.differences.push(
          new Difference({
            key: key,
            fromValue: sourceArray,
            toValue: iterativeArray,
            type: "array",
          })
        );
        if (!sourceArray) {
          change.childChanges.push(
            new Change({
              type: "create",
              instance: iterativeArray,
            })
          );
        } else {
          if (
            (iterativeArray.length > 0 && typeof iterativeArray[0] === "object") ||
            (sourceArray.length > 0 && typeof sourceArray[0] === "object")
          ) {
            change.childChanges.push(...getChanges(iterativeArray, sourceArray));
          }
        }
      }
    } else if (typeof iterativeObject[key] === "object") {
      change.childChanges.push(getChange(iterativeObject[key], sourceObject[key]));
    } else if (
      iterativeObject[key] !== sourceObject[key] &&
      !(iterativeObject[key] === undefined && sourceObject[key] === null)
    ) {
      change.differences.push(
        new Difference({
          key,
          fromValue: sourceObject[key],
          toValue: iterativeObject[key],
          type: "field",
        })
      );
    }
  }

  return change;
}
