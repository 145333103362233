import { ChevronDown, ChevronUp } from "heroicons-react";
import { useState } from "react";

import { ButtonType } from "./Button";
import { Card, Button } from "..";

export default function ExpandableCard({
  className,
  children,
  expandedView,
  initialIsExpanded,
  ...rest
}: {
  className?: string;
  children?: any;
  expandedView: any;
  initialIsExpanded?: boolean;
  [key: string]: any;
}) {
  const [isExpanded, setIsExplanded] = useState(initialIsExpanded);

  const toggleIsExpanded = () => {
    setIsExplanded((prev) => !prev);
  };

  return (
    <Card {...rest} className={`divide-y ${className}`}>
      <div className={`flex gap-2 items-center jusitfy-end ${isExpanded ? "mb-5" : ""}`}>
        <div className="flex-1">{children}</div>
        <Button displayType={ButtonType.text} onClick={toggleIsExpanded}>
          {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </Button>
      </div>
      {isExpanded ? <div className="pt-3">{expandedView()}</div> : null}
    </Card>
  );
}
