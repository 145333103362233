import CSVService from "../CSVService";
import { standardString } from "../helpers";
import Script, { ScriptArg } from "./Script";
import axios from "axios";

const execute = async ({ phrases, locale }: ScriptArg) => {
  const data = await CSVService.instance.readFromUrl(
    "https://kleo-script-files.s3.us-east-2.amazonaws.com/Spanish+All+Generated+Phrases.csv"
  );

  const output = [];

  for (const entry of data) {
    let isValid = true;
    const targetText = entry["target"];
    const words = targetText.split(" ");
    for (const word of words) {
      if (!phrases.find((p) => standardString(p.targetText) === standardString(word))) {
        isValid = false;
        break;
      }
    }
    if (isValid) {
      output.push({
        target: entry["target"],
        native: entry["native"],
      });
    }
  }

  CSVService.instance.downloadCSV(output, `${locale.title} All Generated Phrases.csv`);
};

export default new Script("Temp", execute);
