import { LessonComponent, Objective } from ".";
import { Change, Difference } from "../../../service/Change";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";
import { FailType } from "./types";

export default class FailDestination {
  failVideoClipId: string;
  unheardVideoClipId?: string | null;

  constructor(object: Partial<FailDestination> = {}) {
    this.failVideoClipId = object.failVideoClipId ?? "";
    this.unheardVideoClipId = object.unheardVideoClipId;
  }

  validate(lessonComponent: LessonComponent, objective: Objective) {
    const validation = new Validation({
      type: ValidationType.FailDestination,
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (!lessonComponent.getVideoClip(this.failVideoClipId))
      validation.issues.push(
        new ValidationIssue(
          "Field failVideoClipId must reference a video clip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (this.unheardVideoClipId && !lessonComponent.getVideoClip(this.unheardVideoClipId))
      validation.issues.push(
        new ValidationIssue(
          "Field unheardVideoClipId must reference a video clip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }
  getTypeName() {
    return "FailDestination";
  }

  // -- Gets

  getAllVideoClipIds(): string[] {
    return Object.values(this).filter((uuid) => !!uuid);
  }

  getFailTypeOfVideoClip(uuid: string): FailType | undefined {
    if (this.failVideoClipId === uuid) return FailType.FAIL;
    if (this.unheardVideoClipId === uuid) return FailType.UNHEARD;
  }

  includesVideoClip(uuid: string): boolean {
    return this.getAllVideoClipIds().includes(uuid);
  }

  getAvailableFailTypes(): FailType[] {
    let availableMatchTypes = [];
    if (this.failVideoClipId) availableMatchTypes.push(FailType.FAIL);
    return availableMatchTypes;
  }

  // -- Mutations

  addDestination(uuid: string, matchType: FailType) {
    if (matchType === FailType.UNHEARD) this.unheardVideoClipId = uuid;
  }

  removeDestination(uuid: string) {
    if (this.unheardVideoClipId === uuid) this.unheardVideoClipId = null;
  }

  replaceVideoClipIds(idMap: { [key: string]: string }) {
    this.failVideoClipId = idMap[this.failVideoClipId] ?? this.failVideoClipId;
    this.unheardVideoClipId = idMap[this.unheardVideoClipId ?? ""] ?? this.unheardVideoClipId;
  }
}
