import { hasFile } from "../../../service/StorageSerivce";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";
import { CodecType } from "./StreamPath";
import Video from "./Video";

export default class Variant {
  quality: string;
  size: number;
  bitrate: number;
  codec: string;
  audioPlaylistPath: string;
  videoPlaylistPath: string;
  audioPaths: string[];
  videoPaths: string[];

  constructor(object: Partial<Variant> = {}) {
    this.quality = object.quality ?? "";
    this.size = object.size ?? 0;
    this.codec = object.codec ?? CodecType.h264;
    this.audioPlaylistPath = object.audioPlaylistPath ?? "";
    this.videoPlaylistPath = object.videoPlaylistPath ?? "";
    this.bitrate = object.bitrate ?? 0;
    this.audioPaths = object.audioPaths ?? [];
    this.videoPaths = object.videoPaths ?? [];
  }

  validate(baseUrl: string, files: Map<string, string> | undefined, env: "prod" | "staging" | "dev" = "staging") {
    const validation = new Validation({
      type: ValidationType.Variant,
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (this.codec === CodecType.h265 && this.audioPaths.length !== this.videoPaths.length)
      validation.issues.push(
        new ValidationIssue(
          "Must have the same number of audio and video paths",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    for (const path of [...this.audioPaths, ...this.videoPaths]) {
      if (files && !hasFile(files, baseUrl + path, env)) {
        validation.issues.push(
          new ValidationIssue(
            `Video file ${path} does not exist in S3`,
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );
      }
    }

    return validation;
  }
  getIdentifier() {
    return `${this.codec} - ${this.quality}`;
  }
  getTypeName() {
    return "Variant";
  }
}
