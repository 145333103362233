export enum LessonComponentType {
  INTRODUCTION = "INTRODUCTION",
  PRACTICE = "PRACTICE",
  DEEPDIVE = "DEEPDIVE",
  MAGENT = "MAGENT",
  GLOBALVIDEOSECTION = "GLOBALVIDEOSECTION",
  CHECKPOINT = "CHECKPOINT",
  ROUNDUP = "ROUNDUP",
}

export const generatedComponentTypes = [
  LessonComponentType.PRACTICE,
  LessonComponentType.CHECKPOINT,
  LessonComponentType.ROUNDUP,
];
export enum LessonComponentDisplayType {
  COURSE = "COURSE",
  OTHER = "OTHER",
}

export enum DisplayItemType {
  PROMPT = "PROMPT",
  INFO = "INFO",
  TIP = "TIP",
}

export enum ObjectiveType {
  SPEECH = "SPEECH",
}

export enum VideoClipType {
  SECTION = "SECTION",
  TRANSITION = "TRANSITION",
  PLACEHOLDER = "PLACEHOLDER",
  SUCCESS = "SUCCESS",
  EXACT = "EXACT",
  SUBSTRING = "SUBSTRING",
  UNORDERED = "UNORDERED",
  FLEX = "FLEX",
  FAIL = "FAIL",
  UNHEARD = "UNHEARD",
  MISSING = "MISSING",
  EXTRA = "EXTRA",
}

export enum SectionType {
  SECTION = "SECTION",
  TRANSITION = "TRANSITION",
  PLACEHOLDER = "PLACEHOLDER",
}

export enum FailType {
  FAIL = "FAIL",
  UNHEARD = "UNHEARD",
}

export enum MatchType {
  SUCCESS = "SUCCESS",
  EXACT = "EXACT",
  SUBSTRING = "SUBSTRING",
  UNORDERED = "UNORDERED",
  FLEX = "FLEX",
}

export enum SupplementaryWordType {
  MISSING = "MISSING",
  EXTRA = "EXTRA",
}

export const kleoColorHex: {
  [key: string]: string;
} = {
  darkGreenKleo: "#3CD666",
  lightGreenKleo: "#85ED99",
  yellowKleo: "#FFF563",
  orangeKleo: "#F98958",
  redKleo: "#FA5761",
  goldKleo: "#3CD766",
};

export const oldKleoColorHex: {
  [key: string]: string;
} = {
  "#3CE66E": "darkGreenKleo",
  "#84EE9A": "lightGreenKleo",
  "#EFF29D": "yellowKleo",
  "#F98958": "orangeKleo",
  "#F95761": "redKleo",
  "#6835F8": "goldKleo",
  "#01BB5B": "darkGreenKleo",
  "#F28650": "orangeKleo",
  "#ABDE8E": "lightGreenKleo",
  "#FDD541": "yellowKleo",
  "#D4AF37": "goldKleo",
};

export const responseTypeColors: {
  [key: string]: string;
} = {
  SUCCESS: "darkGreenKleo",
  EXACT: "darkGreenKleo",
  SUBSTRING: "darkGreenKleo",
  UNORDERED: "darkGreenKleo",
  FLEX: "darkGreenKleo",
  FAIL: "orangeKleo",
  UNHEARD: "redKleo",
  EXTRA: "darkGreenKleo",
  MISSING: "yellowKleo",
};

export enum SectionPresetType {
  prompt = "prompt",
  reprompt = "reprompt",
  transition = "transition",
  placeholder = "placeholder",
}
