import { v4 as uuidv4 } from "uuid";
import { MatchType, FailType } from "./types";

export default class UserResult {
  id: string;
  userId: string;
  phraseId: string;
  localeId: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  deviceData?: DeviceData;
  lessonComponentMetadata?: LessonComponenetMetaData;
  speechMetadata?: SpeechMetaData;
  timeOfInitialInteraction?: string;

  constructor(object: Partial<UserResult> = {}) {
    this.id = object.id ?? uuidv4();
    this.userId = object.userId ?? "";
    this.phraseId = object.phraseId ?? "";
    this.localeId = object.localeId ?? "";
    this.type = object.type ?? "";
    this.deviceData = object.deviceData ? new DeviceData(object.deviceData) : undefined;
    this.lessonComponentMetadata = object.lessonComponentMetadata
      ? new LessonComponenetMetaData(object.lessonComponentMetadata)
      : undefined;
    this.speechMetadata = object.speechMetadata ? new SpeechMetaData(object.speechMetadata) : undefined;
    this.createdAt = object.createdAt ?? new Date().toISOString();
    this.updatedAt = object.updatedAt ?? new Date().toISOString();
    this.timeOfInitialInteraction = object.timeOfInitialInteraction ?? new Date().toISOString();
  }

  getLocation() {
    if (!this.lessonComponentMetadata) return UserResultLocation.Vocab;
    if (this.lessonComponentMetadata.isOnReprompt) return UserResultLocation.Reprompt;
    return UserResultLocation.Prompt;
  }
}

export enum UserResultLocation {
  Prompt = "Prompt",
  Reprompt = "Reprompt",
  Vocab = "Vocab",
}

export class DeviceData {
  osName: OS;
  osVersion: string;
  modelCode: string;

  constructor(object: DeviceData) {
    this.osName = object.osName;
    this.osVersion = object.osVersion;
    this.modelCode = object.modelCode;
  }
}

export enum OS {
  iOS = "iOS",
  Android = "Android",
}

export class LessonComponenetMetaData {
  lessonComponentId: string;
  videoClipSectionId: string;
  videoClipDestinationId: string;
  isOnReprompt: boolean;

  constructor(object: Partial<LessonComponenetMetaData> = {}) {
    this.lessonComponentId = object.lessonComponentId ?? "";
    this.videoClipSectionId = object.videoClipSectionId ?? "";
    this.videoClipDestinationId = object.videoClipDestinationId ?? "";
    this.isOnReprompt = object.isOnReprompt ?? false;
  }
}

export class SpeechMetaData {
  timeOfFirstTranscription: string;
  transcriptionText: string;
  matchType: MatchType;
  failType: FailType;
  missingWords: string[];
  extraWords: string[];
  confidenceScore: number;
  rawScore: number;
  isOnDistribution: boolean;
  allTranscriptions: string[];

  constructor(object: Partial<SpeechMetaData> = {}) {
    this.timeOfFirstTranscription = object.timeOfFirstTranscription ?? "";
    this.transcriptionText = object.transcriptionText ?? "";
    this.matchType = object.matchType ?? MatchType.SUCCESS;
    this.failType = object.failType ?? FailType.FAIL;
    this.missingWords = object.missingWords ?? [];
    this.extraWords = object.extraWords ?? [];
    this.confidenceScore = object.confidenceScore ?? 0;
    this.rawScore = object.rawScore ?? 0;
    this.isOnDistribution = object.isOnDistribution ?? false;
    this.allTranscriptions = object.allTranscriptions ?? [];
  }
}
