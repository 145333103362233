import { v4 as uuidv4 } from "uuid";

import { Phrase } from ".";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";

export enum VocabPhraseType {
  phrase = "phrase",
  constructPhrase = "constructPhrase",
}

export default class VocabSet {
  uuid: string;
  title: string;
  phraseIds: string[];
  constructPhraseIds: string[];
  viewGroups: string[];
  orderIndex: number;

  constructor(object: Partial<VocabSet> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.title = object.title ?? "";
    this.phraseIds = object.phraseIds ?? [];
    this.constructPhraseIds = object.constructPhraseIds ?? [];
    this.viewGroups = object.viewGroups ?? ["pro"];
    this.orderIndex = object.orderIndex ?? 0;
  }

  getAllPhraseIds() {
    return [...this.phraseIds, ...this.constructPhraseIds];
  }

  getAllPhrases(phrases: Phrase[]) {
    const allPhrases = [];
    for (const id of this.getAllPhraseIds()) {
      const phrase = phrases.find((phrase) => phrase.id === id);
      if (!phrase) throw new Error(`Could not find phrase with id ${id}`);
      allPhrases.push(phrase);
    }
    return allPhrases;
  }

  // validate(phrases: Phrase[]) {
  //   const validation = new Validation({
  //     type: ValidationType.VocabSet,
  //     instance: this,
  //     issues: [],
  //     childValidations: [],
  //   });

  //   if ([...this.phraseIds, ...this.constructPhraseIds].length === 0)
  //     validation.issues.push(
  //       new ValidationIssue(
  //         "Must have at least one phrase or construct phrase",
  //         ValidationIssueGravity.error,
  //         ValidationIssueCause.userInput
  //       )
  //     );

  //   this.phraseIds.forEach((id, index) => {
  //     if (this.phraseIds.findIndex((phraseId) => phraseId === id) !== index) {
  //       validation.issues.push(
  //         new ValidationIssue("Duplicate phrase ids", ValidationIssueGravity.error, ValidationIssueCause.userInput, id)
  //       );
  //     }
  //     if (!phrases.find((phrase) => phrase.id === id))
  //       validation.issues.push(
  //         new ValidationIssue(
  //           "Each item in field phraseIds must reference a phrase",
  //           ValidationIssueGravity.error,
  //           ValidationIssueCause.internal,
  //           id
  //         )
  //       );
  //   });

  //   this.constructPhraseIds.forEach((id, index) => {
  //     if (this.constructPhraseIds.findIndex((phraseId) => phraseId === id) !== index) {
  //       validation.issues.push(
  //         new ValidationIssue(
  //           "Duplicate construct phrase ids",
  //           ValidationIssueGravity.error,
  //           ValidationIssueCause.userInput,
  //           id
  //         )
  //       );
  //     }
  //     if (!phrases.find((phrase) => phrase.id === id))
  //       validation.issues.push(
  //         new ValidationIssue(
  //           "Each item in field constructPhraseIds must reference a phrase",
  //           ValidationIssueGravity.error,
  //           ValidationIssueCause.internal,
  //           id
  //         )
  //       );
  //   });

  //   return validation;
  // }
  getIdentifier() {
    return `${this.uuid} - ${this.title}`;
  }
  getTypeName() {
    return "VocabSet";
  }
}
