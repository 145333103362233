import { Eye, EyeOff, Selector, XCircle } from "heroicons-react";

import Button from "../base/Button";

export default function ReorderButton({
  className,
  isReordering,
  onToggle,
  ...rest
}: {
  className?: string;
  isReordering: boolean;
  onToggle: (value: boolean) => void;
  [key: string]: any;
}) {
  if (isReordering) {
    return (
      <Button {...rest} className={`${className}`} onClick={() => onToggle(false)} shade={100}>
        <XCircle size={16} />
        Done
      </Button>
    );
  } else {
    return (
      <Button className={`${className}`} onClick={() => onToggle(true)} shade={100}>
        <Selector size={16} />
      </Button>
    );
  }
}
