/* eslint-disable */

export default class Outcome<T> {}

export class Success<T> extends Outcome<T> {
  data: T;

  constructor(data: T) {
    super();
    this.data = data;
  }
}

export class Failure<T> extends Outcome<T> {
  error: string;

  constructor(error: string) {
    super();
    this.error = error;
  }
}
