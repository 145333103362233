import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { StatusType } from "./types";
import { Locale, UserResult } from "../domain/entities";
import { Failure, Success } from "../../service/api/Outcome";
import { RootState } from "./store";
import { remoteDataSource } from "..";
import { OS, UserResultLocation } from "../domain/entities/UserResult";
import { updateObjectInList } from "../../service/helpers";

export type TranscriptionFilter = {
  isMain?: boolean;
  location?: UserResultLocation;
  os?: OS;
};

export type TranscriptionData = {
  count: number;
  userIds: string[];
};

export const fetchUserResults = createAsyncThunk(`userResults/fetch`, async () => {
  const result = await remoteDataSource.allUserResults();

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const fetchProdUserResults = createAsyncThunk(`userResultsProd/fetch`, async () => {
  const result = await remoteDataSource.allUserResults("prod");

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const updateProdUserResult = createAsyncThunk(`userResults/update`, async (userResult: UserResult) => {
  // Update updatedAt
  userResult.updatedAt = new Date().toISOString();

  const result = await remoteDataSource.updateUserResult(userResult, "prod");

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export function mapUserResultsToTranscriptions(userResults: UserResult[], filter: TranscriptionFilter) {
  const transcriptionsMap = new Map<string, TranscriptionData>();

  for (const userResult of userResults) {
    const userId = userResult.userId;
    const deviceData = userResult.deviceData;
    const speechData = userResult.speechMetadata;
    if (!deviceData || !speechData) continue;

    if (
      filter.os &&
      ((filter.os === OS.iOS && filter.os !== deviceData.osName) ||
        (filter.os === OS.Android && OS.iOS === deviceData.osName))
    )
      continue;

    if (filter.location && userResult.getLocation() !== filter.location) continue;

    const mainTrascription = speechData.transcriptionText;
    addTranscription(mainTrascription, userId);

    if (filter.isMain && userResult.type !== "main") continue;
    for (const transcription of speechData.allTranscriptions) {
      addTranscription(transcription, userId);
    }
  }

  function addTranscription(transcription: string, userId: string) {
    const transcriptionData = transcriptionsMap.get(transcription);

    const count = transcriptionData ? transcriptionData.count : 0;

    const userIds = transcriptionData ? transcriptionData.userIds : [];
    if (!userIds.includes(userId)) userIds.push(userId);

    transcriptionsMap.set(transcription, {
      count: count + 1,
      userIds: userIds,
    });
  }

  return transcriptionsMap;
}

const initialState = {
  items: [] as UserResult[],
  current: null as UserResult | undefined | null,
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as UserResult[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
};

export const userResultsSlice = createSlice({
  name: "userResults",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserResults.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchUserResults.fulfilled, (state, { payload: userResults }: { payload: UserResult[] }) => {
      state.status = StatusType.succeeded;
      state.items = userResults;
    });
    builder.addCase(fetchUserResults.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(fetchProdUserResults.pending, (state) => {
      state.prodStatus = StatusType.loading;
    });
    builder.addCase(fetchProdUserResults.fulfilled, (state, { payload: userResults }: { payload: UserResult[] }) => {
      state.prodStatus = StatusType.succeeded;
      state.prodItems = userResults;
    });
    builder.addCase(fetchProdUserResults.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
    builder.addCase(updateProdUserResult.fulfilled, (state, { payload: userResult }: { payload: UserResult }) => {
      state.prodItems = updateObjectInList(state.prodItems, userResult);
    });
    builder.addCase(updateProdUserResult.rejected, () => {
      alert("Error! User Result was not updated. Cheeck logs for more info");
    });
  },
});

export const getCurrentUserResult = (state: RootState) => state.userResults.current;
export const getAllUserResults = (state: RootState) => state.userResults.items;
export const getUserResultsStatus = (state: RootState) => state.userResults.status;
export const getUserResultsError = (state: RootState) => state.userResults.error;
export const getAllProdUserResults = (state: RootState) => state.userResults.prodItems;
export const getProdUserResultsStatus = (state: RootState) => state.userResults.prodStatus;
export const getProdUserResultsError = (state: RootState) => state.userResults.prodError;

export default userResultsSlice.reducer;
