import { v4 as uuidv4 } from "uuid";

export default class UserPhrase {
  id: string;
  didSucceedLastAttempt: boolean;
  failedAttempts: number;
  successfulAttempts: number;
  localeId: string;
  userId: string;
  phraseId: string;
  recentAttempts: boolean[];
  createdAt: Date;
  updatedAt: Date;

  constructor(arg: UserPhraseArgument) {
    this.id = arg.id ?? uuidv4();
    this.didSucceedLastAttempt = arg.didSucceedLastAttempt ?? false;
    this.failedAttempts = arg.failedAttempts ?? 0;
    this.successfulAttempts = arg.successfulAttempts ?? 0;
    this.localeId = arg.localeId;
    this.userId = arg.userId;
    this.phraseId = arg.phraseId;
    this.recentAttempts = arg.recentAttempts ?? [];
    this.createdAt = new Date(arg.updatedAt ?? Date.now());
    this.updatedAt = new Date(arg.createdAt ?? Date.now());
  }

  get accuracy() {
    return this.successfulAttempts / (this.successfulAttempts + this.failedAttempts);
  }

  get recentAccuracy() {
    if (this.recentAttempts.length <= 20) return this.accuracy;
    return this.recentAttempts.filter((a) => a).length / this.recentAttempts.length;
  }

  get strength() {
    const accuracy = this.recentAccuracy;
    if (accuracy >= 0.9) return StrengthType.strong;
    if (accuracy >= 0.7) return StrengthType.medium;
    return StrengthType.weak;
  }
}

export type UserPhraseArgument = {
  id?: string;
  didSucceedLastAttempt?: boolean;
  failedAttempts?: number;
  successfulAttempts?: number;
  localeId: string;
  userId: string;
  phraseId: string;
  recentAttempts?: boolean[];
  createdAt?: Date;
  updatedAt?: Date;
};

export enum StrengthType {
  strong = "strong",
  medium = "medium",
  weak = "weak",
}
