import { useEffect, useRef } from "react";

export default function Dropdown({
  className,
  isOpen,
  disableAutoClose,
  onClose,
  children,
  ...rest
}: {
  className?: string;
  isOpen: boolean;
  disableAutoClose?: boolean;
  onClose: () => void;
  children?: any;
  [key: string]: any;
}) {
  const dropdown: any = useRef(null);

  // -- Effect
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  });

  // -- Functions
  function handleClick(e: any) {
    if (!dropdown || !dropdown.current || !dropdown.current.contains(e.target)) {
      onClose();
    }
  }

  if (isOpen)
    return (
      <div
        {...rest}
        ref={dropdown}
        className={`mt-9 absolute right-0 top-1 bg-white border shadow-xl rounded-md w-60 p-2 z-10 ${className}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!disableAutoClose) onClose();
        }}
      >
        {children}
      </div>
    );

  return <div ref={dropdown} />;
}
