import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { remoteDataSource } from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { updateObjectInList } from "../../service/helpers";
import { Locale, Package } from "../domain/entities";
import { RootState } from "./store";
import { StatusType } from "./types";

export const fetchPackages = createAsyncThunk(`packages/fetch`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.packages.items.length > 0 && state.packages.items[0].localeId !== locale.id;
  if (state.packages.items.length > 0 && (!localeDidChange || state.packages.status !== StatusType.loading))
    return state.packages.items;

  const result = await remoteDataSource.allPackages(locale);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const fetchProdPackages = createAsyncThunk(`packages/fetchProd`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.packages.items.length > 0 && state.packages.items[0].localeId !== locale.id;
  if (state.packages.items.length > 0 && (!localeDidChange || state.packages.status !== StatusType.loading))
    return state.packages.items;

  const result = await remoteDataSource.allPackages(locale, "prod");

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const createPackage = createAsyncThunk(`packages/create`, async (pkg: Package) => {
  const result = await remoteDataSource.createPackage(pkg);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const updatePackage = createAsyncThunk(`packages/update`, async (pkg: Package) => {
  const result = await remoteDataSource.updatePackage(pkg);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const deletePackage = createAsyncThunk(`packages/delete`, async (pkg: Package) => {
  const result = await remoteDataSource.deletePackage(pkg);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

const initialState = {
  items: [] as Package[],
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as Package[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
};

export const packagesSlice = createSlice({
  name: "packages",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchPackages.fulfilled, (state, { payload: packages }: { payload: Package[] }) => {
      state.status = StatusType.succeeded;
      state.items = packages;
    });
    builder.addCase(fetchPackages.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(fetchProdPackages.pending, (state) => {
      state.prodStatus = StatusType.loading;
    });
    builder.addCase(fetchProdPackages.fulfilled, (state, { payload: packages }: { payload: Package[] }) => {
      state.prodStatus = StatusType.succeeded;
      state.prodItems = packages;
    });
    builder.addCase(fetchProdPackages.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
    builder.addCase(createPackage.fulfilled, (state, { payload: pkg }: { payload: Package }) => {
      state.items = [...state.items, pkg];
    });
    builder.addCase(createPackage.rejected, () => {
      alert("Error! Package was not created. Cheeck logs for more info");
    });
    builder.addCase(updatePackage.fulfilled, (state, { payload: pkg }: { payload: Package }) => {
      state.items = updateObjectInList(state.items, pkg);
    });
    builder.addCase(updatePackage.rejected, () => {
      alert("Error! Package was not updated. Cheeck logs for more info");
    });
    builder.addCase(deletePackage.fulfilled, (state, { payload: pkg }: { payload: Package }) => {
      state.items = state.items.filter((p) => p.id !== pkg.id);
    });
    builder.addCase(deletePackage.rejected, () => {
      alert("Error! Package was not deleted. Cheeck logs for more info");
    });
  },
});

export const getAllPackages = (state: RootState) => state.packages.items;
export const getPackagesStatus = (state: RootState) => state.packages.status;
export const getPackagesError = (state: RootState) => state.packages.error;
export const getAllProdPackages = (state: RootState) => state.packages.prodItems;
export const getProdPackagesStatus = (state: RootState) => state.packages.prodStatus;
export const getProdPackagesError = (state: RootState) => state.packages.prodError;

export default packagesSlice.reducer;
