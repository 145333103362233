import { Form, Formik, FormikProps } from "formik";
import { Duplicate, Exclamation, LocationMarker, Plus } from "heroicons-react";
import Hls from "hls.js";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DisplayItem, Video, VideoClip } from "../../core/domain/entities";
import { DisplayItemType } from "../../core/domain/entities/types";
import { getComponent, updateCurrentComponent } from "../../core/redux/componentSlice";
import { getAllVideos, getVideosError, getVideosStatus, verifyVideo } from "../../core/redux/videosSlice";
import { copyToClipboard, removeFromListAtIndex, updateObject } from "../../service/helpers";

import { Button, CheckBoxInput, DisplayItemCard, FormErrorMessage, Input, Modal, StatusView } from "..";
import { CodecType } from "../../core/domain/entities/StreamPath";
import { ButtonType } from "../base/Button";

export type VideoClipModalProps = {
  videoClip: VideoClip;
};

export type VideoClipFormValues = {
  title: string;
  databaseName?: string | null;
  startInteractionTime: number;
  displayItems: DisplayItem[];
  duration: number;
  isGeneric: boolean;
};

export default function VideoClipModal({
  isOpen,
  videoClip,
  onClose,
}: {
  isOpen: boolean;
  videoClip: VideoClip;
  onClose: () => void;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);
  const videos = useSelector(getAllVideos);
  const videosStatus = useSelector(getVideosStatus);
  const videosError = useSelector(getVideosError);

  // -- Refs
  const formRef = useRef<FormikProps<VideoClipFormValues>>(null);
  const player = useRef<any>();

  // -- Local state
  const [videoTime, setVideoTime] = useState(0);
  const [video, setVideo] = useState<Video | null>(null);

  // -- Effect
  // Prepare HLS player on load
  useEffect(() => {
    if (videoClip.databaseName) prepareHlsVideo(videoClip.databaseName);
    else if (videoClip.databaseName) searchForVideo(videoClip.databaseName);
  }, [videosStatus]);

  // Update current video time in state
  useEffect(() => {
    const interval = setInterval(() => {
      if (player && player.current) setVideoTime(player.current.currentTime);
    }, 250);
    return () => clearInterval(interval);
  }, []);

  // -- Functions
  const onSubmit = (values: {
    title: string;
    databaseName?: string | null;
    startInteractionTime?: number | null;
    displayItems: DisplayItem[];
    isGeneric: boolean;
  }) => {
    lessonComponent!.updateVideoClip(updateObject(videoClip, values));
    dispatch(updateCurrentComponent(lessonComponent!));
    onClose();
  };

  const handleVerifyVideo = () => {
    const video = videos.find((video) => video.name === videoClip.databaseName);
    if (!video) return;
    dispatch(verifyVideo(video));
  };

  function prepareHlsVideo(databaseName: string) {
    if (!player.current) return;
    const video = videos.find((video) => video.name === databaseName);
    if (!video) return;
    setVideo(video);
    const source = video.getSourceUrl();
    if (!source) return;
    const hls = new Hls({ maxLoadingDelay: 0.1 });
    hls.loadSource(source);
    hls.attachMedia(player.current);
    hls.startLevel = 0;
    hls.autoLevelCapping = 0;
    hls.on(Hls.Events.ERROR, (_, data) => {
      console.log("HLS Error:", data);
    });
  }

  const handleDatabaseNameChange = (databaseName: string) => {
    if (!formRef.current) return;
    formRef.current.setFieldValue("databaseName", databaseName);
    searchForVideo(databaseName);
  };

  const searchForVideo = (databaseName: string) => {
    if (!formRef.current) return;
    const video = videos.find((video) => video.name === databaseName);
    if (video) {
      prepareHlsVideo(video.name);
    } else {
      const hls = new Hls();
      hls.attachMedia(player.current);
      hls.destroy();
    }
  };

  const deleteDisplayItem = (index: number) => {
    if (!formRef.current) return;
    formRef.current.setFieldValue("displayItems", removeFromListAtIndex(formRef.current.values.displayItems, index));
  };
  const addDisplayItem = () => {
    if (!formRef.current) return;
    formRef.current.setFieldValue("displayItems", [
      ...formRef.current.values.displayItems,
      new DisplayItem(
        formRef.current.values.displayItems.some((di) => di.type === DisplayItemType.PROMPT)
          ? { type: DisplayItemType.INFO }
          : {}
      ),
    ]);
  };

  const markStartInteractionTime = () => {
    if (!formRef.current || !player.current) return;
    formRef.current.setFieldValue("startInteractionTime", player.current.currentTime);
  };

  const markDuration = () => {
    if (!formRef.current || !player.current) return;
    formRef.current.setFieldValue("duration", player.current.currentTime);
  };

  // -- Components

  const DisplayItemView = ({ formProps }: { formProps: FormikProps<VideoClipFormValues> }) => {
    const { values } = formProps;

    const getSortedDisplayItems = () => {
      let output = _.cloneDeep(values.displayItems);
      output.sort((a, b) =>
        a.primaryDisplayTime ? (b.primaryDisplayTime ? b.primaryDisplayTime - a.primaryDisplayTime : -1) : 1
      );
      const prompts = output.filter((d) => d.type === DisplayItemType.PROMPT);
      const infos = output.filter((d) => d.type === DisplayItemType.INFO || d.type === DisplayItemType.TIP);
      output = [...infos, ...prompts];
      return output;
    };

    return (
      <div className="absolute bottom-5 text-white flex flex-col pointer-events-none">
        {getSortedDisplayItems().map((displayItem: DisplayItem, index: number) => {
          const isPrompt = displayItem.type === DisplayItemType.PROMPT;
          const primaryShouldDisplay =
            displayItem.primaryDisplayTime &&
            displayItem.primaryDisplayTime < videoTime &&
            (!displayItem.primaryEndTime || videoTime < displayItem.primaryEndTime);
          const secondaryShouldDisplay =
            displayItem.secondaryDisplayTime &&
            displayItem.secondaryDisplayTime < videoTime &&
            (!displayItem.secondaryEndTime || videoTime < displayItem.secondaryEndTime);
          return (
            <div key={index} className="flex flex-col items-center">
              {primaryShouldDisplay ? (
                <p className={`font-${isPrompt ? "bold" : "medium"} text-${isPrompt ? "xl" : "md"}`}>
                  {displayItem.primaryText}
                </p>
              ) : null}
              {secondaryShouldDisplay ? (
                <p className={`text-${isPrompt ? "md" : "sm"}`}>{displayItem.secondaryText}</p>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  const VerificationButton = () => {
    const video = videos.find((video) => video.name === videoClip.databaseName);

    if (video && video.hasChanged)
      return (
        <Button onClick={handleVerifyVideo} className="mb-5 h-20">
          Verify
        </Button>
      );
    return null;
  };

  const URLCopyButton = ({ codec }: { codec: CodecType }) => {
    if (video && video.getSourceUrl(codec)) {
      return (
        <Button
          displayType={ButtonType.pure}
          color="gray"
          onClick={() => copyToClipboard(video.getSourceUrl(codec) ?? "")}
        >
          <Duplicate size={16} />
          {codec as string}
        </Button>
      );
    } else {
      return <div className="text-xs text-red-600 font-bold mt-2">{`No ${codec as string} variant`}</div>;
    }
  };

  return (
    <Modal
      containerClassName="w-4/5"
      isOpen={isOpen}
      title={videoClip.title ?? "Edit Video Clip"}
      subtitle={videoClip.uuid}
      onClose={onClose}
    >
      <Formik
        innerRef={formRef}
        initialValues={
          {
            title: videoClip.title,
            databaseName: videoClip.databaseName,
            startInteractionTime: videoClip.startInteractionTime,
            displayItems: videoClip.displayItems,
            duration: videoClip.duration,
            isGeneric: videoClip.isGeneric,
          } as VideoClipFormValues
        }
        validate={(values) => {
          const errors: { title?: string; databaseName?: string } = {};
          if (!values.title) errors.title = "Cannot be blank";
          if (!videos.find((video) => video.name === values.databaseName)) errors.databaseName = "No video found";
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {(formProps) => {
          const { values, handleChange, errors } = formProps;
          return (
            <Form className="flex flex-col gap-2">
              <VerificationButton />
              <div className="flex gap-5">
                <div
                  className="flex flex-col items-center relative"
                  style={{
                    width: window.innerHeight * 0.3375,
                    height: (window.innerHeight / 5) * 3,
                  }}
                >
                  <StatusView status={videosStatus} error={videosError}>
                    {!values.databaseName ? (
                      <div className="absolute inset-0 p-5 flex flex-col items-center text-center text-xs z-10">
                        <Exclamation size={16} className="text-red-500" />
                        <FormErrorMessage className="font-medium" error={errors.databaseName} />
                      </div>
                    ) : null}
                    <video id="player" ref={player} className="rounded-xl shadow-sm outline-none" controls />
                    <DisplayItemView formProps={formProps} />
                    <div className="flex gap-2">
                      <URLCopyButton codec={CodecType.h264} />
                      <URLCopyButton codec={CodecType.h265} />
                    </div>
                  </StatusView>
                </div>
                <div className="flex-1 flex flex-col gap-2">
                  <div>
                    <Input
                      title="Title"
                      placeholder="Success"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <FormErrorMessage error={errors.title} />
                  </div>
                  <Input
                    autoFocus
                    title="Database Name"
                    placeholder="I1_S1_C1"
                    name="databaseName"
                    value={values.databaseName ?? ""}
                    set={handleDatabaseNameChange}
                  />
                  {videoClip.objectiveId ? (
                    <Input
                      title="Start Interaction Time"
                      placeholder={0}
                      type="number"
                      name="startInteractionTime"
                      value={values.startInteractionTime?.toString() ?? ""}
                      onChange={handleChange}
                      sideComponent={
                        <Button shade={100} onClick={markStartInteractionTime}>
                          <LocationMarker size={16} />
                        </Button>
                      }
                    />
                  ) : null}
                  <CheckBoxInput
                    className="mt-2"
                    message="isGeneric"
                    value={values.isGeneric}
                    set={(value) => formProps.setFieldValue("isGeneric", value)}
                  />
                  <div className="flex-1" />
                  <Input
                    title="Duration"
                    placeholder="0.00"
                    note="This duration is specific to this video clip and will not affect the duration of the global video.
                        If it is not set, the duration of the global video will be used"
                    type="number"
                    name="duration"
                    value={values.duration?.toString() ?? ""}
                    onChange={handleChange}
                    sideComponent={
                      <Button shade={100} onClick={markDuration}>
                        <LocationMarker size={16} />
                      </Button>
                    }
                  />
                </div>
                <div className="flex flex-col gap-1" style={{ width: 550 }}>
                  <div className="text-xs font-medium">Display Items</div>
                  <div
                    className="flex flex-col gap-2 overflow-scroll p-5 bg-gray-100 rounded-md"
                    style={{ height: (window.innerHeight / 5) * 3 - 60 }}
                  >
                    {values.displayItems.map((_, index) => (
                      <DisplayItemCard
                        key={index}
                        index={index}
                        player={player}
                        handleDelete={() => deleteDisplayItem(index)}
                        formProps={formProps}
                      />
                    ))}
                  </div>
                  <Button shade={100} onClick={addDisplayItem}>
                    <Plus size={16} />
                  </Button>
                </div>
              </div>
              <div className="h-5" />
              <Button type="submit">Done</Button>
              <Button shade={100} onClick={onClose}>
                Cancel
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
