export default function Card({
  className,
  onClick,
  children,
  ...rest
}: {
  className?: string;
  onClick?: (e: any) => void;
  children?: any;
  [key: string]: any;
}) {
  return (
    <div
      {...rest}
      className={`bg-white rounded-md transition transform hover:scale-101 hover:shadow-xl p-5 ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
