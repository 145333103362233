import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";

export enum CodecType {
  h265 = "h265",
  h264 = "h264",
}

export default class StreamPath {
  codec: CodecType;
  path: string;

  constructor(object: Partial<StreamPath> = {}) {
    this.codec = object.codec ?? CodecType.h265;
    this.path = object.path ?? "";
  }

  validate() {
    const validation = new Validation({
      type: ValidationType.StreamPath,
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (!this.path)
      validation.issues.push(
        new ValidationIssue("Field path is required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );

    return validation;
  }
  getIdentifier() {
    return `${this.path}`;
  }
  getTypeName() {
    return "StreamPath";
  }
}
