import { useState } from "react";
import {
  AcademicCap,
  ChevronDown,
  ChevronUp,
  Clipboard,
  Code,
  Database,
  DotsHorizontal,
  Duplicate,
  Flag,
  PlusCircle,
  XCircle,
} from "heroicons-react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Droppable } from "react-beautiful-dnd";

import { ButtonType } from "../base/Button";
import { Objective, Phrase, SupplementaryWord, TargetPhrase, TargetPhraseGroup } from "../../core/domain/entities";
import {
  copyTargetPhraseGroup,
  getCopiedTargetPhraseGroup,
  getComponent,
  pasteTargetPhraseGroup,
  updateCurrentComponent,
} from "../../core/redux/componentSlice";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import { openModal } from "../../core/redux/modalSlice";
import { getCurrentUser } from "../../core/redux/userSlice";
import { getAllPhrases } from "../../core/redux/phrasesSlice";

import { Dropdown, DropdownItem, Button, TargetPhraseCard, ValidationIndicator } from "..";
import { ValidationIssueGravity } from "../../service/Validation";

export default function TargetPhraseGroupCard({
  targetPhraseGroup,
  row,
  column,
  isReordering,
}: {
  targetPhraseGroup: TargetPhraseGroup;
  row: number;
  column: number;
  isReordering: boolean;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);
  const coppiedTargetPhraseGroup = useSelector(getCopiedTargetPhraseGroup);
  const currentLocale = useSelector(getCurrentLocale);
  const currentUser = useSelector(getCurrentUser);
  const phrases = useSelector(getAllPhrases);

  // -- Local state
  const [optionsDropdownIsOpen, setOptionsDropdownIsOpen] = useState(false);

  // -- Functions
  const copy = () => {
    dispatch(copyTargetPhraseGroup(targetPhraseGroup));
  };
  const paste = () => {
    if (coppiedTargetPhraseGroup) {
      targetPhraseGroup.paste(coppiedTargetPhraseGroup);
      lessonComponent!.updateTargetPhraseGroup(targetPhraseGroup);
      dispatch(updateCurrentComponent(lessonComponent!));
      dispatch(pasteTargetPhraseGroup());
    }
  };

  const handleDeleteTargetPhraseGroup = () => {
    lessonComponent!.deleteTargetPhraseGroup(targetPhraseGroup.uuid);
    dispatch(updateCurrentComponent(lessonComponent!));
  };

  const handleMove = (direction: "up" | "down") => {
    lessonComponent!.moveTargetPhraseGroup(targetPhraseGroup.uuid, direction);
    dispatch(updateCurrentComponent(lessonComponent!));
  };

  // UI
  const openOptionsDropdown = () => {
    setOptionsDropdownIsOpen(true);
  };
  const closeOptionsDropdown = () => {
    setOptionsDropdownIsOpen(false);
  };

  const openSupplementaryWordModal = () => {
    dispatch(
      openModal({
        supplementaryWordModal: {
          isCreating: true,
          targetPhraseGroup: targetPhraseGroup,
          supplementaryWord: new SupplementaryWord(),
        },
      })
    );
  };

  const openMatchFailTypeModal = () => {
    dispatch(
      openModal({
        matchFailDestinationModal: {
          targetPhraseGroup: targetPhraseGroup,
        },
      })
    );
  };

  const openCreateTargetPhraseModal = () => {
    dispatch(
      openModal({
        targetPhraseModal: {
          isCreating: true,
          targetPhrase: new TargetPhrase(),
          targetPhraseGroup: targetPhraseGroup,
        },
      })
    );
  };

  const openCreatePhraseModal = () => {
    dispatch(
      openModal({
        phraseModal: {
          isCreating: true,
          phrase: new Phrase({
            localeId: currentLocale!.id,
            createdBy: currentUser?.attributes.sub,
            updatedBy: currentUser?.attributes.sub,
          }),
        },
      })
    );
  };

  const openContingenciesModal = () => {
    const objective = lessonComponent!.getObjectiveForTargetPhraseGroup(targetPhraseGroup.uuid);
    if (!objective) return;
    dispatch(
      openModal({
        contingenciesModal: {
          objective: objective,
          targetPhraseGroup: targetPhraseGroup,
        },
      })
    );
  };

  // -- Components
  const OptionsDropdown = () => {
    return (
      <Dropdown className="-right-1 divide-y" isOpen={optionsDropdownIsOpen} onClose={closeOptionsDropdown}>
        <div>
          <DropdownItem onClick={openCreateTargetPhraseModal}>
            <Database size={16} />
            Add Existing Phrase
          </DropdownItem>
          <DropdownItem onClick={openCreatePhraseModal}>
            <PlusCircle size={16} />
            Create New Phrase
          </DropdownItem>
        </div>
        <div>
          <DropdownItem onClick={openMatchFailTypeModal}>
            <AcademicCap size={16} />
            Specify Match/Fail Type
          </DropdownItem>
          <DropdownItem onClick={openSupplementaryWordModal}>
            <Flag size={16} />
            Add Supplementary Word
          </DropdownItem>
          <DropdownItem onClick={openContingenciesModal}>
            <Code size={16} />
            Contingencies
          </DropdownItem>
        </div>
        <div>
          <DropdownItem onClick={copy}>
            <Duplicate size={16} />
            Copy
          </DropdownItem>
          <DropdownItem onClick={paste}>
            <Clipboard size={16} />
            Paste
          </DropdownItem>
        </div>
        <div>
          <DropdownItem color="red" shade={500} onClick={handleDeleteTargetPhraseGroup}>
            <XCircle size={16} />
            Delete
          </DropdownItem>
        </div>
      </Dropdown>
    );
  };

  const ContingencyIndicator = () => {
    if (targetPhraseGroup.contingencies.length > 0) return <Code size={16} className="self-center ml-4" />;
    return null;
  };

  return (
    <div style={{ gridRow: row, gridColumn: column }}>
      <div
        className={`
          w-52 p-2 h-full
          rounded-lg 
          flex gap-2
          relative
          bg-indigo-100
        `}
      >
        <div className="flex-1 flex">
          <ValidationIndicator
            validationOverride={targetPhraseGroup.validate(
              lessonComponent!,
              lessonComponent!.getObjectiveForTargetPhraseGroup(targetPhraseGroup.uuid)!,
              phrases
            )}
          />
          <div className="flex-1">
            <Droppable droppableId={targetPhraseGroup.uuid}>
              {(provided) => (
                <div ref={provided.innerRef} className={`flex flex-col gap-2`} {...provided.droppableProps}>
                  {targetPhraseGroup.targetPhrases.map((targetPhrase, index) => (
                    <TargetPhraseCard
                      key={targetPhrase.uuid}
                      targetPhrase={targetPhrase}
                      targetPhraseGroup={targetPhraseGroup}
                      index={index}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {isReordering ? (
              <div className="flex gap-2 mt-2">
                <Button shade={200}>
                  <ChevronUp size={16} onClick={() => handleMove("up")} />
                </Button>
                <Button shade={200}>
                  <ChevronDown size={16} onClick={() => handleMove("down")} />
                </Button>
              </div>
            ) : null}
          </div>
          <ContingencyIndicator />
          <OptionsDropdown />
        </div>
        <div>
          <Button className="h-full" displayType={ButtonType.text} onClick={openOptionsDropdown}>
            <DotsHorizontal size={16} />
          </Button>
        </div>
      </div>
    </div>
  );
}
