import { useState } from "react";
import { DotsHorizontal, Duplicate, PencilAlt, XCircle } from "heroicons-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { deleteGlobalVideoSection, updateGlobalVideoSection } from "../../core/redux/globalVideoSectionsSlice";
import { ButtonType } from "../../components/base/Button";
import { copyToClipboard, parseDateTime, updateObject } from "../../service/helpers";
import { openModal } from "../../core/redux/modalSlice";
import { GlobalVideoSection } from "../../core/domain/entities";
import { pagePaths } from "../../App";
import { selectComponent } from "../../core/redux/componentSlice";

import { Button, Dropdown, DropdownItem, IsHiddenButton, CheckBoxInput, ValidationIndicator } from "..";

export default function GlobalVideoSectionCard({ globalVideoSection }: { globalVideoSection: GlobalVideoSection }) {
  // -- Navigation
  const history = useHistory();
  // -- Redux
  const dispatch = useDispatch();

  // -- Local state
  const [isHidden, setIsHidden] = useState(globalVideoSection.isHidden);
  const [isChecked, setIsChecked] = useState(globalVideoSection.isChecked);

  const [optionsDropdownIsOpen, setOptionsDropdownIsOpen] = useState(false);

  // -- Functions
  const handleMainAction = () => {
    dispatch(selectComponent(globalVideoSection));
    history.push(pagePaths.editLessonComponent);
  };

  const toggleIsChecked = (value: boolean) => {
    setIsChecked(value);
    dispatch(
      updateGlobalVideoSection(
        updateObject(globalVideoSection, {
          isChecked: value,
        })
      )
    );
  };
  const toggleIsHidden = (value: boolean) => {
    setIsHidden(value);
    dispatch(
      updateGlobalVideoSection(
        updateObject(globalVideoSection, {
          isHidden: value,
        })
      )
    );
  };
  const handleDeleteGlobalVideoSection = () => {
    dispatch(deleteGlobalVideoSection(globalVideoSection));
  };

  const openEditModal = () => {
    dispatch(
      openModal({
        globalVideoSectionModal: {
          globalVideoSection: globalVideoSection,
        },
      })
    );
  };

  const openOptionsDropdown = () => {
    setOptionsDropdownIsOpen(true);
  };
  const closeOptionsDropdown = () => {
    setOptionsDropdownIsOpen(false);
  };

  // -- Components
  const OptionsDropdown = () => {
    return (
      <Dropdown className="top-4" isOpen={optionsDropdownIsOpen} onClose={closeOptionsDropdown}>
        <DropdownItem onClick={openEditModal}>
          <PencilAlt size={16} />
          Edit
        </DropdownItem>
        <DropdownItem color="red" shade={500} onClick={handleDeleteGlobalVideoSection}>
          <XCircle size={16} />
          Delete
        </DropdownItem>
      </Dropdown>
    );
  };

  return (
    <div className="relative">
      {/* <ValidationIndicator object={globalVideoSection} /> */}
      <div className="bg-white p-2 pl-4 rounded-md flex gap-5 items-center">
        <div className="flex flex-1 items-center gap-2">
          <CheckBoxInput value={isChecked} set={toggleIsChecked} />
          <Button className="text-sm text-left" displayType={ButtonType.textAlt} onClick={handleMainAction}>
            {globalVideoSection.title}
          </Button>
        </div>
        <div className="flex flex-1 gap-5 items-center" style={{ flex: 0.8 }}>
          <div className="flex gap-2 items-center text-gray-400" style={{ flex: 0.6 }}>
            <Button displayType={ButtonType.pure} color="gray" onClick={() => copyToClipboard(globalVideoSection.id)}>
              <Duplicate size={16} />
            </Button>
            <div className="text-xs">{globalVideoSection.id}</div>
          </div>
          <div className="text-xs text-gray-400" style={{ flex: 0.4 }}>
            {parseDateTime(globalVideoSection.updatedAt)}
          </div>
        </div>
        <div className="flex gap-1 justify-end items-center">
          <IsHiddenButton isHidden={isHidden} onToggle={toggleIsHidden} />
          <Button className="w-9" onClick={openOptionsDropdown} displayType={ButtonType.text}>
            <DotsHorizontal size={16} />
          </Button>
        </div>
      </div>
      <OptionsDropdown />
    </div>
  );
}
