import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { StatusType } from "./types";
import { Locale, GlobalVideoSection } from "../domain/entities";
import {
  remoteDataSource,
  useCaseCreateGlobalVideoSection,
  useCaseDeleteGlobalVideoSection,
  useCaseGetGlobalVideoSections,
  useCaseUpdateGlobalVideoSection,
} from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { RootState } from "./store";
import { updateObjectInList } from "../../service/helpers";

export const fetchGlobalVideoSections = createAsyncThunk(
  `globalVideoSections/fetch`,
  async (locale: Locale, { getState }) => {
    const state = getState() as RootState;

    const localeDidChange =
      state.globalVideoSections.items.length > 0 && state.globalVideoSections.items[0].localeId !== locale.id;
    if (
      state.globalVideoSections.items.length > 0 &&
      (!localeDidChange || state.globalVideoSections.status !== StatusType.loading)
    )
      return state.globalVideoSections.items;

    const result = await useCaseGetGlobalVideoSections.execute(locale);

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const fetchProdGlobalVideoSections = createAsyncThunk(
  `globalVideoSections/fetchProd`,
  async (locale: Locale, { getState }) => {
    const state = getState() as RootState;

    const localeDidChange =
      state.globalVideoSections.prodItems.length > 0 && state.globalVideoSections.prodItems[0].localeId !== locale.id;
    if (
      state.globalVideoSections.prodItems.length > 0 &&
      (!localeDidChange || state.globalVideoSections.status !== StatusType.loading)
    )
      return state.globalVideoSections.prodItems;

    const result = await remoteDataSource.allGlobalVideoSections(locale, "prod");

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const createGlobalVideoSection = createAsyncThunk(
  `globalVideoSections/create`,
  async (globalVideoSection: GlobalVideoSection) => {
    const result = await useCaseCreateGlobalVideoSection.execute(globalVideoSection);

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const updateGlobalVideoSection = createAsyncThunk(
  `globalVideoSections/update`,
  async (globalVideoSection: GlobalVideoSection, { getState }) => {
    // Update updatedBy to the current user
    const state: any = getState();
    globalVideoSection.updatedBy = state.users.current.attributes.sub;
    globalVideoSection.updatedAt = new Date().toISOString();

    const result = await useCaseUpdateGlobalVideoSection.execute(globalVideoSection);

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const deleteGlobalVideoSection = createAsyncThunk(
  `globalVideoSections/delete`,
  async (globalVideoSection: GlobalVideoSection) => {
    if (window.confirm(`Are you sure you want to delete the GlobalVideoSection: ${globalVideoSection.title}?`)) {
      const result = await useCaseDeleteGlobalVideoSection.execute(globalVideoSection);

      if (result instanceof Success) {
        return result.data;
      } else if (result instanceof Failure) {
        throw result.error;
      }
    } else throw "Cancelled";
  }
);

const initialState = {
  items: [] as GlobalVideoSection[],
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as GlobalVideoSection[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
};

export const globalVideoSectionsSlice = createSlice({
  name: "globalVideoSections",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalVideoSections.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(
      fetchGlobalVideoSections.fulfilled,
      (state, { payload: globalVideoSection }: { payload: GlobalVideoSection[] }) => {
        state.status = StatusType.succeeded;
        state.items = globalVideoSection;
      }
    );
    builder.addCase(fetchGlobalVideoSections.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(fetchProdGlobalVideoSections.pending, (state) => {
      state.prodStatus = StatusType.loading;
    });
    builder.addCase(
      fetchProdGlobalVideoSections.fulfilled,
      (state, { payload: globalVideoSection }: { payload: GlobalVideoSection[] }) => {
        state.prodStatus = StatusType.succeeded;
        state.prodItems = globalVideoSection;
      }
    );
    builder.addCase(fetchProdGlobalVideoSections.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
    builder.addCase(
      createGlobalVideoSection.fulfilled,
      (state, { payload: lesson }: { payload: GlobalVideoSection }) => {
        state.items = [...state.items, lesson];
      }
    );
    builder.addCase(createGlobalVideoSection.rejected, () => {
      alert("Error! Global Video Section was not created. Cheeck logs for more info");
    });
    builder.addCase(
      updateGlobalVideoSection.fulfilled,
      (state, { payload: globalVideoSection }: { payload: GlobalVideoSection }) => {
        state.items = updateObjectInList(state.items, globalVideoSection);
      }
    );
    builder.addCase(updateGlobalVideoSection.rejected, () => {
      alert("Error! Global Video Section was not updated. Cheeck logs for more info");
    });
    builder.addCase(
      deleteGlobalVideoSection.fulfilled,
      (state, { payload: lesson }: { payload: GlobalVideoSection }) => {
        state.items = state.items.filter((item) => item.id !== lesson.id);
      }
    );
    builder.addCase(deleteGlobalVideoSection.rejected, () => {
      alert("Error! Global Video Section was not deleted. Cheeck logs for more info");
    });
  },
});

export const getAllGlobalVideoSections = (state: RootState) => state.globalVideoSections.items;
export const getGlobalVideoSectionsStatus = (state: RootState) => state.globalVideoSections.status;
export const getGlobalVideoSectionsError = (state: RootState) => state.globalVideoSections.error;
export const getAllProdGlobalVideoSections = (state: RootState) => state.globalVideoSections.prodItems;

export const getProdGlobalVideoSectionsStatus = (state: RootState) => state.globalVideoSections.prodStatus;
export const getProdGlobalVideoSectionsError = (state: RootState) => state.globalVideoSections.prodError;

export default globalVideoSectionsSlice.reducer;
