import { v4 as uuidv4 } from "uuid";

import { DataObjective } from ".";
import { Changeable } from "../../../service/Change";
import { EnvironmentArgument } from "../../../service/PushService";
import { GlobalVideoSection, VideoClip } from "../../domain/entities";

export default class DataGlobalVideoSection implements Changeable {
  id: string;
  title: string;
  videoClips: VideoClip[];
  objectives: DataObjective[];
  localeId: string;
  isHidden: boolean;
  createdBy: string;
  updatedBy: string;
  groupsCanAccess: string[];
  updatedAt: string;
  sectionId?: string | null;
  isGeneric: boolean;
  isDojo: boolean;
  isChecked: boolean;
  lessonPrefix?: string;

  constructor(object: Partial<DataGlobalVideoSection> = {}) {
    this.id = object.id ?? uuidv4();
    this.title = object.title ?? "";
    this.videoClips = object.videoClips ? object.videoClips.map((vc: VideoClip) => new VideoClip(vc)) : [];
    this.objectives = object.objectives ? object.objectives.map((o: DataObjective) => new DataObjective(o)) : [];
    this.localeId = object.localeId ?? "";
    this.isHidden = object.isHidden ?? false;
    this.createdBy = object.createdBy ?? "";
    this.updatedBy = object.updatedBy ?? "";
    this.updatedAt = object.updatedAt ?? new Date().toISOString();
    this.groupsCanAccess = ["free"];
    this.sectionId = object.sectionId;
    this.isGeneric = object.isGeneric ?? false;
    this.isDojo = object.isDojo ?? true;
    this.isChecked = object.isChecked ?? false;
    this.lessonPrefix = object.lessonPrefix;
  }

  convertToDomain(): GlobalVideoSection {
    return new GlobalVideoSection({
      id: this.id,
      title: this.title,
      videoClips: this.videoClips,
      objectives: this.objectives.map((o) => o.convertToDomain()),
      localeId: this.localeId,
      isHidden: this.isHidden,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      groupsCanAccess: ["free"],
      sectionId: this.sectionId ?? "",
      isGeneric: this.isGeneric,
      isDojo: this.isDojo,
      isChecked: this.isChecked,
      lessonPrefix: this.lessonPrefix,
    });
  }
  getIdentifier() {
    return `${this.title} - ${this.id}`;
  }
  getTypeName() {
    return "GlobalVideoSection";
  }

  async toEnv(_0: EnvironmentArgument, _1: EnvironmentArgument, _2: boolean = false) {
    return this;
  }
}
