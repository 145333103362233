import { v4 as uuidv4 } from "uuid";
import { Objective, TargetPhraseGroup } from ".";
import { Change, Difference } from "../../../service/Change";

import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";
import LessonComponent from "./LessonComponent";
import { MatchType } from "./types";

export default class Contingency {
  uuid: string;
  placeholderId: string;
  matchDestinationIds: string[];

  constructor(object: Partial<Contingency> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.placeholderId = object.placeholderId ?? "";
    this.matchDestinationIds = object.matchDestinationIds ?? [];
  }

  validate(lessonComponent: LessonComponent, objective: Objective) {
    const validation = new Validation({
      type: ValidationType.Contingency,
      instance: this,
      issues: [],
      childValidations: [],
    });

    this.matchDestinationIds.forEach((id, index) => {
      if (this.matchDestinationIds.findIndex((mdId) => mdId === id) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate match destination ids",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            id
          )
        );
      }
      if (objective && !objective.getMatchDestination(id))
        validation.issues.push(
          new ValidationIssue(
            "Field matchDestinationId must reference a match destination",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            objective.uuid
          )
        );
    });

    if (!lessonComponent.getVideoClip(this.placeholderId))
      validation.issues.push(
        new ValidationIssue(
          "Field placeholderId must reference a video clip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }

  getIdentifier() {
    return `${this.uuid}`;
  }
  getTypeName() {
    return "Contingency";
  }
}
