import RemoteDataSource from "./RemoteDataSource";
import VocabComponentRepository from "../domain/VocabComponentRepository";
import { VocabComponent, Locale } from "../domain/entities";
import Outcome from "../../service/api/Outcome";

export default class VocabComponentRepositoryImplementation extends VocabComponentRepository {
  remoteDataSource: RemoteDataSource;

  constructor(remoteDataSource: RemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async allVocabComponents(locale: Locale): Promise<Outcome<VocabComponent[]>> {
    return this.remoteDataSource.allVocabComponents(locale);
  }

  async createVocabComponent(vocabComponent: VocabComponent): Promise<Outcome<VocabComponent>> {
    return this.remoteDataSource.createVocabComponent(vocabComponent);
  }

  async updateVocabComponent(vocabComponent: VocabComponent): Promise<Outcome<VocabComponent>> {
    return this.remoteDataSource.updateVocabComponent(vocabComponent);
  }

  async deleteVocabComponent(vocabComponent: VocabComponent): Promise<Outcome<VocabComponent>> {
    return this.remoteDataSource.deleteVocabComponent(vocabComponent);
  }
}
