import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { StatusType } from "./types";
import { Locale } from "../domain/entities";
import { remoteDataSource, useCaseGetLocales } from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { RootState } from "./store";

export const fetchLocales = createAsyncThunk(`locales/fetch`, async () => {
  const result = await useCaseGetLocales.execute();

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const fetchProdLocales = createAsyncThunk(`locales/fetchProd`, async () => {
  const result = await remoteDataSource.allLocales("prod");

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

const initialState = {
  items: [] as Locale[],
  current: null as Locale | undefined | null,
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as Locale[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
};

export const localesSlice = createSlice({
  name: "locales",
  initialState: initialState,
  reducers: {
    selectLocale(state, { payload: id }: { payload: string }) {
      const locale = state.items.find((item) => item.id === id);
      if (!locale) return;
      state.current = locale;
      localStorage.setItem("formattedLocaleCode", locale.formattedCode);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocales.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchLocales.fulfilled, (state, { payload: locales }: { payload: Locale[] }) => {
      state.status = StatusType.succeeded;
      state.items = locales;
      state.current =
        locales.find((locale) => locale.formattedCode == localStorage.getItem("formattedLocaleCode") ?? "en_nt_es") ??
        locales[0];
    });
    builder.addCase(fetchLocales.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(fetchProdLocales.pending, (state) => {
      state.prodStatus = StatusType.loading;
    });
    builder.addCase(fetchProdLocales.fulfilled, (state, { payload: locales }: { payload: Locale[] }) => {
      state.prodStatus = StatusType.succeeded;
      state.prodItems = locales;
    });
    builder.addCase(fetchProdLocales.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
  },
});

export const { selectLocale } = localesSlice.actions;

export const getAllLocales = (state: RootState) => state.locales.items;
export const getLocalesStatus = (state: RootState) => state.locales.status;
export const getLocalesError = (state: RootState) => state.locales.error;
export const getCurrentLocale = (state: RootState) => state.locales.current;
export const getAllProdLocales = (state: RootState) => state.locales.prodItems;
export const getProdLocalesStatus = (state: RootState) => state.locales.prodStatus;
export const getProdLocalesError = (state: RootState) => state.locales.prodError;

export default localesSlice.reducer;
