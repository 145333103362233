import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { pagePaths } from "../App";
import { deselectUser, selectUser } from "../core/redux/userSlice";
import * as logs from "../service/logs";

import { Button, FormErrorMessage, Input, LoadingIndicator } from "../components";

export default function SignInPage() {
  // -- Navigation
  const history = useHistory();

  // -- Redux
  const dispatch = useDispatch();

  // -- Functions
  const signIn = async (username: string, password: string, setSubmitting: (value: boolean) => void) => {
    setSubmitting(true);
    try {
      const user = await Auth.signIn(username, password);
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

      if (!groups.includes("admin")) {
        alert("Sorry! You aren't an admin. You can't access this page");
      }

      logs.signedIn(user);
      dispatch(selectUser(user));
      setSubmitting(false);
      history.push(pagePaths.manageLessons);
    } catch (error: any) {
      dispatch(deselectUser());
      setSubmitting(false);
      switch (error.code) {
        case "UserNotConfirmedException":
          alert("Sign In failed. Your user is not confirmed. Please check your username and confirm your account");
          break;
        case "PasswordResetRequiredException":
          alert("Sign In failed. Your password must be reset");
          break;
        case "NotAuthorizedException" || "UserNotFoundException":
          alert("Sign In failed. Your username or password is incorrect");
          break;
        default:
          alert("Sign In failed. Your username or password is incorrect");
          console.log(error);
      }
    }
  };

  // -- Components
  const UsernameField = ({ values, errors, handleChange }: { values: any; errors: any; handleChange: any }) => {
    return (
      <div>
        <Input
          title="Username/Email"
          placeholder="first.last@wesayhi.com"
          type="email"
          name="username"
          value={values.username}
          onChange={handleChange}
        />
        <FormErrorMessage error={errors.username} />
      </div>
    );
  };

  const PasswordField = ({ values, errors, handleChange }: { values: any; errors: any; handleChange: any }) => {
    return (
      <div>
        <Input title="Password" type="password" name="password" value={values.password} onChange={handleChange} />
        <FormErrorMessage error={errors.password} />
      </div>
    );
  };

  return (
    <div className="flex-1 flex justify-center items-center">
      <div className="bg-white rounded-xl shadow-sm p-10 flex-1 max-w-2xl flex flex-col items-center">
        <div className="text-3xl font-bold">Sign in to your account</div>
        <div className="h-10" />
        <Formik
          initialValues={{ username: "", password: "" }}
          validate={(values) => {
            const errors: { username?: string; password?: string } = {};
            if (!values.username) {
              errors.username = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
              errors.username = "Invalid username address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            signIn(values.username, values.password, setSubmitting);
          }}
        >
          {({ isSubmitting, ...formProps }) => (
            <Form className="flex flex-col gap-2 w-96">
              <UsernameField {...formProps} />
              <PasswordField {...formProps} />
              <div className="h-5" />
              <Button type="submit" disabled={isSubmitting}>
                {!isSubmitting ? "Sign In" : <LoadingIndicator />}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
