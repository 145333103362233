import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CheckBoxInput, Modal, ModalFormButtons, SelectMenu, StatusView, Table, TableItem, TableRow } from "..";
import { remoteDataSource } from "../../core";
import { AcceptedText, Phrase, UserResult } from "../../core/domain/entities";
import { OS, UserResultLocation } from "../../core/domain/entities/UserResult";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import { StatusType } from "../../core/redux/types";
import {
  mapUserResultsToTranscriptions,
  TranscriptionData,
  TranscriptionFilter,
} from "../../core/redux/userResultsSlice";
import { PhraseFormValues } from "./PhraseModal";

export default function DistributionModal({
  isOpen,
  phrase,
  formProps,
  onClose,
}: {
  isOpen: boolean;
  phrase: Phrase;
  formProps: FormikProps<PhraseFormValues>;
  onClose: () => void;
}) {
  // -- Redux
  const currentLocale = useSelector(getCurrentLocale);

  // -- Local state
  const [userResultsStatus, setUserResultsStatus] = useState(StatusType.idle);
  const [userResults, setUserResults] = useState<UserResult[]>([]);
  const [filter, setFilter] = useState<TranscriptionFilter>({});
  const [transcriptionsMap, setTranscriptionsMap] = useState(new Map<string, TranscriptionData>());
  const [transcriptionCount, setTranscriptionCount] = useState(0);
  const [acceptedTexts, setAcceptedTexts] = useState(formProps.values.acceptedTexts);

  //   -- Effect
  useEffect(() => {
    fetchUserResults();
  }, []);

  useEffect(() => {
    const transcriptionsMap = mapUserResultsToTranscriptions(userResults, filter);
    setTranscriptionsMap(transcriptionsMap);

    let count = 0;
    transcriptionsMap.forEach((transcriptionData) => (count += transcriptionData.count));
    setTranscriptionCount(count);
  }, [userResults, filter]);

  useEffect(() => {
    setAcceptedTexts(formProps.values.acceptedTexts);
  }, [formProps.values.acceptedTexts]);

  // -- Functions
  function onSubmit() {
    formProps.setFieldValue("acceptedTexts", acceptedTexts);
    onClose();
  }

  async function fetchUserResults() {
    setUserResultsStatus(StatusType.loading);
    const userResults = await remoteDataSource.userResultsByPhraseId(currentLocale!, phrase.id);
    setUserResultsStatus(StatusType.succeeded);
    setUserResults(userResults);
  }

  function toggleTranscription(transcription: string) {
    const existingAcceptedText = acceptedTexts.find((acceptedText) => acceptedText.text === transcription);
    if (existingAcceptedText) {
      setAcceptedTexts(acceptedTexts.filter((acceptedText) => acceptedText.text !== transcription));
    } else {
      setAcceptedTexts([
        ...acceptedTexts,
        new AcceptedText({
          text: transcription,
        }),
      ]);
    }
  }

  function getPercentOfTotal(count: number) {
    return ((count / transcriptionCount) * 100).toFixed(2);
  }

  return (
    <Modal isOpen={isOpen} title="Distribtutions" containerClassName="w-3/4" onClose={onClose}>
      <div className="flex gap-2">
        <SelectMenu
          title="Location"
          value={filter.location}
          set={(value) =>
            setFilter({
              ...filter,
              location: value === "Any" ? undefined : (value as UserResultLocation),
            })
          }
        >
          <option>Any</option>
          {Object.keys(UserResultLocation).map((location) => (
            <option>{location}</option>
          ))}
        </SelectMenu>
        <SelectMenu
          title="OS"
          value={filter.os}
          set={(value) =>
            setFilter({
              ...filter,
              os: value === "Any" ? undefined : (value as OS),
            })
          }
        >
          <option>Any</option>
          {Object.keys(OS).map((os) => (
            <option>{os}</option>
          ))}
        </SelectMenu>
        <CheckBoxInput
          message="Main targets only"
          value={filter.isMain ?? false}
          set={(value) =>
            setFilter({
              ...filter,
              isMain: value,
            })
          }
        />
      </div>
      <Table rows={["Accept", "Transcription", "Count", "% Total", "User Count"]} className="h-96">
        <StatusView status={userResultsStatus}>
          {Array.from(new Map([...transcriptionsMap].sort((a, b) => b[1].count - a[1].count)).entries()).map(
            ([transcription, { count, userIds }], index) => (
              <TableRow key={transcription} index={index}>
                <TableItem>
                  <CheckBoxInput
                    value={acceptedTexts.some((acceptedText) => acceptedText.text === transcription)}
                    set={() => toggleTranscription(transcription)}
                  />
                </TableItem>
                <TableItem className="w-1/2">{transcription}</TableItem>
                <TableItem>{count}</TableItem>
                <TableItem>{getPercentOfTotal(count) + "%"}</TableItem>
                <TableItem>{userIds.length}</TableItem>
              </TableRow>
            )
          )}
        </StatusView>
      </Table>
      <div className="h-10" />
      <ModalFormButtons onSubmit={onSubmit} onClose={onClose} />
    </Modal>
  );
}
