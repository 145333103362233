import { v4 as uuidv4 } from "uuid";

import Validation, {
  validateNumbers,
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
  ValidationType,
} from "../../../service/Validation";
import { DisplayItem, GlobalVideoSection, LessonComponent, SectionInfo } from "./index";
import {
  DisplayItemType,
  kleoColorHex,
  LessonComponentType,
  responseTypeColors,
  SectionType,
  VideoClipType,
} from "./types";
import Video from "./Video";

export default class VideoClip {
  uuid: string;
  sectionId: string;
  title: string;
  type: VideoClipType;
  databaseName?: string | null;
  colorHex?: string | null;
  objectiveId?: string | null;
  duration?: number | null;
  startInteractionTime?: number | null;
  displayItems: DisplayItem[];
  sectionInfo: SectionInfo;
  isGeneric: boolean;

  constructor(object: Partial<VideoClip> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.sectionId = object.sectionId ?? "";
    this.title = object.title ?? "";
    this.type = object.type ?? VideoClipType.SECTION;
    this.databaseName = object.databaseName;
    this.colorHex = object.colorHex ?? kleoColorHex[responseTypeColors[this.type]];
    this.objectiveId = object.objectiveId;
    this.duration = object.duration;
    this.startInteractionTime = object.startInteractionTime;
    this.displayItems = object.displayItems
      ? object.displayItems.map((di: DisplayItem) => new DisplayItem(di))
      : [
          new DisplayItem({
            type: DisplayItemType.PROMPT,
            primaryText: object.sectionInfo ? object.sectionInfo.targetText : "",
            secondaryText: object.sectionInfo ? object.sectionInfo.nativeText : "",
            primaryDisplayTime: 0.25,
            secondaryDisplayTime: 0.25,
          }),
        ];
    this.sectionInfo = new SectionInfo(object.sectionInfo);
    this.isGeneric = object.isGeneric ?? false;
  }

  fixNumberStrings() {
    this.duration = this.duration ? parseFloat(this.duration as any as string) : undefined;
    this.startInteractionTime = this.startInteractionTime
      ? parseFloat(this.startInteractionTime as any as string)
      : undefined;
    this.displayItems.forEach((di) => di.fixNumberStrings());
  }

  get isSection() {
    return this.type === VideoClipType.SECTION;
  }

  update(videoClip: VideoClip) {
    this.uuid = videoClip.uuid;
    this.sectionId = videoClip.sectionId;
    this.title = videoClip.title;
    this.type = videoClip.type;
    this.databaseName = videoClip.databaseName;
    this.colorHex = videoClip.colorHex;
    this.objectiveId = videoClip.objectiveId;
    this.startInteractionTime = videoClip.startInteractionTime;
    this.duration = videoClip.duration;
    this.displayItems = videoClip.displayItems;
    this.sectionInfo = videoClip.sectionInfo;
    this.isGeneric = videoClip.isGeneric;
  }

  validate(lessonComponent: LessonComponent, videos: Video[]) {
    const validation = new Validation({
      type: ValidationType.VideoClip,
      instance: this,
      issues: [],
      childValidations: [],
    });

    const video = videos.find((v) => v.name === this.databaseName);
    this.displayItems.forEach((displayItem) => validation.childValidations.push(displayItem.validate(this, video)));

    if (!lessonComponent.getAllLinkedVideoClipIds().includes(this.uuid))
      validation.issues.push(
        new ValidationIssue(
          `Video clip ${this.uuid} not used in the parent lesson component`,
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (!this.title)
      validation.issues.push(
        new ValidationIssue("Field title required", ValidationIssueGravity.error, ValidationIssueCause.userInput)
      );

    const section = lessonComponent.getVideoClip(this.sectionId);
    if (!section)
      validation.issues.push(
        new ValidationIssue(
          "Field sectionId must reference a section video clip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );
    else {
      // Section validation
      if (
        section.uuid !== section.sectionId ||
        !Object.values(SectionType).includes(section.type as string as SectionType)
      )
        validation.issues.push(
          new ValidationIssue(
            "Field sectionId references a non-section video clip",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );

      if (this.type === VideoClipType.SECTION && !this.objectiveId)
        validation.issues.push(
          new ValidationIssue(
            "Section video clip must have an objective",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );

      if (this.type === VideoClipType.TRANSITION && this.objectiveId)
        validation.issues.push(
          new ValidationIssue(
            "Transition video clip cannot have an objective",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );

      if (this.type === VideoClipType.PLACEHOLDER && (this.objectiveId || this.databaseName))
        validation.issues.push(
          new ValidationIssue(
            "Placeholder video clip cannot have an objective or databaseName",
            ValidationIssueGravity.error,
            ValidationIssueCause.userInput
          )
        );
    }

    if (this.type !== VideoClipType.PLACEHOLDER) {
      if (!video)
        validation.issues.push(
          new ValidationIssue(
            "Field databaseName must reference a video",
            ValidationIssueGravity.error,
            ValidationIssueCause.userInput
          )
        );

      if (!(this.sectionId == this.uuid) && !this.colorHex)
        validation.issues.push(
          new ValidationIssue("Field colorHex required", ValidationIssueGravity.error, ValidationIssueCause.userInput)
        );

      if (this.colorHex === kleoColorHex.yellowKleo && !this.objectiveId)
        validation.issues.push(
          new ValidationIssue(
            "Should have objective because of yellow color (indicating reprompt)",
            ValidationIssueGravity.error,
            ValidationIssueCause.userInput
          )
        );

      if (this.objectiveId) {
        if (!lessonComponent.getObjective(this.objectiveId))
          validation.issues.push(
            new ValidationIssue(
              "Field objectiveId must reference an objective",
              ValidationIssueGravity.error,
              ValidationIssueCause.internal
            )
          );

        if (!this.startInteractionTime)
          validation.issues.push(
            new ValidationIssue(
              "Field startInteractionTime required",
              ValidationIssueGravity.error,
              ValidationIssueCause.userInput
            )
          );
        else {
          if (this.startInteractionTime <= 0)
            validation.issues.push(
              new ValidationIssue(
                "Field startInteractionTime cannot be negative",
                ValidationIssueGravity.error,
                ValidationIssueCause.userInput
              )
            );
          if (video && video.duration < this.startInteractionTime)
            validation.issues.push(
              new ValidationIssue(
                "Field startInteractionTime cannot be greater than the duration of the video",
                ValidationIssueGravity.error,
                ValidationIssueCause.userInput
              )
            );
        }
      }

      for (const displayItem of this.displayItems) {
        if (displayItem.type === DisplayItemType.PROMPT) {
          if (this.type === VideoClipType.SECTION) {
            if (
              (lessonComponent.type === LessonComponentType.DEEPDIVE ||
                lessonComponent instanceof GlobalVideoSection) &&
              !displayItem.secondaryDisplayTime
            ) {
              validation.issues.push(
                new ValidationIssue(
                  "Prompt DisplayItem in DeepDive or GlobalVideoSection should have a secondary display time",
                  ValidationIssueGravity.warning,
                  ValidationIssueCause.userInput
                )
              );
            }
          } else if (this.type !== VideoClipType.TRANSITION) {
            if (
              !displayItem.primaryText ||
              !displayItem.secondaryText ||
              !displayItem.primaryDisplayTime ||
              !displayItem.secondaryDisplayTime
            ) {
              validation.issues.push(
                new ValidationIssue(
                  "Response DisplayItem should have primary and secondary text and display times",
                  ValidationIssueGravity.warning,
                  ValidationIssueCause.userInput
                )
              );
            }
          }
        } else {
          if (
            (!!displayItem.primaryText && !displayItem.primaryDisplayTime) ||
            (!!displayItem.secondaryText && !displayItem.secondaryDisplayTime)
          )
            validation.issues.push(
              new ValidationIssue(
                "Tip and info DisplayItems should have display times for each text",
                ValidationIssueGravity.warning,
                ValidationIssueCause.userInput
              )
            );
        }
      }

      if (this.displayItems.filter((di) => di.type === DisplayItemType.PROMPT).length > 1)
        validation.issues.push(
          new ValidationIssue(
            "Video Clip can only have one PROMPT DisplayItem (change type or remove)",
            ValidationIssueGravity.error,
            ValidationIssueCause.userInput
          )
        );
    }

    // Schema validation
    const numberFields = {
      duration: this.duration,
      startInteractionTime: this.startInteractionTime,
    };
    validation.issues.push(...validateNumbers(numberFields));

    return validation;
  }
  getIdentifier() {
    return `${this.uuid} - ${this.title}`;
  }
  getTypeName() {
    return "VideoClip";
  }

  paste(copied: VideoClip) {
    this.title = copied.title;
    this.databaseName = copied.databaseName;
    this.startInteractionTime = this.objectiveId ? copied.startInteractionTime : null;
    this.displayItems = copied.displayItems.map((di) => di.copy());
    this.isGeneric = copied.isGeneric;
  }
}
