import { v4 as uuidv4 } from "uuid";

import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";
import { Contingency, SupplementaryWord, TargetPhrase, LessonComponent, Objective } from "./index";
import Phrase from "./Phrase";

export default class TargetPhraseGroup {
  uuid: string;
  targetPhrases: TargetPhrase[];
  matchDestinationId: string;
  contingencies: Contingency[];
  supplementaryWords: SupplementaryWord[];

  constructor(object: Partial<TargetPhraseGroup> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.targetPhrases = object.targetPhrases
      ? object.targetPhrases.map((tp: TargetPhrase) => new TargetPhrase(tp))
      : [];
    this.matchDestinationId = object.matchDestinationId ?? "";
    this.contingencies = object.contingencies ? object.contingencies.map((c: Contingency) => new Contingency(c)) : [];
    this.supplementaryWords = object.supplementaryWords
      ? object.supplementaryWords.map((sw: SupplementaryWord) => new SupplementaryWord(sw))
      : [];
  }

  update(targetPhrase: TargetPhraseGroup) {
    this.uuid = targetPhrase.uuid;
    this.targetPhrases = targetPhrase.targetPhrases;
    this.matchDestinationId = targetPhrase.matchDestinationId;
    this.contingencies = targetPhrase.contingencies;
    this.supplementaryWords = targetPhrase.supplementaryWords;
  }

  validate(lessonComponent: LessonComponent, objective: Objective, phrases: Phrase[]) {
    const validation = new Validation({
      type: ValidationType.TargetPhraseGroup,
      instance: this,
      issues: [],
      childValidations: [],
    });

    this.targetPhrases.forEach((targetPhrase, index) => {
      if (this.targetPhrases.findIndex((tp) => tp.uuid === targetPhrase.uuid) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate target phrases",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            targetPhrase.uuid
          )
        );
      }
      validation.childValidations.push(targetPhrase.validate(phrases));
    });

    this.contingencies.forEach((contingency, index) => {
      if (this.contingencies.findIndex((c) => c.uuid === contingency.uuid) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate contingencies",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            contingency.uuid
          )
        );
      }
      validation.childValidations.push(contingency.validate(lessonComponent, objective));
    });

    this.supplementaryWords.forEach((supplementaryWord, index) => {
      if (this.supplementaryWords.findIndex((sw) => sw.uuid === supplementaryWord.uuid) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate supplementary words",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            supplementaryWord.uuid
          )
        );
      }
      validation.childValidations.push(supplementaryWord.validate(lessonComponent));
    });

    if (this.targetPhrases.length === 0)
      validation.issues.push(
        new ValidationIssue(
          "Must have at least one TargetPhrase",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (!this.matchDestinationId)
      validation.issues.push(
        new ValidationIssue(
          "Field matchDestinationId required",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );
    else if (objective && !objective.getMatchDestination(this.matchDestinationId))
      validation.issues.push(
        new ValidationIssue(
          "Field matchDestinationId must reference a match destination",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }
  getIdentifier() {
    return `${this.uuid}`;
  }
  getTypeName() {
    return "TargetPhraseGroup";
  }

  paste(copied: TargetPhraseGroup) {
    this.targetPhrases = copied.targetPhrases.map((tp) => tp.copy());
  }

  // -- Gets

  getTargetPhrase(uuid: string): TargetPhrase | undefined {
    return this.targetPhrases.find((tp) => tp.uuid === uuid);
  }

  getContingency(uuid: string) {
    return this.contingencies.find((c) => c.uuid === uuid);
  }

  // -- Mutations

  addTargetPhrase(targetPhrase: TargetPhrase) {
    this.targetPhrases.push(targetPhrase);
  }

  addSupplementaryWord(supplementaryWord: SupplementaryWord) {
    this.supplementaryWords.push(supplementaryWord);
  }
  removeSupplementaryWord(uuid: string) {
    this.supplementaryWords = this.supplementaryWords.filter((sw) => sw.uuid !== uuid);
  }
}
