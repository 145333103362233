import RemoteDataSource from "./RemoteDataSource";
import VideoRepository from "../domain/VideoRepository";
import { Video, Locale } from "../domain/entities";
import Outcome from "../../service/api/Outcome";

export default class VideoRepositoryImplementation extends VideoRepository {
  remoteDataSource: RemoteDataSource;

  constructor(remoteDataSource: RemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async allVideos(locale: Locale): Promise<Outcome<Video[]>> {
    return this.remoteDataSource.allVideos(locale);
  }

  async updateVideo(video: Video): Promise<Outcome<Video[]>> {
    return this.remoteDataSource.updateVideo(video);
  }

  async deleteVideo(video: Video): Promise<Outcome<Video[]>> {
    return this.remoteDataSource.deleteVideo(video);
  }
}
