import RemoteDataSource from "./RemoteDataSource";
import PhraseRepository from "../domain/PhraseRepository";
import { Phrase, Locale } from "../domain/entities";
import Outcome from "../../service/api/Outcome";

export default class PhraseRepositoryImplementation extends PhraseRepository {
  remoteDataSource: RemoteDataSource;

  constructor(remoteDataSource: RemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async allPhrases(locale: Locale): Promise<Outcome<Phrase[]>> {
    return this.remoteDataSource.allPhrases(locale);
  }

  async allPhrasesNoLocale(): Promise<Outcome<Phrase[]>> {
    return this.remoteDataSource.allPhrasesNoLocale();
  }

  async createPhrase(phrase: Phrase): Promise<Outcome<Phrase>> {
    return this.remoteDataSource.createPhrase(phrase);
  }

  async updatePhrase(phrase: Phrase): Promise<Outcome<Phrase>> {
    return this.remoteDataSource.updatePhrase(phrase);
  }

  async deletePhrase(phrase: Phrase): Promise<Outcome<Phrase>> {
    return this.remoteDataSource.deletePhrase(phrase);
  }
}
