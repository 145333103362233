import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import { FailDestination, MatchDestination, Objective, TargetPhraseGroup } from "../../domain/entities";
import { DataTargetPhrase } from "./index";
import { ObjectiveType } from "../../domain/entities/types";

export default class DataObjective {
  uuid: string;
  type: ObjectiveType;
  matchDestinations: MatchDestination[];
  failDestination: FailDestination;
  targetPhrases: DataTargetPhrase[];
  isGeneric?: boolean;

  constructor(object: Partial<DataObjective> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.type = ObjectiveType.SPEECH;
    this.matchDestinations = object.matchDestinations
      ? object.matchDestinations.map((md: MatchDestination) => new MatchDestination(md))
      : [];
    this.failDestination = new FailDestination(object.failDestination);
    this.targetPhrases = object.targetPhrases
      ? object.targetPhrases.map((tp: DataTargetPhrase) => new DataTargetPhrase(tp))
      : [];
    this.isGeneric = object.isGeneric;
  }

  getIdentifier() {
    return `${this.uuid}`;
  }
  getTypeName() {
    return "Objective";
  }

  convertToDomain(): Objective {
    const targetPhraseDataGroups = Object.values(_.groupBy(this.targetPhrases, "matchDestinationId"));
    const targetPhraseGroups = targetPhraseDataGroups.map((targetPhrases) => {
      return new TargetPhraseGroup({
        matchDestinationId: targetPhrases[0].matchDestinationId,
        supplementaryWords: targetPhrases[0].supplementaryWords,
        contingencies: targetPhrases[0].contingencies,
        targetPhrases: targetPhrases.map((tp) => tp.convertToDomain()),
      });
    });

    return new Objective({
      uuid: this.uuid,
      type: this.type,
      matchDestinations: this.matchDestinations,
      failDestination: this.failDestination,
      targetPhraseGroups: targetPhraseGroups,
      isGeneric: this.isGeneric,
    });
  }
}
