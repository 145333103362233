import { v4 as uuidv4 } from "uuid";
import { Changeable } from "../../../service/Change";
import { environments } from "../../../service/constants";
import { updateObject } from "../../../service/helpers";
import { EnvironmentArgument, envUrl } from "../../../service/PushService";
import { hasFile } from "../../../service/StorageSerivce";
import Validation, {
  Validatable,
  ValidationArgument,
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
  ValidationType,
} from "../../../service/Validation";

const env = (process.env.REACT_APP_ENV! as "dev" | "staging" | "prod") || "staging";

export default class Package implements Validatable, Changeable {
  id: string;
  localeId: string;
  title: string;
  description: string;
  orderIndex: number;
  unitIds: string[];
  imageUrl: string;
  isHidden: boolean;

  constructor(arg: PackageArgument) {
    this.id = arg.id;
    this.localeId = arg.localeId;
    this.title = arg.title;
    this.description = arg.description;
    this.orderIndex = arg.orderIndex;
    this.unitIds = arg.unitIds;
    this.imageUrl = arg.imageUrl;
    this.isHidden = arg.isHidden;
  }

  getIdentifier(): string {
    return `${this.title} - ${this.id}`;
  }
  getTypeName() {
    return "Package";
  }
  async toEnv(fromEnv: EnvironmentArgument, toEnv: EnvironmentArgument, push: boolean = false) {
    this.imageUrl = await envUrl(this.imageUrl, fromEnv, toEnv, push);
    return this;
  }

  // CSV Methods
  static csvHeaders = {
    id: "ID",
    title: "Title",
    description: "Description",
    orderIndex: "Order Number (Starting at 1)",
    unitIds: "Unit IDs (comma separated - no space)",
    imagePath: "Image Path (ex: /images/en_nt_es/package/name.png)",
    isHidden: "Hidden (yes/blank)",
    deleted: "Delete (yes/blank)",
  };
  toCSVEntry() {
    return {
      [Package.csvHeaders.id]: this.id,
      [Package.csvHeaders.orderIndex]: this.orderIndex,
      [Package.csvHeaders.title]: this.title,
      [Package.csvHeaders.description]: this.description,
      [Package.csvHeaders.unitIds]: this.unitIds.join(","),
      [Package.csvHeaders.imagePath]: new URL(this.imageUrl).pathname.replace("/staging", ""),
      [Package.csvHeaders.isHidden]: this.isHidden ? "yes" : "",
      [Package.csvHeaders.deleted]: "",
    };
  }
  static fromCSVEntry(entry: any, localeId: string, packages: Package[]) {
    const errors = [] as string[];
    const warnings = [] as string[];

    if (!entry[Package.csvHeaders.imagePath]) {
      errors.push("Image path is required");
    }

    const id = entry[Package.csvHeaders.id] ? entry[Package.csvHeaders.id] : uuidv4();
    const title = entry[Package.csvHeaders.title];
    const description = entry[Package.csvHeaders.description];
    const orderIndex = entry[Package.csvHeaders.orderIndex]
      ? parseInt(entry[Package.csvHeaders.orderIndex])
      : packages.length + 1;
    const unitIds = entry[Package.csvHeaders.unitIds];
    const imageUrl = environments[env].cloudinaryUrl + entry[Package.csvHeaders.imagePath];
    const isHidden = entry[Package.csvHeaders.isHidden] === "yes";
    const isDeleted = entry[Package.csvHeaders.deleted] === "yes";

    const existingPackage = packages.find((p) => p.id === id && p.id !== "" && p.id !== undefined);

    const packageObject = {
      id: id,
      localeId: localeId,
      title: title,
      description: description,
      orderIndex: orderIndex,
      unitIds: unitIds ? unitIds.split(",").map((id: string) => id.trim()) : [],
      imageUrl: imageUrl,
      isHidden: isHidden,
    };

    if (existingPackage) {
      const updatedPackage = updateObject(existingPackage, packageObject);

      if (isDeleted) {
        return {
          package: updatedPackage,
          errors,
          warnings,
          isUpdated: false,
          isCreated: false,
          isDeleted: true,
        };
      }

      return {
        package: updatedPackage,
        errors,
        warnings,
        isUpdated: true,
        isCreated: false,
        isDeleted: false,
      };
    }

    return {
      package: new Package(packageObject),
      errors,
      warnings,
      isUpdated: false,
      isCreated: true,
      isDeleted: false,
    };
  }
  equals(other: Package) {
    return (
      this.id === other.id &&
      this.localeId === other.localeId &&
      this.title === other.title &&
      this.description === other.description &&
      this.orderIndex === other.orderIndex &&
      this.unitIds.length === other.unitIds.length &&
      this.unitIds.every((value, index) => value === other.unitIds[index]) &&
      this.imageUrl === other.imageUrl &&
      this.isHidden === other.isHidden
    );
  }

  validate({ lessons, locales, files }: ValidationArgument) {
    const validation = new Validation({
      type: ValidationType.Lesson,
      instance: this,
      issues: [],
      childValidations: [],
    });

    this.unitIds.forEach((id, index) => {
      if (this.unitIds.findIndex((uId) => uId === id) !== index) {
        validation.issues.push(
          new ValidationIssue("Duplicate unit ids", ValidationIssueGravity.error, ValidationIssueCause.internal, id)
        );
      }
    });

    // Check if all unit ids are valid
    this.unitIds.forEach((id) => {
      if (!lessons.find((l) => l.id === id)) {
        validation.issues.push(
          new ValidationIssue(`Invalid unit id: ${id}`, ValidationIssueGravity.error, ValidationIssueCause.internal, id)
        );
      }
    });

    if (!this.title)
      validation.issues.push(
        new ValidationIssue("Field title required", ValidationIssueGravity.error, ValidationIssueCause.userInput)
      );
    if (!this.description)
      validation.issues.push(
        new ValidationIssue(
          "Field description required",
          ValidationIssueGravity.warning,
          ValidationIssueCause.userInput
        )
      );

    if (files && !this.isHidden && !hasFile(files, this.imageUrl, env))
      validation.issues.push(
        new ValidationIssue(
          `Invalid imageUrl: ${this.imageUrl ? new URL(this.imageUrl).pathname : "null"}`,
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (!locales.map((locale) => locale.id).includes(this.localeId))
      validation.issues.push(
        new ValidationIssue(
          "Field localeId must reference a locale",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }
}

export type PackageArgument = {
  id: string;
  localeId: string;
  title: string;
  description: string;
  orderIndex: number;
  unitIds: string[];
  imageUrl: string;
  isHidden: boolean;
};
