import { Trash } from "heroicons-react";
import { useDispatch, useSelector } from "react-redux";

import { Lesson, VocabComponent } from "../../core/domain/entities";
import { openModal } from "../../core/redux/modalSlice";
import { ButtonType } from "../base/Button";

import { chooseComponent, getDestinationLessonId } from "../../core/redux/lessonsSlice";
import { getAllPhrases, getPhrasesError, getPhrasesStatus } from "../../core/redux/phrasesSlice";
import { deleteVocabComponent } from "../../core/redux/vocabComponentsSlice";
import { Button, StatusView } from "../index";

export default function VocabComponentCard({
  vocabComponent,
  lesson,
}: {
  vocabComponent: VocabComponent;
  lesson: Lesson;
}) {
  // -- Redux
  const dispatch = useDispatch();

  const destinationLessonId = useSelector(getDestinationLessonId);

  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);

  // -- Functions
  const handleMainAction = () => {
    if (destinationLessonId) {
      dispatch(chooseComponent(vocabComponent));
      return;
    }

    dispatch(
      openModal({
        vocabComponentModal: {
          vocabComponent: vocabComponent,
          lesson: lesson,
        },
      })
    );
  };
  const handleDeleteVocabComponent = () => {
    if (window.confirm(`Are you sure you want to delete ${vocabComponent.title}?`)) {
      dispatch(deleteVocabComponent({ vocabComponent: vocabComponent, lesson: lesson }));
    }
  };

  return (
    <div className={`rounded-md flex justify-between items-center relative bg-gray-100 pl-2`}>
      <div style={{ flex: 0.4 }} className="flex items-center gap-2 mr-5">
        <Button className="text-sm text-left" displayType={ButtonType.textAlt} onClick={handleMainAction}>
          {vocabComponent.orderIndex + 1 + ". " + vocabComponent.title}
        </Button>
      </div>
      <div style={{ flex: 0.6 }} className="flex gap-5 items-center text-xs truncate text-gray-500">
        <StatusView status={phrasesStatus} error={phrasesError}>
          {/* {phrases.length > 0 &&
            vocabComponent
              .getAllPhrases(phrases)
              .map((p) => p.targetText)
              .join(", ")} */}
          {vocabComponent.id}
        </StatusView>
      </div>
      <div style={{ flex: 0.1 }} className="flex justify-end">
        <Button className="w-9" color="grey" onClick={handleDeleteVocabComponent} displayType={ButtonType.pure}>
          <Trash size={16} />
        </Button>
      </div>
    </div>
  );
}
