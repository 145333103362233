import { Formik, Form, FormikProps } from "formik";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SupplementaryWord, TargetPhraseGroup } from "../../core/domain/entities";
import { SupplementaryWordType } from "../../core/domain/entities/types";
import { getComponent, updateCurrentComponent } from "../../core/redux/componentSlice";
import { updateObject } from "../../service/helpers";

import { Modal, Input, FormErrorMessage, ModalFormButtons, SelectMenu, CheckBoxInput } from "../index";

export type SupplementaryWordModalProps = {
  targetPhraseGroup?: TargetPhraseGroup;
  supplementaryWord: SupplementaryWord;
  isCreating?: boolean;
};

export type SupplementaryWordFormValues = {
  type: SupplementaryWordType;
  text: string;
  isExclusive: boolean;
};

export default function SupplementaryWordModal({
  isOpen,
  targetPhraseGroup,
  supplementaryWord,
  isCreating,
  onClose,
}: {
  isOpen: boolean;
  targetPhraseGroup?: TargetPhraseGroup;
  supplementaryWord: SupplementaryWord;
  isCreating?: boolean;
  onClose: () => void;
}) {
  // --Refs
  const formRef = useRef<FormikProps<SupplementaryWordFormValues>>(null);

  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);

  // -- Functions
  const onSubmit = (values: { type: SupplementaryWordType; text: string; isExclusive: boolean }) => {
    isCreating
      ? lessonComponent!.addSupplementaryWordToTargetPhraseGroup(
          updateObject(supplementaryWord, values),
          targetPhraseGroup!.uuid
        )
      : lessonComponent!.updateSupplementaryWord(updateObject(supplementaryWord, values));
    dispatch(updateCurrentComponent(lessonComponent!));
    onClose();
  };

  const resetIsExclusive = (type: SupplementaryWordType) => {
    if (!formRef.current) return;
    formRef.current.setFieldValue("isExclusive", type === SupplementaryWordType.MISSING);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={isCreating ? "Create Supplementary Word" : "Edit Supplementary Word"}
      subtitle={supplementaryWord.uuid}
      onClose={onClose}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          type: supplementaryWord.type,
          text: supplementaryWord.text,
          isExclusive: supplementaryWord.isExclusive,
        }}
        validate={(values) => {
          const errors: { type?: string; text?: string; isExclusive?: string } = {};
          if (!values.text) errors.text = "Cannot be blank";
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange }) => (
          <Form className="grid grid-cols-2 gap-2">
            <div>
              <Input
                autoFocus
                title="Text"
                placeholder="hola"
                name="text"
                value={values.text}
                onChange={handleChange}
              />
              <FormErrorMessage error={errors.text} />
            </div>
            <SelectMenu title="Type" name="type" value={values.type} onChange={handleChange} set={resetIsExclusive}>
              {Object.values(SupplementaryWordType)
                .filter((type) => typeof type === "string")
                .map((type) => (
                  <option key={type}>{type}</option>
                ))}
            </SelectMenu>
            <CheckBoxInput
              className="mt-2"
              value={values.isExclusive}
              name="isExclusive"
              message="isExclusive"
              onChange={handleChange}
            />
            <div className="h-5 col-span-full" />
            <ModalFormButtons className="col-span-full" submitText={isCreating ? "Create" : "Done"} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
