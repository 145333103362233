import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import {
  ValidationArgument,
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
  ValidationType,
} from "../../../service/Validation";
import { DataGlobalVideoSection } from "../../data/entities";
import { TargetPhrase, TargetPhraseGroup, VideoClip } from "./";
import LessonComponent from "./LessonComponent.reference";
import Phrase from "./Phrase";
import SectionInfo from "./SectionInfo";
import { LessonComponentType, SectionPresetType, VideoClipType } from "./types";

export default class GlobalVideoSection extends LessonComponent {
  sectionId: string;
  isGeneric: boolean;
  isDojo: boolean;
  lessonPrefix?: string;

  constructor(object: Partial<GlobalVideoSection>) {
    super(object);
    this.sectionId = object.sectionId ?? "not created";
    this.isGeneric = object.isGeneric ?? false;
    this.isDojo = object.isDojo ?? true;
    this.lessonPrefix = object.lessonPrefix;
    this.type = LessonComponentType.GLOBALVIDEOSECTION;
  }

  static generateFromPhrases(
    phrases: Phrase[],
    title: string = "Generated",
    allGlobalVideoSections: GlobalVideoSection[],
    primaryPhrases?: Phrase[]
  ) {
    const localeId = phrases[0].localeId;

    const usableGlobalVideoSections = allGlobalVideoSections.filter((gvs) => gvs.isUsable());
    const generics = usableGlobalVideoSections.filter((gvs) => gvs.isGeneric);

    const generated = new GlobalVideoSection({
      title: title,
      type: LessonComponentType.GLOBALVIDEOSECTION,
      localeId: localeId,
      isChecked: true,
      createdBy: "Generated from phrases",
    });

    const genericSection = _.sample(generics);
    if (!genericSection) throw "No generic sections found";
    linkToGeneric(genericSection, phrases);

    function linkToGeneric(genericSection: GlobalVideoSection, phrases: Phrase[]) {
      const { promptClip, successClip, repromptClip, repromptSuccessClip, exitClip } =
        getClipsFromSection(genericSection);

      const firstPhrase = phrases[0];
      const primaryPhrase = primaryPhrases && primaryPhrases.length > 0 ? primaryPhrases[0] : firstPhrase;

      generated.addSection(
        new SectionInfo({
          title: primaryPhrase.targetText,
          targetText: primaryPhrase.targetText,
          nativeText: primaryPhrase.nativeText,
        }),
        SectionPresetType.reprompt
      );

      const {
        promptClip: generatedPromptClip,
        successClip: generatedSuccessClip,
        repromptClip: generatedRepromptClip,
        repromptSuccessClip: generatedRepromptSuccessClip,
        exitClip: generatedExitClip,
        promptObjective: generatedPromptObjective,
        repromptObjective: generatedRepomptObjective,
      } = getClipsFromSection(generated);

      const generatedPromptTargetPhraseGroup = generatedPromptObjective.targetPhraseGroups[0];
      const gneratedRepromptTargetPhraseGroup = generatedRepomptObjective.targetPhraseGroups[0];
      if (!generatedPromptTargetPhraseGroup) throw "No prompt objective found";
      if (!gneratedRepromptTargetPhraseGroup) throw "No reprompt objective found";

      generated.addTargetPhraseToGroup(
        generatedPromptTargetPhraseGroup.uuid,
        new TargetPhrase({
          rootPhraseId: firstPhrase.id,
          isPrimary: primaryPhrases?.some((p) => p.id === firstPhrase.id),
        })
      );
      for (const displayItem of generatedSuccessClip.displayItems) {
        displayItem.primaryText = firstPhrase.targetText;
        displayItem.secondaryText = firstPhrase.nativeText;
      }
      generatedSuccessClip.title = generatedSuccessClip.title + " " + firstPhrase.targetText;

      generated.addTargetPhraseToGroup(
        gneratedRepromptTargetPhraseGroup.uuid,
        new TargetPhrase({
          rootPhraseId: firstPhrase.id,
          isPrimary: primaryPhrases?.some((p) => p.id === firstPhrase.id),
        })
      );
      for (const displayItem of generatedRepromptSuccessClip.displayItems) {
        displayItem.primaryText = firstPhrase.targetText;
        displayItem.secondaryText = firstPhrase.nativeText;
      }
      generatedRepromptSuccessClip.title = generatedRepromptSuccessClip.title + " " + firstPhrase.targetText;

      for (const phrase of phrases) {
        if (phrase.id === firstPhrase.id) continue;

        addPhraseToPrompt(phrase, generatedPromptClip, successClip, primaryPhrases);
        addPhraseToPrompt(phrase, generatedRepromptClip, repromptSuccessClip, primaryPhrases);
      }

      generatedPromptClip.databaseName = promptClip.databaseName;
      generatedSuccessClip.databaseName = successClip.databaseName;
      generatedRepromptClip.databaseName = repromptClip.databaseName;
      generatedRepromptSuccessClip.databaseName = repromptSuccessClip.databaseName;
      generatedExitClip.databaseName = exitClip.databaseName;

      generatedPromptClip.startInteractionTime = promptClip.startInteractionTime;
      generatedRepromptClip.startInteractionTime = repromptClip.startInteractionTime;
    }

    function getClipsFromSection(section: GlobalVideoSection) {
      const promptClip = section.videoClips.find((vc) => vc.type === VideoClipType.SECTION);
      if (!promptClip) throw "No prompt clip found";
      const promptObjective = section.getObjective(promptClip.objectiveId!);
      if (!promptObjective) throw "No prompt objective found";
      const promptMatchDestination = promptObjective.getMatchDestination(
        promptObjective.targetPhraseGroups[0].matchDestinationId
      )!;
      const successClip = section.getVideoClip(promptMatchDestination.successVideoClipId);
      if (!successClip) throw "No success clip found";
      const repromptClip = section.getVideoClip(promptObjective.failDestination.failVideoClipId);
      if (!repromptClip) throw "No reprompt clip found";
      const repromptObjective = section.getObjective(repromptClip.objectiveId!);
      if (!repromptObjective) throw "No reprompt objective found";
      const repromptMatchDestination = repromptObjective.getMatchDestination(
        repromptObjective.targetPhraseGroups[0].matchDestinationId
      )!;
      const repromptSuccessClip = section.getVideoClip(repromptMatchDestination.successVideoClipId);
      if (!repromptSuccessClip) throw "No reprompt success clip found";
      const exitClip = section.getVideoClip(repromptObjective.failDestination.failVideoClipId);
      if (!exitClip) throw "No exit clip found";

      return {
        promptClip,
        successClip,
        repromptClip,
        repromptSuccessClip,
        exitClip,
        promptObjective,
        repromptObjective,
      };
    }

    function addPhraseToPrompt(
      phrase: Phrase,
      promptClip: VideoClip,
      genericSuccessClip: VideoClip,
      primaryPhrases?: Phrase[]
    ) {
      const targetPhraseGroup = generated.addDestinationToVideoClip(promptClip.uuid, false);
      if (!targetPhraseGroup) throw "No target phrase group found";
      generated.addTargetPhraseToGroup(
        targetPhraseGroup.uuid,
        new TargetPhrase({
          rootPhraseId: phrase.id,
          isPrimary: primaryPhrases?.some((p) => p.id === phrase.id),
        })
      );
      const matchDestination = generated.getMatchDestination(targetPhraseGroup.matchDestinationId);
      if (!matchDestination) throw "No match destination found";
      const videoClip = generated.getVideoClip(matchDestination.successVideoClipId);
      if (!videoClip) throw "No video clip found";
      videoClip.databaseName = genericSuccessClip.databaseName;
      videoClip.title = videoClip.title + " " + phrase.targetText;

      for (const displayItem of videoClip.displayItems) {
        displayItem.primaryText = phrase.targetText;
        displayItem.secondaryText = phrase.nativeText;
      }
    }

    return generated;
  }

  attachPhrase(phrase: Phrase, acceptedPhrases: Phrase[], isUsed: boolean) {
    if (!this.isGeneric) return;

    const videoClipIdMap: { [key: string]: string } = {};
    const objectiveIdMap: { [key: string]: string } = {};

    const newSectionId = uuidv4();
    if (isUsed) this.sectionId = newSectionId;

    for (const videoClip of this.videoClips) {
      if (isUsed) {
        // Re-assign video clip ids
        const newId = videoClip.isSection ? newSectionId : uuidv4();
        videoClipIdMap[videoClip.uuid] = newId;
        videoClip.uuid = newId;
        videoClip.sectionId = newSectionId;
      }

      // Populate display items
      for (const displayItem of videoClip.displayItems) {
        displayItem.setFromPhrase(phrase, !videoClip.isSection);
      }
    }

    for (const objective of this.objectives) {
      const newId = uuidv4();
      objectiveIdMap[objective.uuid] = newId;
      objective.uuid = newId;
      objective.isGeneric = true;

      // Populate target phrases
      const matchDestinationId = objective.targetPhraseGroups[0].matchDestinationId;
      const targetPhrases = acceptedPhrases.map(
        (p) => new TargetPhrase({ isPrimary: p.id === phrase.id, rootPhraseId: p.id })
      );
      objective.targetPhraseGroups = [
        new TargetPhraseGroup({
          targetPhrases,
          matchDestinationId,
        }),
      ];

      // Update video clip ids in destinations
      objective.failDestination.replaceVideoClipIds(videoClipIdMap);
      for (const matchDestination of objective.matchDestinations) matchDestination.replaceVideoClipIds(videoClipIdMap);
    }

    // Update objective ids in video clips
    for (const videoClip of this.videoClips) {
      if (!videoClip.objectiveId) continue;
      videoClip.objectiveId = objectiveIdMap[videoClip.objectiveId];
    }
  }

  convertToData(): any {
    return new DataGlobalVideoSection({
      id: this.id,
      title: this.title,
      isGeneric: this.isGeneric,
      isDojo: this.isDojo,
      objectives: this.objectives.map((o) => o.convertToData()),
      videoClips: this.videoClips,
      sectionId: this.sectionId,
      localeId: this.localeId,
      isHidden: this.isHidden,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      groupsCanAccess: ["free"],
      isChecked: this.isChecked,
      lessonPrefix: this.lessonPrefix,
    });
  }

  async validate(args: ValidationArgument) {
    const validation = await super.validate({ ...args, files: undefined });
    validation.type = ValidationType.GlobalVideoSection;

    if (
      !this.isGeneric &&
      !this.videoClips.find((videoClip) => this.sectionId === videoClip.uuid && videoClip.uuid === videoClip.sectionId)
    )
      validation.issues.push(
        new ValidationIssue(
          "Field sectionId must reference a video clip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    for (const videoClip of this.videoClips) {
      if (videoClip.databaseName?.includes("I"))
        validation.issues.push(
          new ValidationIssue(
            `Cannot have video clips prefixed with "I"`,
            ValidationIssueGravity.warning,
            ValidationIssueCause.userInput
          )
        );
    }

    // for (const phraseId of this.getAllPhraseIds()) {
    //   const phrase = args.phrases.find((p) => p.id === phraseId);
    //   if (phrase && !phrase.isHidden && !phrase.isDojo)
    //     validation.issues.push(
    //       new Issue(
    //         `Cannot have phrase not flagged isDojo: ${phrase.targetText} - ${phrase.nativeText}`,
    //         GravityType.error,
    //         IssueFixType.userInput
    //       )
    //     );
    // }

    return validation;
  }

  // attachPhrases(phrases: Phrase[], isUsed: boolean) {
  //   if (!this.isGeneric) return;

  //   const videoClipIdMap: { [key: string]: string } = {};
  //   const objectiveIdMap: { [key: string]: string } = {};

  //   const newSectionId = uuidv4();
  //   if (isUsed) this.sectionId = newSectionId;

  //   for (const videoClip of this.videoClips) {
  //     if (isUsed) {
  //       // Re-assign video clip ids
  //       const newId = videoClip.isSection ? newSectionId : uuidv4();
  //       videoClipIdMap[videoClip.uuid] = newId;
  //       videoClip.uuid = newId;
  //       videoClip.sectionId = newSectionId;
  //     }

  //     // Populate display items
  //     for (const displayItem of videoClip.displayItems) {
  //       displayItem.setFromPhrase(phrase, !videoClip.isSection);
  //     }
  //   }

  //   for (const [index, objective] of Object.entries(this.objectives)) {
  //     const newId = uuidv4();
  //     objectiveIdMap[objective.uuid] = newId;
  //     objective.uuid = newId;

  //     // Populate target phrases
  //     const matchDestinationId = objective.targetPhraseGroups[0].matchDestinationId;
  //     const targetPhraseGroups =
  //       parseInt(index) === 0
  //         ? phrases.map((phrase) => {
  //             const targetPhrases = [
  //               new TargetPhrase({
  //                 rootPhraseId: phrase.id,
  //               }),
  //             ];
  //             return new TargetPhraseGroup({
  //               matchDestinationId,
  //               targetPhrases,
  //             });
  //           })
  //         : [
  //             new TargetPhraseGroup({
  //               matchDestinationId,
  //               targetPhrases: [
  //                 new TargetPhrase({
  //                   rootPhraseId: phrases[0].id,
  //                 }),
  //               ],
  //             }),
  //           ];

  //     objective.targetPhraseGroups = targetPhraseGroups;

  //     // Update video clip ids in destinations
  //     objective.failDestination.replaceVideoClipIds(videoClipIdMap);
  //     for (const matchDestination of objective.matchDestinations) matchDestination.replaceVideoClipIds(videoClipIdMap);
  //   }

  //   // Update objective ids in video clips
  //   for (const videoClip of this.videoClips) {
  //     if (!videoClip.objectiveId) continue;
  //     videoClip.objectiveId = objectiveIdMap[videoClip.objectiveId];
  //   }
  // }

  addSection(sectionInfo: SectionInfo, preset: SectionPresetType) {
    super.addSection(sectionInfo, preset, true);

    this.sectionId = this.videoClips.find((vc) => vc.uuid === vc.sectionId)!.uuid;
  }

  getVideoNamePrefixs() {
    return this.lessonPrefix ? [...super.getVideoNamePrefixs(), this.lessonPrefix] : super.getVideoNamePrefixs();
  }

  isUsable() {
    return !this.isHidden && this.isChecked;
  }
}
