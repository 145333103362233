import { v4 as uuidv4 } from "uuid";
import { LessonComponentType } from "./types";

export default class DeepLink {
  id: string;
  shortLink: string;
  componentId: string;
  componentType: string;
  title: string;
  description: string;
  imageUrl?: string;
  localeId: string;
  groupsCanAccess = ["free"];
  createdAt?: Date;

  constructor(object: Partial<DeepLink> = {}) {
    this.id = object.id ?? uuidv4();
    this.shortLink = object.shortLink ?? "";
    this.componentId = object.componentId ?? "";
    this.componentType = object.componentType ?? LessonComponentType.INTRODUCTION;
    this.title = object.title ?? "";
    this.description = object.description ?? "";
    this.imageUrl = object.imageUrl;
    this.localeId = object.localeId ?? "";
    this.createdAt = object.createdAt ? new Date(object.createdAt) : new Date();
  }
}
