import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { StatusType } from "./types";
import { LessonComponent, TargetPhrase, VideoClip, TargetPhraseGroup, GlobalVideoSection } from "../domain/entities";
import { RootState } from "./store";
import { updateGlobalVideoSection } from "./globalVideoSectionsSlice";
import { updateLessonComponent } from "./lessonComponentsSlice";

export const updateComponent = createAsyncThunk(
  `component/update`,
  async (component: LessonComponent | GlobalVideoSection, { dispatch }) => {
    component instanceof GlobalVideoSection
      ? dispatch(updateGlobalVideoSection(component))
      : dispatch(updateLessonComponent(component));
  }
);

const initialState = {
  item: null as LessonComponent | GlobalVideoSection | null,
  status: StatusType.idle,
  error: null as string | undefined | null,
  isSaved: true,
  copiedVideoClip: null as VideoClip | null,
  copiedTargetPhrase: null as TargetPhrase | null,
  copiedTargetPhraseGroup: null as TargetPhraseGroup | null,
};

export const componentSlice = createSlice({
  name: "component",
  initialState: initialState,
  reducers: {
    selectComponent(state, { payload: component }: { payload: LessonComponent | GlobalVideoSection }) {
      state.isSaved = true;
      state.item = _.cloneDeep(component);
    },
    updateCurrentComponent(state, { payload: component }: { payload: LessonComponent | GlobalVideoSection }) {
      state.isSaved = false;
      state.item = _.cloneDeep(component);
    },
    copyVideoClip(state, { payload: videoClip }: { payload: VideoClip }) {
      state.copiedVideoClip = videoClip;
    },
    copyTargetPhrase(state, { payload: targetPhrase }: { payload: TargetPhrase }) {
      state.copiedTargetPhrase = targetPhrase;
    },
    copyTargetPhraseGroup(state, { payload: targetPhraseGroup }: { payload: TargetPhraseGroup }) {
      state.copiedTargetPhraseGroup = targetPhraseGroup;
    },
    pasteVideoClip(state) {
      state.copiedVideoClip = null;
    },
    pasteTargetPhrase(state) {
      state.copiedTargetPhrase = null;
    },
    pasteTargetPhraseGroup(state) {
      state.copiedTargetPhraseGroup = null;
    },
    setIsSaved(state, { payload: isSaved }: { payload: boolean }) {
      state.isSaved = isSaved;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateLessonComponent.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(
      updateLessonComponent.fulfilled,
      (state, { payload: lessonComponent }: { payload: LessonComponent }) => {
        state.status = StatusType.succeeded;
        state.isSaved = true;
        if (state.item && lessonComponent.id === state.item.id) {
          state.item = lessonComponent;
        }
      }
    );
    builder.addCase(updateLessonComponent.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(updateGlobalVideoSection.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(
      updateGlobalVideoSection.fulfilled,
      (state, { payload: lessonComponent }: { payload: LessonComponent }) => {
        state.status = StatusType.succeeded;
        state.isSaved = true;
        if (state.item && lessonComponent.id === state.item.id) {
          state.item = lessonComponent;
        }
      }
    );
    builder.addCase(updateGlobalVideoSection.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
  },
});

export const {
  selectComponent,
  updateCurrentComponent,
  copyVideoClip,
  copyTargetPhrase,
  copyTargetPhraseGroup,
  pasteVideoClip,
  pasteTargetPhrase,
  pasteTargetPhraseGroup,
  setIsSaved,
} = componentSlice.actions;

export const getComponent = (state: RootState) => state.component.item;
export const getComponentStatus = (state: RootState) => state.component.status;
export const getComponentError = (state: RootState) => state.component.error;
export const getComponentIsSaved = (state: RootState) => state.component.isSaved;
export const getCopiedVideoClip = (state: RootState) => state.component.copiedVideoClip;
export const getCopiedTargetPhrase = (state: RootState) => state.component.copiedTargetPhrase;
export const getCopiedTargetPhraseGroup = (state: RootState) => state.component.copiedTargetPhraseGroup;

export default componentSlice.reducer;
