import { Link, Translate } from "heroicons-react";

import Button from "../base/Button";

export default function IsWordButton({
  className,
  isWord,
  onToggle,
  ...rest
}: {
  className?: string;
  isWord: boolean;
  onToggle: (value: boolean) => void;
  [key: string]: any;
}) {
  if (isWord) {
    return (
      <Button {...rest} className={`w-9 font-bold ${className}`} onClick={() => onToggle(false)} shade={100}>
        W
      </Button>
    );
  } else {
    return <Button {...rest} className={`w-9 ${className}`} onClick={() => onToggle(true)} shade={100}></Button>;
  }
}
