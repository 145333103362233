import aws from "aws-sdk";
import { environments } from "./constants";

const cloudfront = new aws.CloudFront({
  region: "us-east-2",
  signatureVersion: "v4",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

export async function invalidateProdJSON() {
  const result = await invalidateProdPath("/*");
  console.log(result);
}

function invalidateProdPath(path: string) {
  return new Promise((resolve, reject) => {
    cloudfront.createInvalidation(
      {
        DistributionId: environments.prod.cloudfrontDistributionId,
        InvalidationBatch: {
          CallerReference: `${Date.now()}`,
          Paths: {
            Quantity: 1,
            Items: [path],
          },
        },
      },
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      }
    );
  });
}
