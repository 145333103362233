import { Phrase, TargetPhrase, TargetPhraseGroup } from "../../core/domain/entities";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { PencilAlt } from "heroicons-react";
import _ from "lodash";
import { useEffect } from "react";
import { Form, Formik, FormikProps, FormikValues } from "formik";

import { getAllPhrases, getPhrasesError, getPhrasesStatus } from "../../core/redux/phrasesSlice";
import {
  phraseByWordSearchFilter,
  phraseExactSearchFilter,
  phraseLibSearchFilter,
  SearchType,
  updateObject,
} from "../../service/helpers";
import { getComponent, updateCurrentComponent } from "../../core/redux/componentSlice";
import { openModal } from "../../core/redux/modalSlice";

import { Modal, ModalFormButtons, CheckBoxInput, Button, ExpandableCard, SearchView, StatusView } from "..";
import { OSTargetData } from "../../core/domain/entities/TargetPhrase";

export type TargetPhraseModalProps = {
  isCreating?: boolean;
  targetPhrase: TargetPhrase;
  targetPhraseGroup: TargetPhraseGroup;
};

export default function TargetPhraseModal({
  isOpen,
  isCreating,
  targetPhrase,
  targetPhraseGroup,
  onClose,
}: {
  isOpen: boolean;
  isCreating?: boolean;
  targetPhrase: TargetPhrase;
  targetPhraseGroup: TargetPhraseGroup;
  onClose: () => void;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);

  // -- Functions
  const onSubmit = (values: FormikValues) => {
    if (isCreating) {
      const acceptedTextIds = values.acceptedTextIds.filter((id: any) => !!id);
      if (acceptedTextIds.length === 0) {
        lessonComponent!.addTargetPhraseToGroup(
          targetPhraseGroup.uuid,
          updateObject(targetPhrase, {
            ...values,
            acceptedTextId: undefined,
          })
        );
      } else {
        const baseNewTargetPhrase = updateObject(targetPhrase, values);
        for (const acceptedTextId of values.acceptedTextIds) {
          if (!acceptedTextId) continue;
          const newTargetPhrase = new TargetPhrase({
            ...baseNewTargetPhrase,
            uuid: undefined,
            acceptedTextId: acceptedTextId,
          });
          lessonComponent!.addTargetPhraseToGroup(targetPhraseGroup.uuid, newTargetPhrase);
        }
      }
    } else {
      values.acceptedTextId = values.acceptedTextIds ? values.acceptedTextIds[0] : undefined;
      lessonComponent!.updateTargetPhrase(updateObject(targetPhrase, values));
    }
    dispatch(updateCurrentComponent(lessonComponent!));
    onClose();
  };

  const getInitialSearchText = () => {
    if (!isCreating) {
      const phrase = phrases.find((phrase) => phrase.id === targetPhrase.rootPhraseId);
      if (phrase) {
        return phrase.targetText;
      }
    }
    return "";
  };

  // UI
  const openPhraseModal = (phrase: Phrase) => {
    dispatch(
      openModal({
        phraseModal: {
          phrase: phrase,
        },
      })
    );
  };

  // -- Components
  const PhraseCard = ({ phrase, formProps }: { phrase: Phrase; formProps: FormikProps<FormValues> }) => {
    // -- Constants
    const { values, setFieldValue } = formProps;

    const isChecked =
      phrase.id === values.rootPhraseId && !phrase.acceptedTexts.some((at) => values.acceptedTextIds.includes(at.uuid));

    // -- Functions
    const handlePhraseCheckChange = () => {
      setFieldValue("rootPhraseId", !isChecked ? phrase.id : null);
      setFieldValue("acceptedTextIds", []);
    };

    const handleAcceptedTextCheckChange = (value: boolean, uuid: string) => {
      if (value) {
        setFieldValue("rootPhraseId", phrase.id);
        setFieldValue("acceptedTextIds", [...values.acceptedTextIds, uuid]);
      } else {
        setFieldValue("acceptedTextIds", []);
      }
    };

    // -- Components
    const ExpandedView = () => {
      return (
        <div className="flex-1 flex flex-col gap-2">
          {phrase.acceptedTexts.map((acceptedText) => (
            <CheckBoxInput
              key={acceptedText.uuid}
              value={values.acceptedTextIds.includes(acceptedText.uuid)}
              set={(value) => handleAcceptedTextCheckChange(value, acceptedText.uuid)}
              message={acceptedText.text}
              disabled={isChecked}
            />
          ))}
        </div>
      );
    };

    return (
      <ExpandableCard expandedView={ExpandedView} initialIsExpanded={true}>
        <div className="flex justify-between items-center gap-2">
          <div>
            <div className="text-sm font-bold">{phrase.targetText}</div>
            <div className="text-xs text-gray-500">{phrase.nativeText}</div>
            <div className="text-xs text-gray-400">{phrase.id}</div>
          </div>
          <div className="flex gap-2">
            <CheckBoxInput value={isChecked} set={handlePhraseCheckChange} />
            <Button shade={100} onClick={() => openPhraseModal(phrase)}>
              <PencilAlt size={16} />
            </Button>
          </div>
        </div>
      </ExpandableCard>
    );
  };

  type FormValues = {
    isMistake: boolean;
    rootPhraseId: string;
    acceptedTextIds: string[];
    android: OSTargetData;
    ios: OSTargetData;
  };

  return (
    <Modal
      isOpen={isOpen}
      title={isCreating ? "Add Target Phrase" : "Edit Target Phrase"}
      subtitle={targetPhrase.uuid}
      onClose={onClose}
    >
      <Formik
        initialValues={
          {
            isMistake: targetPhrase.isMistake,
            rootPhraseId: targetPhrase.rootPhraseId,
            acceptedTextIds: [targetPhrase.acceptedTextId],
            android: targetPhrase.android,
            ios: targetPhrase.ios,
          } as FormValues
        }
        onSubmit={onSubmit}
      >
        {(formProps) => {
          const { values, handleChange } = formProps;
          return (
            <Form className="flex flex-col gap-2">
              <CheckBoxInput
                value={values.isMistake}
                name="isMistake"
                onChange={handleChange}
                message="isMistake"
                note="As a mistake, no credit will be given to the user if they match on this Target Phrase"
              />
              <CheckBoxInput
                value={values.android.ignorePartialUnstableTranscriptions}
                name="android.ignorePartialUnstableTranscriptions"
                onChange={handleChange}
                message="Android: Ignore unstable transcriptions"
              />
              <StatusView status={phrasesStatus} error={phrasesError}>
                <SearchView
                  items={phrases}
                  initialSearchText={getInitialSearchText()}
                  initialSearchType={isCreating ? SearchType.contains : SearchType.exact}
                  containsFilter={phraseLibSearchFilter}
                  exactFilter={phraseExactSearchFilter}
                  wordFilter={phraseByWordSearchFilter}
                  noneFoundMessage="No phrases found"
                  limit={100}
                >
                  {(phrases, indicator) => (
                    <div className="bg-gray-100 rounded-md p-5 h-96 flex flex-col gap-2 overflow-scroll">
                      {!indicator
                        ? phrases.map((phrase) => <PhraseCard key={phrase.id} phrase={phrase} formProps={formProps} />)
                        : indicator}
                    </div>
                  )}
                </SearchView>
              </StatusView>
              <div className="h-5" />
              <ModalFormButtons submitText={isCreating ? "Add" : "Done"} onClose={onClose} />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
