import CSVService from "../CSVService";
import { standardString } from "../helpers";
import Script, { ScriptArg } from "./Script";
import axios from "axios";

const execute = async ({
  lessons,
  lessonComponents,
  vocabComponents,
  globalVideoSections,
  phrases,
  locale,
}: ScriptArg) => {
  const data = [];
  const allData = [];

  const sortedLessons = lessons.sort((a, b) => a.orderIndex - b.orderIndex);
  for (const l of sortedLessons) {
    // Get all words from all lessons up to this lesson, including this lesson
    const allWords: string[] = [];
    for (const lesson of sortedLessons.slice(0, l.orderIndex + 1)) {
      const phraseIds = lesson.getAllPhraseIds(lessonComponents, vocabComponents, globalVideoSections);
      const lessonPhrases = phraseIds.map((id) => phrases.find((p) => p.id === id)!);
      const words = lessonPhrases.filter((p) => p.isWord).map((w) => w.targetText);
      allWords.push(...words);
    }

    const allUniqueWords = [...new Set(allWords)];
    const wordsString = allUniqueWords.join(",");

    const prompt = `
    Act as a ${locale.title} tutor. I will give you a list of words that a user of my app has learned and you will follow the instructions below.
  
    Give me 75 sentences that would be spoken in a conversation (3-7 words) for them to practice using only these words. 
    Make the sentences as natural as possible while still as challenging as possible an advanced learner.
    VERY IMPORTANT: Do not include any other words or parts of these words, only combinations of these exact words.
    VERY IMPORTANT: Give me this in the form of a complete string that can be parsed by JSON.parse(), where each object has a "text" property with the sentence, and a "translation" property with the English translation of the sentence.
  
    Here are the words:
    ${wordsString}
    `;
    console.log(prompt);

    let retry = true;
    while (retry) {
      try {
        // Get the sentences from GPT, retrying if there is an error
        let sentences;
        console.log("Sending request...");
        const response = await axios.post("http://localhost:80/admin/completeText", { prompt });
        console.log("Received response: ", response.data);

        let parsedData = JSON.parse(JSON.stringify(response.data));

        if (!Array.isArray(parsedData)) {
          // Cut of the last row and re-parse
          const lastBracket = response.data.lastIndexOf("{");
          const lastComma = response.data.substring(0, lastBracket).lastIndexOf(",");
          const dataWithoutLastRow = response.data.substring(0, lastComma) + "]";
          parsedData = JSON.parse(dataWithoutLastRow);
          console.log("Retrying with: ", parsedData);
        }

        sentences = parsedData;

        // Validate the sentences and add them to the data
        if (!sentences) return;
        for (const sentence of sentences) {
          if (!sentence.text || !sentence.translation) {
            console.log("Invalid sentence: ", sentence);
            continue;
          }

          const entry = {
            lessonId: l.id,
            lesson: l.title,
            target: sentence.text,
            native: sentence.translation,
          };

          // Check if any of the words are not in the list of words (disregard case and punctuation)
          let isValid = true;
          const words = sentence.text.split(" ");
          for (const word of words) {
            if (!allUniqueWords.find((w) => standardString(w) === standardString(word))) {
              isValid = false;
              console.log("Invalid word: ", standardString(word));
              allData.push(entry);
              break;
            }
          }
          if (!isValid) continue;

          data.push(entry);
          allData.push(entry);

          retry = false;
        }
      } catch (e) {
        console.log("Error: ", e);
        console.log("Retrying...");
      }
    }
  }

  CSVService.instance.downloadCSV(data, `${locale.title} Round Up Phrases.csv`);
  CSVService.instance.downloadCSV(allData, `${locale.title} All Generated Phrases.csv`);
};

export default new Script("Generate Round Up Phrases", execute);
