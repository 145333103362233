import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Lesson, LessonComponent } from "../../core/domain/entities";
import { LessonComponentDisplayType, LessonComponentType } from "../../core/domain/entities/types";
import { createLessonComponent, createMagnet, updateLessonComponent } from "../../core/redux/lessonComponentsSlice";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import { updateObject } from "../../service/helpers";
import { getNextSuffix } from "../../service/StorageSerivce";

import { CheckBoxInput, FormErrorMessage, Input, Modal, ModalFormButtons, SelectMenu } from "../index";
import DeepLinkGenerator from "./DeepLinkGenerator";
import UploadView from "./UploadView";

export type LessonComponentModalProps = {
  lessonComponent: LessonComponent;
  lesson?: Lesson;
  isCreating?: boolean;
};

export default function LessonComponentModal({
  isOpen,
  lessonComponent,
  lesson,
  isCreating,
  onClose,
}: {
  isOpen: boolean;
  lessonComponent: LessonComponent;
  lesson?: Lesson;
  isCreating?: boolean;
  onClose: () => void;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const currentLocale = useSelector(getCurrentLocale);

  // -- Functions
  const onSubmit = (values: {
    title: string;
    type: LessonComponentType;
    audioUrl?: string | null;
    imageUrl: string;
    displayType: LessonComponentDisplayType;
    isMagnet: boolean;
    viewGroups: string[];
    freePromoImage?: string;
  }) => {
    isCreating
      ? lesson
        ? dispatch(createLessonComponent({ lessonComponent: updateObject(lessonComponent, values), lesson: lesson! }))
        : dispatch(createMagnet(updateObject(lessonComponent, values)))
      : dispatch(updateLessonComponent(updateObject(lessonComponent, values)));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} title={isCreating ? "Create Lesson Component" : lessonComponent.title} onClose={onClose}>
      <Formik
        initialValues={{
          title: lessonComponent.title,
          type: lessonComponent.type,
          audioUrl: lessonComponent.audioUrl,
          imageUrl: lessonComponent.imageUrl,
          displayType: lessonComponent.displayType,
          isMagnet: lessonComponent.isMagnet,
          viewGroups: lessonComponent.viewGroups,
          freePromoImage: lessonComponent.freePromoImage,
        }}
        validate={(values) => {
          const errors: { title?: string; type?: string } = {};
          if (!values.title) errors.title = "Cannot be blank";
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors, handleChange }) => (
          <Form className="grid grid-cols-2 gap-2">
            <div>
              <Input
                autoFocus
                title="Title"
                placeholder="Introduction: Quiero"
                name="title"
                value={values.title}
                onChange={handleChange}
              />
              <FormErrorMessage error={errors.title} />
            </div>
            {lessonComponent.type !== LessonComponentType.MAGENT ? (
              <SelectMenu title="Type" name="type" value={values.type} onChange={handleChange}>
                {Object.values(LessonComponentType)
                  .filter((type) => typeof type === "string")
                  .map((type) => (
                    <option key={type}>{type}</option>
                  ))}
              </SelectMenu>
            ) : null}
            <SelectMenu title="Display Type" name="displayType" value={values.displayType} onChange={handleChange}>
              {Object.values(LessonComponentDisplayType)
                .filter((type) => typeof type === "string")
                .map((type) => (
                  <option key={type}>{type}</option>
                ))}
            </SelectMenu>
            <SelectMenu
              title="Available to..."
              value={values.viewGroups[0]}
              set={(group) => {
                setFieldValue("viewGroups", group === "pro" ? ["pro"] : ["free", "pro"]);
              }}
            >
              <option value={"free"}>Free Users</option>
              <option value={"pro"}>Pro Users</option>
            </SelectMenu>
            <CheckBoxInput
              className="col-span-full"
              value={values.isMagnet}
              message="isMagnet"
              name="isMagnet"
              onChange={handleChange}
            />
            <UploadView
              title="Image"
              accept="image/*"
              url={values.imageUrl}
              setUrl={(url) => setFieldValue("imageUrl", url)}
              path={`images/${currentLocale!.formattedCode}/lesson/tile`}
              fileName={lessonComponent.id + getNextSuffix(values.imageUrl)}
            />
            <Input
              title="Promo Image URL"
              name="freePromoImage"
              value={values.freePromoImage ?? ""}
              set={(url) => setFieldValue("freePromoImage", url === "" ? undefined : url)}
              className="col-span-full"
            />
            <DeepLinkGenerator className="col-span-full" lesson={lesson} component={lessonComponent} />
            <div className="h-5 col-span-full" />
            <ModalFormButtons className="col-span-full" submitText={isCreating ? "Create" : "Done"} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
