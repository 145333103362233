import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGlobalVideoSections,
  getAllGlobalVideoSections,
  getGlobalVideoSectionsError,
  getGlobalVideoSectionsStatus,
} from "../core/redux/globalVideoSectionsSlice";

import {
  fetchLessonComponents,
  fetchProdLessonComponents,
  getAllLessonComponents,
  getAllProdLessonComponents,
  getLessonComponentsError,
  getLessonComponentsStatus,
} from "../core/redux/lessonComponentsSlice";
import { getCurrentLocale } from "../core/redux/localesSlice";
import { fetchVideos, getAllVideos, getVideosError, getVideosStatus } from "../core/redux/videosSlice";
import scripts from "../service/scripts";
import Script from "../service/scripts/Script";
import { getError, getWorstStatus, sorted } from "../service/helpers";
import {
  fetchAllPhrases,
  fetchPhrases,
  getAllPhrases,
  getAllPhrasesNoLocale,
  getPhrasesError,
  getPhrasesStatus,
} from "../core/redux/phrasesSlice";

import { Button, StatusView } from "../components";
import {
  fetchLessons,
  getAllLessons,
  getLessonsError,
  getLessonsStatus,
  getSortedLessons,
} from "../core/redux/lessonsSlice";
import { getCurrentUser } from "../core/redux/userSlice";
import {
  getAllUserResults,
  getUserResultsStatus,
  getUserResultsError,
  fetchUserResults,
  getAllProdUserResults,
  getProdUserResultsStatus,
  getProdUserResultsError,
  fetchProdUserResults,
} from "../core/redux/userResultsSlice";
import {
  fetchProdVocabComponents,
  fetchVocabComponents,
  getAllProdVocabComponents,
  getAllVocabComponents,
  getVocabComponentsError,
  getVocabComponentsStatus,
} from "../core/redux/vocabComponentsSlice";

export default function ScriptsPage() {
  // -- Redux
  const dispatch = useDispatch();

  const currentLocale = useSelector(getCurrentLocale);

  const lessonComponents = useSelector(getAllLessonComponents);
  const lessonComponentsStatus = useSelector(getLessonComponentsStatus);
  const lessonComponentsError = useSelector(getLessonComponentsError);
  const vocabComponents = useSelector(getAllVocabComponents);
  const vocabComponentsStatus = useSelector(getVocabComponentsStatus);
  const vocabComponentsError = useSelector(getVocabComponentsError);
  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSectionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const globalVideoSectionsError = useSelector(getGlobalVideoSectionsError);
  const videos = useSelector(getAllVideos);
  const videosStatus = useSelector(getVideosStatus);
  const videosError = useSelector(getVideosError);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);
  const allPhrases = useSelector(getAllPhrasesNoLocale);
  const lessons = useSelector(getSortedLessons);
  const lessonsStatus = useSelector(getLessonsStatus);
  const lessonsError = useSelector(getLessonsError);
  const user = useSelector(getCurrentUser);
  const userResults = useSelector(getAllUserResults);
  const userResultsStatus = useSelector(getUserResultsStatus);
  const userResultsError = useSelector(getUserResultsError);
  const prodUserResults = useSelector(getAllProdUserResults);
  const prodUserResultsStatus = useSelector(getProdUserResultsStatus);
  const prodUserResultsError = useSelector(getProdUserResultsError);
  const prodLessonComponents = useSelector(getAllProdLessonComponents);
  const prodVocabComponents = useSelector(getAllProdVocabComponents);

  // -- Effect
  // Fetch all
  useEffect(() => {
    if (currentLocale) {
      dispatch(fetchLessonComponents(currentLocale));
      dispatch(fetchVocabComponents(currentLocale));
      dispatch(fetchGlobalVideoSections(currentLocale));
      dispatch(fetchVideos(currentLocale));
      dispatch(fetchPhrases(currentLocale));
      dispatch(fetchAllPhrases());
      dispatch(fetchLessons(currentLocale));
      dispatch(fetchProdLessonComponents(currentLocale));
      dispatch(fetchProdVocabComponents(currentLocale));
      // dispatch(fetchUserResults());
      // dispatch(fetchProdUserResults());
    }
  }, [dispatch, currentLocale]);

  // -- Functions
  const executeScript = async (script: Script) => {
    if (window.confirm(`Are you sure you want to run the script: ${script.title}`)) {
      script.execute({
        lessonComponents: lessonComponents,
        vocabComponents: vocabComponents,
        globalVideoSections: globalVideoSections,
        videos: videos,
        phrases: phrases,
        allPhrases: allPhrases,
        lessons: lessons,
        locale: currentLocale!,
        user: user,
        userResults: userResults,
        prodUserResults: prodUserResults,
        prodLessonComponents: prodLessonComponents,
        prodVocabComponents: prodVocabComponents,
        dispatch: dispatch,
      });
    }
  };

  return (
    <div className="flex-1 p-5 flex flex-col gap-2">
      <div className="text-2xl font-bold">Scripts</div>
      <div className="grid grid-cols-5 gap-2 mt-5">
        <StatusView
          status={getWorstStatus([
            lessonComponentsStatus,
            vocabComponentsStatus,
            globalVideoSectionsStatus,
            videosStatus,
            phrasesStatus,
            lessonsStatus,
            // userResultsStatus,
            // prodUserResultsStatus,
          ])}
          error={getError([
            lessonComponentsError,
            vocabComponentsError,
            globalVideoSectionsError,
            videosError,
            phrasesError,
            lessonsError,
            // userResultsError,
            // prodUserResultsError,
          ])}
        >
          {scripts.map((script) => (
            <Button key={script.title} onClick={() => executeScript(script)}>
              {script.title}
            </Button>
          ))}
        </StatusView>
      </div>
    </div>
  );
}
