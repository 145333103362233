import { v4 as uuidv4 } from "uuid";

export default class DisplaySkill {
  id: string;
  title: string;
  skillIds: string[];

  constructor(object: Partial<DisplaySkill> = {}) {
    this.id = object.id ?? uuidv4();
    this.title = object.title ?? "";
    this.skillIds = object.skillIds ?? [];
  }
}
