import RemoteDataSource from "./RemoteDataSource";
import DisplaySkillRepository from "../domain/DisplaySkillRepository";
import { DisplaySkill, Locale } from "../domain/entities";
import Outcome from "../../service/api/Outcome";

export default class DisplaySkillRepositoryImplementation extends DisplaySkillRepository {
  remoteDataSource: RemoteDataSource;

  constructor(remoteDataSource: RemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async allDisplaySkills(locale: Locale): Promise<Outcome<DisplaySkill[]>> {
    return this.remoteDataSource.allDisplaySkills(locale);
  }
}
