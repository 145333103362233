import { Lesson, Locale, LessonComponent, VocabComponent } from "./entities";
import Outcome from "../../service/api/Outcome";
import LessonComponentRepository from "./LessonComponentRepository";
import LessonRepository from "./LessonRepository";
import { updateObject } from "../../service/helpers";

export class UseCaseGetLessonComponents {
  repository: LessonComponentRepository;
  constructor(repository: LessonComponentRepository) {
    this.repository = repository;
  }

  async execute(locale: Locale): Promise<Outcome<Lesson[]>> {
    return this.repository.allLessonComponents(locale);
  }
}

export class UseCaseCreateLessonComponent {
  repository: LessonComponentRepository;
  lessonRepository: LessonRepository;
  constructor(repository: LessonComponentRepository, lessonRepository: LessonRepository) {
    this.repository = repository;
    this.lessonRepository = lessonRepository;
  }

  async execute(
    lessonComponent: LessonComponent,
    lesson: Lesson,
    lessonComponents: LessonComponent[],
    vocabComponents: VocabComponent[]
  ): Promise<{
    lessonComponent: Promise<Outcome<LessonComponent>>;
    lesson: Lesson;
  }> {
    const allSortedComponents = [...lesson.allSortedComponents(lessonComponents, vocabComponents)];

    if (lessonComponent.title !== "Check-In") lessonComponent.orderIndex = allSortedComponents.length;

    allSortedComponents.splice(lessonComponent.orderIndex, 0, lessonComponent);
    const updatedComponentIds = allSortedComponents
      .filter((c) => c instanceof LessonComponent)
      .map((lc) => (lc as LessonComponent).id);

    const updatedLesson = updateObject(lesson, {
      componentIds: updatedComponentIds,
    });
    return {
      lessonComponent: this.repository.createLessonComponent(lessonComponent),
      lesson: updatedLesson,
    };
  }
}

export class UseCaseCreateMagnet {
  repository: LessonComponentRepository;
  constructor(repository: LessonComponentRepository) {
    this.repository = repository;
  }

  async execute(lessonComponent: LessonComponent): Promise<Outcome<LessonComponent>> {
    return this.repository.createLessonComponent(lessonComponent);
  }
}

export class UseCaseUpdateLessonComponent {
  repository: LessonComponentRepository;
  constructor(repository: LessonComponentRepository) {
    this.repository = repository;
  }

  async execute(lessonComponent: LessonComponent): Promise<Outcome<LessonComponent>> {
    return this.repository.updateLessonComponent(lessonComponent);
  }
}

export class UseCaseDeleteLessonComponent {
  repository: LessonComponentRepository;
  lessonRepository: LessonRepository;
  constructor(repository: LessonComponentRepository, lessonRepository: LessonRepository) {
    this.repository = repository;
    this.lessonRepository = lessonRepository;
  }

  async execute(
    lessonComponent: LessonComponent,
    lesson?: Lesson
  ): Promise<{
    lessonComponent: Promise<Outcome<LessonComponent>>;
    lesson?: Lesson;
  }> {
    const lessonResponse = lesson
      ? {
          lesson: updateObject(lesson, {
            componentIds: lesson.componentIds.filter((id) => id !== lessonComponent.id),
          }),
        }
      : {};
    return {
      lessonComponent: this.repository.deleteLessonComponent(lessonComponent),
      ...lessonResponse,
    };
  }
}

export class UseCaseDeleteMagnet {
  repository: LessonComponentRepository;
  constructor(repository: LessonComponentRepository) {
    this.repository = repository;
  }

  async execute(lessonComponent: LessonComponent): Promise<Outcome<LessonComponent>> {
    return this.repository.deleteLessonComponent(lessonComponent);
  }
}
