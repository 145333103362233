export default function Modal({
  className,
  containerClassName,
  isOpen,
  title,
  subtitle,
  onClose,
  children,
  ...rest
}: {
  className?: string;
  containerClassName?: string;
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  onClose: () => void;
  children?: any;
  [key: string]: any;
}) {
  if (isOpen)
    return (
      <div
        {...rest}
        className={`fixed z-10 inset-0 flex items-center justify-center ${className}`}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className={`bg-white rounded-xl p-10 w-1/2 flex flex-col gap-2 divide-y ${containerClassName} max-h-screen overflow-scroll`}
        >
          {title || subtitle ? (
            <div className="flex flex-col">
              <div className="text-2xl font-bold">{title}</div>
              <div className="text-xs text-gray-400">{subtitle}</div>
            </div>
          ) : null}
          <div className={title || subtitle ? "pt-5" : ""}>{children}</div>
        </div>
      </div>
    );

  return null;
}
