import { Check } from "heroicons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StatusView } from "..";
import { getAllGlobalVideoSections, getGlobalVideoSectionsStatus } from "../../core/redux/globalVideoSectionsSlice";
import { getAllLessonComponents, getLessonComponentsStatus } from "../../core/redux/lessonComponentsSlice";
import { getAllLessons, getLessonsStatus } from "../../core/redux/lessonsSlice";
import { getAllLocales, getCurrentLocale, getLocalesStatus } from "../../core/redux/localesSlice";
import { getAllPackages, getPackagesStatus } from "../../core/redux/packagesSlice";
import { getAllPhrases, getPhrasesStatus } from "../../core/redux/phrasesSlice";
import { StatusType } from "../../core/redux/types";
import { getAllVideos, getVideosStatus } from "../../core/redux/videosSlice";
import { getAllVocabComponents, getVocabComponentsStatus } from "../../core/redux/vocabComponentsSlice";
import { getWorstStatus } from "../../service/helpers";

import Validation, { Validatable, ValidationArgument } from "../../service/Validation";

export default function ValidationIndicator({
  className,
  object,
  validationOverride,
  color = "yellow",
  shade = 400,
  absolute = true,
  ...rest
}: {
  className?: string;
  object?: Validatable;
  validationOverride?: Validation;
  color?: string;
  shade?: number;
  absolute?: boolean;
  [key: string]: any;
}) {
  // -- Redux
  const currentLocale = useSelector(getCurrentLocale);
  const lessonComponents = useSelector(getAllLessonComponents);
  const lessonComponentsStatus = useSelector(getLessonComponentsStatus);
  const vocabComponents = useSelector(getAllVocabComponents);
  const vocabComponentsStatus = useSelector(getVocabComponentsStatus);
  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSectionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const videos = useSelector(getAllVideos);
  const videosStatus = useSelector(getVideosStatus);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const lessons = useSelector(getAllLessons);
  const lessonsStatus = useSelector(getLessonsStatus);
  const packages = useSelector(getAllPackages);
  const packagesStatus = useSelector(getPackagesStatus);
  const locales = useSelector(getAllLocales);
  const localesStatus = useSelector(getLocalesStatus);

  // -- Local state
  const [validation, setValidation] = useState<Validation | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  // -- Effect
  useEffect(() => {
    if (validationOverride) {
      setValidation(validationOverride);
      return;
    }

    if (
      getWorstStatus([
        lessonComponentsStatus,
        lessonsStatus,
        packagesStatus,
        globalVideoSectionsStatus,
        videosStatus,
        phrasesStatus,
        localesStatus,
      ]) === StatusType.succeeded
    ) {
      getWorstStatus([
        lessonComponentsStatus,
        vocabComponentsStatus,
        lessonsStatus,
        packagesStatus,
        globalVideoSectionsStatus,
        videosStatus,
        phrasesStatus,
        localesStatus,
      ]);
      validate();
    }
  }, [lessonComponents, lessons, phrases, videos, locales, globalVideoSections, currentLocale]);
  async function validate() {
    if (object) {
      const args: ValidationArgument = {
        lessonComponents,
        vocabComponents,
        lessons,
        packages,
        phrases,
        videos,
        locales,
        globalVideoSections,
        currentLocale: currentLocale!,
      };
      setValidation(await object.validate(args));
    }
  }

  // -- Components
  const Popup = () => {
    return (
      <div className="absolute bg-white rounded-lg shadow-md p-5 w-64 m-2 border z-10">
        {validation &&
          validation.getUniqueIssues().map((issue, index) => (
            <div key={index} className="text-xs">
              {issue}
            </div>
          ))}
      </div>
    );
  };

  return (
    <div {...rest} className={`${absolute ? "absolute -top-1 -left-1" : ""} ${className}`}>
      <StatusView status={validation ? StatusType.succeeded : StatusType.loading}>
        <div className="bg-black"></div>
        {validation && !validation.isDeepValidated() ? (
          <div className="w-3 h-3" onMouseEnter={() => setShowPopup(true)} onMouseLeave={() => setShowPopup(false)}>
            <div className={`absolute w-full h-full rounded-full bg-${color}-${shade}`} />
            <div className={`absolute w-full h-full rounded-full animate-ping bg-${color}-${shade}`} />
            {showPopup && <Popup />}
          </div>
        ) : (
          <div className="w-3 h-3 p-0.5 rounded-full bg-indigo-600">
            <Check className="w-full h-full text-white" />
          </div>
        )}
      </StatusView>
    </div>
  );
}
