import ReactDOM from "react-dom";
import Amplify, { Auth } from "aws-amplify";

import "./index.css";
import awsConfig from "./service/aws-exports";
import * as logs from "./service/logs";
import { selectUser, deselectUser } from "./core/redux/userSlice";
import store from "./core/redux/store";

import App from "./App";

const start = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

// Check if user is authenticated before starting
(async () => {
  Auth.configure(awsConfig);
  Amplify.configure(awsConfig);
  try {
    await Auth.currentSession();
    const user = await Auth.currentAuthenticatedUser();
    store.dispatch(selectUser(user));
    logs.signedIn(user);
    start();
  } catch {
    store.dispatch(deselectUser());
    logs.notSignedIn();
    start();
  }
})();
