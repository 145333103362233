import RemoteDataSource from "./data/RemoteDataSource";
import LocaleRepositoryImplementation from "./data/LocaleRepositoryImplementation";
import LessonRepositoryImplementation from "./data/LessonRepositoryImplementation";
import LessonComponentRepositoryImplementation from "./data/LessonComponentRepositoryImplementation";
import PhraseRepositoryImplementation from "./data/PhraseRepositoryImplementation";
import { UseCaseGetLocales } from "./domain/LocaleUseCases";
import {
  UseCaseGetLessons,
  UseCaseCreateLesson,
  UseCaseUpdateLesson,
  UseCaseDeleteLesson,
  UseCaseReorderLessons,
  UseCaseReorderComponents,
} from "./domain/LessonUseCases";
import {
  UseCaseGetLessonComponents,
  UseCaseCreateLessonComponent,
  UseCaseUpdateLessonComponent,
  UseCaseDeleteLessonComponent,
  UseCaseCreateMagnet,
  UseCaseDeleteMagnet,
} from "./domain/LessonComponentUseCases";
import {
  UseCaseCreatePhrase,
  UseCaseGetPhrases,
  UseCaseGetAllPhrases,
  UseCaseUpdatePhrase,
  UseCaseDeletePhrase,
} from "./domain/PhraseUseCases";
import { UseCaseDeleteVideo, UseCaseGetVideos, UseCaseUpdateVideo } from "./domain/VideoUseCases";
import VideoRepositoryImplementation from "./data/VideoRepositoryImplementarion";
import SkillRepositoryImplementation from "./data/SkillRepositoryImplementation";
import { UseCaseGetSkills } from "./domain/SkillUseCases";
import GlobalVideoSectionRepositoryImplementation from "./data/GlobalVideoSectionRepositoryImplementation";
import {
  UseCaseCreateGlobalVideoSection,
  UseCaseDeleteGlobalVideoSection,
  UseCaseGetGlobalVideoSections,
  UseCaseUpdateGlobalVideoSection,
} from "./domain/GlobalVideoSectionUseCases";
import DisplaySkillRepositoryImplementation from "./data/DisplaySkillRepositoryImplementation";
import { UseCaseGetDisplaySkills } from "./domain/DisplaySkillUseCases";
import {
  UseCaseCreateVocabComponent,
  UseCaseDeleteVocabComponent,
  UseCaseGetVocabComponents,
  UseCaseUpdateVocabComponent,
} from "./domain/VocabComponentUseCases";
import VocabComponentRepositoryImplementation from "./data/VocabComponentRepositoryImplementation";

// Data source and repository instances
export const remoteDataSource = new RemoteDataSource();
const localeRepositoryImplemetation = new LocaleRepositoryImplementation(remoteDataSource);
const lessonRepositoryImplementation = new LessonRepositoryImplementation(remoteDataSource);
const lessonComponentRepositoryImplemetation = new LessonComponentRepositoryImplementation(remoteDataSource);
const vocabComponentRepositoryImplementation = new VocabComponentRepositoryImplementation(remoteDataSource);
const phraseRepositoryImplementation = new PhraseRepositoryImplementation(remoteDataSource);
const videoRepositoryImplementation = new VideoRepositoryImplementation(remoteDataSource);
const skillRepositoryImplementation = new SkillRepositoryImplementation(remoteDataSource);
const globalVideoSectionRepositoryImplementation = new GlobalVideoSectionRepositoryImplementation(remoteDataSource);
const displaySkillRepositoryImplementation = new DisplaySkillRepositoryImplementation(remoteDataSource);

// Use case instances
export const useCaseGetLocales = new UseCaseGetLocales(localeRepositoryImplemetation);

export const useCaseGetLessons = new UseCaseGetLessons(lessonRepositoryImplementation);
export const useCaseCreateLesson = new UseCaseCreateLesson(lessonRepositoryImplementation);
export const useCaseUpdateLesson = new UseCaseUpdateLesson(lessonRepositoryImplementation);
export const useCaseDeleteLesson = new UseCaseDeleteLesson(lessonRepositoryImplementation);
export const useCaseReorderLessons = new UseCaseReorderLessons(lessonRepositoryImplementation);
export const useCaseReorderComponents = new UseCaseReorderComponents(
  lessonRepositoryImplementation,
  lessonComponentRepositoryImplemetation,
  vocabComponentRepositoryImplementation
);

export const useCaseGetLessonComponents = new UseCaseGetLessonComponents(lessonComponentRepositoryImplemetation);
export const useCaseCreateLessonComponent = new UseCaseCreateLessonComponent(
  lessonComponentRepositoryImplemetation,
  lessonRepositoryImplementation
);
export const useCaseCreateMagnet = new UseCaseCreateMagnet(lessonComponentRepositoryImplemetation);
export const useCaseUpdateLessonComponent = new UseCaseUpdateLessonComponent(lessonComponentRepositoryImplemetation);
export const useCaseDeleteLessonComponent = new UseCaseDeleteLessonComponent(
  lessonComponentRepositoryImplemetation,
  lessonRepositoryImplementation
);
export const useCaseDeleteMagnet = new UseCaseDeleteMagnet(lessonComponentRepositoryImplemetation);

export const useCaseGetVocabComponents = new UseCaseGetVocabComponents(vocabComponentRepositoryImplementation);
export const useCaseCreateVocabComponent = new UseCaseCreateVocabComponent(
  vocabComponentRepositoryImplementation,
  lessonRepositoryImplementation
);
export const useCaseUpdateVocabComponent = new UseCaseUpdateVocabComponent(vocabComponentRepositoryImplementation);
export const useCaseDeleteVocabComponent = new UseCaseDeleteVocabComponent(
  vocabComponentRepositoryImplementation,
  lessonRepositoryImplementation
);

export const useCaseGetPhrases = new UseCaseGetPhrases(phraseRepositoryImplementation);
export const useCaseGetAllPhrases = new UseCaseGetAllPhrases(phraseRepositoryImplementation);
export const useCaseCreatePhrase = new UseCaseCreatePhrase(phraseRepositoryImplementation);
export const useCaseUpdatePhrase = new UseCaseUpdatePhrase(phraseRepositoryImplementation);
export const useCaseDeletePhrase = new UseCaseDeletePhrase(phraseRepositoryImplementation);

export const useCaseGetVideos = new UseCaseGetVideos(videoRepositoryImplementation);
export const useCaseUpdateVideo = new UseCaseUpdateVideo(videoRepositoryImplementation);
export const useCaseDeleteVideo = new UseCaseDeleteVideo(videoRepositoryImplementation);

export const useCaseGetSkills = new UseCaseGetSkills(skillRepositoryImplementation);

export const useCaseGetDisplaySkills = new UseCaseGetDisplaySkills(displaySkillRepositoryImplementation);

export const useCaseGetGlobalVideoSections = new UseCaseGetGlobalVideoSections(
  globalVideoSectionRepositoryImplementation
);
export const useCaseCreateGlobalVideoSection = new UseCaseCreateGlobalVideoSection(
  globalVideoSectionRepositoryImplementation
);
export const useCaseUpdateGlobalVideoSection = new UseCaseUpdateGlobalVideoSection(
  globalVideoSectionRepositoryImplementation
);
export const useCaseDeleteGlobalVideoSection = new UseCaseDeleteGlobalVideoSection(
  globalVideoSectionRepositoryImplementation
);
