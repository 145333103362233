import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

import componentReducer from "./componentSlice";
import deepLinksReducer from "./deepLinksSlice";
import displaySkillsReducer from "./displaySkillSlice";
import globalVideoSectionsReducer from "./globalVideoSectionsSlice";
import lessonComponentsReducer from "./lessonComponentsSlice";
import lessonsReducer from "./lessonsSlice";
import localesReducer from "./localesSlice";
import modalReducer from "./modalSlice";
import packagesReducer from "./packagesSlice";
import phrasesReducer from "./phrasesSlice";
import skillsReducer from "./skillsSlice";
import userResultsReducer from "./userResultsSlice";
import userReducer from "./userSlice";
import videosReducer from "./videosSlice";
import vocabComponentsReducer from "./vocabComponentsSlice";
import sectionsReducer from "./sectionsSlice";

const store = configureStore({
  reducer: {
    locales: localesReducer,
    lessons: lessonsReducer,
    lessonComponents: lessonComponentsReducer,
    users: userReducer,
    phrases: phrasesReducer,
    modal: modalReducer,
    videos: videosReducer,
    skills: skillsReducer,
    displaySkills: displaySkillsReducer,
    globalVideoSections: globalVideoSectionsReducer,
    userResults: userResultsReducer,
    deepLinks: deepLinksReducer,
    component: componentReducer,
    vocabComponents: vocabComponentsReducer,
    packages: packagesReducer,
    sections: sectionsReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    logger,
  ],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
