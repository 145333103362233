import RemoteDataSource from "./RemoteDataSource";
import LessonRepository from "../domain/LessonRepository";
import { Lesson, Locale } from "../domain/entities";
import Outcome from "../../service/api/Outcome";

export default class LessonRepositoryImplementation extends LessonRepository {
  remoteDataSource: RemoteDataSource;

  constructor(remoteDataSource: RemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async allLessons(locale: Locale): Promise<Outcome<Lesson[]>> {
    return this.remoteDataSource.allLessons(locale);
  }

  async createLesson(lesson: Lesson): Promise<Outcome<Lesson>> {
    return this.remoteDataSource.createLesson(lesson);
  }

  async updateLesson(lesson: Lesson): Promise<Outcome<Lesson>> {
    return this.remoteDataSource.updateLesson(lesson);
  }

  async deleteLesson(lesson: Lesson): Promise<Outcome<Lesson>> {
    return this.remoteDataSource.deleteLesson(lesson);
  }
}
