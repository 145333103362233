import { VideoClip, Video, Phrase } from ".";
import { Change, Difference } from "../../../service/Change";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
  validateNumbers,
} from "../../../service/Validation";
import { DisplayItemType } from "./types";

export default class DisplayItem {
  type: DisplayItemType;
  primaryText?: string | null;
  secondaryText?: string | null;
  primaryDisplayTime?: number | null;
  secondaryDisplayTime?: number | null;
  primaryEndTime?: number | null;
  secondaryEndTime?: number | null;

  constructor(object: Partial<DisplayItem> = {}) {
    this.type = object.type ?? DisplayItemType.PROMPT;
    this.primaryText = object.primaryText;
    this.secondaryText = object.secondaryText;
    this.primaryDisplayTime = object.primaryDisplayTime;
    this.secondaryDisplayTime = object.secondaryDisplayTime;
    this.primaryEndTime = object.primaryEndTime;
    this.secondaryEndTime = object.secondaryEndTime;
  }

  fixNumberStrings() {
    this.primaryDisplayTime = this.primaryDisplayTime
      ? parseFloat(this.primaryDisplayTime as any as string)
      : undefined;
    this.secondaryDisplayTime = this.secondaryDisplayTime
      ? parseFloat(this.secondaryDisplayTime as any as string)
      : undefined;
    this.primaryEndTime = this.primaryEndTime ? parseFloat(this.primaryEndTime as any as string) : undefined;
    this.secondaryEndTime = this.secondaryEndTime ? parseFloat(this.secondaryEndTime as any as string) : undefined;
  }

  validate(videoClip: VideoClip, video?: Video) {
    const validation = new Validation({
      type: ValidationType.DisplayItem,
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (this.primaryDisplayTime && this.primaryDisplayTime < 0)
      validation.issues.push(
        new ValidationIssue(
          "Field primaryDisplayTime cannot be negative",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    if (this.primaryEndTime && this.primaryEndTime < 0)
      validation.issues.push(
        new ValidationIssue(
          "Field primaryEndTime cannot be negative",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    if (this.secondaryDisplayTime && this.secondaryDisplayTime < 0)
      validation.issues.push(
        new ValidationIssue(
          "Field secondaryDisplayTime cannot be negative",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    if (this.secondaryEndTime && this.secondaryEndTime < 0)
      validation.issues.push(
        new ValidationIssue(
          "Field secondaryEndTime cannot be negative",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (
      videoClip.startInteractionTime &&
      [this.primaryDisplayTime, this.primaryEndTime, this.secondaryDisplayTime, this.secondaryEndTime].some(
        (time) => (time ?? 0) > videoClip.startInteractionTime!
      )
    )
      validation.issues.push(
        new ValidationIssue(
          "Display times cannot be after the startInteractionTime",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    else if (
      video &&
      [this.primaryDisplayTime, this.primaryEndTime, this.secondaryDisplayTime, this.secondaryEndTime].some(
        (time) => (time ?? 0) > video.duration
      )
    )
      validation.issues.push(
        new ValidationIssue(
          "Display times cannot be after the video duration",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    const numberFields = {
      primaryDisplayTime: this.primaryDisplayTime,
      primaryEndTime: this.primaryEndTime,
      secondaryDisplayTime: this.secondaryDisplayTime,
      secondaryEndTime: this.secondaryEndTime,
    };
    validation.issues.push(...validateNumbers(numberFields));

    return validation;
  }

  getIdentifier() {
    return `${this.primaryText}/${this.secondaryText}`;
  }
  getTypeName() {
    return "DisplayItem";
  }

  copy() {
    return new DisplayItem(this);
  }

  setFromPhrase(phrase: Phrase, isResponse: boolean) {
    this.primaryText = phrase.targetText;
    this.secondaryText = phrase.nativeText;
    this.primaryDisplayTime = isResponse ? 0.5 : undefined;
    this.secondaryDisplayTime = 0.5;
  }
}
