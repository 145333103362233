import AdmZip from "adm-zip";

export function zip(data: any, name: string) {
  var zip = new AdmZip();
  zip.addFile(name, Buffer.from(data, "utf8"));
  return zip.toBuffer();
}

export function unzip(data: any) {
  var zip = new AdmZip(data);
  return zip.getEntries()[0].getData().toString("utf8");
}
