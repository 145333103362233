export default function SelectMenu({
  className,
  selectClassName,
  title,
  value,
  onChange,
  set,
  sideComponent,
  children,
  ...rest
}: {
  className?: string;
  selectClassName?: string;
  title?: string;
  value: any;
  onChange?: (e: any) => void;
  set?: (e: any) => void;
  sideComponent?: any;
  children: any;
  [key: string]: any;
}) {
  return (
    <div className={`flex flex-col gap-1 text-xs font-medium ${className}`}>
      {title}
      <div className="flex gap-1 flex-1">
        <select
          {...rest}
          className={`flex-1 bg-white h-9 px-3 border rounded-md shadow-sm text-xs ${selectClassName}`}
          onChange={(e) => {
            if (onChange) onChange(e);
            if (set) set(e.target.value);
          }}
          value={value}
        >
          {children}
        </select>
        {sideComponent ? sideComponent : null}
      </div>
    </div>
  );
}
