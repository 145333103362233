import RemoteDataSource from "./RemoteDataSource";
import LocaleRepository from "../domain/LocaleRepository";
import { Locale } from "../domain/entities";
import Outcome from "../../service/api/Outcome";

export default class LocaleRepositoryImplementation extends LocaleRepository {
  remoteDataSource: RemoteDataSource;

  constructor(remoteDataSource: RemoteDataSource) {
    super();
    this.remoteDataSource = remoteDataSource;
  }

  async allLocales(): Promise<Outcome<Locale[]>> {
    return this.remoteDataSource.allLocales();
  }
}
