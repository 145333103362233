import Papa from "papaparse";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Package } from "../../core/domain/entities";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import {
  createPackage,
  deletePackage,
  getAllPackages,
  getPackagesError,
  getPackagesStatus,
  updatePackage,
} from "../../core/redux/packagesSlice";
import CSVService from "../../service/CSVService";
import { getError, getWorstStatus } from "../../service/helpers";
import StatusView from "../base/StatusView";
import { FileInput, LoadingIndicator, Modal, ModalFormButtons } from "../index";

export default function ImportPackageCSVModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  // -- Redux
  const dispatch = useDispatch();
  const currentLocale = useSelector(getCurrentLocale);
  const packages = useSelector(getAllPackages);
  const packagesStatus = useSelector(getPackagesStatus);
  const packagesError = useSelector(getPackagesError);

  // -- Local state
  const [file, setFile] = useState<any>();
  const [isProcessing, setIsProcessing] = useState(false);

  // -- Functions
  const onSubmit = async () => {
    if (!file) return onClose();

    setIsProcessing(true);

    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (!target || !target.result) return;
      const data: any = Papa.parse(target.result as any, { header: true }).data;

      const allErrors = [];
      const allWarnings = [];
      const updated = [];

      const allPackages = [...packages];
      for (const entry of data) {
        if (Object.values(entry).every((val) => val == "")) continue;
        const {
          package: pkg,
          errors,
          warnings,
          isUpdated,
          isCreated,
          isDeleted,
        } = Package.fromCSVEntry(entry, currentLocale!.id, allPackages);
        allErrors.push(...errors);
        allWarnings.push(...warnings);

        if (isUpdated || isCreated || isDeleted) updated.push({ package: pkg, isUpdated, isCreated, isDeleted });
        if (isCreated) {
          allPackages.push(pkg);
        } else if (isUpdated) {
          allPackages.splice(
            allPackages.findIndex((p) => p.id === pkg.id),
            1,
            pkg
          );
        } else if (isDeleted) {
          allPackages.splice(
            allPackages.findIndex((p) => p.id === pkg.id),
            1
          );
        }
      }

      console.log("Errors", allErrors);
      console.log("Warnings", allWarnings);
      CSVService.instance.downloadCSV(
        [
          ...allErrors.map((error) => {
            return {
              Error: error,
            };
          }),
          ...allWarnings.map((warning) => {
            return {
              Warning: warning,
            };
          }),
        ],
        `CSV Package Errors ${new Date()}.csv`
      );

      setIsProcessing(false);
      onClose();

      if (allErrors.length > 0)
        return alert("There were errors that prevented the package import. Check the downloaded file the details");

      if (
        allWarnings.length > 0 &&
        !window.confirm(
          "There were warnings that prevented the package import. The packages can still be created/updated but there are some things to look at. Click OK if you still want to continue with the creating/updating"
        )
      )
        return;

      for (const { package: pkg, isUpdated, isCreated, isDeleted } of updated) {
        if (isUpdated) {
          console.log("Update", pkg);
          dispatch(updatePackage(pkg));
        } else if (isCreated) {
          console.log("Create", pkg);
          dispatch(createPackage(pkg));
        } else if (isDeleted) {
          console.log("Delete", pkg);
          dispatch(deletePackage(pkg));
        }
      }
    };
    reader.readAsText(file);
  };

  const onFileChange = (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
  };

  return (
    <Modal isOpen={isOpen} title="Import Package CSV" onClose={onClose}>
      <StatusView status={getWorstStatus([packagesStatus])} error={getError([packagesError])}>
        <div className="flex flex-col gap-5 ">
          <FileInput title="CSV File" onChange={(e) => onFileChange(e)} accept=".csv" />
          {isProcessing ? <LoadingIndicator className="self-center" /> : null}
          <ModalFormButtons onSubmit={onSubmit} onClose={onClose} />
        </div>
      </StatusView>
    </Modal>
  );
}
