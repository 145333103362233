import { DisplaySkill, Locale } from "./entities";
import Outcome from "../../service/api/Outcome";
import DisplaySkillRepository from "./DisplaySkillRepository";

export class UseCaseGetDisplaySkills {
  repository: DisplaySkillRepository;
  constructor(repository: DisplaySkillRepository) {
    this.repository = repository;
  }

  async execute(locale: Locale): Promise<Outcome<DisplaySkill[]>> {
    return this.repository.allDisplaySkills(locale);
  }
}
