import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { StatusType } from "./types";
import { Locale, Skill } from "../domain/entities";
import { useCaseGetSkills } from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { RootState } from "./store";

export const fetchSkills = createAsyncThunk(`skills/fetch`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.skills.items.length > 0 && state.skills.items[0].localeId !== locale.id;
  if (state.skills.items.length > 0 && (!localeDidChange || state.skills.status !== StatusType.loading))
    return state.skills.items;

  const result = await useCaseGetSkills.execute(locale);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

const initialState = {
  items: [] as Skill[],
  status: StatusType.idle,
  error: null as string | undefined | null,
};

export const skillsSlice = createSlice({
  name: "skills",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSkills.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchSkills.fulfilled, (state, { payload: skills }: { payload: Skill[] }) => {
      state.status = StatusType.succeeded;
      state.items = skills;
    });
    builder.addCase(fetchSkills.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
  },
});

export const getAllSkills = (state: RootState) => state.skills.items;
export const getSkillsStatus = (state: RootState) => state.skills.status;
export const getSkillsError = (state: RootState) => state.skills.error;

export default skillsSlice.reducer;
