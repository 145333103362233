import {
  GlobalVideoSection,
  LessonComponent,
  Video,
  Phrase,
  Lesson,
  Locale,
  UserResult,
  VocabComponent,
} from "../../core/domain/entities";

export type ScriptArg = {
  lessonComponents: LessonComponent[];
  prodLessonComponents: LessonComponent[];
  vocabComponents: VocabComponent[];
  prodVocabComponents: VocabComponent[];
  globalVideoSections: GlobalVideoSection[];
  videos: Video[];
  phrases: Phrase[];
  allPhrases: Phrase[];
  lessons: Lesson[];
  locale: Locale;
  user: any;
  userResults: UserResult[];
  prodUserResults: UserResult[];
  dispatch: any;
};

export default class Script {
  title: string;
  execute: (arg: ScriptArg) => void;

  constructor(title: string, execute: (arg: ScriptArg) => void) {
    this.title = title;
    this.execute = execute;
  }
}
