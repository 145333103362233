import { v4 as uuidv4 } from "uuid";
import { spaceStripped, stripSpecialCharacters } from "../../../service/helpers";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";

export default class AcceptedText {
  uuid: string;
  text: string;

  constructor(object: Partial<AcceptedText> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.text = object.text ?? "";
  }

  validate() {
    const validation = new Validation({
      type: ValidationType.AcceptedText,
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (!(this.text === spaceStripped(stripSpecialCharacters(this.text))))
      validation.issues.push(
        new ValidationIssue(
          "Field text must not have special characters or leading trailing, or double spaces",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (!(this.text === this.text.toLowerCase()))
      validation.issues.push(
        new ValidationIssue(
          "Field text must not have capitals",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    return validation;
  }
  getIdentifier() {
    return `${this.text} - ${this.uuid}`;
  }
  getTypeName() {
    return "AcceptedText";
  }

  equals(acceptedText: AcceptedText) {
    return acceptedText.uuid === this.uuid && acceptedText.text === this.text;
  }
}
