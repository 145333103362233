import aws from "aws-sdk";
import { environments } from "./constants";

const s3 = new aws.S3({
  region: "us-east-2",
  signatureVersion: "v4",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

export async function uploadFile(data, bucket, key, contentType) {
  const fileParams = {
    Bucket: bucket,
    Key: key,
    ContentType: contentType,
    Body: data,
  };
  const s3Result = await s3.upload(fileParams).promise();
  return s3Result;
}

export async function copyS3(path, fromBucket, toBucket) {
  if (path[0] === "/") path = path.slice(1);
  if (path === "") return;
  const files = [];
  const params = {
    Bucket: fromBucket,
    Prefix: path,
  };
  let nextKey = "1";
  while (nextKey) {
    const items = await s3.listObjectsV2(params).promise();
    files.push(...items.Contents);
    nextKey = items.NextContinuationToken;
    params.ContinuationToken = nextKey;
  }
  const newFiles = {};
  const newParams = {
    Bucket: toBucket,
    Prefix: path,
  };
  nextKey = "1";
  while (nextKey) {
    const items = await s3.listObjectsV2(newParams).promise();
    for (const file of items.Contents) {
      newFiles[file.Key] = new Date(file.LastModified).getTime();
    }
    nextKey = items.NextContinuationToken;
    newParams.ContinuationToken = nextKey;
  }
  const promises = [];
  for (const file of files) {
    if (newFiles[file.Key] && newFiles[file.Key] >= new Date(file.LastModified).getTime()) continue;
    const copyParams = {
      Bucket: toBucket,
      CopySource: `${fromBucket}/${file.Key}`,
      Key: file.Key,
    };
    promises.push(s3.copyObject(copyParams).promise());
  }
  const promiseResults = await Promise.allSettled(promises);
  for (const promise of promiseResults) {
    if (promise.status === "rejected") console.log(promise.reason);
  }
  return;
}

export async function getFile(path, bucket) {
  if (path[0] === "/") path = path.slice(1);
  if (path === "") return;
  const params = {
    Bucket: bucket,
    Key: path,
  };
  const file = await s3.getObject(params).promise();
  return file;
}

export async function getFiles(path, bucket) {
  if (path[0] === "/") path = path.slice(1);
  if (path === "") return;
  const params = {
    Bucket: bucket,
    Prefix: path,
  };
  const files = [];
  let nextKey = "1";
  while (nextKey) {
    const items = await s3.listObjectsV2(params).promise();
    files.push(...items.Contents);
    nextKey = items.NextContinuationToken;
    params.ContinuationToken = nextKey;
  }
  return files;
}

export function hasFile(files, url, env = "staging") {
  if (!url) return false;
  const origin = new URL(url).origin;
  if (origin !== environments.staging.cloudfrontUrl && origin !== environments.prod.cloudfrontUrl) return false;
  const path = new URL(url).pathname.slice(1);
  return files.has(standardS3Path(path));

  function standardS3Path(path) {
    return path
      .replaceAll("%20", " ")
      .replaceAll("%28", "(")
      .replaceAll("%29", ")")
      .replaceAll("%21", "!")
      .replaceAll("+", " ");
  }
}

export async function deleteFile(key, bucket) {
  const params = {
    Bucket: bucket,
    Key: key,
  };
  await s3.deleteObject(params).promise();
}

export function getNextSuffix(url) {
  const currentSuffix = url ? url.split("_").pop().split(".")[0] : undefined;
  const nextSuffix = isNaN(parseInt(currentSuffix)) ? 0 : parseInt(currentSuffix) + 1;
  return "_" + nextSuffix;
}

export async function copy(fromKey, toKey, bucket) {
  const params = {
    Bucket: bucket,
    CopySource: `${bucket}/${fromKey}`,
    Key: toKey,
  };
  await s3.copyObject(params).promise();
}
