import Button from "../base/Button";

export default function IsDojoButton({
  className,
  isDojo,
  onToggle,
  ...rest
}: {
  className?: string;
  isDojo: boolean;
  onToggle: (value: boolean) => void;
  [key: string]: any;
}) {
  if (isDojo) {
    return (
      <Button {...rest} className={`w-9 font-bold ${className}`} onClick={() => onToggle(false)} shade={100}>
        D
      </Button>
    );
  } else {
    return <Button {...rest} className={`w-9 ${className}`} onClick={() => onToggle(true)} shade={100}></Button>;
  }
}
