import { Formik, Form, FormikProps } from "formik";
import { Plus, XCircle } from "heroicons-react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Contingency, TargetPhraseGroup, Objective } from "../../core/domain/entities";
import { SectionType } from "../../core/domain/entities/types";
import { getComponent, updateCurrentComponent } from "../../core/redux/componentSlice";

import { Modal, Button, ModalFormButtons, SelectMenu } from "../index";
import { useState } from "react";

export type ContingenciesModalProps = {
  objective: Objective;
  targetPhraseGroup: TargetPhraseGroup;
};

export type ContingenciesFormValues = {
  objective: Objective;
};

export default function ContingenciesModal({
  isOpen,
  objective,
  targetPhraseGroup,
  onClose,
}: {
  isOpen: boolean;
  objective: Objective;
  targetPhraseGroup: TargetPhraseGroup;
  onClose: () => void;
}) {
  // -- Refs
  const formRef = useRef<FormikProps<ContingenciesFormValues>>(null);

  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);

  // -- Local state
  const [removedContingencies, setRemovedContingencies] = useState([]);

  // Constants
  const section = lessonComponent!.getSectionForTargetPhraseGroup(targetPhraseGroup.uuid);
  const availableSections = lessonComponent!.videoClips.filter(
    (vc) => Object.keys(SectionType).includes(vc.type as string) && section && vc.uuid !== section.uuid
  );

  // -- Effect
  // Reset variables on entry
  useEffect(() => {
    setRemovedContingencies([]);
  }, []);

  // -- Functions
  const onSubmit = ({ objective: formObjective }: { objective: Objective }) => {
    const formTargetPhraseGroup = formObjective.targetPhraseGroups.find((tfg) => tfg.uuid === targetPhraseGroup.uuid);
    if (!formTargetPhraseGroup) return;
    lessonComponent!.updateObjective(formObjective);
    lessonComponent!.updateContingencies(
      formTargetPhraseGroup.uuid,
      targetPhraseGroup.contingencies.map((c) => objective.getAllVideoClipIdsForContingency(c.uuid)),
      formTargetPhraseGroup.contingencies,
      removedContingencies
    );
    dispatch(updateCurrentComponent(lessonComponent!));
    onClose();
  };

  const handleAddContingency = () => {
    if (!formRef.current) return;
    const { values, setFieldValue } = formRef.current;

    const clone = _.cloneDeep(values.objective);
    clone.addContingencyToTargetPhraseGroup(
      targetPhraseGroup.uuid,
      availableSections[0].uuid,
      availableSections[1].uuid
    );
    setFieldValue("objective", clone);
  };

  const handleRemoveContingency = (uuid: string) => {
    if (!formRef.current) return;
    const { values, setFieldValue } = formRef.current;

    const clone = _.cloneDeep(values.objective);
    clone.removeContingency(uuid);
    setFieldValue("objective", clone);
  };

  // -- Components

  const ContingnecyInput = ({
    targetPhraseGroupIndex,
    contingnecyIndex,
    formProps,
  }: {
    targetPhraseGroupIndex: number;
    contingnecyIndex: number;
    formProps: FormikProps<ContingenciesFormValues>;
  }) => {
    const { values, setFieldValue } = formProps;

    const setPlaceholderId = (value: string) => {
      setFieldValue(
        `objective.targetPhraseGroups[${targetPhraseGroupIndex}].contingencies[${contingnecyIndex}].placeholderId`,
        value
      );
    };

    const setMatchDestinationSuccessId = (value: string, uuid: string) => {
      const index = values.objective.matchDestinations.findIndex((matchDestination) => matchDestination.uuid === uuid);
      setFieldValue(`objective.matchDestinations[${index}].successVideoClipId`, value);
    };

    const addMatchDestination = (contingency: Contingency) => {
      const clone = _.cloneDeep(values.objective);
      clone.addMatchDestinationToContingency(
        contingency.uuid,
        availableSections.filter((s) => contingency.placeholderId !== s.uuid)[0].uuid
      );
      setFieldValue("objective", clone);
    };

    const removeMatchDestination = (uuid: string, matchDestinationId: string) => {
      const clone = _.cloneDeep(values.objective);
      clone.removeMatchDestinationFromContingency(uuid, matchDestinationId);
      setFieldValue("objective", clone);
    };

    const contingency = values.objective.targetPhraseGroups[targetPhraseGroupIndex].contingencies[contingnecyIndex];

    return (
      <div className="flex gap-2">
        <SelectMenu
          className="flex-1"
          title="Placeholder"
          value={contingency.placeholderId}
          set={setPlaceholderId}
          sideComponent={
            <Button shade={100} color="red" onClick={() => handleRemoveContingency(contingency.uuid)}>
              <XCircle size={16} />
            </Button>
          }
        >
          {availableSections.map((s) => (
            <option key={s.uuid} value={s.uuid}>
              {s.sectionInfo.title}
            </option>
          ))}
        </SelectMenu>
        <div className="flex-1 flex flex-col gap-2">
          {contingency.matchDestinationIds.map((uuid, index) => {
            const matchDestination = values.objective.getMatchDestination(uuid);
            if (!matchDestination) return null;
            return (
              <SelectMenu
                key={uuid}
                title={index === 0 ? "Success Sections" : ""}
                value={matchDestination.successVideoClipId}
                set={(value) => setMatchDestinationSuccessId(value, matchDestination.uuid)}
                sideComponent={
                  contingency.matchDestinationIds.length > 1 ? (
                    <Button
                      color="red"
                      shade={100}
                      onClick={() => removeMatchDestination(contingency.uuid, matchDestination.uuid)}
                    >
                      <XCircle size={16} />
                    </Button>
                  ) : null
                }
              >
                {availableSections
                  .filter((s) => contingency.placeholderId !== s.uuid)
                  .map((s, index) => (
                    <option key={index} value={s.uuid}>
                      {s.sectionInfo.title}
                    </option>
                  ))}
              </SelectMenu>
            );
          })}
          <Button shade={100} onClick={() => addMatchDestination(contingency)}>
            <Plus size={16} />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} title="Edit Contingencies" onClose={onClose}>
      <Formik
        innerRef={formRef}
        initialValues={{
          objective: objective,
        }}
        validate={(values) => {
          const errors: { type?: string; text?: string; isExclusive?: string } = {};
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {(formProps) => {
          const targetPhraseGroupIndex = formProps.values.objective.getTargetPhraseGroupIndex(targetPhraseGroup.uuid);
          const formTargetPhraseGroup = formProps.values.objective.targetPhraseGroups[targetPhraseGroupIndex];
          if (!formTargetPhraseGroup) return;
          const contingencies = formTargetPhraseGroup.contingencies;

          return (
            <Form className="flex flex-col gap-2">
              {contingencies.map((contingency, index) => (
                <ContingnecyInput
                  key={contingency.uuid}
                  targetPhraseGroupIndex={targetPhraseGroupIndex}
                  contingnecyIndex={index}
                  formProps={formProps}
                />
              ))}
              <Button shade={100} className="self-start" onClick={handleAddContingency}>
                <Plus size={16} />
              </Button>
              <div className="h-5 col-span-full" />
              <ModalFormButtons className="col-span-full" submitText="Done" onClose={onClose} />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
