import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchGlobalVideoSections,
  fetchProdGlobalVideoSections,
  getAllGlobalVideoSections,
  getAllProdGlobalVideoSections,
  getGlobalVideoSectionsError,
  getGlobalVideoSectionsStatus,
  getProdGlobalVideoSectionsError,
  getProdGlobalVideoSectionsStatus,
} from "../core/redux/globalVideoSectionsSlice";

import {
  fetchLessonComponents,
  fetchProdLessonComponents,
  getAllLessonComponents,
  getAllProdLessonComponents,
  getLessonComponentsError,
  getLessonComponentsStatus,
  getProdLessonComponentsError,
  getProdLessonComponentsStatus,
} from "../core/redux/lessonComponentsSlice";
import {
  fetchProdLocales,
  getAllLocales,
  getAllProdLocales,
  getCurrentLocale,
  getLocalesError,
  getLocalesStatus,
  getProdLocalesError,
  getProdLocalesStatus,
} from "../core/redux/localesSlice";
import {
  fetchPhrases,
  fetchProdPhrases,
  getAllPhrases,
  getAllProdPhrases,
  getPhrasesError,
  getPhrasesStatus,
  getProdPhrasesError,
  getProdPhrasesStatus,
} from "../core/redux/phrasesSlice";
import {
  fetchProdVideos,
  fetchVideos,
  getAllProdVideos,
  getAllVideos,
  getProdVideosError,
  getProdVideosStatus,
  getVideosError,
  getVideosStatus,
} from "../core/redux/videosSlice";
import { getError, getWorstStatus } from "../service/helpers";

import { Auth } from "aws-amplify";
import axios from "axios";
import { CloudUpload, Document, DocumentSearch, Server, ShieldCheck } from "heroicons-react";
import { Button, CheckBoxInput, LoadingIndicator, StatusView } from "../components";
import { remoteDataSource } from "../core";
import {
  fetchLessons,
  fetchProdLessons,
  getAllLessons,
  getAllProdLessons,
  getLessonsError,
  getLessonsStatus,
  getProdLessonsError,
  getProdLessonsStatus,
} from "../core/redux/lessonsSlice";
import {
  fetchPackages,
  fetchProdPackages,
  getAllPackages,
  getAllProdPackages,
  getPackagesError,
  getPackagesStatus,
  getProdPackagesError,
  getProdPackagesStatus,
} from "../core/redux/packagesSlice";
import {
  fetchProdVocabComponents,
  fetchVocabComponents,
  getAllProdVocabComponents,
  getAllVocabComponents,
  getProdVocabComponentsError,
  getProdVocabComponentsStatus,
  getVocabComponentsError,
  getVocabComponentsStatus,
} from "../core/redux/vocabComponentsSlice";
import { environments } from "../service/constants";
import CSVService from "../service/CSVService";
import { compareEnvironments, pushToEnvironment, validateLocale } from "../service/PushService";
import { getFiles } from "../service/StorageSerivce";
import { ValidationArgument, ValidationIssueGravity } from "../service/Validation";
import {
  fetchProdSections,
  getAllProdSections,
  getAllSections,
  getProdSectionsError,
  getProdSectionsStatus,
  getSectionsError,
  getSectionsStatus,
} from "../core/redux/sectionsSlice";

export default function PushPage() {
  // -- Redux
  const dispatch = useDispatch();

  const currentLocale = useSelector(getCurrentLocale);
  const lessonComponents = useSelector(getAllLessonComponents);
  const lessonComponentsStatus = useSelector(getLessonComponentsStatus);
  const lessonComponentsError = useSelector(getLessonComponentsError);
  const prodLessonComponents = useSelector(getAllProdLessonComponents);
  const prodLessonComponentsStatus = useSelector(getProdLessonComponentsStatus);
  const prodLessonComponentsError = useSelector(getProdLessonComponentsError);
  const vocabComponents = useSelector(getAllVocabComponents);
  const vocabComponentsStatus = useSelector(getVocabComponentsStatus);
  const vocabComponentsError = useSelector(getVocabComponentsError);
  const prodVocabComponents = useSelector(getAllProdVocabComponents);
  const prodVocabComponentsStatus = useSelector(getProdVocabComponentsStatus);
  const prodVocabComponentsError = useSelector(getProdVocabComponentsError);
  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSectionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const globalVideoSectionsError = useSelector(getGlobalVideoSectionsError);
  const prodGlobalVideoSections = useSelector(getAllProdGlobalVideoSections);
  const prodGlobalVideoSectionsStatus = useSelector(getProdGlobalVideoSectionsStatus);
  const prodGlobalVideoSectionsError = useSelector(getProdGlobalVideoSectionsError);
  const videos = useSelector(getAllVideos);
  const videosStatus = useSelector(getVideosStatus);
  const videosError = useSelector(getVideosError);
  const prodVideos = useSelector(getAllProdVideos);
  const prodVideosStatus = useSelector(getProdVideosStatus);
  const prodVideosError = useSelector(getProdVideosError);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);
  const prodPhrases = useSelector(getAllProdPhrases);
  const prodPhrasesStatus = useSelector(getProdPhrasesStatus);
  const prodPhrasesError = useSelector(getProdPhrasesError);
  const lessons = useSelector(getAllLessons);
  const lessonsStatus = useSelector(getLessonsStatus);
  const lessonsError = useSelector(getLessonsError);
  const prodLessons = useSelector(getAllProdLessons);
  const prodLessonsStatus = useSelector(getProdLessonsStatus);
  const prodLessonsError = useSelector(getProdLessonsError);
  const sections = useSelector(getAllSections);
  const sectionsStatus = useSelector(getSectionsStatus);
  const sectionsError = useSelector(getSectionsError);
  const prodSections = useSelector(getAllProdSections);
  const prodSectionsStatus = useSelector(getProdSectionsStatus);
  const prodSectionsError = useSelector(getProdSectionsError);
  const packages = useSelector(getAllPackages);
  const packagesStatus = useSelector(getPackagesStatus);
  const packagesError = useSelector(getPackagesError);
  const prodPackages = useSelector(getAllProdPackages);
  const prodPackagesStatus = useSelector(getProdPackagesStatus);
  const prodPackagesError = useSelector(getProdPackagesError);
  const locales = useSelector(getAllLocales);
  const localesStatus = useSelector(getLocalesStatus);
  const localesError = useSelector(getLocalesError);
  const prodLocales = useSelector(getAllProdLocales);
  const prodLocalesStatus = useSelector(getProdLocalesStatus);
  const prodLocalesError = useSelector(getProdLocalesError);

  // -- Local state
  const [isPushing, setIsPushing] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isUploadingZips, setIsUploadingZips] = useState(false);
  const [isRefreshingCacheServer, setIsRefreshingCacheServer] = useState(false);
  const [isRefreshingProdCacheServer, setIsRefreshingProdCacheServer] = useState(false);
  const [isComparingProdZip, setIsComparingProdZip] = useState(false);
  const [downloadReport, setDownloadReport] = useState(false);
  // const [validateUrls, setValidateUrls] = useState(false);
  const [downloadChanges, setDownloadChanges] = useState(false);
  // const [pushValidateUrls, setPushValidateUrls] = useState(true);

  // -- Effect
  // Fetch all
  useEffect(() => {
    if (currentLocale) {
      dispatch(fetchProdLocales());
      dispatch(fetchLessonComponents(currentLocale));
      dispatch(fetchGlobalVideoSections(currentLocale));
      dispatch(fetchVocabComponents(currentLocale));
      dispatch(fetchVideos(currentLocale));
      dispatch(fetchPhrases(currentLocale));
      dispatch(fetchLessons(currentLocale));
      dispatch(fetchProdSections(currentLocale));
      dispatch(fetchPackages(currentLocale));
      dispatch(fetchProdLessonComponents(currentLocale));
      dispatch(fetchProdVocabComponents(currentLocale));
      dispatch(fetchProdGlobalVideoSections(currentLocale));
      dispatch(fetchProdVideos(currentLocale));
      dispatch(fetchProdPhrases(currentLocale));
      dispatch(fetchProdLessons(currentLocale));
      dispatch(fetchProdSections(currentLocale));
      dispatch(fetchProdPackages(currentLocale));
    }
  }, [dispatch, currentLocale]);

  // -- Functions
  async function validate(env: "staging" | "prod" = "staging") {
    setIsValidating(true);

    const environment = environments[env];
    if (!environment) {
      console.log("Invalid environment");
      return;
    }
    const bucket = environment.bucket;
    if (!bucket) {
      console.log("No bucket found for environment: ", env);
      return;
    }

    const filesArray = [
      ...((await getFiles(`/images`, bucket)) ?? []),
      ...((await getFiles(`/audio/${currentLocale!.formattedCode}`, bucket)) ?? []),
      ...((await getFiles("/video", bucket)) ?? []),
    ];
    const files = new Map(
      filesArray.map((file) => {
        return [file.Key, file.Key];
      })
    );
    console.log(files);

    const args: ValidationArgument =
      env === "prod"
        ? {
            lessonComponents: prodLessonComponents,
            vocabComponents: prodVocabComponents,
            globalVideoSections: prodGlobalVideoSections,
            videos: prodVideos,
            phrases: prodPhrases,
            lessons: prodLessons,
            packages: prodPackages,
            locales: prodLocales,
            currentLocale: currentLocale!,
            files,
            env: env,
          }
        : {
            lessonComponents,
            vocabComponents,
            globalVideoSections,
            videos,
            phrases,
            lessons,
            packages,
            locales,
            currentLocale: currentLocale!,
            files,
          };

    const validationGroup = await validateLocale(args);

    setIsValidating(false);

    console.log(
      "Invalid validations (errors):",
      validationGroup.filterIssuesByGravity([ValidationIssueGravity.error]).filterByInvalid().removeValidChildren()
        .validations
    );

    console.log(
      "Invalid validations (errors + warnings):",
      validationGroup.filterByInvalid().removeValidChildren().validations
    );

    console.log("📁 Unique issues (errors + warnings):", validationGroup.filterByInvalid().getUniqueIssues());
    console.log(
      "🚨 Unique issues (errors):",
      validationGroup.filterIssuesByGravity([ValidationIssueGravity.error]).filterByInvalid().getUniqueIssues()
    );

    // for (const validation of validationGroup
    //   .filterIssuesByGravity([GravityType.error])
    //   .filterByInvalid()
    //   .removeValidChildren().validations) {
    //   if (validation.instance instanceof Phrase && validation.issues[0].message.includes("imageUrl")) {
    //     const path = new URL(validation.instance.imageUrl!).pathname;
    //     await copyS3(path, "kleo-staging", "kleo-prod");
    //     console.log("Copied image:", path);
    //   }
    // }

    if (downloadReport)
      CSVService.instance.downloadCSV(
        validationGroup.filterByInvalid().toCSV(),
        `Validation Report - ${currentLocale!.formattedCode} - ${new Date().toLocaleString()}.csv`
      );
  }

  async function compareToProd() {
    const { stagingArgs, prodArgs } = getArgs();
    const changeGroup = await compareEnvironments(stagingArgs, prodArgs);

    console.log("Database changes:", changeGroup.changes);
    console.log("Unique key differences:", changeGroup.getUniqueDifferenceKeys());

    if (downloadChanges)
      CSVService.instance.downloadCSV(
        changeGroup.toCSV(),
        `Change Report (staging to prod) - ${currentLocale!.title} - ${new Date().toLocaleString()}.csv`
      );
  }

  // async function compareToProdZip() {
  //   setIsComparingProdZip(true);

  //   const zippedPhrases = await remoteDataSource.allProdZippedPhrases(currentLocale!);
  //   const zippedVideos = await remoteDataSource.allProdZippedVideos(currentLocale!);
  //   const zippedGlobalVideoSections = await remoteDataSource.allProdZippedGlobalVideoSections(currentLocale!);
  //   const zippedLessonComponents = await remoteDataSource.allProdZippedLessonComponents(currentLocale!);

  //   const { stagingArgs } = getArgs();
  //   const changeGroup = await compareEnvironments(stagingArgs, {
  //     baseUrl: environments.prod.cloudfrontUrl,
  //     bucket: environments.prod.bucket,
  //     tableSuffix: environments.prod.tableSuffix,
  //     lessonComponents: zippedLessonComponents,
  //     globalVideoSections: zippedGlobalVideoSections,
  //     videos: zippedVideos,
  //     phrases: zippedPhrases,
  //     lessons: prodLessons,
  //     locales,
  //     currentLocale: currentLocale!,
  //   });

  //   setIsComparingProdZip(false);

  //   console.log("Database changes:", changeGroup.changes);
  //   console.log("Unique key differences:", changeGroup.getUniqueDifferenceKeys());

  //   if (downloadChanges)
  //     CSVService.instance.downloadCSV(
  //       changeGroup.toCSV(),
  //       `Change Report (staging to prod zip) - ${currentLocale!.title} - ${new Date().toLocaleString()}.csv`
  //     );
  // }

  async function pushToProd() {
    if (window.confirm("Are you sure you want to push to production? This will affect the app with live users.")) {
      const { stagingArgs, prodArgs } = getArgs();
      setIsPushing(true);
      try {
        await pushToEnvironment(stagingArgs, prodArgs);
      } finally {
        setIsPushing(false);
      }
    }
  }

  const getArgs = () => {
    return {
      stagingArgs: {
        baseUrl: environments.staging.cloudfrontUrl,
        bucket: environments.staging.bucket,
        tableSuffix: environments.staging.tableSuffix,
        lessonComponents,
        vocabComponents,
        globalVideoSections,
        videos,
        phrases,
        lessons,
        packages,
        locales,
        sections,
        currentLocale: currentLocale!,
      },
      prodArgs: {
        baseUrl: environments.prod.cloudfrontUrl,
        bucket: environments.prod.bucket,
        tableSuffix: environments.prod.tableSuffix,
        lessonComponents: prodLessonComponents,
        vocabComponents: prodVocabComponents,
        globalVideoSections: prodGlobalVideoSections,
        videos: prodVideos,
        phrases: prodPhrases,
        lessons: prodLessons,
        sections: prodSections,
        packages: prodPackages,
        locales: prodLocales,
        currentLocale: currentLocale!,
      },
    };
  };

  async function zipToApp() {
    setIsUploadingZips(true);
    try {
      await remoteDataSource.zipToS3(
        environments.staging.bucket,
        currentLocale!,
        phrases,
        videos,
        globalVideoSections.map((gvs) => gvs.convertToData()),
        lessonComponents.map((lc) => lc.convertToData()),
        vocabComponents
      );
    } catch (e) {
      alert("Error zipping to S3. Check console for details.");
      console.log(e);
    } finally {
      setIsUploadingZips(false);
    }
  }

  async function refreshCacheServer(env: "staging" | "prod" = "staging") {
    setIsRefreshingCacheServer(true);
    try {
      const response = await axios.post(
        `${environments[env].cacheServerEndpoint}/refresh`,
        {},
        {
          headers: {
            authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
          },
        }
      );
      console.log(response);
      alert(`Started refetching data in the cache server. Wait a few minutes for the data to be updated`);
    } catch (e) {
      console.log(e);
      alert("Error refreshing cache server. Check the cache server console for details.");
    } finally {
      setIsRefreshingCacheServer(false);
    }
  }

  // -- Components

  const Card = ({ title, children }: { title: string; children?: any }) => {
    return (
      <div className="p-10 rounded-xl bg-white shadow-xl flex flex-col gap-2 w-full">
        <div className="font-bold mb-3 text-xl">{title}</div>
        {children}
      </div>
    );
  };

  return (
    <div className="flex-1 p-5">
      <StatusView
        status={getWorstStatus([
          lessonComponentsStatus,
          vocabComponentsStatus,
          globalVideoSectionsStatus,
          videosStatus,
          phrasesStatus,
          lessonsStatus,
          packagesStatus,
          localesStatus,
          prodLessonComponentsStatus,
          prodVocabComponentsStatus,
          prodGlobalVideoSectionsStatus,
          prodVideosStatus,
          prodPhrasesStatus,
          prodLessonsStatus,
          prodPackagesStatus,
          prodLocalesStatus,
        ])}
        error={getError([
          lessonComponentsError,
          vocabComponentsError,
          globalVideoSectionsError,
          videosError,
          phrasesError,
          lessonsError,
          packagesError,
          localesError,
          prodLessonComponentsError,
          prodVocabComponentsError,
          prodGlobalVideoSectionsError,
          prodVideosError,
          prodPhrasesError,
          prodLessonsError,
          prodPackagesError,
          prodLocalesError,
        ])}
      >
        <div className="flex gap-5 justify-center">
          <div className="flex-1 flex flex-col gap-5 max-w-lg">
            <Card title="1. Validate">
              <CheckBoxInput value={downloadReport} set={setDownloadReport} message="Download Report CSV" />
              {/* <CheckBoxInput
                value={validateUrls}
                set={setValidateUrls}
                message="Validate File URLs (may take a while)"
              /> */}
              <Button onClick={() => validate()} className="mt-5">
                {!isValidating ? (
                  <div className="flex gap-2">
                    <ShieldCheck size={16} /> Validate
                  </div>
                ) : (
                  <LoadingIndicator />
                )}
              </Button>
              <Button onClick={() => validate("prod")}>
                {!isValidating ? (
                  <div className="flex gap-2">
                    <ShieldCheck size={16} /> Validate Production
                  </div>
                ) : (
                  <LoadingIndicator />
                )}
              </Button>
            </Card>
            <Card title="2. Compare">
              <CheckBoxInput value={downloadChanges} set={setDownloadChanges} message="Download Report CSV" />
              <Button onClick={compareToProd} className="mt-5">
                <DocumentSearch size={16} /> Compare to Production
              </Button>
            </Card>
            <Card title="3. Push">
              {/* <CheckBoxInput
                value={pushValidateUrls}
                set={setPushValidateUrls}
                message="Validate File URLs (may take a while)"
              /> */}
              <Button onClick={pushToProd} color="red" shade={400}>
                {!isPushing ? (
                  <div className="flex gap-2">
                    <CloudUpload size={16} /> Push to Production
                  </div>
                ) : (
                  <LoadingIndicator />
                )}
              </Button>
            </Card>
          </div>
          <div>
            <Card title="Test Changes (staging)">
              <Button onClick={zipToApp}>
                {!isUploadingZips ? (
                  <div className="flex gap-2">
                    <Document size={16} /> Zip to S3
                  </div>
                ) : (
                  <LoadingIndicator />
                )}
              </Button>
              <Button onClick={() => refreshCacheServer()}>
                {!isRefreshingCacheServer ? (
                  <div className="flex gap-2">
                    <Server size={16} /> Refresh cache server
                  </div>
                ) : (
                  <LoadingIndicator />
                )}
              </Button>
              <Button onClick={() => refreshCacheServer("prod")}>
                {!isRefreshingCacheServer ? (
                  <div className="flex gap-2">
                    <Server size={16} /> Refresh prod cache server
                  </div>
                ) : (
                  <LoadingIndicator />
                )}
              </Button>
            </Card>
          </div>
        </div>
      </StatusView>
    </div>
  );
}
