import { v4 as uuidv4 } from "uuid";
import { LessonComponent, Objective } from ".";
import Validation, {
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
  ValidationType,
} from "../../../service/Validation";

import { MatchType } from "./types";

export default class MatchDestination {
  uuid: string;
  successVideoClipId: string;
  exactVideoClipId?: string | null;
  substringVideoClipId?: string | null;
  unorderedVideoClipId?: string | null;
  flexVideoClipId?: string | null;

  constructor(object: Partial<MatchDestination> = {}) {
    this.uuid = object.uuid ?? uuidv4();
    this.successVideoClipId = object.successVideoClipId ?? "";
    this.exactVideoClipId = object.exactVideoClipId;
    this.substringVideoClipId = object.substringVideoClipId;
    this.unorderedVideoClipId = object.unorderedVideoClipId;
    this.flexVideoClipId = object.flexVideoClipId;
  }

  validate(lessonComponent: LessonComponent, objective: Objective) {
    const validation = new Validation({
      type: ValidationType.MatchDestination,
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (!lessonComponent.getAllLinkedMatchDestionationIds().includes(this.uuid))
      validation.issues.push(
        new ValidationIssue(
          `Match destination ${this.uuid} not used in the parent lesson component`,
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (!lessonComponent.getVideoClip(this.successVideoClipId))
      validation.issues.push(
        new ValidationIssue(
          "Field successVideoClipId must reference a VideoClip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (this.exactVideoClipId && !lessonComponent.getVideoClip(this.exactVideoClipId))
      validation.issues.push(
        new ValidationIssue(
          "Field exactVideoClipId must reference a VideoClip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (this.substringVideoClipId && !lessonComponent.getVideoClip(this.substringVideoClipId))
      validation.issues.push(
        new ValidationIssue(
          "Field substringVideoClipId must reference a VideoClip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (this.unorderedVideoClipId && !lessonComponent.getVideoClip(this.unorderedVideoClipId))
      validation.issues.push(
        new ValidationIssue(
          "Field unorderedVideoClipId must reference a VideoClip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (this.flexVideoClipId && !lessonComponent.getVideoClip(this.flexVideoClipId))
      validation.issues.push(
        new ValidationIssue(
          "Field flexVideoClipId must reference a VideoClip",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }

  getIdentifier() {
    return `${this.uuid}`;
  }
  getTypeName() {
    return "MatchDestination";
  }

  // -- Gets

  getAllVideoClipIds(): string[] {
    return Object.values(this)
      .slice(1)
      .filter((uuid) => !!uuid);
  }

  includesVideoClip(uuid: string): boolean {
    return this.getAllVideoClipIds().includes(uuid);
  }

  getMatchTypeOfVideoClip(uuid: string): MatchType | undefined {
    if (this.successVideoClipId === uuid) return MatchType.SUCCESS;
    if (this.exactVideoClipId === uuid) return MatchType.EXACT;
    if (this.substringVideoClipId === uuid) return MatchType.SUBSTRING;
    if (this.unorderedVideoClipId === uuid) return MatchType.UNORDERED;
    if (this.flexVideoClipId === uuid) return MatchType.FLEX;
  }

  getAvailableMatchTypes(): MatchType[] {
    let availableMatchTypes = [];
    if (this.successVideoClipId) availableMatchTypes.push(MatchType.SUCCESS);
    if (this.exactVideoClipId) availableMatchTypes.push(MatchType.EXACT);
    if (this.substringVideoClipId) availableMatchTypes.push(MatchType.SUBSTRING);
    if (this.unorderedVideoClipId) availableMatchTypes.push(MatchType.UNORDERED);
    if (this.flexVideoClipId) availableMatchTypes.push(MatchType.FLEX);
    return availableMatchTypes;
  }

  // -- Muations

  addDestination(uuid: string, matchType: MatchType) {
    if (matchType === MatchType.EXACT) this.exactVideoClipId = uuid;
    if (matchType === MatchType.SUBSTRING) this.substringVideoClipId = uuid;
    if (matchType === MatchType.UNORDERED) this.unorderedVideoClipId = uuid;
    if (matchType === MatchType.FLEX) this.flexVideoClipId = uuid;
  }

  removeDestination(uuid: string) {
    if (this.exactVideoClipId === uuid) this.exactVideoClipId = null;
    if (this.substringVideoClipId === uuid) this.substringVideoClipId = null;
    if (this.unorderedVideoClipId === uuid) this.unorderedVideoClipId = null;
    if (this.flexVideoClipId === uuid) this.flexVideoClipId = null;
  }

  replaceVideoClipIds(idMap: { [key: string]: string }) {
    this.successVideoClipId = idMap[this.successVideoClipId] ?? this.successVideoClipId;
    this.exactVideoClipId = idMap[this.exactVideoClipId ?? ""] ?? this.exactVideoClipId;
    this.substringVideoClipId = idMap[this.substringVideoClipId ?? ""] ?? this.substringVideoClipId;
    this.unorderedVideoClipId = idMap[this.unorderedVideoClipId ?? ""] ?? this.unorderedVideoClipId;
    this.flexVideoClipId = idMap[this.flexVideoClipId ?? ""] ?? this.flexVideoClipId;
  }
}
