import { useState } from "react";
import {
  phraseSearchFilter,
  phraseExactSearchFilter,
  phraseByWordSearchFilter,
  allPhrasesFilter,
  PhraseFilterType,
  phraseSingleWordFilter,
  phraseNotSingleWordFilter,
  phraseIsWordFilter,
  phraseNotIsWordFilter,
  phraseIsHiddenFilter,
  phraseNotIsHiddenFilter,
  phraseArticleFilter,
  phraseArticlePairsFilter,
  phraseIsVocabFilter,
} from "../../service/helpers";
import { Phrase, Locale, VocabComponent } from "../.../../../core/domain/entities";

import { SearchView, SelectMenu } from "..";

export default function PhraseSearchView({
  phrases,
  vocabComponents,
  currentLocale,
  children,
}: {
  phrases: Phrase[];
  vocabComponents: VocabComponent[];
  currentLocale: Locale;
  children: any;
}) {
  const [extraFilterType, setExtraFilterType] = useState(PhraseFilterType.all);

  const getExtraFilter = (phrase: Phrase) => {
    switch (extraFilterType) {
      case PhraseFilterType.all:
        return allPhrasesFilter();
      case PhraseFilterType.singleWord:
        return phraseSingleWordFilter(phrase);
      case PhraseFilterType.notSingleWord:
        return phraseNotSingleWordFilter(phrase);
      case PhraseFilterType.isWord:
        return phraseIsWordFilter(phrase);
      case PhraseFilterType.notIsWord:
        return phraseNotIsWordFilter(phrase);
      case PhraseFilterType.isHidden:
        return phraseIsHiddenFilter(phrase);
      case PhraseFilterType.notIsHidden:
        return phraseNotIsHiddenFilter(phrase);
      case PhraseFilterType.article:
        return phraseArticleFilter(phrase, currentLocale);
      case PhraseFilterType.articlePairs:
        return phraseArticlePairsFilter(phrase, phrases, currentLocale);
      case PhraseFilterType.isVocab:
        return phraseIsVocabFilter(phrase, vocabComponents);
      default:
        return true;
    }
  };

  return (
    <SearchView
      items={phrases}
      containsFilter={phraseSearchFilter}
      exactFilter={phraseExactSearchFilter}
      wordFilter={phraseByWordSearchFilter}
      extraFilter={(item: Phrase, _: string) => getExtraFilter(item) ?? false}
      noneFoundMessage="No phrases found"
      fixed
      limit={100}
      extraFilterComponent={
        <SelectMenu value={extraFilterType} set={setExtraFilterType}>
          <option value={PhraseFilterType.all}>All</option>
          <option value={PhraseFilterType.singleWord}>Single Word</option>
          <option value={PhraseFilterType.notSingleWord}>Multi Word</option>
          <option value={PhraseFilterType.isWord}>isWord</option>
          <option value={PhraseFilterType.notIsWord}>Not isWord</option>
          <option value={PhraseFilterType.isHidden}>isHidden</option>
          <option value={PhraseFilterType.notIsHidden}>Not isHidden</option>
          <option value={PhraseFilterType.article}>Article</option>
          <option value={PhraseFilterType.articlePairs}>Article Pairs</option>
          <option value={PhraseFilterType.isVocab}>Is in vocab</option>
        </SelectMenu>
      }
    >
      {children}
    </SearchView>
  );
}
