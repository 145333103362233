import axios from "axios";
import { remoteDataSource } from "../core";
import {
  GlobalVideoSection,
  Lesson,
  LessonComponent,
  Locale,
  Package,
  Phrase,
  Video,
  VocabComponent,
} from "../core/domain/entities";
import { Changeable, ChangeGroup, getChanges } from "./Change";
import { environments, newNamedTables } from "./constants";
import { createItem, deleteItem, updateItem } from "./DocClientService";
import { copyS3, getFiles } from "./StorageSerivce";
import { ValidationArgument, ValidationGroup, ValidationIssueGravity } from "./Validation";
import Section from "../core/domain/entities/Section";

export type EnvironmentArgument = {
  baseUrl: string;
  bucket: string;
  tableSuffix: string;
  lessonComponents: LessonComponent[];
  vocabComponents: VocabComponent[];
  globalVideoSections: GlobalVideoSection[];
  videos: Video[];
  phrases: Phrase[];
  lessons: Lesson[];
  sections: Section[];
  packages: Package[];
  locales: Locale[];
  currentLocale: Locale;
};

export type EnvironmentChangeArgument = {
  fromEnv: { baseUrl: string; s3Bucket: string };
  toEnv: { baseUrl: string; s3Bucket: string };
};

export async function pushToEnvironment(fromEnv: EnvironmentArgument, toEnv: EnvironmentArgument) {
  if (Object.values(fromEnv).every((items) => Array.isArray(items) && items.length === 0)) {
    alert("Error: All empty arrays when attempting to validate. Will not push to production");
    return;
  }

  const filesArray = [
    ...((await getFiles(`/images`, fromEnv.bucket)) ?? []),
    ...((await getFiles(`/audio/${fromEnv.currentLocale!.formattedCode}`, fromEnv.bucket)) ?? []),
    ...((await getFiles("/video", fromEnv.bucket)) ?? []),
  ];
  const files = new Map(
    filesArray.map((file) => {
      return [file.Key, file.Key];
    })
  );
  const validationGroup = await validateLocale({ ...fromEnv, files });
  console.log(
    validationGroup.filterIssuesByGravity([ValidationIssueGravity.error]).filterByInvalid().removeValidChildren()
      .validations
  );
  if (
    validationGroup.filterIssuesByGravity([ValidationIssueGravity.error]).filterByInvalid().removeValidChildren()
      .validations.length === 0
  ) {
    console.log("All validations passed. Writing changes...");

    const changeGroup = (await compareEnvironments(fromEnv, toEnv)).filterDifferences(
      (d) => !d.key.toLowerCase().includes("url") && !d.key.toLowerCase().includes("s3")
    );
    console.log("Changes:", changeGroup.changes);

    for (const change of changeGroup.changes) {
      const suffix = newNamedTables.includes(change.instance.getTypeName()) ? "-prod" : toEnv.tableSuffix;
      const tableName = (change.instance.getTypeName() + suffix).replace("Data", "");
      switch (change.type) {
        case "create":
          change.instance = await change.instance.toEnv(fromEnv, toEnv, true);
          await createItem(tableName, {
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            ...change.instance,
          });
          console.log("Created", change.instance);
          break;
        case "update":
          const shouldCopyS3 =
            change.instance instanceof Video ||
            change.getUniqueDifferenceKeys().some((key) => key.toLowerCase().includes("url"));
          change.instance = await change.instance.toEnv(fromEnv, toEnv, shouldCopyS3);
          await updateItem(tableName, change.instance.id, change.instance);
          console.log("Updated", change.instance);
          break;
        case "delete":
          await deleteItem(tableName, change.instance.id);
          console.log("Deleted", change.instance);
          break;
      }
    }
    console.log("All changes written");

    console.log("Zipping and uploading data...");

    const { lessonComponents, vocabComponents, phrases, globalVideoSections, videos } = fromEnv;
    await remoteDataSource.zipToS3(
      toEnv.bucket,
      fromEnv.currentLocale,
      await Promise.all(phrases.map(async (p) => await p.toEnv(fromEnv, toEnv))),
      await Promise.all(videos.map(async (v) => await v.toEnv(fromEnv, toEnv))),
      await Promise.all(
        globalVideoSections.map((gvs) => gvs.convertToData()).map(async (gvs) => await gvs.toEnv(fromEnv, toEnv))
      ),
      await Promise.all(
        lessonComponents.map((lc) => lc.convertToData()).map(async (lc) => await lc.toEnv(fromEnv, toEnv))
      ),
      await Promise.all(vocabComponents.map(async (vc) => await vc.toEnv(fromEnv, toEnv)))
    );

    console.log("All data zipped and uploaded");

    console.log("Refreshing cache server...");
    await axios.post(`${environments.prod.cacheServerEndpoint}/refresh`, {});
    console.log("Cache server refreshing. Prod data will be ready shortly.");

    console.log("✅ Finished");
  } else {
    console.log("Validations failed! Will not push to production");
  }
}

export async function validateLocale(args: ValidationArgument) {
  const { lessons, lessonComponents, phrases, globalVideoSections, vocabComponents, videos, packages } = args;

  const validationGroup = new ValidationGroup([]);

  for (const lesson of lessons) validationGroup.validations.push(lesson.validate(args));

  for (const pkg of packages) validationGroup.validations.push(pkg.validate(args));

  for (const phrase of phrases) {
    // if (args.validateUrls) console.log(`Validating Phrase ${parseInt(index) + 1} of ${phrases.length}: ${phrase.id}`);
    validationGroup.validations.push(await phrase.validate(args));
  }

  for (const globalVideoSection of globalVideoSections) {
    // if (args.validateUrls)
    //   console.log(
    //     `Validating GlobalVideoSection ${parseInt(index) + 1} of ${phrases.length}: ${globalVideoSection.id}`
    //   );
    validationGroup.validations.push(await globalVideoSection.validate(args));
  }

  for (const lessonComponent of lessonComponents) {
    // if (args.validateUrls)
    //   console.log(
    //     `Validating LessonComponent ${parseInt(index) + 1} of ${lessonComponents.length}: ${lessonComponent.id}`
    //   );
    validationGroup.validations.push(await lessonComponent.validate(args));
  }

  for (const vocabComponent of vocabComponents) {
    // if (args.validateUrls)
    //   console.log(
    //     `Validating VocabComponent ${parseInt(index) + 1} of ${vocabComponents.length}: ${vocabComponent.id}`
    //   );
    validationGroup.validations.push(await vocabComponent.validate(args));
  }

  for (const video of videos) validationGroup.validations.push(video.validate(args));

  return validationGroup;
}

export async function compareEnvironments(fromEnv: EnvironmentArgument, toEnv: EnvironmentArgument) {
  const { lessons, packages, lessonComponents, vocabComponents, phrases, globalVideoSections, videos, sections } =
    fromEnv;
  const {
    lessons: prodLessons,
    packages: prodPackages,
    lessonComponents: prodLessonComponents,
    vocabComponents: prodVocabComponents,
    phrases: prodPhrases,
    globalVideoSections: prodGlobalVideoSections,
    videos: prodVideos,
    sections: prodSections,
  } = toEnv;

  const objects = await Promise.all(
    [
      ...lessons,
      ...packages,
      ...phrases,
      ...lessonComponents.map((lc) => lc.convertToData()),
      ...vocabComponents,
      ...globalVideoSections.map((gvs) => gvs.convertToData()),
      ...videos,
      ...sections,
    ].map(async (object: Changeable) => await object.toEnv(fromEnv, toEnv))
  );
  const prodObjects = [
    ...prodLessons,
    ...prodPackages,
    ...prodPhrases,
    ...prodLessonComponents.map((lc) => lc.convertToData()),
    ...prodVocabComponents,
    ...prodGlobalVideoSections.map((gvs) => gvs.convertToData()),
    ...prodVideos,
    ...prodSections,
  ];

  return new ChangeGroup(getChanges(objects, prodObjects))
    .filterDifferences((d) => !["updatedAt", "updatedBy"].includes(d.key))
    .filterByChanged();
}

export async function envUrl(
  url: string,
  fromEnv: EnvironmentArgument,
  toEnv: EnvironmentArgument,
  push: boolean = false
) {
  const path = new URL(url).pathname;
  if (push) await copyS3(path, fromEnv.bucket, toEnv.bucket);
  return toEnv.baseUrl + path;
}
