import { v4 as uuidv4 } from "uuid";
import { Changeable } from "../../../service/Change";
import { EnvironmentArgument } from "../../../service/PushService";
import Validation, {
  Validatable,
  ValidationArgument,
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
  ValidationType,
} from "../../../service/Validation";
import GlobalVideoSection from "./GlobalVideoSection";
import LessonComponent from "./LessonComponent";
import { LessonComponentDisplayType, LessonComponentType } from "./types";
import VocabComponent from "./VocabComponent";
import VocabSet from "./VocabSet";

export default class Lesson implements Validatable, Changeable {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  orderIndex: number;
  componentIds: string[];
  vocabComponentIds: string[];
  localeId: string;
  isHidden: boolean;
  createdBy: string;
  updatedBy: string;
  groupsCanAccess: string[];
  vocabSets: any[];
  isCourse?: boolean;

  constructor(object: Partial<Lesson> = {}) {
    this.id = object.id ?? uuidv4();
    this.title = object.title ?? "";
    this.subtitle = "";
    this.description = object.description ?? "";
    this.orderIndex = object.orderIndex ?? 0;
    this.componentIds = object.componentIds ?? [];
    this.vocabComponentIds = object.vocabComponentIds ?? object.vocabSets?.map((set) => set.uuid) ?? [];
    this.localeId = object.localeId ?? "";
    this.isHidden = object.isHidden ?? false;
    this.createdBy = object.createdBy ?? "";
    this.updatedBy = object.updatedBy ?? "";
    this.groupsCanAccess = ["free"];
    this.vocabSets = object.vocabSets?.map((vs) => new VocabSet(vs)) ?? [];
    this.isCourse = object.isCourse;
  }

  getIdentifier(): string {
    return `${this.title} - ${this.id}`;
  }
  getTypeName() {
    return "Lesson";
  }

  async toEnv(_0: EnvironmentArgument, _1: EnvironmentArgument, _2: boolean = false) {
    return this;
  }

  validate({ lessons, lessonComponents, vocabComponents, locales }: ValidationArgument) {
    const validation = new Validation({
      type: ValidationType.Lesson,
      instance: this,
      issues: [],
      childValidations: [],
    });

    this.componentIds.forEach((id, index) => {
      if (this.componentIds.findIndex((cId) => cId === id) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate component ids",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            id
          )
        );
      }
    });

    this.vocabComponentIds.forEach((id, index) => {
      if (this.vocabComponentIds.findIndex((cId) => cId === id) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate vocab component ids",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            id
          )
        );
      }
    });

    if (!this.title)
      validation.issues.push(
        new ValidationIssue("Field title required", ValidationIssueGravity.error, ValidationIssueCause.userInput)
      );
    // if (!this.description)
    //   validation.issues.push(new Issue("Field description required", GravityType.warning, IssueFixType.userInput));

    if (!this.createdBy)
      validation.issues.push(
        new ValidationIssue("Field createdBy required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );

    if (!this.updatedBy)
      validation.issues.push(
        new ValidationIssue("Field updatedBy required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );

    if (lessons.find((lesson) => lesson.orderIndex === this.orderIndex && lesson.id !== this.id))
      validation.issues.push(
        new ValidationIssue(
          "Field orderIndex must be unique",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    for (const id of this.vocabComponentIds) {
      const vocabComponent = vocabComponents.find((vc) => vc.id === id);
      if (!vocabComponent)
        validation.issues.push(
          new ValidationIssue(
            "Each item in field vocabcomponentIds must reference a lesson component",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );
    }

    const courseComponents = [];
    for (const id of this.componentIds) {
      const lessonComponent = lessonComponents.find((lc) => lc.id === id);
      if (!lessonComponent)
        validation.issues.push(
          new ValidationIssue(
            "Each item in field componentIds must reference a lesson component",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal
          )
        );
      else if (lessonComponent.displayType === LessonComponentDisplayType.COURSE)
        courseComponents.push(lessonComponent);
    }

    const courseIntroductions = courseComponents.filter((lc) => lc.type === LessonComponentType.INTRODUCTION);
    const courseDrills = courseComponents.filter((lc) => lc.type === LessonComponentType.PRACTICE);
    const courseDeepDives = courseComponents.filter((lc) => lc.type === LessonComponentType.DEEPDIVE);

    if (courseIntroductions.length < 1 || courseDrills.length < 1 || courseDeepDives.length < 1)
      validation.issues.push(
        new ValidationIssue(
          "Should have one Introduction, Drill, and Deep Dive with a course displayType",
          ValidationIssueGravity.warning,
          ValidationIssueCause.userInput
        )
      );

    if (courseIntroductions.length > 1 || courseDrills.length > 1 || courseDeepDives.length > 1)
      validation.issues.push(
        new ValidationIssue(
          "Should not have more than one Introduction, Drill, or Deep Dive with a course displayType",
          ValidationIssueGravity.warning,
          ValidationIssueCause.userInput
        )
      );

    if (
      this.orderIndex === 0 &&
      [...this.componentIds, ...this.vocabComponentIds].length > 0 &&
      this.allSortedComponents(lessonComponents, vocabComponents)[0] instanceof VocabComponent
    ) {
      validation.issues.push(
        new ValidationIssue(
          "The first lesson in a unit should not be vocab",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    }

    if (!locales.map((locale) => locale.id).includes(this.localeId))
      validation.issues.push(
        new ValidationIssue(
          "Field localeId must reference a locale",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }

  allSortedComponents(lessonComponents: LessonComponent[], vocabComponents: VocabComponent[]) {
    const components = [];
    for (const id of this.componentIds) {
      const component = lessonComponents.find((lc) => lc.id === id);
      if (!component) continue;
      components.push(component);
    }
    for (const id of this.vocabComponentIds) {
      const component = vocabComponents.find((lc) => lc.id === id);
      if (!component) continue;
      components.push(component);
    }
    components.sort((a, b) => a.orderIndex - b.orderIndex);
    return components;
  }

  addComponent(component: LessonComponent | VocabComponent) {
    if (component instanceof VocabComponent) {
      this.vocabComponentIds.push(component.id);
    } else {
      this.componentIds.push(component.id);
    }
  }

  removeComponent(component: LessonComponent | VocabComponent) {
    if (component instanceof VocabComponent) {
      this.vocabComponentIds = this.vocabComponentIds.filter((id) => id !== component.id);
    } else {
      this.componentIds = this.componentIds.filter((id) => id !== component.id);
    }
  }

  getAllVocabPhraseIds(vocabComponents: VocabComponent[]) {
    const vocabPhrases = [];
    for (const id of this.vocabComponentIds) {
      const vocabComponent = vocabComponents.find((vc) => vc.id === id)!;
      vocabPhrases.push(...vocabComponent.getAllPhraseIds());
    }
    return vocabPhrases;
  }

  getAllPhraseIds(
    lessonComponents: LessonComponent[],
    vocabComponents: VocabComponent[],
    globalVideoSections?: GlobalVideoSection[]
  ) {
    const phraseIds = [];

    for (const id of this.componentIds) {
      const lessonComponent = lessonComponents.find((lc) => lc.id === id);
      if (!lessonComponent) throw new Error(`Could not find lesson component with id ${id}`);
      phraseIds.push(...lessonComponent.getAllPhraseIds(globalVideoSections));
    }

    phraseIds.push(...this.getAllVocabPhraseIds(vocabComponents));

    return [...new Set(phraseIds)];
  }
}
