import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { StatusType } from "./types";
import { DisplaySkill, Locale } from "../domain/entities";
import { useCaseGetDisplaySkills } from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { RootState } from "./store";

export const fetchDisplaySkills = createAsyncThunk(`displaySkills/fetch`, async (locale: Locale) => {
  const result = await useCaseGetDisplaySkills.execute(locale);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

const initialState = {
  items: [] as DisplaySkill[],
  current: null as DisplaySkill | undefined | null,
  status: StatusType.idle,
  error: null as string | undefined | null,
};

export const displaySkillsSlice = createSlice({
  name: "displaySkills",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDisplaySkills.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchDisplaySkills.fulfilled, (state, { payload: displaySkills }: { payload: DisplaySkill[] }) => {
      state.status = StatusType.succeeded;
      state.items = displaySkills;
    });
    builder.addCase(fetchDisplaySkills.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
  },
});

export const getAllDisplaySkills = (state: RootState) => state.displaySkills.items;
export const getDisplaySkillsStatus = (state: RootState) => state.displaySkills.status;
export const getDisplaySkillsError = (state: RootState) => state.displaySkills.error;
export const getCurrentDisplaySkill = (state: RootState) => state.displaySkills.current;

export default displaySkillsSlice.reducer;
