import { Upload } from "heroicons-react";
import { useState } from "react";
import { useSelector } from "react-redux";

import { Button, FileInput, FormErrorMessage, LoadingIndicator } from "..";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import { cloudfrontBaseUrl, environments } from "../../service/constants";
import { uploadFile } from "../../service/StorageSerivce";

const env = (process.env.REACT_APP_ENV! as "dev" | "staging" | "prod") || "staging";

export default function UploadView({
  className,
  title,
  url,
  setUrl,
  accept,
  endpoint,
  showUrl = true,
  path,
  fileName,
  ...rest
}: {
  className?: string;
  title?: string;
  url: string | null | undefined;
  setUrl: (url: string) => void;
  accept: string;
  showUrl?: boolean;
  path: string;
  fileName: string;
  [key: string]: any;
}) {
  // -- Redux
  const currentLocale = useSelector(getCurrentLocale);

  // -- Local state
  const [files, setFiles] = useState<any>();
  const [error, setError] = useState<string | undefined>();
  const [isUploading, setIsUploading] = useState(false);

  // -- Functions
  const upload = async (files: any[]) => {
    if (!files || files.length == 0) {
      setError("No file selected");
      return;
    }
    setIsUploading(true);
    const file = files[0];

    try {
      const type = file.name.split(".")[1];
      const key = `${path}/${fileName}.${type}`;
      await uploadFile(file, environments[env].bucket, key);
      const url = `${cloudfrontBaseUrl}/${key}`;

      setUrl(url);
      setIsUploading(false);
    } catch (error) {
      handleUploadError(error);
    }
  };

  const handleUploadError = (error: any) => {
    setError(`Upload errored with: ${error.message}`);
    setIsUploading(false);
  };

  return (
    <div {...rest} className={`flex flex-col gap-2 ${className}`}>
      <FileInput title={title} accept={accept} onChange={(e) => setFiles(e.target.files)} />
      <Button className="w-full" shade={100} onClick={() => upload(files)}>
        {!isUploading ? <Upload size={16} /> : <LoadingIndicator />}
        {!isUploading ? <div>Upload</div> : ""}
      </Button>
      {showUrl ? <div className="text-xs text-gray-500 break-words">{url ?? "No url. Upload to add"}</div> : null}
      <FormErrorMessage error={error} />
    </div>
  );
}
