import { FormikProps } from "formik";
import { Plus, XCircle } from "heroicons-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { SkillTree } from "../../core/domain/entities";
import { PhraseFormValues } from "./PhraseModal";
import { fetchSkills, getAllSkills, getSkillsError, getSkillsStatus } from "../../core/redux/skillsSlice";

import { Button, SelectMenu, StatusView } from "../index";
import { removeFromListAtIndex } from "../../service/helpers";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import {
  fetchDisplaySkills,
  getAllDisplaySkills,
  getDisplaySkillsError,
  getDisplaySkillsStatus,
} from "../../core/redux/displaySkillSlice";

export default function SkillsInput({ formProps }: { formProps: FormikProps<PhraseFormValues> }) {
  // -- Constants
  const { values, setFieldValue } = formProps;

  // -- Redux
  const dispatch = useDispatch();

  const skills = useSelector(getAllSkills);
  const skillsStatus = useSelector(getSkillsStatus);
  const skillsError = useSelector(getSkillsError);
  const displaySkills = useSelector(getAllDisplaySkills);
  const displaySkillsStatus = useSelector(getDisplaySkillsStatus);
  const displaySkillsError = useSelector(getDisplaySkillsError);

  const currentLocale = useSelector(getCurrentLocale);

  // -- Effect
  // Fetch skills
  useEffect(() => {
    if (currentLocale) {
      dispatch(fetchSkills(currentLocale));
      dispatch(fetchDisplaySkills(currentLocale));
    }
  }, [dispatch, currentLocale]);

  // -- Functions
  const addSkillTree = () => {
    setFieldValue("skillTrees", [...values.skillTrees, new SkillTree({ skillIds: [""] })]);
  };

  const deleteSkillTree = (index: number) => {
    setFieldValue("skillTrees", removeFromListAtIndex(values.skillTrees, index));
  };

  // -- Components
  const SkillTreeInput = ({ index }: { formProps: FormikProps<PhraseFormValues>; index: number }) => {
    const skillTree = values.skillTrees[index];

    const displaySkill = displaySkills.find((ds) => skillTree.skillIds.some((id) => ds.skillIds.includes(id)));

    const RootSkillInput = ({ id }: { id: string }) => {
      const skill = skills.find((skill) => skill.id === id);

      const setSkillId = (id: string) => {
        skillTree.setSkillId(id, 0);
        setFieldValue(`skillTrees[${index}].skillIds`, skillTree.skillIds);
      };

      return (
        <SelectMenu
          className="flex-1"
          value={skill ? skill.id : "select"}
          set={(id) => setSkillId(id)}
          sideComponent={
            <Button color="red" shade={100} onClick={() => deleteSkillTree(index)}>
              <XCircle size={16} />
            </Button>
          }
        >
          <option value="select">Select skill...</option>
          {skills
            .filter((skill) => !skill.parentSkillId)
            .map((skill) => (
              <option key={skill.id} value={skill.id}>
                {skill.title}
              </option>
            ))}
        </SelectMenu>
      );
    };

    const ParentSkillInput = ({ id, skillTreeIndex, index }: { id: string; skillTreeIndex: number; index: number }) => {
      // -- Constants
      const skill = skills.find((skill) => skill.id === id);

      // -- Functions
      const setSkillId = (id: string, index: number) => {
        skillTree.setSkillId(id, index + 1);
        setFieldValue(`skillTrees[${skillTreeIndex}].skillIds`, skillTree.skillIds);
      };

      if (!skill || skill.childSkillIds.length === 0) return null;
      return (
        <SelectMenu
          className="flex-1"
          value={skillTree.skillIds[index + 1] ?? ""}
          set={(id) => setSkillId(id, index)}
          sideComponent={
            <div className="w-9 h-9 flex items-center justify-center text-xs font-medium">{index + 2}</div>
          }
        >
          <option value="">Select skill...</option>
          {skill.childSkillIds.map((id) => {
            const skill = skills.find((skill) => skill.id === id);
            if (!skill) return null;
            const splitTitle = skill.title.split(",");
            const title = splitTitle[splitTitle.length - 1];
            return (
              <option key={id} value={skill.id}>
                {title}
              </option>
            );
          })}
        </SelectMenu>
      );
    };

    return (
      <div
        className={`
          flex flex-col gap-2 
          ${index !== 0 ? "pt-3" : ""} ${index !== values.skillTrees.length - 1 ? "pb-3" : ""}
        `}
      >
        <div className="text-xs">{displaySkill ? displaySkill.title : "No display skill"}</div>
        <RootSkillInput id={skillTree.skillIds[0]} />
        {skillTree.skillIds.map((id, parentSkillIndex) => (
          <ParentSkillInput key={id} id={id} skillTreeIndex={index} index={parentSkillIndex} />
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="text-xs font-medium">Skills</div>
      <div className="bg-gray-100 rounded-md p-3 flex flex-col divide-y">
        {values.skillTrees.length === 0 ? (
          <div className="text-xs text-gray-400">This phrase has no skills yet</div>
        ) : (
          <StatusView status={skillsStatus} error={skillsError}>
            {values.skillTrees.map((_, index) => (
              <SkillTreeInput key={index} formProps={formProps} index={index} />
            ))}
          </StatusView>
        )}
      </div>
      <Button shade={100} onClick={addSkillTree}>
        <Plus size={16} />
      </Button>
    </div>
  );
}
