import { StatusType } from "../../core/redux/types";
import { ErrorMessage, LoadingIndicator } from "..";

export default function StatusView({
  status,
  error,
  children,
}: {
  status: StatusType;
  error?: string | null | undefined;
  children: any;
}) {
  switch (status) {
    case StatusType.loading:
      return (
        <div className="flex flex-1 justify-center items-center">
          <LoadingIndicator />
        </div>
      );
    case StatusType.errored:
      return (
        <div className="flex justify-center items-center">
          <ErrorMessage error={error!} />
        </div>
      );
    case StatusType.succeeded:
      return children;
    default:
      return null;
  }
}
