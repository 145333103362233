export default class SectionInfo {
  title: string;
  targetText?: string | null;
  nativeText?: string | null;

  constructor(object: Partial<SectionInfo> = {}) {
    this.title = object.title ?? "";
    this.targetText = object.targetText;
    this.nativeText = object.nativeText;
  }
}
