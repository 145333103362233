export enum ButtonType {
  default = "default",
  white = "white",
  text = "text",
  textAlt = "textAlt",
  pure = "pure",
  border = "border",
}

export default function Button({
  className,
  onClick,
  color = "indigo",
  shade = 600,
  disabled,
  selected,
  displayType,
  type,
  children,
  ...rest
}: {
  className?: string;
  onClick?: (e: any) => void;
  color?: string;
  shade?: number;
  disabled?: boolean;
  selected?: boolean;
  displayType?: ButtonType;
  type?: "button" | "submit" | "reset" | undefined;
  children?: any;
  [key: string]: any;
}) {
  const handleClick = (e: any) => {
    if (!disabled) {
      e.stopPropagation();
      if (onClick) onClick(e);
    }
  };

  const defaultClassName = `
    p-2.5 shadow-sm hover:shadow-md h-9
    text-${shade > 300 ? "white" : `${color}-600`}
    bg-${color}-${shade} hover:bg-${color}-${shade + 200} 
  `;

  const whiteClassName = `
    p-2.5 shadow-sm hover:shadow-md h-9
    text-${color}-${shade}
    bg-white hover:bg-${color}-50
  `;

  const textClassName = `
    p-2.5 h-9
    text-${selected ? color : "gray"}-${shade} hover:bg-${color}-50 
  `;

  const textAltClassName = `
    text-gray-${shade} hover:text-${color}-${shade} h-9
  `;

  const pureClassName = `
    rounded-none
    text-${color}-${shade} hover:text-${color}-900
  `;

  const borderClassName = `
    p-2.5 border border-gray-200 hover:border-${color}-600 h-9
    text-${color}-${shade}
  `;

  const diabledClassName = `
    p-2.5 h-9
    text-${shade > 300 ? "white" : `gray-600`}
    bg-gray-200
  `;

  const computedClassName = () => {
    if (disabled) {
      return diabledClassName;
    } else {
      switch (displayType) {
        case ButtonType.white:
          return whiteClassName;
        case ButtonType.text:
          return textClassName;
        case ButtonType.textAlt:
          return textAltClassName;
        case ButtonType.pure:
          return pureClassName;
        case ButtonType.border:
          return borderClassName;
        default:
          return defaultClassName;
      }
    }
  };

  return (
    <button
      {...rest}
      type={type ?? "button"}
      className={`
        flex gap-1 justify-center items-center text-xs font-medium rounded-md focus:outline-none
        ${disabled ? "cursor-default" : ""}
        ${computedClassName()} ${className}
      `}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}
