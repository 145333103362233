import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { StatusType } from "./types";
import { VocabComponent, Locale, Lesson, LessonComponent } from "../domain/entities";
import {
  remoteDataSource,
  useCaseCreateVocabComponent,
  useCaseDeleteVocabComponent,
  useCaseGetVocabComponents,
  // useCaseReorderVocabComponents,
  useCaseUpdateLessonComponent,
  useCaseUpdateVocabComponent,
} from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { RootState } from "./store";
import { updateObjectInList } from "../../service/helpers";
import { updateLesson } from "./lessonsSlice";

export const fetchVocabComponents = createAsyncThunk(`vocabComponents/fetch`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange =
    state.vocabComponents.items.length > 0 && state.vocabComponents.items[0].localeId !== locale.id;
  if (
    state.vocabComponents.items.length > 0 &&
    (!localeDidChange || state.vocabComponents.status !== StatusType.loading)
  )
    return state.vocabComponents.items;

  const result = await useCaseGetVocabComponents.execute(locale);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const fetchProdVocabComponents = createAsyncThunk(
  `vocabComponents/fetchProd`,
  async (locale: Locale, { getState }) => {
    const state = getState() as RootState;

    const localeDidChange =
      state.vocabComponents.items.length > 0 && state.vocabComponents.items[0].localeId !== locale.id;
    if (
      state.vocabComponents.items.length > 0 &&
      (!localeDidChange || state.vocabComponents.status !== StatusType.loading)
    )
      return state.vocabComponents.items;

    const result = await remoteDataSource.allVocabComponents(locale, "prod");

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const createVocabComponent = createAsyncThunk(
  `vocabComponents/create`,
  async ({ vocabComponent, lesson }: { vocabComponent: VocabComponent; lesson: Lesson }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const allVocabComponents = state.vocabComponents.items;
    const allLessonComponents = state.lessonComponents.items;
    const { vocabComponent: vocabComponentPromise, lesson: updatedLesson } = await useCaseCreateVocabComponent.execute(
      vocabComponent,
      lesson,
      allVocabComponents,
      allLessonComponents
    );
    const result = await vocabComponentPromise;
    if (result instanceof Success) {
      if (updatedLesson) dispatch(updateLesson(updatedLesson));
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const updateVocabComponent = createAsyncThunk(
  `vocabComponents/update`,
  async ({ vocabComponent, lesson }: { vocabComponent: VocabComponent; lesson: Lesson }, { getState }) => {
    const state = getState() as RootState;
    const allComponents = lesson.allSortedComponents(state.lessonComponents.items, state.vocabComponents.items);

    for (let i = 0; i < allComponents.length; i++) {
      const component = allComponents[i];
      if (component.orderIndex !== i) {
        component.orderIndex = i;
        if (component instanceof LessonComponent) {
          await useCaseUpdateLessonComponent.execute(component);
        } else {
          await useCaseUpdateVocabComponent.execute(component);
        }
      }
    }

    const result = await useCaseUpdateVocabComponent.execute(vocabComponent);

    if (result instanceof Success) {
      return result.data;
    } else if (result instanceof Failure) {
      throw result.error;
    }
  }
);

export const deleteVocabComponent = createAsyncThunk(
  `vocabComponents/delete`,
  async ({ vocabComponent, lesson }: { vocabComponent: VocabComponent; lesson: Lesson }, { dispatch }) => {
    if (window.confirm(`Are you sure you want to delete the LessonComponent: ${vocabComponent.title}?`)) {
      const { vocabComponent: vocabComponentPromise, lesson: updatedLesson } =
        await useCaseDeleteVocabComponent.execute(vocabComponent, lesson);
      const result = await vocabComponentPromise;
      if (result instanceof Success) {
        if (updatedLesson) dispatch(updateLesson(updatedLesson));
        return result.data;
      } else if (result instanceof Failure) {
        throw result.error;
      }
    } else throw "Cancelled";
  }
);

const initialState = {
  items: [] as VocabComponent[],
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as VocabComponent[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
};

export const vocabComponentsSlice = createSlice({
  name: "vocabComponents",
  initialState: initialState,
  reducers: {
    // reorderVocabComponents(state, { payload: vocabComponents }: { payload: VocabComponent[] }) {
    //   const reorderedVocabComponents = useCaseReorderVocabComponents.execute(vocabComponents);
    //   state.items = reorderedVocabComponents;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVocabComponents.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(
      fetchVocabComponents.fulfilled,
      (state, { payload: vocabComponents }: { payload: VocabComponent[] }) => {
        state.status = StatusType.succeeded;
        state.items = vocabComponents;
      }
    );
    builder.addCase(fetchVocabComponents.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(
      fetchProdVocabComponents.fulfilled,
      (state, { payload: vocabComponents }: { payload: VocabComponent[] }) => {
        state.prodStatus = StatusType.succeeded;
        state.prodItems = vocabComponents;
      }
    );
    builder.addCase(fetchProdVocabComponents.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
    builder.addCase(
      createVocabComponent.fulfilled,
      (state, { payload: vocabComponent }: { payload: VocabComponent }) => {
        state.items = [...state.items, vocabComponent];
      }
    );
    builder.addCase(createVocabComponent.rejected, () => {
      alert("Error! VocabComponent was not created. Cheeck logs for more info");
    });
    builder.addCase(
      updateVocabComponent.fulfilled,
      (state, { payload: vocabComponent }: { payload: VocabComponent }) => {
        state.items = updateObjectInList(state.items, vocabComponent);
      }
    );
    builder.addCase(updateVocabComponent.rejected, () => {
      alert("Error! VocabComponent was not updated. Cheeck logs for more info");
    });
    builder.addCase(
      deleteVocabComponent.fulfilled,
      (state, { payload: vocabComponent }: { payload: VocabComponent }) => {
        state.items = state.items.filter((item) => item.id !== vocabComponent.id);
      }
    );
    builder.addCase(deleteVocabComponent.rejected, () => {
      alert("Error! VocabComponent was not deleted. Cheeck logs for more info");
    });
  },
});

// export const { selectVocabComponent, reorderVocabComponents } = vocabComponentsSlice.actions;

export const getAllVocabComponents = (state: RootState) => state.vocabComponents.items;
export const getVocabComponentsStatus = (state: RootState) => state.vocabComponents.status;
export const getVocabComponentsError = (state: RootState) => state.vocabComponents.error;
export const getAllProdVocabComponents = (state: RootState) => state.vocabComponents.prodItems;
export const getProdVocabComponentsStatus = (state: RootState) => state.vocabComponents.prodStatus;
export const getProdVocabComponentsError = (state: RootState) => state.vocabComponents.prodError;

export default vocabComponentsSlice.reducer;
