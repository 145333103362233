import _, { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

import {
  Contingency,
  FailDestination,
  GlobalVideoSection,
  Lesson,
  MatchDestination,
  Objective,
  Phrase,
  SectionInfo,
  SupplementaryWord,
  TargetPhrase,
  TargetPhraseGroup,
  VideoClip,
} from ".";
import { isS3Url } from "../../../service/helpers";
import { hasFile } from "../../../service/StorageSerivce";
import Validation, {
  Validatable,
  validateNumbers,
  ValidationArgument,
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
  ValidationType,
} from "../../../service/Validation";
import { DataLessonComponent } from "../../data/entities";
import {
  FailType,
  generatedComponentTypes,
  kleoColorHex,
  LessonComponentDisplayType,
  LessonComponentType,
  MatchType,
  SectionPresetType,
  SectionType,
  VideoClipType,
} from "./types";
import VocabComponent from "./VocabComponent";

export type GridCard = {
  data: VideoClip | TargetPhraseGroup;
  row: number;
  column: number;
};

export default class LessonComponent implements Validatable {
  id: string;
  title: string;
  audioUrl?: string | null;
  imageUrl: string;
  videoClips: VideoClip[];
  type: LessonComponentType;
  objectives: Objective[];
  masterPlaylist: string[];
  localeId: string;
  isHidden: boolean;
  isMagnet: boolean;
  createdBy: string;
  updatedBy: string;
  groupsCanAccess: string[];
  createdAt: string;
  updatedAt: string;
  isChecked: boolean;
  displayType: LessonComponentDisplayType;
  viewGroups: string[];
  orderIndex: number;
  freePromoImage?: string;

  constructor(object: Partial<LessonComponent> = {}) {
    this.id = object.id ?? uuidv4();
    this.title = object.title ?? "";
    this.audioUrl = object.audioUrl;
    this.imageUrl = object.imageUrl ?? "https://kleolearn.com";
    this.videoClips = object.videoClips ? object.videoClips.map((vc: VideoClip) => new VideoClip(vc)) : [];
    this.type = object.type ?? LessonComponentType.INTRODUCTION;
    this.objectives = object.objectives ? object.objectives.map((o: Objective) => new Objective(o)) : [];
    this.masterPlaylist = object.masterPlaylist ?? [];
    this.localeId = object.localeId ?? "";
    this.isHidden = object.isHidden ?? false;
    this.isMagnet = object.isMagnet ?? false;
    this.createdBy = object.createdBy ?? "unknown";
    this.updatedBy = object.updatedBy ?? "unknown";
    this.createdAt = object.createdAt ?? new Date().toISOString();
    this.updatedAt = object.updatedAt ?? new Date().toISOString();
    this.groupsCanAccess = ["free"];
    this.isChecked = object.isChecked ?? false;
    this.displayType = object.displayType ?? LessonComponentDisplayType.COURSE;
    this.viewGroups = object.viewGroups ?? ["pro"];
    this.orderIndex = object.orderIndex ?? 0;
    this.freePromoImage = object.freePromoImage;
  }

  fixNumberStrings() {
    this.orderIndex = parseFloat(this.orderIndex as any as string);
    for (const videoClip of this.videoClips) {
      videoClip.fixNumberStrings();
    }
  }

  static checkIn(
    lesson: Lesson,
    orderNumber: number,
    lessons: Lesson[],
    lessonComponents: LessonComponent[],
    vocabComponents: VocabComponent[],
    globalVideoSections: GlobalVideoSection[],
    phrases: Phrase[]
  ) {
    const orderIndex = orderNumber - 1;

    // Use 6 phrases from the previous lesson components of this lesson
    const learnedComponents = lesson
      .allSortedComponents(lessonComponents, vocabComponents)
      .filter((c) => c.orderIndex < orderIndex)
      .filter((c) => c instanceof LessonComponent) as LessonComponent[];
    const learnedPhraseIds = learnedComponents.flatMap((lc) => lc.getAllPrimaryPhraseIds());
    const learnedPhrases = _.shuffle(learnedPhraseIds).slice(0, 6);

    // Use 4 phrases from all lesson components of the previous lesson
    const PREV_LESSON_COUNT = 4;
    let sortedLessons = [...lessons];
    sortedLessons.sort((a, b) => b.orderIndex - a.orderIndex);
    const prevLessons = sortedLessons.filter((l) => l.orderIndex < lesson.orderIndex).slice(0, PREV_LESSON_COUNT);
    const prevLessonComponents = prevLessons.flatMap(
      (l) =>
        l
          .allSortedComponents(lessonComponents, vocabComponents)
          .filter((c) => c instanceof LessonComponent) as LessonComponent[]
    );

    const prevLessonPhraseIds = prevLessonComponents.flatMap((lc) => lc.getAllPrimaryPhraseIds());
    const prevLessonPhrases = _.shuffle(prevLessonPhraseIds).slice(0, 4);

    const contentPhrases = [...learnedPhrases, ...prevLessonPhrases].map((id) => phrases.find((p) => p.id === id)!);
    const generatedLessonComponent = LessonComponent.fromPhrases(
      contentPhrases,
      phrases,
      globalVideoSections,
      false,
      "Check-In",
      LessonComponentDisplayType.OTHER,
      "https://d1wccmadqdmt6c.cloudfront.net/Images/shared/checkin3_F.jpg",
      orderIndex
    );
    return generatedLessonComponent;
  }

  static fromPhrases(
    fromPhrases: Phrase[],
    phrases: Phrase[],
    globalVideoSections: GlobalVideoSection[],
    isFree: boolean = false,
    title: string = "Generated",
    displayType?: LessonComponentDisplayType,
    imageUrl?: string,
    orderIndex?: number
  ) {
    const shuffledPhrases = _.shuffle(fromPhrases).slice(0, 10);

    const usableGlobalVideoSections = globalVideoSections.filter((gvs) => gvs.isUsable());
    const generics = usableGlobalVideoSections.filter((gvs) => gvs.isGeneric);
    const nonGenerics = usableGlobalVideoSections.filter((gvs) => !gvs.isGeneric);

    const lessonComponent = new LessonComponent({
      title: title,
      imageUrl: imageUrl ?? shuffledPhrases.find((phrase) => phrase.imageUrl)?.imageUrl,
      type: LessonComponentType.PRACTICE,
      localeId: fromPhrases[0].localeId,
      viewGroups: isFree ? ["free", "pro"] : ["pro"],
      displayType: displayType,
      isChecked: true,
      orderIndex: orderIndex,
    });

    for (const phrase of shuffledPhrases) {
      const availableNonGenerics = nonGenerics.filter((gvs) => !lessonComponent.masterPlaylist.includes(gvs.sectionId));
      const availableGenerics = generics.filter((gvs) => !lessonComponent.masterPlaylist.includes(gvs.sectionId));

      const globalVideoSection = _.sample(
        availableNonGenerics.filter((gvs) => gvs.getAllPrimaryPhraseIds().includes(phrase.id))
      );
      if (globalVideoSection) {
        lessonComponent.masterPlaylist.push(globalVideoSection.sectionId);
        lessonComponent.videoClips.push(...globalVideoSection.videoClips);
        lessonComponent.objectives.push(...globalVideoSection.objectives);
      } else {
        const genericSection = _.sample(availableGenerics);
        if (genericSection) linkToGeneric(new GlobalVideoSection(genericSection), phrase, false);
        else {
          const usedGenericSection = _.sample(generics);
          if (usedGenericSection) linkToGeneric(new GlobalVideoSection(usedGenericSection), phrase, true);
        }
      }
    }

    function linkToGeneric(genericSection: GlobalVideoSection, phrase: Phrase, isUsed: boolean) {
      const acceptedPhrases = phrases.filter((p) => p.nativeText.toLowerCase() === phrase.nativeText.toLowerCase());
      genericSection.attachPhrase(phrase, acceptedPhrases, isUsed);
      lessonComponent.masterPlaylist.push(genericSection.sectionId);
      lessonComponent.videoClips.push(...genericSection.videoClips);
      lessonComponent.objectives.push(...genericSection.objectives);
    }

    return lessonComponent;
  }

  convertToData(): DataLessonComponent {
    return new DataLessonComponent({
      id: this.id,
      title: this.title,
      audioUrl: this.audioUrl,
      imageUrl: this.imageUrl,
      videoClips: generatedComponentTypes.includes(this.type) ? [] : this.videoClips,
      type: this.type,
      objectives: generatedComponentTypes.includes(this.type) ? [] : this.objectives.map((o) => o.convertToData()),
      masterPlaylist: this.masterPlaylist,
      localeId: this.localeId,
      isHidden: this.isHidden,
      isMagnet: this.isMagnet,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      groupsCanAccess: this.groupsCanAccess,
      isChecked: this.isChecked,
      displayType: this.displayType,
      viewGroups: this.viewGroups,
      orderIndex: this.orderIndex,
      freePromoImage: this.freePromoImage,
    });
  }

  getGrid(sectionId?: string): GridCard[][] {
    var currentRow = 1;
    var currentColumn = 1;
    var grid = [];

    const getCardsForVideoClip = (uuid: string) => {
      let cards = [];

      const startColumn = currentColumn;

      const videoClip = this.getVideoClip(uuid);
      if (videoClip) {
        // Handle video clip
        cards.push({
          data: videoClip,
          row: currentRow,
          column: currentColumn,
        });

        // Handle objective
        if (videoClip.objectiveId) {
          const objective = this.getObjective(videoClip.objectiveId);
          if (objective) {
            currentColumn += 1;

            for (const targetPhraseGroup of objective.targetPhraseGroups) {
              // Handle target phrase group
              cards.push({
                data: targetPhraseGroup,
                row: currentRow,
                column: currentColumn,
              });

              currentColumn += 1;

              // Handle match video clips (including supplementary words)
              const matchIds = objective.getVideoClipIdsForTargetPhraseGroup(targetPhraseGroup.uuid);
              matchIds.forEach((uuid) => cards.push(...getCardsForVideoClip(uuid)));

              currentColumn -= 1;
            }

            currentColumn += 1;

            // Handle fail video clips
            objective.failDestination.getAllVideoClipIds().forEach((uuid) => cards.push(...getCardsForVideoClip(uuid)));
          }
        }

        currentRow += 1;
        currentColumn = startColumn;
      }

      return cards;
    };

    for (const uuid of this.getDisplayIds(sectionId)) {
      grid.push(getCardsForVideoClip(uuid));
      currentRow = 1;
    }

    return grid;
  }

  async validate({ lessons, videos, phrases, locales, globalVideoSections, files, env }: ValidationArgument) {
    const validation = new Validation({
      type: ValidationType.LessonComponent,
      instance: this,
      issues: [],
      childValidations: [],
    });

    this.objectives.forEach((objective, index) => {
      if (this.objectives.findIndex((o) => o.uuid === objective.uuid) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate objectives",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            objective.uuid
          )
        );
      }
      validation.childValidations.push(objective.validate(this, phrases));
    });

    if (!this.title)
      validation.issues.push(
        new ValidationIssue("Field title required", ValidationIssueGravity.error, ValidationIssueCause.userInput)
      );
    if (!this.createdBy)
      validation.issues.push(
        new ValidationIssue("Field createdBy required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );
    if (!this.updatedBy)
      validation.issues.push(
        new ValidationIssue("Field updatedBy required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );
    if (!this.updatedAt)
      validation.issues.push(
        new ValidationIssue("Field updatedAt required", ValidationIssueGravity.error, ValidationIssueCause.internal)
      );

    if (this.videoClips.length === 0 && !generatedComponentTypes.includes(this.type)) {
      validation.issues.push(
        new ValidationIssue(
          "At least one video clip required",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    }

    // if (!this.isChecked && !this.isHidden)
    //   validation.issues.push(new Issue("Must be checked or hidden", GravityType.error, IssueFixType.userInput));
    // TODO

    if (isS3Url(this.imageUrl))
      validation.issues.push(
        new ValidationIssue(
          "Field imageUrl cannot be an S3 url",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (files && !this.isHidden && this.type !== LessonComponentType.CHECKPOINT && !hasFile(files, this.imageUrl, env))
      validation.issues.push(
        new ValidationIssue(
          `Invalid imageUrl: ${this.imageUrl ? new URL(this.imageUrl).pathname : "null"}`,
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    if (this.audioUrl && isS3Url(this.audioUrl))
      validation.issues.push(
        new ValidationIssue(
          "Field audioUrl cannot be an S3 url",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );
    if (this.audioUrl && files && !hasFile(files, this.audioUrl, env))
      validation.issues.push(
        new ValidationIssue(
          `Invalid audioUrl: ${this.audioUrl ? new URL(this.audioUrl).pathname : "null"}`,
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    if (this.freePromoImage && files && !hasFile(files, this.freePromoImage, env))
      validation.issues.push(
        new ValidationIssue(
          `Invalid freePromoImage: ${this.freePromoImage ? new URL(this.freePromoImage).pathname : "null"}`,
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (
      !(this.type === LessonComponentType.GLOBALVIDEOSECTION) &&
      !lessons.find((lesson) => lesson.componentIds.includes(this.id))
    )
      validation.issues.push(
        new ValidationIssue(
          "Not referenced in the componentIds field of a lesson",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    this.videoClips.forEach((videoClip, index) => {
      validation.childValidations.push(videoClip.validate(this, videos));

      if (this.videoClips.findIndex((vc) => vc.uuid === videoClip.uuid) !== index) {
        validation.issues.push(
          new ValidationIssue(
            "Duplicate video clips",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            videoClip.uuid
          )
        );
      }

      // let matchDestinationMatched = false;
      // for (const matchDestination of this.getAllMatchDestinations()) {
      //   if (matchDestination.getAllVideoClipIds().some((id) => id === videoClip.uuid)) {
      //     if (!matchDestinationMatched) matchDestinationMatched = true;
      //     else
      //       validation.issues.push(
      //         new Issue(
      //           "Multiple match destinations reference the same video clip",
      //           GravityType.error,
      //           IssueFixType.internal,
      //           videoClip.uuid
      //         )
      //       );
      //   }
      // }

      let supplementaryWordMatched = false;
      for (const supplementaryWord of this.getAllSupplementaryWords()) {
        if (supplementaryWord.destinationVideoClipId === videoClip.uuid) {
          if (!supplementaryWordMatched) supplementaryWordMatched = true;
          else
            validation.issues.push(
              new ValidationIssue(
                "Multiple supplementary words reference the same video clip",
                ValidationIssueGravity.error,
                ValidationIssueCause.internal,
                videoClip.uuid
              )
            );
        }
      }
    });

    if (this.type === LessonComponentType.PRACTICE) {
      for (const id of this.masterPlaylist)
        if (!globalVideoSections.some((globalVideoSection) => globalVideoSection.sectionId === id))
          validation.issues.push(
            new ValidationIssue(
              "In a practice lesson component, each id in the field masterPlaylist should reference the sectionId of a global video section",
              ValidationIssueGravity.error,
              ValidationIssueCause.internal,
              id
            )
          );
    }

    for (const id of this.masterPlaylist) {
      if (this.masterPlaylist.indexOf(id) !== this.masterPlaylist.lastIndexOf(id))
        validation.issues.push(
          new ValidationIssue(
            "Each id in the field masterPlaylist should be unique",
            ValidationIssueGravity.error,
            ValidationIssueCause.userInput,
            id
          )
        );
    }

    if (!locales.map((locale) => locale.id).includes(this.localeId))
      validation.issues.push(
        new ValidationIssue(
          "Field localeId must reference a locale",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    // Schema validation
    const numberFields = {
      orderIndex: this.orderIndex,
    };
    validation.issues.push(...validateNumbers(numberFields));

    return validation;
  }

  getVideoNamePrefixs() {
    let prefixs: string[] = [];
    for (const videoClip of this.videoClips) {
      if (videoClip.databaseName) {
        const prefix = videoClip.databaseName.split("_")[0];
        if (!prefixs.includes(prefix)) prefixs.push(prefix);
      }
    }
    return prefixs;
  }

  generateGlobalVideoSectionFromSection(uuid: string) {
    const clone = cloneDeep(this);

    const videoClip = clone.getVideoClip(uuid);
    if (!videoClip) return;

    const sectionClips = clone.getVideoClipsForSection(uuid);
    const sectionObjectives = clone.getObjectivesForSection(uuid);

    if (videoClip.objectiveId) {
      const objective = clone.getObjective(videoClip.objectiveId);
      if (objective) {
        const successGroup = objective!.targetPhraseGroups.find((tpg) => tpg.targetPhrases.some((tp) => tp.isPrimary));
        if (successGroup) {
          const successClip = clone.getVideoClip(
            objective!.getMatchDestination(successGroup!.matchDestinationId)!.successVideoClipId
          );
          if (successClip) {
            videoClip.displayItems[0].primaryText = successClip.displayItems[0].primaryText;
            videoClip.displayItems[0].primaryDisplayTime = null;
            videoClip.displayItems[0].primaryEndTime = null;
          }
        }
      }
    }

    const globalVideoSection = new GlobalVideoSection({
      title: `${videoClip.sectionInfo.targetText} - ${videoClip.databaseName?.split("_")[0]}_${
        videoClip.databaseName?.split("_")[1]
      }`,
      sectionId: videoClip.sectionId,
      localeId: clone.localeId,
      isChecked: true,
      videoClips: sectionClips,
      objectives: sectionObjectives,
    });
    return globalVideoSection;
  }

  // getLongestPathLength() {
  //   const getPathLengthForVideoClip = (uuid: string) => {
  //     let pathLength = 1;

  //     const videoClip = this.getVideoClip(uuid);

  //     if (videoClip && videoClip.objectiveId) {
  //       const objective = this.getObjective(videoClip.objectiveId);
  //       if (objective) {
  //         pathLength += Math.max(
  //           ...objective.getAllDestinationVideoClipIds().map((id) => getPathLengthForVideoClip(id))
  //         );
  //       }
  //     }
  //     return pathLength;
  //   };

  //   return Math.max(
  //     ...this.videoClips.filter((vc) => vc.uuid === vc.sectionId).map((vc) => getPathLengthForVideoClip(vc.uuid))
  //   );
  // }

  // -- Gets

  // Video clip
  getAllLinkedVideoClipIds(sectionId?: string) {
    let ids: string[] = [];

    const getLinkedIdsForVideoClip = (uuid: string) => {
      let ids: string[] = [];

      const videoClip = this.getVideoClip(uuid);
      if (videoClip) {
        ids.push(uuid);
        if (videoClip.objectiveId) {
          const objective = this.getObjective(videoClip.objectiveId);
          if (objective) {
            // Handle match video clips (including supplementary words)
            objective.targetPhraseGroups.forEach((targetPhraseGroup) =>
              ids.push(
                ...objective
                  .getVideoClipIdsForTargetPhraseGroup(targetPhraseGroup.uuid)
                  .flatMap((uuid) => getLinkedIdsForVideoClip(uuid))
              )
            );
            // Handle fail video clips
            ids.push(
              ...objective.failDestination.getAllVideoClipIds().flatMap((uuid) => getLinkedIdsForVideoClip(uuid))
            );
          }
        }
      }

      return ids;
    };

    for (const uuid of this.getDisplayIds(sectionId)) {
      ids.push(...getLinkedIdsForVideoClip(uuid));
    }

    return ids;
  }
  getDisplayIds(sectionId?: string) {
    const getPlaylist = () => {
      const computedSection = this.videoClips.find((vc) => vc.uuid === vc.sectionId);
      if (sectionId) return [sectionId];
      if (this.masterPlaylist.length === 0 && computedSection) return [computedSection.uuid];
      return this.masterPlaylist;
    };

    let displayIds: string[] = [];

    // sectionId ? (this.masterPlaylist.length === 0 ? (computedSection ? computedSection.uuid) : [sectionId] : this.masterPlaylist;

    for (const uuid of getPlaylist()) {
      displayIds.push(uuid);
      const contingentIdGroup = this.getContingentIdGroupForVideoClip(uuid);
      if (contingentIdGroup) displayIds.push(...contingentIdGroup);
    }

    return displayIds;
  }
  getAllVideoNames() {
    const names = this.videoClips.map((vc) => vc.databaseName);
    return names.filter((name, index) => names.indexOf(name) === index);
  }
  getVideoClip(uuid: string): VideoClip | undefined {
    return this.videoClips.find((vc) => vc.uuid === uuid);
  }
  getVideoClipIndex(uuid: string): number | undefined {
    return this.videoClips.findIndex((vc) => vc.uuid === uuid);
  }
  getParentForObjective(uuid: string): VideoClip | undefined {
    return this.videoClips.find((videoClip) => videoClip.objectiveId === uuid);
  }
  getSectionForVideoClip(uuid: string): VideoClip | undefined {
    const videoClip = this.getVideoClip(uuid);
    if (videoClip) {
      if (videoClip.sectionId === videoClip.uuid) {
        return videoClip;
      } else {
        return this.getSectionForVideoClip(videoClip.sectionId);
      }
    }
  }
  getSectionForTargetPhraseGroup(uuid: string): VideoClip | undefined {
    const objective = this.getObjectiveForTargetPhraseGroup(uuid);
    if (objective) {
      const videoClip = this.getParentForObjective(objective.uuid);
      if (videoClip) return this.getSectionForVideoClip(videoClip.uuid);
    }
  }
  getContingentIdGroupForVideoClip(uuid: string) {
    const checkContingency = (contingency: Contingency, objective: Objective) => {
      const matchDestinations = contingency.matchDestinationIds.map((uuid) => objective.getMatchDestination(uuid));
      if (
        contingency.placeholderId === uuid ||
        matchDestinations.some(
          (matchDestination) => matchDestination && matchDestination.getAllVideoClipIds().includes(uuid)
        )
      ) {
        let ids = [];
        ids.push(contingency.placeholderId);
        for (const matchDestination of matchDestinations) {
          if (matchDestination) {
            matchDestination.getAllVideoClipIds().forEach((uuid) => {
              if (uuid) ids.push(uuid);
            });
          }
        }
        return ids;
      }
    };

    let output: string[] = [];
    for (const objective of this.objectives) {
      for (const targetPhraseGroup of objective.targetPhraseGroups) {
        for (const contingency of targetPhraseGroup.contingencies) {
          const ids = checkContingency(contingency, objective);
          if (ids) output.push(...ids);
        }
      }
    }
    const contingentIds = new Set(output.filter((id) => id !== uuid));
    return Array.from(contingentIds);
  }
  getVideoClipsForSection(uuid: string) {
    return this.videoClips.filter((vc) => vc.sectionId === uuid);
  }

  // Objective
  getAllLinkedObjectiveIds() {
    return this.getAllLinkedVideoClipIds()
      .map((uuid) => this.getVideoClip(uuid))
      .filter((videoClip) => videoClip && videoClip.objectiveId)
      .map((videoClip) => videoClip!.objectiveId!);
  }
  getObjective(uuid: string): Objective | undefined {
    return this.objectives.find((o) => o.uuid === uuid);
  }
  getObjectiveIndex(uuid: string): number | undefined {
    return this.objectives.findIndex((o) => o.uuid === uuid);
  }
  getObjectiveForTargetPhraseGroup(uuid: string): Objective | undefined {
    for (const objective of this.objectives) {
      if (objective.getTargetPhraseGroup(uuid)) return objective;
    }
  }
  getObjectivesForSection(uuid: string) {
    return this.objectives.filter((objective) => {
      const videoClip = this.getParentForObjective(objective.uuid);
      return videoClip && videoClip.sectionId === uuid;
    });
  }

  // Target phrase
  getTargetPhrase(uuid: string): TargetPhrase | undefined {
    for (const objective of this.objectives) {
      const targetPhrase = objective.getTargetPhrase(uuid);
      if (targetPhrase) return targetPhrase;
    }
  }

  getAllPhraseIds(globalVideoSections?: GlobalVideoSection[]) {
    if (generatedComponentTypes.includes(this.type)) {
      if (!globalVideoSections)
        throw new Error("GlobalVideoSections argument is required for finding phrases in practice components");
      const usedGlobalVideoSections = this.masterPlaylist.map((sectionId) =>
        globalVideoSections.find((gvs) => gvs.sectionId === sectionId)
      );
      const objectives = usedGlobalVideoSections.map((gvs) => gvs!.objectives).flat();
      const targetPhraseGroups = objectives.map((o) => o.targetPhraseGroups).flat();
      return targetPhraseGroups.flatMap((targetPhraseGroup) =>
        targetPhraseGroup.targetPhrases.map((targetPhrase) => targetPhrase.rootPhraseId)
      );
    }
    return this.getAllTargetPhraseGroups().flatMap((targetPhraseGroup) =>
      targetPhraseGroup.targetPhrases.map((targetPhrase) => targetPhrase.rootPhraseId)
    );
  }
  getAllPrimaryPhraseIds() {
    return [
      ...new Set(
        this.objectives.flatMap((o) =>
          o.targetPhraseGroups.flatMap((tpg) =>
            tpg.targetPhrases.filter((tp) => tp.isPrimary).map((tp) => tp.rootPhraseId)
          )
        )
      ),
    ];
  }

  // Target phrase group
  getTargetPhraseGroup(uuid: string): TargetPhraseGroup | undefined {
    for (const objective of this.objectives) {
      const targetPhraseGroup = objective.getTargetPhraseGroup(uuid);
      if (targetPhraseGroup) return targetPhraseGroup;
    }
  }
  getAllTargetPhraseGroups(): TargetPhraseGroup[] {
    let allTargetPhraseGroups: TargetPhraseGroup[] = [];
    for (const objective of this.objectives) {
      allTargetPhraseGroups = [...allTargetPhraseGroups, ...objective.targetPhraseGroups];
    }
    return allTargetPhraseGroups;
  }
  getGroupForTargetPhrase(uuid: string): TargetPhraseGroup | undefined {
    for (const objective of this.objectives) {
      const targetPhraseGroup = objective.getGroupForTargetPhrase(uuid);
      if (targetPhraseGroup) return targetPhraseGroup;
    }
  }
  getTargetPhraseGroupForSupplementaryWord(uuid: string): TargetPhraseGroup | undefined {
    for (const objective of this.objectives) {
      const targetPhraseGroup = objective.getTargetPhraseGroupForSupplementaryWord(uuid);
      if (targetPhraseGroup) return targetPhraseGroup;
    }
  }

  // Match destination
  getAllMatchDestinations() {
    return this.objectives.flatMap((objective) => objective.matchDestinations);
  }
  getAllLinkedMatchDestionationIds() {
    return this.getAllLinkedObjectiveIds()
      .map((uuid) => this.getObjective(uuid))
      .filter((objective) => !!objective)
      .flatMap((objective) => [
        ...objective!.targetPhraseGroups.map((targetPhraseGroup) => targetPhraseGroup.matchDestinationId),
        ...objective!.targetPhraseGroups.flatMap((targetPhraseGroup) =>
          targetPhraseGroup.contingencies.flatMap((contingency) => contingency.matchDestinationIds)
        ),
      ]);
  }
  getMatchDestination(uuid: string): MatchDestination | undefined {
    for (const objective of this.objectives) {
      const matchDestination = objective.getMatchDestination(uuid);
      if (matchDestination) return matchDestination;
    }
  }
  getMatchDestinationWithVideoClip(uuid: string): MatchDestination | undefined {
    for (const objective of this.objectives) {
      for (const targetPhraseGroup of objective.targetPhraseGroups) {
        const matchDestination = objective.getMatchDestination(targetPhraseGroup.matchDestinationId);
        if (matchDestination && matchDestination.includesVideoClip(uuid)) {
          return matchDestination;
        }
      }
    }
  }

  // Supplementary word
  getAllSupplementaryWords(): SupplementaryWord[] {
    let allSupplementaryWords: SupplementaryWord[] = [];
    for (const objective of this.objectives) {
      for (const targetPhraseGroup of objective.targetPhraseGroups) {
        allSupplementaryWords = [...allSupplementaryWords, ...targetPhraseGroup.supplementaryWords];
      }
    }
    return allSupplementaryWords;
  }
  getSupplementaryWordForVideoClip(uuid: string): SupplementaryWord | undefined {
    for (const supplementaryWord of this.getAllSupplementaryWords()) {
      if (supplementaryWord.destinationVideoClipId === uuid) return supplementaryWord;
    }
  }

  // Other
  getParentForVideoClip(uuid: string): TargetPhraseGroup | Objective | SupplementaryWord | undefined {
    for (const objective of this.objectives) {
      // Check for objective fail destination
      if (objective.failDestination.includesVideoClip(uuid)) return objective;

      for (const targetPhraseGroup of objective.targetPhraseGroups) {
        // Check for target phrase group match destination
        const matchDestination = objective.getMatchDestination(targetPhraseGroup.matchDestinationId);
        if (matchDestination && matchDestination.includesVideoClip(uuid)) {
          return targetPhraseGroup;
        }

        // Check for supplementary word destination
        const supplementaryWord = targetPhraseGroup.supplementaryWords.find((sw) => sw.destinationVideoClipId === uuid);
        if (supplementaryWord) return supplementaryWord;
      }
    }
  }
  getAllPossibleVideoClipIdsForContingency(contingency: Contingency) {
    // NOTE: DOESN'T INLUDE PLACEHOLDER ID
    let ids: string[] = [];
    contingency.matchDestinationIds.forEach((uuid) => {
      const matchDestination = this.getMatchDestination(uuid);
      ids.push(...(matchDestination ? matchDestination.getAllVideoClipIds() : []));
    });
    return ids;
  }

  // -- Mutations

  // Video clip
  updateVideoClip(updatedVideoClip: VideoClip) {
    for (const videoClip of this.videoClips) {
      if (videoClip.uuid === updatedVideoClip.uuid) {
        videoClip.update(updatedVideoClip);
      }
    }
  }
  deleteVideoClip(uuid: string) {
    const videoClip = this.getVideoClip(uuid);
    if (videoClip) {
      const parent = this.getParentForVideoClip(uuid);
      if (parent) {
        // Remove from parent match destination if needed
        if (parent instanceof TargetPhraseGroup) {
          const matchDestination = this.getMatchDestinationWithVideoClip(videoClip.uuid);
          if (matchDestination) matchDestination.removeDestination(videoClip.uuid);
        }
        // Remove parent supplementary word if needed
        if (parent instanceof SupplementaryWord) {
          const targetPhraseGroup = this.getTargetPhraseGroupForSupplementaryWord(parent.uuid);
          if (targetPhraseGroup) targetPhraseGroup.removeSupplementaryWord(parent.uuid);
        }
        // Remove from parent fail destination if needed
        if (parent instanceof Objective) {
          parent.failDestination.removeDestination(videoClip.uuid);
        }
      }

      // Delete objective if needed
      if (videoClip.objectiveId) {
        this.deleteObjective(videoClip.objectiveId);
      }

      // Remove from masterPlaylist if needed
      if (this.masterPlaylist.includes(uuid)) this.masterPlaylist = this.masterPlaylist.filter((id) => id !== uuid);

      // Delete video clip
      this.videoClips = this.videoClips.filter((vc) => vc.uuid !== uuid);
    }
  }
  addDestinationToVideoClip(uuid: string, isReprompt?: boolean) {
    const videoClip = this.getVideoClip(uuid);
    if (!!videoClip) {
      // Create success video clip
      const success = new VideoClip({
        title: "Success",
        sectionInfo: videoClip.sectionInfo,
        type: VideoClipType.SUCCESS,
        sectionId: videoClip.sectionId,
      });

      // Set light green on a reprompt success
      if (!Object.values(SectionType).includes(videoClip.type as string as SectionType)) {
        success.colorHex = kleoColorHex.lightGreenKleo;
      }

      // Create match destinaton and target phrase group
      const matchDestination = new MatchDestination({ successVideoClipId: success.uuid });
      const targetPhraseGroup = new TargetPhraseGroup({
        matchDestinationId: matchDestination.uuid,
      });

      // Create new objective if needed
      if (!videoClip.objectiveId) {
        // Create fail video clip
        const fail = new VideoClip({
          title: isReprompt ? "Reprompt" : "Exit",
          sectionInfo: videoClip.sectionInfo,
          type: VideoClipType.FAIL,
          sectionId: videoClip.sectionId,
          colorHex: isReprompt ? kleoColorHex.yellowKleo : null,
        });

        // Create fail destination
        const failDestination = new FailDestination({ failVideoClipId: fail.uuid });

        // Create the objective and target phrase group
        const objective = new Objective({
          matchDestinations: [matchDestination],
          targetPhraseGroups: [targetPhraseGroup],
          failDestination: failDestination,
        });

        // Add to the lesson component
        this.objectives.push(objective);
        this.videoClips.push(success, fail);
        videoClip.objectiveId = objective.uuid;

        // If the video clip was a transition, make it a section
        if (videoClip.type === VideoClipType.TRANSITION) videoClip.type = VideoClipType.SECTION;

        // Add objective to fail clip for reprompt
        if (isReprompt) this.addDestinationToVideoClip(fail.uuid);
      }
      // Add target phrase group to objective if an objective ex  ists
      else {
        const objective = this.getObjective(videoClip.objectiveId);
        if (objective) {
          // Add to the lesson component
          this.videoClips.push(success);
          objective.matchDestinations.push(matchDestination);
          objective.targetPhraseGroups.push(targetPhraseGroup);
        }
      }

      return targetPhraseGroup;
    }
  }
  addSection(sectionInfo: SectionInfo, preset: SectionPresetType, isGlobalVideoSection: boolean = false) {
    const getVideoClipType = () => {
      switch (preset) {
        case SectionPresetType.transition:
          return VideoClipType.TRANSITION;
        case SectionPresetType.placeholder:
          return VideoClipType.PLACEHOLDER;
        default:
          return VideoClipType.SECTION;
      }
    };

    // Create video clip
    const videoClip = new VideoClip({
      title: sectionInfo.title,
      sectionInfo: sectionInfo,
      type: getVideoClipType(),
    });
    videoClip.sectionId = videoClip.uuid;

    // Reset display item defaults
    videoClip.displayItems[0].primaryDisplayTime = null;
    if (!(this.type === LessonComponentType.DEEPDIVE || isGlobalVideoSection))
      videoClip.displayItems[0].secondaryDisplayTime = null;

    // Add video clip to lesson component and master playlist
    this.videoClips.push(videoClip);
    this.masterPlaylist.push(videoClip.uuid);

    // Add objective if needed
    switch (preset) {
      case SectionPresetType.transition:
        videoClip.displayItems[0].secondaryDisplayTime = null;
        break;
      case SectionPresetType.prompt:
        this.addDestinationToVideoClip(videoClip.uuid);
        break;
      case SectionPresetType.reprompt:
        this.addDestinationToVideoClip(videoClip.uuid, true);
        break;
    }
  }
  updateSection(uuid: string, sectionInfo: SectionInfo) {
    for (const videoClip of this.videoClips) {
      if (videoClip.sectionId === uuid) videoClip.sectionInfo = sectionInfo;
    }
  }
  populateGlobalVideoSections(globalVideoSections: GlobalVideoSection[], databaseName: string) {
    this.masterPlaylist = [];
    for (const globalVideoSection of globalVideoSections) {
      const gvsNames = globalVideoSection.getVideoNamePrefixs();
      if (gvsNames.includes(databaseName)) this.addGlobalVideoSection(globalVideoSection);
    }
  }
  addGlobalVideoSection(globalVideoSection: GlobalVideoSection) {
    this.masterPlaylist.push(globalVideoSection.sectionId);
    this.populateGlobalVideoSection(globalVideoSection);
  }

  // Objective
  updateObjective(updatedObjective: Objective) {
    for (const objective of this.objectives) {
      if (objective.uuid === updatedObjective.uuid) {
        objective.update(updatedObjective);
      }
    }
  }
  deleteObjective(uuid: string) {
    const objective = this.getObjective(uuid);
    const parent = this.getParentForObjective(uuid);
    if (objective && parent) {
      // Remove from parent video clip and update parent type if needed
      parent.objectiveId = null;
      if (parent.type === VideoClipType.SECTION) parent.type = VideoClipType.TRANSITION;

      // Delete fail video clips
      objective.failDestination.getAllVideoClipIds().forEach((uuidVideoClip) => this.deleteVideoClip(uuidVideoClip));

      // Delete target phrase groups
      objective.targetPhraseGroups.forEach((group) => this.deleteTargetPhraseGroup(group.uuid));

      // Delete objective
      this.objectives = this.objectives.filter((o) => o.uuid !== uuid);
    }
  }

  // Target phrase
  updateTargetPhrase(updatedTargetPhrase: TargetPhrase) {
    for (const objective of this.objectives) {
      for (const targetPhraseGroup of objective.targetPhraseGroups) {
        for (const targetPhrase of targetPhraseGroup.targetPhrases) {
          if (targetPhrase.uuid === updatedTargetPhrase.uuid) {
            targetPhrase.update(updatedTargetPhrase);
          }
        }
      }
    }
  }
  deleteTargetPhrase(uuid: string) {
    for (const objective of this.objectives) {
      for (const targetPhraseGroup of objective.targetPhraseGroups) {
        targetPhraseGroup.targetPhrases = targetPhraseGroup.targetPhrases.filter((tp) => tp.uuid !== uuid);
      }
    }
  }
  addTargetPhraseToGroup(uuid: string, targetPhrase: TargetPhrase) {
    const targetPhraseGroup = this.getTargetPhraseGroup(uuid);
    if (!targetPhraseGroup) return;
    targetPhraseGroup.addTargetPhrase(targetPhrase);
  }

  // Target phrase group
  updateTargetPhraseGroup(updatedTargetPhraseGroup: TargetPhraseGroup) {
    for (const objective of this.objectives) {
      for (const targetPhraseGroup of objective.targetPhraseGroups) {
        if (targetPhraseGroup.uuid === updatedTargetPhraseGroup.uuid) {
          targetPhraseGroup.update(updatedTargetPhraseGroup);
        }
      }
    }
  }
  deleteTargetPhraseGroup(uuid: string) {
    const targetPhraseGroup = this.getTargetPhraseGroup(uuid);
    if (targetPhraseGroup) {
      for (const videoClip of this.videoClips) {
        // Delete match video clips
        const matchDestination = this.getMatchDestination(targetPhraseGroup.matchDestinationId);
        if (matchDestination) {
          if (matchDestination.includesVideoClip(videoClip.uuid)) {
            this.deleteVideoClip(videoClip.uuid);
          }
        }

        // Delete supplementary word video clips
        _.flatten(targetPhraseGroup.supplementaryWords.map((sw) => sw.destinationVideoClipId)).forEach(
          (uuidVideoClip) => this.deleteVideoClip(uuidVideoClip)
        );
      }

      // Delete target phrase group and objective if needed
      for (const objective of this.objectives) {
        if (objective.targetPhraseGroups.find((group) => group.uuid === uuid)) {
          objective.matchDestinations = objective.matchDestinations.filter(
            (md) => md.uuid !== targetPhraseGroup.matchDestinationId
          );
          objective.targetPhraseGroups = objective.targetPhraseGroups.filter((group) => group.uuid !== uuid);
          if (objective.targetPhraseGroups.length === 0) this.deleteObjective(objective.uuid);
        }
      }
    }
  }
  moveTargetPhraseToGroup(uuid: string, uuidGroup: string, index: number) {
    for (const objective of this.objectives) {
      const targetPhrase = objective.getTargetPhrase(uuid);
      if (targetPhrase) {
        // We know the target phrase is in this objective
        const fromGroup = objective.getGroupForTargetPhrase(uuid);
        const toGroup = this.getTargetPhraseGroup(uuidGroup);
        if (fromGroup && toGroup) {
          fromGroup.targetPhrases = fromGroup.targetPhrases.filter((tp) => tp.uuid !== uuid);
          toGroup.targetPhrases.splice(index, 0, targetPhrase);
        }
      }
    }
  }
  addSupplementaryWordToTargetPhraseGroup(supplementaryWord: SupplementaryWord, uuid: string) {
    const targetPhraseGroup = this.getTargetPhraseGroup(uuid);
    const section = this.getSectionForTargetPhraseGroup(uuid);
    if (targetPhraseGroup && section) {
      // Create new video clip
      const videoClip = new VideoClip({
        title: `${_.capitalize(supplementaryWord.type.toLowerCase())} ${supplementaryWord.text}`,
        sectionInfo: section.sectionInfo,
        type: supplementaryWord.type as string as VideoClipType,
        sectionId: section.sectionId,
      });

      // Add to lesson component, supplementary word, and target phrase group
      this.videoClips.push(videoClip);
      supplementaryWord.destinationVideoClipId = videoClip.uuid;
      targetPhraseGroup.addSupplementaryWord(supplementaryWord);
    }
  }
  addMatchDestinationToTargetPhraseGroup(uuid: string, matchType: MatchType) {
    const targetPhraseGroup = this.getTargetPhraseGroup(uuid);
    if (targetPhraseGroup) {
      const matchDestination = this.getMatchDestination(targetPhraseGroup.matchDestinationId);
      const section = this.getSectionForTargetPhraseGroup(uuid);
      if (matchDestination && section) {
        // Create video clip
        const videoClip = new VideoClip({
          title: _.capitalize(matchType.toLowerCase()),
          sectionInfo: section.sectionInfo,
          type: matchType as string as VideoClipType,
          sectionId: section.sectionId,
        });

        // Add to lesson component and match destination
        this.videoClips.push(videoClip);
        matchDestination.addDestination(videoClip.uuid, matchType);
      }
    }
  }
  addFailDestinationToTargetPhraseGroup(uuid: string, failType: FailType) {
    const objective = this.getObjectiveForTargetPhraseGroup(uuid);
    const section = this.getSectionForTargetPhraseGroup(uuid);
    if (objective && section) {
      // Create video clip
      const videoClip = new VideoClip({
        title: _.capitalize(failType.toLowerCase()),
        sectionInfo: section.sectionInfo,
        type: failType as string as VideoClipType,
        sectionId: section.sectionId,
      });

      // Add to lesson component and fail destination
      this.videoClips.push(videoClip);
      objective.failDestination.addDestination(videoClip.uuid, failType);
    }
  }
  moveTargetPhraseGroup(uuid: string, direction: "up" | "down") {
    const objective = this.getObjectiveForTargetPhraseGroup(uuid);
    if (!objective) return;
    const fromIndex = objective.targetPhraseGroups.findIndex((tfg) => tfg.uuid === uuid);
    const toIndex = fromIndex + (direction === "up" ? -1 : 1);
    const targetPhraseGroup = objective.targetPhraseGroups.splice(fromIndex, 1)[0];
    objective.targetPhraseGroups.splice(toIndex, 0, targetPhraseGroup);
  }

  // Supplementary word
  updateSupplementaryWord(updatedSupplementaryWord: SupplementaryWord) {
    for (const supplementaryWord of this.getAllSupplementaryWords()) {
      if (supplementaryWord.uuid === updatedSupplementaryWord.uuid) supplementaryWord.update(updatedSupplementaryWord);
    }
  }

  // Other
  populateGlobalVideoSection(globalVideoSection: GlobalVideoSection) {
    this.videoClips.push(...globalVideoSection.videoClips);
    this.objectives.push(...globalVideoSection.objectives);
  }
  updateContingencies(
    targetPhraseGroupUuid: string,
    originalPossibleVideoClipIds: string[][],
    contingencies: Contingency[],
    removedContingencies: Contingency[]
  ) {
    const updateForContingency = (originalPossibleVideoClipIds: string[], contingency: Contingency) => {
      // Compare all possible destination video clip ids of the original contingency to get the added ids and removed ids
      const possibleVideoClipIds = this.getAllPossibleVideoClipIdsForContingency(contingency);
      const removedIds = originalPossibleVideoClipIds.filter((id) => !possibleVideoClipIds.includes(id));
      const addedIds = possibleVideoClipIds.filter((id) => !originalPossibleVideoClipIds.includes(id));

      // Remove all the ids added to the contingency from the master playlist
      addedIds.forEach((id) => {
        const index = this.masterPlaylist.indexOf(id);
        if (id !== contingency.placeholderId && index && this.masterPlaylist[index]) {
          this.masterPlaylist.splice(index, 1);
        }
      });

      // Add all the ids removed from the contingency back into the master playlist at the index of the placeholder
      const index = this.masterPlaylist.indexOf(contingency.placeholderId);
      removedIds.forEach((id) => this.masterPlaylist.splice(index + 1, 0, id));
    };

    const targetPhraseGroup = this.getTargetPhraseGroup(targetPhraseGroupUuid);
    if (!targetPhraseGroup) return;

    // Update contingencies in target phrase group
    targetPhraseGroup.contingencies = contingencies;

    // Add back the video clip ids of all removed contingencies to masterPlaylist at their placeholder index
    removedContingencies.forEach((contingency) => {
      const allPossibleVideoClipIds = this.getAllPossibleVideoClipIdsForContingency(contingency);
      const index = this.masterPlaylist.indexOf(contingency.placeholderId);
      allPossibleVideoClipIds.forEach((id) => {
        if (!this.masterPlaylist.includes(id)) this.masterPlaylist.splice(index + 1, 0, id);
      });
    });

    // Update for each contingency
    contingencies.forEach((contingency, index) =>
      updateForContingency(originalPossibleVideoClipIds[index] ?? [], contingency)
    );
  }
}
