import { v4 as uuidv4 } from "uuid";

import { Variant, StreamPath } from ".";
import { copyS3 } from "../../../service/StorageSerivce";
import { spaceStripped } from "../../../service/helpers";
import { EnvironmentArgument } from "../../../service/PushService";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
  ValidationArgument,
  Validatable,
} from "../../../service/Validation";
import { CodecType } from "./StreamPath";
import { Changeable } from "../../../service/Change";

export enum VideoType {
  mp4 = "mp4",
}

export default class Video implements Validatable, Changeable {
  id: string;
  name: string;
  type: VideoType;
  inputS3Bucket: string;
  inputS3Path: string;
  inputS3Region: string;
  inputS3Status: string;
  outputS3Bucket: string;
  outputS3Path: string;
  outputS3Region: string;
  outputS3Status: string;
  localeId: string;
  duration: number;
  cloudfrontBaseUrl: string;
  backupBaseUrl: string;
  streamPaths: StreamPath[];
  variants: Variant[];
  hasChanged: boolean;
  updatedAt: string;

  constructor(object: Partial<Video> = {}) {
    this.id = object.id ?? uuidv4();
    this.name = object.name ?? "";
    this.type = object.type ?? VideoType.mp4;
    this.inputS3Bucket = object.inputS3Bucket ?? "";
    this.inputS3Path = object.inputS3Path ?? "";
    this.inputS3Region = object.inputS3Region ?? "";
    this.inputS3Status = object.inputS3Status ?? "";
    this.outputS3Bucket = object.outputS3Bucket ?? "";
    this.outputS3Path = object.outputS3Path ?? "";
    this.outputS3Region = object.outputS3Region ?? "";
    this.outputS3Status = object.outputS3Status ?? "";
    this.localeId = object.localeId ?? "";
    this.duration = object.duration ?? 0;
    this.cloudfrontBaseUrl = object.cloudfrontBaseUrl ?? "";
    this.backupBaseUrl = object.backupBaseUrl ?? "";
    this.streamPaths = object.streamPaths ? object.streamPaths.map((sp) => new StreamPath(sp)) : [];
    this.variants = object.variants ? object.variants.map((v) => new Variant(v)) : [];
    this.hasChanged = object.hasChanged ?? false;
    this.updatedAt = object.updatedAt ?? new Date().toISOString();
  }
  getIdentifier(): string {
    return `${this.name} - ${this.id}`;
  }
  getTypeName() {
    return "Video";
  }

  async toEnv(fromEnv: EnvironmentArgument, toEnv: EnvironmentArgument, push: boolean = false) {
    this.cloudfrontBaseUrl = toEnv.baseUrl;
    if (push) await copyS3(this.outputS3Path, fromEnv.bucket, toEnv.bucket);
    this.outputS3Bucket = toEnv.bucket;
    return this;
  }

  validate({ lessonComponents, globalVideoSections, locales, files, env }: ValidationArgument) {
    const validation = new Validation({
      type: ValidationType.Video,
      instance: this,
      issues: [],
      childValidations: [],
    });

    const isUsed = [...lessonComponents, ...globalVideoSections].some((component) =>
      component.getAllVideoNames().includes(this.name)
    );

    this.streamPaths.forEach((streamPath) => validation.childValidations.push(streamPath.validate()));
    this.variants.forEach((variant) =>
      validation.childValidations.push(variant.validate(this.cloudfrontBaseUrl, files, env))
    );

    if (this.name.split("_").length !== 3)
      validation.issues.push(
        new ValidationIssue(
          "Field name must have 2 underscores",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    if (!(this.name === spaceStripped(this.name)))
      validation.issues.push(
        new ValidationIssue(
          "Field name must not have leading, trailing, or double spaces",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );
    if (this.name.includes("audio") || this.name.includes("video"))
      validation.issues.push(
        new ValidationIssue(
          `Field name cannot contain "audio" or "video"`,
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    if (isUsed && this.hasChanged)
      validation.issues.push(
        new ValidationIssue(
          "Video must be verified (hasChanged must be false)",
          ValidationIssueGravity.error,
          ValidationIssueCause.userInput
        )
      );

    // if (!this.variants.some((v) => v.codec === CodecType.h265))
    //   validation.issues.push(new Issue("Must have an h265 variant", GravityType.error, IssueFixType.internal));

    if (!this.variants.some((v) => v.codec === CodecType.h264))
      validation.issues.push(
        new ValidationIssue("Must have an h264 variant", ValidationIssueGravity.warning, ValidationIssueCause.internal)
      );

    if (!locales.map((locale) => locale.id).includes(this.localeId))
      validation.issues.push(
        new ValidationIssue(
          "Field localeId must reference a locale",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    if (5.95 < this.duration && this.duration < 6.05 && this.variants.some((v) => v.videoPaths.length > 1)) {
      new ValidationIssue(
        "May have a problematic segment split (at 6 seconds)",
        ValidationIssueGravity.warning,
        ValidationIssueCause.internal
      );
    }

    return validation;
  }

  getSourceUrl(codec: CodecType = CodecType.h264) {
    const streamPath = this.streamPaths.find((streamPath) => streamPath.codec === codec);
    if (!streamPath) return;
    return this.cloudfrontBaseUrl + streamPath.path;
  }
}
