import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useEffect } from "react";

import { getComponent, updateCurrentComponent } from "../../core/redux/componentSlice";
import {
  fetchGlobalVideoSections,
  getAllGlobalVideoSections,
  getGlobalVideoSectionsError,
  getGlobalVideoSectionsStatus,
  updateGlobalVideoSection,
} from "../../core/redux/globalVideoSectionsSlice";
import {
  globalVideoSectionFilter,
  globalVideoSectionExactFilter,
  globalVideoSectionByWordFilter,
} from "../../service/helpers";
import { GlobalVideoSection } from "../../core/domain/entities";
import { getCurrentLocale } from "../../core/redux/localesSlice";
import { StatusType } from "../../core/redux/types";

import { Modal, SearchView, Card, StatusView, CheckBoxInput } from "../index";
import Button from "../base/Button";

export default function SelectGlobalVideoSectionModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);
  const globalVideoSections = useSelector(getAllGlobalVideoSections);
  const globalVideoSecionsStatus = useSelector(getGlobalVideoSectionsStatus);
  const globalVideoSecionsError = useSelector(getGlobalVideoSectionsError);
  const currentLocale = useSelector(getCurrentLocale);

  // -- Effect
  useEffect(() => {
    if (currentLocale) dispatch(fetchGlobalVideoSections(currentLocale));
  }, [dispatch, currentLocale]);

  // -- Components
  const GlobalVideoSectionCard = ({ globalVideoSection }: { globalVideoSection: GlobalVideoSection }) => {
    const handleAddGlobalVideoSection = () => {
      lessonComponent!.addGlobalVideoSection(globalVideoSection);
      dispatch(updateCurrentComponent(lessonComponent!));
      onClose();
    };

    const toggleIsChecked = (isChecked: boolean) => {
      globalVideoSection.isChecked = isChecked;
      dispatch(updateGlobalVideoSection(globalVideoSection));
    };

    return (
      <Card className="cursor-pointer flex gap-2 items-center" onClick={handleAddGlobalVideoSection}>
        <CheckBoxInput value={globalVideoSection.isChecked} set={toggleIsChecked} />
        <div>
          <div className="text-xs font-bold">{globalVideoSection.title}</div>
          <div className="text-xs text-gray-400 mt-0.5">{globalVideoSection.id}</div>
        </div>
      </Card>
    );
  };

  return (
    <Modal isOpen={isOpen} title={"Add Global Video Section"} onClose={onClose}>
      <div className="flex flex-col gap-5">
        <StatusView status={globalVideoSecionsStatus} error={globalVideoSecionsError}>
          <SearchView
            items={globalVideoSections}
            containsFilter={globalVideoSectionFilter}
            exactFilter={globalVideoSectionExactFilter}
            wordFilter={globalVideoSectionByWordFilter}
            noneFoundMessage="No global video sections found"
            limit={100}
          >
            {(globalVideoSecions, indicator) => (
              <div className="bg-gray-100 rounded-md p-5 h-96 overflow-scroll flex flex-col gap-2">
                {!indicator
                  ? globalVideoSecions.map((globalVideoSection) => (
                      <GlobalVideoSectionCard key={globalVideoSection.id} globalVideoSection={globalVideoSection} />
                    ))
                  : indicator}
              </div>
            )}
          </SearchView>
        </StatusView>
        <Button shade={100} onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
