import { v4 as uuidv4 } from "uuid";
import { Locale } from ".";
import Validation, {
  ValidationIssueGravity,
  ValidationIssue,
  ValidationIssueCause,
  ValidationType,
} from "../../../service/Validation";

export default class Skill {
  id: string;
  localeId: string;
  parentSkillId?: string;
  childSkillIds: string[];
  title: string;
  groupsCanAccess: string[];

  constructor(object: Partial<Skill> = {}) {
    this.id = object.id ?? uuidv4();
    this.localeId = object.localeId ?? "";
    this.parentSkillId = object.parentSkillId;
    this.childSkillIds = object.childSkillIds ?? [];
    this.title = object.title ?? "";
    this.groupsCanAccess = ["free"];
  }

  validate(skills: Skill[], locales: Locale[]) {
    const validation = new Validation({
      type: ValidationType.Skill,
      instance: this,
      issues: [],
      childValidations: [],
    });

    if (
      !skills.find((skill) => skill.id === this.parentSkillId) &&
      skills.find((skill) => skill.childSkillIds.includes(this.id))
    )
      validation.issues.push(
        new ValidationIssue(
          "Field parentSkillId must reference a skill",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    for (const id of this.childSkillIds)
      if (!skills.find((skill) => skill.id === id))
        validation.issues.push(
          new ValidationIssue(
            "Each item in the field childSkillIds must reference a skill",
            ValidationIssueGravity.error,
            ValidationIssueCause.internal,
            id
          )
        );

    if (!locales.map((locale) => locale.id).includes(this.localeId))
      validation.issues.push(
        new ValidationIssue(
          "Field localeId must reference a locale",
          ValidationIssueGravity.error,
          ValidationIssueCause.internal
        )
      );

    return validation;
  }
}
