import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  remoteDataSource,
  useCaseCreatePhrase,
  useCaseGetAllPhrases,
  useCaseGetPhrases,
  useCaseUpdatePhrase,
} from "..";
import { Failure, Success } from "../../service/api/Outcome";
import { kleoWords, updateObjectInList } from "../../service/helpers";
import { Locale, Phrase } from "../domain/entities";
import { RootState } from "./store";
import { StatusType } from "./types";

export const fetchPhrases = createAsyncThunk(`phrases/fetch`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.phrases.items.length > 0 && state.phrases.items[0].localeId !== locale.id;
  if (state.phrases.items.length > 0 && (!localeDidChange || state.phrases.status !== StatusType.loading))
    return state.phrases.items;

  const result = await useCaseGetPhrases.execute(locale);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const fetchProdPhrases = createAsyncThunk(`phrases/fetchProd`, async (locale: Locale, { getState }) => {
  const state = getState() as RootState;

  const localeDidChange = state.phrases.prodItems.length > 0 && state.phrases.prodItems[0].localeId !== locale.id;
  if (state.phrases.prodItems.length > 0 && (!localeDidChange || state.phrases.status !== StatusType.loading))
    return state.phrases.prodItems;

  const result = await remoteDataSource.allPhrases(locale, "prod");

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const fetchAllPhrases = createAsyncThunk(`allPhrases/fetch`, async () => {
  const result = await useCaseGetAllPhrases.execute();

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const createPhrase = createAsyncThunk(`phrases/create`, async (phrase: Phrase, { getState }) => {
  const state = getState() as RootState;

  // Check if there is an existing phrase with the same target and native text
  const existingPhrase = state.phrases.items.find(
    (p) => p.targetText === phrase.targetText && p.nativeText === phrase.nativeText
  );
  if (existingPhrase) {
    alert(`Cannot create phrase. A phrase with the same target and native text already exists`);
    throw `Cannot create phrase. A phrase with the same target and native text already exists`;
  }

  // Autopopulate fields
  if (phrase.intermediateTimeout === new Phrase().determineIntermediateTimeout())
    phrase.intermediateTimeout = phrase.determineIntermediateTimeout();
  phrase.wordCount = kleoWords(phrase.targetText).length;

  // Generate audioUrl
  await phrase.generatePollyAudio(state.locales.current!);

  const result = await useCaseCreatePhrase.execute(phrase);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

export const updatePhrase = createAsyncThunk(`phrases/update`, async (phrase: Phrase, { getState }) => {
  // Update updatedBy to the current user
  const state = getState() as RootState;
  phrase.updatedBy = state.users.current.attributes.sub;
  phrase.updatedAt = new Date().toISOString();

  // Update audioUrl if needed
  const existingPhrase = state.phrases.items.find((p) => p.id === phrase.id);
  if (existingPhrase) {
    if (existingPhrase.targetText.toLowerCase() != phrase.targetText.toLowerCase())
      await phrase.generatePollyAudio(state.locales.current!);
    phrase.wordCount = kleoWords(phrase.targetText).length;
  }

  const result = await useCaseUpdatePhrase.execute(phrase);

  if (result instanceof Success) {
    return result.data;
  } else if (result instanceof Failure) {
    throw result.error;
  }
});

// export const deletePhrase = createAsyncThunk(`phrases/delete`, async (phrase: Phrase) => {
//   if (window.confirm(`Are you sure you want to delete the Phrase: ${phrase.targetText}?`)) {
//     const result = await useCaseDeletePhrase.execute(phrase);

//     if (result instanceof Success) {
//       return result.data;
//     } else if (result instanceof Failure) {
//       throw result.error;
//     }
//   } else throw "Cancelled";
// });

const initialState = {
  items: [] as Phrase[],
  status: StatusType.idle,
  error: null as string | undefined | null,
  prodItems: [] as Phrase[],
  prodStatus: StatusType.idle,
  prodError: null as string | undefined | null,
  allItems: [] as Phrase[],
  allStatus: StatusType.idle,
  allError: null as string | undefined | null,
};

export const phrasesSlice = createSlice({
  name: "phrases",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPhrases.pending, (state) => {
      state.status = StatusType.loading;
    });
    builder.addCase(fetchPhrases.fulfilled, (state, { payload: phrases }: { payload: Phrase[] }) => {
      state.status = StatusType.succeeded;
      state.items = phrases;
    });
    builder.addCase(fetchPhrases.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.error = action.error.message;
    });
    builder.addCase(fetchProdPhrases.pending, (state) => {
      state.prodStatus = StatusType.loading;
    });
    builder.addCase(fetchProdPhrases.fulfilled, (state, { payload: phrases }: { payload: Phrase[] }) => {
      state.prodStatus = StatusType.succeeded;
      state.prodItems = phrases;
    });
    builder.addCase(fetchProdPhrases.rejected, (state, action) => {
      state.prodStatus = StatusType.errored;
      state.prodError = action.error.message;
    });
    builder.addCase(fetchAllPhrases.pending, (state) => {
      state.allStatus = StatusType.loading;
    });
    builder.addCase(fetchAllPhrases.fulfilled, (state, { payload: phrases }: { payload: Phrase[] }) => {
      state.allStatus = StatusType.succeeded;
      state.allItems = phrases;
    });
    builder.addCase(fetchAllPhrases.rejected, (state, action) => {
      state.status = StatusType.errored;
      state.allError = action.error.message;
    });
    builder.addCase(createPhrase.fulfilled, (state, { payload: phrase }: { payload: Phrase }) => {
      state.items = [...state.items, phrase];
    });
    builder.addCase(createPhrase.rejected, () => {
      alert("Error! Phrase was not created. Cheeck logs for more info");
    });
    builder.addCase(updatePhrase.fulfilled, (state, { payload: phrase }: { payload: Phrase }) => {
      state.items = updateObjectInList(state.items, phrase);
    });
    builder.addCase(updatePhrase.rejected, () => {
      alert("Error! Phrase was not updated. Cheeck logs for more info");
    });
    // builder.addCase(deletePhrase.fulfilled, (state, { payload: phrase }: { payload: Phrase }) => {
    //   state.items = state.items.filter((item) => item.id !== phrase.id);
    // });
    // builder.addCase(deletePhrase.rejected, () => {
    //   alert("Error! Phrase was not deleted. Cheeck logs for more info");
    // });
  },
});

export const getAllPhrases = (state: RootState) => state.phrases.items;
export const getPhrasesStatus = (state: RootState) => state.phrases.status;
export const getPhrasesError = (state: RootState) => state.phrases.error;
export const getAllProdPhrases = (state: RootState) => state.phrases.prodItems;
export const getProdPhrasesStatus = (state: RootState) => state.phrases.prodStatus;
export const getProdPhrasesError = (state: RootState) => state.phrases.prodError;
export const getAllPhrasesNoLocale = (state: RootState) => state.phrases.allItems;
export const getAllPhrasesNoLocaleStatus = (state: RootState) => state.phrases.allStatus;
export const getAllPhrasesNoLocaleError = (state: RootState) => state.phrases.allError;

export default phrasesSlice.reducer;
