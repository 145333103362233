import { useState } from "react";
import { DotsHorizontal, Duplicate, PencilAlt, XCircle } from "heroicons-react";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { updatePhrase } from "../../core/redux/phrasesSlice";
import { copyToClipboard, parseDateTime, updateObject } from "../../service/helpers";
import { openModal } from "../../core/redux/modalSlice";
import { Phrase } from "../../core/domain/entities";
import { ButtonType } from "../../components/base/Button";

import {
  Button,
  Dropdown,
  DropdownItem,
  IsHiddenButton,
  IsWordButton,
  CheckBoxInput,
  IsDojoButton,
} from "../../components";
import ValidationIndicator from "../base/ValidationIndicator";

export default function PhraseCard({ phrase, showImage }: { phrase: Phrase; showImage?: boolean }) {
  // -- Redux
  const dispatch = useDispatch();

  // -- Local state
  const [isHidden, setIsHidden] = useState(phrase.isHidden);
  const [isWord, setIsWord] = useState(phrase.isWord);
  const [isChecked, setIsChecked] = useState(phrase.isChecked);
  const [isDojo, setIsDojo] = useState(phrase.isDojo);

  const [optionsDropdownIsOpen, setOptionsDropdownIsOpen] = useState(false);

  // -- Functions
  // const handleDeletePhrase = () => {
  //   dispatch(deletePhrase(phrase));
  // };

  const toggleIsChecked = (value: boolean) => {
    setIsChecked(value);
    dispatch(
      updatePhrase(
        updateObject(phrase, {
          isChecked: value,
        })
      )
    );
  };
  const toggleIsHidden = (value: boolean) => {
    setIsHidden(value);
    dispatch(
      updatePhrase(
        updateObject(phrase, {
          isHidden: value,
        })
      )
    );
  };
  const toggleIsWord = (value: boolean) => {
    setIsWord(value);
    dispatch(
      updatePhrase(
        updateObject(phrase, {
          isWord: value,
        })
      )
    );
  };
  const toggleIsDojo = (value: boolean) => {
    setIsDojo(value);
    dispatch(
      updatePhrase(
        updateObject(phrase, {
          isDojo: value,
        })
      )
    );
  };

  const openEditModal = () => {
    dispatch(
      openModal({
        phraseModal: {
          phrase: _.cloneDeep(phrase),
        },
      })
    );
  };

  const openOptionsDropdown = () => {
    setOptionsDropdownIsOpen(true);
  };
  const closeOptionsDropdown = () => {
    setOptionsDropdownIsOpen(false);
  };

  // -- Components
  // const OptionsDropdown = () => {
  //   return (
  //     <Dropdown isOpen={optionsDropdownIsOpen} onClose={closeOptionsDropdown}>
  //       <DropdownItem onClick={openEditModal}>
  //         <PencilAlt size={16} />
  //         Edit
  //       </DropdownItem>
  //       {/* <DropdownItem color="red" shade={500} onClick={handleDeletePhrase}>
  //         <XCircle size={16} />
  //         Delete
  //       </DropdownItem> */}
  //     </Dropdown>
  //   );
  // };

  return (
    <div className="relative">
      {/* <ValidationIndicator object={phrase} /> */}
      <div className="bg-white p-2 rounded-md flex gap-1 items-center">
        <IsHiddenButton isHidden={isHidden} onToggle={toggleIsHidden} />
        <IsWordButton isWord={isWord} onToggle={toggleIsWord} />
        <IsDojoButton isDojo={isDojo} onToggle={toggleIsDojo} />
        <CheckBoxInput className="mx-2" value={isChecked} set={toggleIsChecked} />
        <div className="flex-1 hover:text-indigo-600 cursor-pointer" onClick={openEditModal}>
          <div className="text-xs font-bold">{phrase.targetText}</div>
          <div className="text-xs text-gray-500">{phrase.nativeText}</div>
        </div>
        <div className="flex-1 flex gap-2 items-center text-gray-400">
          <Button displayType={ButtonType.pure} color="gray" onClick={() => copyToClipboard(phrase.id)}>
            <Duplicate size={16} />
          </Button>
          <div className="text-xs flex-1">{phrase.id}</div>
        </div>
        <div className="flex-1 text-xs text-gray-400">{parseDateTime(phrase.updatedAt)}</div>
        {showImage ? (
          <div className="flex-1">
            {showImage ? (
              <div>
                <div className="text-xs text-gray-400 text-center mb-2">{phrase.getImageName()}</div>
                <img className="rounded-md object-cover" src={phrase.imageUrl} alt="" />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="relative flex">
          <Button shade={100} onClick={openEditModal}>
            <PencilAlt size={16} />
          </Button>
          {/* <OptionsDropdown /> */}
        </div>
      </div>
    </div>
  );
}
