import { useState } from "react";
import { Ban, Clipboard, Duplicate, PencilAlt, Star, XCircle } from "heroicons-react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";

import { TargetPhrase, TargetPhraseGroup } from "../../core/domain/entities";
import {
  copyTargetPhrase,
  getCopiedTargetPhrase,
  getComponent,
  pasteTargetPhrase,
  updateCurrentComponent,
} from "../../core/redux/componentSlice";
import { getAllPhrases, getPhrasesError, getPhrasesStatus } from "../../core/redux/phrasesSlice";
import { openModal } from "../../core/redux/modalSlice";

import { Dropdown, DropdownItem, StatusView } from "..";

export default function TargetPhraseCard({
  targetPhrase,
  targetPhraseGroup,
  index,
}: {
  targetPhrase: TargetPhrase;
  targetPhraseGroup: TargetPhraseGroup;
  index: number;
}) {
  // -- Redux
  const dispatch = useDispatch();
  const lessonComponent = useSelector(getComponent);
  const coppiedTargetPhrase = useSelector(getCopiedTargetPhrase);
  const phrases = useSelector(getAllPhrases);
  const phrasesStatus = useSelector(getPhrasesStatus);
  const phrasesError = useSelector(getPhrasesError);

  // -- Local state
  const [optionsDropdownIsOpen, setOptionsDropdownIsOpen] = useState(false);

  // -- Functions
  const getPhraseText = () => {
    const phrase = phrases.find((phrase) => phrase.id === targetPhrase.rootPhraseId);
    if (!phrase) return "NO PHRASE";
    if (targetPhrase.acceptedTextId)
      return phrase.acceptedTexts.find((at) => at.uuid === targetPhrase.acceptedTextId)?.text ?? "";
    return phrase.targetText;
  };

  const handleDeleteTargetPhrase = () => {
    lessonComponent!.deleteTargetPhrase(targetPhrase.uuid);
    dispatch(updateCurrentComponent(lessonComponent!));
  };

  const toggleIsPrimary = () => {
    targetPhrase.isPrimary = !targetPhrase.isPrimary;
    lessonComponent!.updateTargetPhrase(targetPhrase);
    dispatch(updateCurrentComponent(lessonComponent!));
  };

  const copy = () => {
    dispatch(copyTargetPhrase(targetPhrase));
  };
  const paste = () => {
    if (coppiedTargetPhrase) {
      targetPhrase.paste(coppiedTargetPhrase);
      lessonComponent!.updateTargetPhrase(targetPhrase);
      dispatch(updateCurrentComponent(lessonComponent!));
      dispatch(pasteTargetPhrase());
    }
  };

  // UI
  const openOptionsDropdown = () => {
    setOptionsDropdownIsOpen(true);
  };
  const closeOptionsDropdown = () => {
    setOptionsDropdownIsOpen(false);
  };

  const openEditModal = () => {
    dispatch(
      openModal({
        targetPhraseModal: {
          targetPhrase: targetPhrase,
          targetPhraseGroup: targetPhraseGroup,
        },
      })
    );
  };

  // -- Components
  const OptionsDropdown = () => {
    return (
      <Dropdown className="-top-0 divide-y" isOpen={optionsDropdownIsOpen} onClose={closeOptionsDropdown}>
        <div>
          <DropdownItem onClick={openEditModal}>
            <PencilAlt size={16} />
            Edit
          </DropdownItem>
        </div>
        <div>
          <DropdownItem onClick={copy}>
            <Duplicate size={16} />
            Copy
          </DropdownItem>
          <DropdownItem onClick={paste}>
            <Clipboard size={16} />
            Paste
          </DropdownItem>
        </div>
        <div>
          <DropdownItem color="red" shade={500} onClick={handleDeleteTargetPhrase}>
            <XCircle size={16} />
            Delete
          </DropdownItem>
        </div>
      </Dropdown>
    );
  };

  const PrimaryInput = () => {
    return (
      <div
        className={`w-4 h-4 bg-white rounded-full text-gray-400 flex items-center justify-center transform transition hover:scale-125`}
        onClick={(e) => {
          e.stopPropagation();
          toggleIsPrimary();
        }}
      >
        {targetPhrase.isPrimary ? <Star size={12} /> : null}
      </div>
    );
  };

  return (
    <div>
      <Draggable key={targetPhrase.uuid} draggableId={targetPhrase.uuid} index={index}>
        {(provided) => (
          <div className="relative" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div
              className={`
                rounded-md cursor-pointer p-2 
                text-white text-xs font-medium 
                transform transition hover:scale-105 hover:shadow-xl 
                ${
                  targetPhrase.isMistake
                    ? targetPhrase.acceptedTextId
                      ? "bg-red-300"
                      : "bg-red-500"
                    : targetPhrase.acceptedTextId
                    ? "bg-indigo-200 text-indigo-800"
                    : "bg-indigo-600"
                }
              `}
              onClick={openOptionsDropdown}
            >
              <StatusView status={phrasesStatus} error={phrasesError}>
                <div className="flex gap-1 items-center">
                  <div className="flex-1"> {getPhraseText()}</div>
                  {targetPhrase.isMistake ? <Ban size={16} /> : null}
                  <PrimaryInput />
                </div>
              </StatusView>
            </div>
            <OptionsDropdown />
          </div>
        )}
      </Draggable>
    </div>
  );
}
