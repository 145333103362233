import { Locale } from "./entities";
import Outcome from "../../service/api/Outcome";
import LocaleRepository from "./LocaleRepository";

export class UseCaseGetLocales {
  repository: LocaleRepository;
  constructor(repository: LocaleRepository) {
    this.repository = repository;
  }

  async execute(): Promise<Outcome<Locale[]>> {
    return this.repository.allLocales();
  }
}
