import { FormikProps } from "formik";
import { DocumentDuplicate, X } from "heroicons-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, SearchView, StatusView, UploadView } from "..";
import { Phrase } from "../../core/domain/entities";
import { getAllLocales, getCurrentLocale } from "../../core/redux/localesSlice";
import {
  fetchAllPhrases,
  getAllPhrasesNoLocale,
  getAllPhrasesNoLocaleError,
  getAllPhrasesNoLocaleStatus,
} from "../../core/redux/phrasesSlice";
import { environments } from "../../service/constants";
import {
  copyToClipboard,
  phraseNativeByWordSearchFilter,
  phraseNativeExactSearchFilter,
  phraseNativeSearchFilter,
} from "../../service/helpers";
import { getNextSuffix } from "../../service/StorageSerivce";
import { PhraseFormValues } from "./PhraseModal";

export default function SelectPhraseImage({
  phraseId,
  formProps,
}: {
  phraseId: string;
  formProps: FormikProps<PhraseFormValues>;
}) {
  const { values, setFieldValue } = formProps;

  // -- Redux
  const dispatch = useDispatch();
  const allPhrases = useSelector(getAllPhrasesNoLocale);
  const allPhrasesStatus = useSelector(getAllPhrasesNoLocaleStatus);
  const allPhrasesError = useSelector(getAllPhrasesNoLocaleError);
  const locales = useSelector(getAllLocales);
  const currentLocale = useSelector(getCurrentLocale);

  // -- Local state
  const [loadingHasStarted, setLoadingHasStarted] = useState(false);

  // -- Functions
  function startLoading() {
    dispatch(fetchAllPhrases());
    setLoadingHasStarted(true);
  }

  // -- Components
  const PhraseImageCard = ({ imageUrl, phrases }: { imageUrl: string; phrases: Phrase[] }) => {
    return (
      <div className="flex flex-col rounded-md shadow-lg relative">
        <Button className="absolute top-2 right-2" onClick={() => selectImage(imageUrl)}>
          Use
        </Button>
        <img src={imageUrl} className="rounded-t-md" />
        <div className="p-3 w-full">
          {phrases.map((phrase) => (
            <div className="text-sm flex justify-between">
              <div>{phrase.nativeText}</div>
              <div className="text-gray-500">
                {locales.find((l) => l.id === phrase.localeId)?.title ?? "Locale not found"}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // -- Functions
  function selectImage(imageUrl: string) {
    setFieldValue("imageUrl", imageUrl);
  }

  function deselectImage() {
    setFieldValue("imageUrl", null);
  }

  const getPhraseImageGroups = (phrases: Phrase[]) => {
    const groups: {
      imageUrl: string;
      phrases: Phrase[];
    }[] = [];
    for (const phrase of phrases) {
      const existingGroup = groups.find((group) => group.imageUrl === phrase.imageUrl);
      if (!existingGroup) {
        groups.push({ imageUrl: phrase.imageUrl!, phrases: [phrase] });
      } else {
        existingGroup.phrases.push(phrase);
      }
    }

    return groups;
  };

  return (
    <div className="flex flex-col gap-2 mt-2">
      <div className="text-xs font-medium">Current Image</div>
      {values.imageUrl ? (
        <div className="relative max-w-md">
          <img className="rounded-md" src={values.imageUrl} />
          <Button className="absolute top-2 right-2" onClick={deselectImage} color="red" shade={100}>
            <X size={16} />
          </Button>
          <div className="text-gray-500 text-xs mt-2">{values.imageUrl}</div>
        </div>
      ) : (
        <div className="text-xs text-gray-500">No image</div>
      )}
      <div className="text-xs font-medium">Select Image</div>
      {loadingHasStarted ? (
        <StatusView status={allPhrasesStatus} error={allPhrasesError}>
          <SearchView
            items={allPhrases.filter((p) => p.imageUrl)}
            containsFilter={phraseNativeSearchFilter}
            exactFilter={phraseNativeExactSearchFilter}
            wordFilter={phraseNativeByWordSearchFilter}
            limit={100}
          >
            {(phrases, indicator) => {
              return (
                <div
                  className="overflow-scroll gap-2 p-2 flex flex-col 2xl:grid 2xl:grid-cols-2"
                  style={{ height: 600 }}
                >
                  {!indicator
                    ? getPhraseImageGroups(phrases).map((group) => (
                        <PhraseImageCard imageUrl={group.imageUrl} phrases={group.phrases} />
                      ))
                    : indicator}
                </div>
              );
            }}
          </SearchView>
        </StatusView>
      ) : (
        <Button shade={200} onClick={startLoading}>
          Start loading
        </Button>
      )}
      <UploadView
        url={values.imageUrl}
        setUrl={selectImage}
        accept="image/*"
        showUrl={false}
        path={`images/${currentLocale!.formattedCode}/phrase`}
        fileName={phraseId + getNextSuffix(values.imageUrl)}
      />
      <div className="flex gap-2">
        <Button
          className="flex-1"
          shade={100}
          onClick={() =>
            copyToClipboard(
              formProps.values.audioUrl?.replace(
                environments.staging.cloudfrontUrl,
                environments.staging.cloudinaryUrl
              ) ?? ""
            )
          }
        >
          <DocumentDuplicate size={16} />
          Staging url
        </Button>
        <Button
          className="flex-1"
          shade={100}
          onClick={() =>
            copyToClipboard(
              formProps.values.audioUrl?.replace(environments.staging.cloudfrontUrl, environments.prod.cloudinaryUrl) ??
                ""
            )
          }
        >
          <DocumentDuplicate size={16} />
          Prod url
        </Button>
      </div>
    </div>
  );
}
