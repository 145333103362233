import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";

import { pagePaths } from "../../App";
import { StatusType } from "../../core/redux/types";
import { ButtonType } from "../base/Button";
import { Locale } from "../../core/domain/entities";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLocales,
  getLocalesStatus,
  getLocalesError,
  getCurrentLocale,
  selectLocale,
  fetchLocales,
} from "../../core/redux/localesSlice";
import { deselectUser } from "../../core/redux/userSlice";
import * as logs from "../../service/logs";

import { Button, SelectMenu, StatusView } from "..";
import { fetchPhrases } from "../../core/redux/phrasesSlice";
import { fetchVideos } from "../../core/redux/videosSlice";
import { fetchGlobalVideoSections } from "../../core/redux/globalVideoSectionsSlice";
import { fetchLessons } from "../../core/redux/lessonsSlice";
import { fetchLessonComponents } from "../../core/redux/lessonComponentsSlice";
import { fetchDisplaySkills } from "../../core/redux/displaySkillSlice";

export default function Header({
  pages,
}: {
  pages: {
    title: string;
    path: string;
    isHidden?: boolean;
  }[];
}) {
  // -- Navigation
  const history = useHistory();
  const location = useLocation();

  // -- Constants
  const env = process.env.REACT_APP_ENV;

  // -- Redux
  const dispatch = useDispatch();

  const locales = useSelector(getAllLocales);
  const localesStatus = useSelector(getLocalesStatus);
  const localesError = useSelector(getLocalesError);
  const currentLocale = useSelector(getCurrentLocale);

  // -- Effect
  useEffect(() => {
    dispatch(fetchLocales());
  }, [dispatch]);

  // Fetch data if needed
  useEffect(() => {
    if (currentLocale) {
      dispatch(fetchPhrases(currentLocale));
      dispatch(fetchVideos(currentLocale));
      dispatch(fetchGlobalVideoSections(currentLocale));
      dispatch(fetchLessons(currentLocale));
      dispatch(fetchLessonComponents(currentLocale));
      dispatch(fetchDisplaySkills(currentLocale));
    }
  }, [dispatch, currentLocale]);

  // -- Functions
  const signOut = async () => {
    await Auth.signOut();
    dispatch(deselectUser);
    logs.notSignedIn();
    history.push(pagePaths.signIn);
  };

  const goTo = (path: string) => {
    history.push(path);
  };

  if (!pages.find((page) => page.path === location.pathname)?.isHidden) {
    return (
      <div className="sticky top-0 w-screen p-5 bg-white shadow-sm z-10 flex items-center justify-between">
        <div className="flex gap-2 justify-start">
          {pages.map((page) => {
            if (!page.isHidden) {
              return (
                <Button
                  key={page.path}
                  className="text-sm"
                  displayType={ButtonType.text}
                  selected={location.pathname === page.path}
                  onClick={() => goTo(page.path)}
                >
                  {page.title}
                </Button>
              );
            }
            return null;
          })}
        </div>
        <div className="flex gap-2 items-center">
          <div
            className={`mr-5 text-xs font-medium ${(() => {
              switch (env) {
                case "prod":
                  return "text-lg font-bold text-red-500";
                case "dev":
                  return "text-red-500";
              }
            })()}`}
          >
            {env}
          </div>
          <StatusView status={localesStatus} error={localesError}>
            {locales && currentLocale ? (
              <SelectMenu value={currentLocale.id} set={(value) => dispatch(selectLocale(value))}>
                {locales.map((locale: Locale) => (
                  <option key={locale.id} value={locale.id}>
                    {locale.title}
                  </option>
                ))}
              </SelectMenu>
            ) : null}
          </StatusView>
          <Button onClick={signOut}>Sign Out</Button>
        </div>
      </div>
    );
  }

  return null;
}
