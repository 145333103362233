import { v4 as uuidv4 } from "uuid";
import { Changeable } from "../../../service/Change";
import { EnvironmentArgument } from "../../../service/PushService";
import Validation, {
  Validatable,
  ValidationArgument,
  ValidationIssue,
  ValidationIssueCause,
  ValidationIssueGravity,
  ValidationType,
} from "../../../service/Validation";
import GlobalVideoSection from "./GlobalVideoSection";
import LessonComponent from "./LessonComponent";
import { LessonComponentDisplayType, LessonComponentType } from "./types";
import VocabComponent from "./VocabComponent";
import VocabSet from "./VocabSet";

export default class Section implements Changeable {
  id: string;
  title: string;
  orderIndex: number;
  unitIds: string[];
  localeId: string;

  constructor(object: Partial<Section> = {}) {
    this.id = object.id ?? uuidv4();
    this.title = object.title ?? "";
    this.orderIndex = object.orderIndex ?? 0;
    this.unitIds = object.unitIds ?? [];
    this.localeId = object.localeId ?? "";
  }

  getIdentifier(): string {
    return `${this.title} - ${this.id}`;
  }
  getTypeName() {
    return "Section";
  }

  async toEnv(_0: EnvironmentArgument, _1: EnvironmentArgument, _2: boolean = false) {
    return this;
  }
}
