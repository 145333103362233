import { ChevronLeft, ChevronRight, Search, X } from "heroicons-react";
import { useState, useEffect } from "react";

import { SearchType } from "../../service/helpers";
import { Input, SelectMenu, Button } from "..";

export default function SearchView<T>({
  className,
  items,
  initialSearchText = "",
  initialSearchType = SearchType.contains,
  containsFilter,
  exactFilter,
  wordFilter,
  extraFilter,
  noneFoundMessage,
  limit,
  fixed,
  extraFilterComponent,
  containerComponent,
  children,
}: {
  className?: string;
  items: T[];
  initialSearchText?: string;
  initialSearchType?: SearchType;
  containsFilter?: (item: T, searchText: string) => boolean;
  exactFilter?: (item: T, searchText: string) => boolean;
  wordFilter?: (item: T, searchText: string) => boolean;
  extraFilter?: (item: T, searchText: string) => boolean;
  noneFoundMessage?: string;
  limit?: number;
  fixed?: boolean;
  extraFilterComponent?: any;
  containerComponent?: any;
  children: (items: T[], indicator?: any) => any;
}) {
  // -- Local state
  const [searchText, setSearchText] = useState(initialSearchText);
  const [searchType, setSearchType] = useState(initialSearchType);
  const [page, setPage] = useState(0);
  const [filteredItems, setFilteredItems] = useState(items);

  // -- Effect
  useEffect(() => {
    const filteredItems = items
      .filter((item) => {
        switch (searchType) {
          case SearchType.contains:
            return containsFilter ? containsFilter(item, searchText) : items;
          case SearchType.exact:
            return exactFilter ? exactFilter(item, searchText) : items;
          case SearchType.word:
            return wordFilter ? wordFilter(item, searchText) : items;
        }
      })
      .filter((item) => {
        return extraFilter ? extraFilter(item, searchText) : items;
      });
    setFilteredItems(limit ? filteredItems.slice(limit * page, limit * (page + 1)) : filteredItems);
  }, [searchText, searchType, page, items, extraFilter]);

  // -- Functions
  const handleSearchTextChange = (value: string) => {
    setPage(0);
    setSearchText(value);
  };

  const handleSearchTypeChange = (value: SearchType) => {
    setPage(0);
    setSearchType(value);
  };

  const paginateLeft = () => {
    setPage((prev) => (prev - 1 > 0 ? prev - 1 : 0));
  };
  const paginateRight = () => {
    setPage((prev) => prev + 1);
  };

  // -- Components

  const IndicatorView = () => {
    const indicator = (
      <div className="text-xs text-center self-center text-gray-400">{noneFoundMessage ?? "None found"}</div>
    );
    return children([], indicator);
  };

  const LimitIndicator = () => {
    if (limit && filteredItems.length > limit)
      return (
        <div className="text-xs text-center self-center text-gray-400 my-5">{`Showing 1-${limit} of ${filteredItems.length}`}</div>
      );
    return null;
  };

  const Container = ({ children }: { children: any }) => {
    return containerComponent ? (
      containerComponent({ children: children })
    ) : (
      <div className="flex flex-col gap-2">{children}</div>
    );
  };

  return (
    <div className={`flex flex-col gap-2 ${className} relative flex-1`}>
      <div className="flex gap-2 items-center">
        <Input className="flex-1" inputClassName="pl-7" value={searchText} set={handleSearchTextChange}>
          <Search size={16} className="text-gray-500" />
        </Input>
        <SelectMenu value={searchType} set={handleSearchTypeChange}>
          {containsFilter ? <option value={SearchType.contains}>Contains</option> : null}
          {exactFilter ? <option value={SearchType.exact}>Exact</option> : null}
          {wordFilter ? <option value={SearchType.word}>By Word</option> : null}
        </SelectMenu>
        {extraFilterComponent ? extraFilterComponent : null}
        {/* <div className="flex justify-between gap-2"> */}
        <Button shade={100} onClick={paginateLeft}>
          <ChevronLeft size={16} />
        </Button>
        <div className="text-xs w-3 text-center">{page}</div>
        <Button shade={100} onClick={paginateRight}>
          <ChevronRight size={16} />
        </Button>
        {/* </div> */}
      </div>
      <div />
      <div className={`${fixed ? "overflow-scroll absolute top-12 -bottom-5 left-0 right-0 p-2 pb-5" : ""}`}>
        {filteredItems.length > 0 ? (
          <Container>
            {children(limit && filteredItems.length > limit ? filteredItems.slice(0, limit) : filteredItems)}
            <LimitIndicator />
          </Container>
        ) : (
          <IndicatorView />
        )}
      </div>
    </div>
  );
}
