import { Locale, GlobalVideoSection } from "./entities";
import Outcome from "../../service/api/Outcome";
import GlobalVideoSectionRepository from "./GlobalVideoSectionRepository";

export class UseCaseGetGlobalVideoSections {
  repository: GlobalVideoSectionRepository;
  constructor(repository: GlobalVideoSectionRepository) {
    this.repository = repository;
  }

  async execute(locale: Locale): Promise<Outcome<GlobalVideoSection[]>> {
    return this.repository.allGlobalVideoSections(locale);
  }
}

export class UseCaseCreateGlobalVideoSection {
  repository: GlobalVideoSectionRepository;
  constructor(repository: GlobalVideoSectionRepository) {
    this.repository = repository;
  }

  async execute(globalVideoSection: GlobalVideoSection): Promise<Outcome<GlobalVideoSection[]>> {
    return this.repository.createGlobalVideoSection(globalVideoSection);
  }
}

export class UseCaseUpdateGlobalVideoSection {
  repository: GlobalVideoSectionRepository;
  constructor(repository: GlobalVideoSectionRepository) {
    this.repository = repository;
  }

  async execute(globalVideoSection: GlobalVideoSection): Promise<Outcome<GlobalVideoSection[]>> {
    return this.repository.updateGlobalVideoSection(globalVideoSection);
  }
}

export class UseCaseDeleteGlobalVideoSection {
  repository: GlobalVideoSectionRepository;
  constructor(repository: GlobalVideoSectionRepository) {
    this.repository = repository;
  }

  async execute(globalVideoSection: GlobalVideoSection): Promise<Outcome<GlobalVideoSection[]>> {
    return this.repository.deleteGlobalVideoSection(globalVideoSection);
  }
}
