export default function CheckBoxInput({
  className,
  value,
  onChange,
  set,
  message,
  disabled,
  note,
  ...rest
}: {
  className?: string;
  value: boolean;
  onChange?: (e: any) => void;
  set?: (value: boolean) => void;
  message?: string;
  disabled?: boolean;
  note?: string;
  [key: string]: any;
}) {
  return (
    <div {...rest} className={`flex flex-col justify-center ${disabled ? "opacity-20" : ""} ${className}`}>
      <div className="flex gap-2 text-xs items-center">
        <input
          type="checkbox"
          checked={value}
          className="cursor-pointer w-5 h-5"
          onChange={(e) => {
            if (!disabled) {
              if (onChange) onChange(e);
              if (set) set(e.target.checked);
            }
          }}
          {...rest}
        />
        <p className="font-medium">{message}</p>
      </div>
      {note ? <div className="text-xs text-gray-400 mt-1.5">{note}</div> : null}
    </div>
  );
}
