import { Formik, Form, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Lesson } from "../../core/domain/entities";
import { createLesson, updateLesson } from "../../core/redux/lessonsSlice";

import { updateObject } from "../../service/helpers";

import { Modal, Input, FormErrorMessage, ModalFormButtons, UploadView } from "../index";
import { getCurrentLocale } from "../../core/redux/localesSlice";

export type LessonModalProps = {
  lesson: Lesson;
  isCreating?: boolean;
};

type LessonFormValues = {
  title: string;
  description: string;
};

export default function LessonModal({
  isOpen,
  lesson,
  isCreating,
  onClose,
}: {
  isOpen: boolean;
  lesson: Lesson;
  isCreating?: boolean;
  onClose: () => void;
}) {
  // -- Redux
  const dispatch = useDispatch();

  // -- Functions
  const onSubmit = (values: LessonFormValues) => {
    isCreating ? handleCreateLesson(updateObject(lesson, values)) : handleUpdateLesson(updateObject(lesson, values));
    onClose();
  };
  const handleCreateLesson = (lesson: Lesson) => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
    dispatch(createLesson(lesson));
  };
  const handleUpdateLesson = (set: Partial<Lesson>) => {
    dispatch(updateLesson(updateObject(lesson, set)));
  };

  return (
    <Modal isOpen={isOpen} title={isCreating ? "Create Lesson" : lesson.title} subtitle={lesson.id} onClose={onClose}>
      <Formik
        initialValues={{
          title: lesson.title,
          description: lesson.description,
        }}
        validate={(values) => {
          const errors: { title?: string; subtitle?: string } = {};
          if (!values.title) errors.title = "Cannot be blank";
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {(formProps) => (
          <Form className="grid grid-cols-2 gap-2">
            <div>
              <Input
                autoFocus
                title="Title"
                placeholder="Introduction: Quiero"
                name="title"
                value={formProps.values.title}
                onChange={formProps.handleChange}
              />
              <FormErrorMessage error={formProps.errors.title} />
            </div>
            <div>
              <Input
                title="Description"
                placeholder="Learn how to say what you want by using the verb Quiero"
                name="description"
                value={formProps.values.description}
                onChange={formProps.handleChange}
              />
              <FormErrorMessage error={formProps.errors.description} />
            </div>
            <div className="h-5" />
            <ModalFormButtons className="col-span-full" submitText={isCreating ? "Create" : "Done"} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
