import { Locale, Video } from "./entities";
import Outcome from "../../service/api/Outcome";
import VideoRepository from "./VideoRepository";

export class UseCaseGetVideos {
  repository: VideoRepository;
  constructor(repository: VideoRepository) {
    this.repository = repository;
  }

  async execute(locale: Locale): Promise<Outcome<Video[]>> {
    return this.repository.allVideos(locale);
  }
}

export class UseCaseUpdateVideo {
  repository: VideoRepository;
  constructor(repository: VideoRepository) {
    this.repository = repository;
  }

  async execute(video: Video): Promise<Outcome<Video[]>> {
    return this.repository.updateVideo(video);
  }
}

export class UseCaseDeleteVideo {
  repository: VideoRepository;
  constructor(repository: VideoRepository) {
    this.repository = repository;
  }

  async execute(video: Video): Promise<Outcome<Video[]>> {
    return this.repository.deleteVideo(video);
  }
}
